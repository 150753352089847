import { IconProps } from "./utils/utils";

const CircleCheckIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_269_5)">
        <path
          d="M9.9975 0C4.48107 0 -0.00250244 4.48357 -0.00250244 10C-0.00250244 15.5164 4.48107 20 9.9975 20C15.5139 20 19.9975 15.5164 19.9975 10C19.9975 4.48357 15.5139 0 9.9975 0ZM9.9975 18.9906C5.04445 18.9906 1.00689 14.9531 1.00689 10C1.00689 5.04695 5.04445 1.00939 9.9975 1.00939C14.9505 1.00939 18.9881 5.04695 18.9881 10C18.9881 14.9531 14.9505 18.9906 9.9975 18.9906Z"
          fill="black"
        />
        <path
          d="M7.7413 12.9162C7.3709 12.4756 7.07169 12.0447 6.78266 11.6274C6.51856 11.2479 6.28956 10.8424 6.09883 10.4165C5.97946 10.1581 5.92662 9.89643 6.1852 9.72819C6.92625 9.24608 7.07811 9.70642 7.4809 10.2405C7.71965 10.557 8.07171 11.0789 8.29231 11.4087C8.50302 11.7236 8.75989 11.1552 8.86244 10.9949C9.22691 10.4249 10.1742 9.02959 10.5656 8.51382C10.9373 8.02419 12.1368 6.65251 12.3604 6.43434C12.5508 6.24857 12.9526 5.84111 13.2229 6.06589C13.5068 6.30206 13.6456 6.751 13.446 7.04291C13.0983 7.5511 12.5523 8.03453 12.1744 8.51842C11.4096 9.49792 10.6855 10.5537 9.99043 11.605C9.75424 11.9623 9.42262 12.5606 9.21675 12.948C8.83163 13.6728 8.49337 13.8107 7.7413 12.9162Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_269_5">
          <rect width="20" height="20" fill="white" transform="translate(-0.00250244)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleCheckIcon;
