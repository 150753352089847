import { components } from "../../../api/schema";
import React, { Dispatch, useEffect, useState } from "react";
import * as policyUtils from "../../../utils/policyUtils";
import {
  DialogContentText,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Add, Delete } from "@mui/icons-material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import ScaleToZeroAwakeTrigger from "./ScaleToZeroAwakeTrigger";
import Tooltip from "../../../components/Tooltip";
import DaySelection from "../../../components/DaySelection";
import clsx from "clsx";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";

const PRODUCTION_POLICY_NAME = "production";

interface SchedulePolicyContentProps {
  selectedPolicyToUpdate: components["schemas"]["V1alpha1Policy"];
  setSelectedPolicyToUpdate: Dispatch<components["schemas"]["V1alpha1Policy"]>;
  policies: components["schemas"]["V1alpha1Policy"][];
  availableMetricsList: components["schemas"]["V1alpha1MetricConf"][];
}

export default function SchedulePolicyContent(props: SchedulePolicyContentProps) {
  const defaultOverrider = () => {
    const overrider: components["schemas"]["V1alpha1Override"] = {
      period: {
        beginTime: "00:00",
        endTime: "23:59",
        days: [0, 1, 2, 3, 4, 5, 6],
      },
      policyOverriderName: "",
    };
    return overrider;
  };
  const [selectedPolicyToUpdate, setSelectedPolicyToUpdate] = useState<components["schemas"]["V1alpha1Policy"]>(
    props.selectedPolicyToUpdate
  );

  useEffect(() => {
    props.setSelectedPolicyToUpdate(selectedPolicyToUpdate);
  }, [selectedPolicyToUpdate]);

  const [scheduleIndex] = useState<number | undefined>(undefined);

  const [metricsConfList] = useState<components["schemas"]["V1alpha1MetricConfWithValue"][] | undefined>(undefined);
  const [scaleZeroConfigOpen, setScaleZeroConfigOpen] = useState<boolean>(false);

  const newPolicyOverrider = () => {
    const updated = { ...selectedPolicyToUpdate };
    policyUtils.addPolicyScheduleOverride(updated, defaultOverrider());
    setSelectedPolicyToUpdate(updated);
  };

  const deletePolicyOverrider = (index: number) => {
    const updated = { ...selectedPolicyToUpdate };
    policyUtils.deletePolicyScheduleOverride(updated, index);
    setSelectedPolicyToUpdate(updated);
  };

  function buildTextFieldForTimeSelection(
    index: number,
    value: string,
    label: string,
    schedule: components["schemas"]["V1alpha1Override"],
    updateTimeFunction: (scheduleToUpdate: components["schemas"]["V1alpha1Override"], newTimeValue: string) => void
  ) {
    return (
      <TimePicker
        label={label}
        ampm={false}
        inputFormat={"HH:mm"}
        value={adjustedDayjs(value, "HH:mm")}
        onChange={(newValue: Dayjs | null) => {
          if (newValue != null) {
            updateTimeFunction(schedule, newValue.format("HH:mm"));
            const update = { ...selectedPolicyToUpdate };
            if (update.spec?.policySchedule?.overrides?.at(index) != undefined) {
              update.spec.policySchedule.overrides[index] = schedule;
              setSelectedPolicyToUpdate(update);
            }
          }
        }}
        renderInput={(params) => <TextField size="small" sx={{ width: "110px" }} {...params} />}
      />
    );
  }

  function updateSchedulePeriodBeginTime(scheduleOverride: components["schemas"]["V1alpha1Override"], newTime: string) {
    scheduleOverride.period.beginTime = newTime;
  }

  function updateSchedulePeriodEndTime(scheduleOverride: components["schemas"]["V1alpha1Override"], newTime: string) {
    scheduleOverride.period.endTime = newTime;
  }

  return (
    <Box>
      <DialogContentText marginTop={1} fontSize={20}>
        Schedule
      </DialogContentText>
      {selectedPolicyToUpdate.spec?.policySchedule?.overrides?.map(
        (schedule: components["schemas"]["V1alpha1Override"], index) => {
          if (
            schedule.period.scaleToZeroEnabled &&
            (!schedule.policyOverriderName || schedule.policyOverriderName.length === 0)
          ) {
            schedule.policyOverriderName = PRODUCTION_POLICY_NAME;
          }

          return (
            <Box
              sx={{
                marginBottom: 3,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                border: 0,
                borderBottom: 1,
                borderStyle: "dotted",
                borderColor: "secondary.main",
              }}
            >
              <Box sx={{ m: 1, maxWidth: "236px" }}>
                <DaySelection
                  onChange={(event: React.MouseEvent<HTMLElement>, newDays: number[]) => {
                    const updated = { ...selectedPolicyToUpdate };
                    if (updated.spec?.policySchedule?.overrides?.at(index) != undefined) {
                      updated.spec.policySchedule.overrides[index].period.days = newDays;
                    }
                    setSelectedPolicyToUpdate(updated);
                  }}
                  selectedDays={schedule.period.days}
                />
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ m: 1 }}>
                  {buildTextFieldForTimeSelection(
                    index,
                    schedule.period.beginTime,
                    "From",
                    schedule,
                    updateSchedulePeriodBeginTime
                  )}
                </Box>
                <Box sx={{ m: 1 }}>
                  {buildTextFieldForTimeSelection(
                    index,
                    schedule.period.endTime,
                    "To",
                    schedule,
                    updateSchedulePeriodEndTime
                  )}
                </Box>
              </LocalizationProvider>
              <Box sx={{ m: 1 }}>
                <IconButton onClick={() => deletePolicyOverrider(index)}>
                  <Delete style={{ padding: "0px" }} />
                </IconButton>
              </Box>
              <Box
                sx={{ m: 1 }}
                className={clsx({
                  "opacity-0": schedule.period.scaleToZeroEnabled,
                })}
              >
                <FormControl variant="outlined" sx={{ width: "242px" }}>
                  <InputLabel id="schedule-policy-override-label">Use Policy</InputLabel>
                  <Select
                    disabled={schedule.period.scaleToZeroEnabled}
                    displayEmpty
                    sx={{ maxHeight: "40px" }}
                    label="Use Policy"
                    labelId="schedule-policy-override-label"
                    defaultValue={schedule.policyOverriderName || props.policies[0].metadata?.name || "production"}
                    id="schedule-policy-override"
                    onChange={(e) => {
                      schedule.policyOverriderName = e.target.value;
                    }}
                  >
                    {props.policies
                      .filter((policy: components["schemas"]["V1alpha1Policy"]) => {
                        return policy.spec?.type != "Schedule";
                      })
                      .map((option: components["schemas"]["V1alpha1Policy"]) => (
                        <MenuItem value={option.metadata?.name} style={{ padding: "5px" }}>
                          {option.metadata?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ m: 1, marginBottom: "20px", borderBottom: "1px" }}>
                <FormControlLabel
                  label="Sleep Mode:"
                  labelPlacement="start"
                  control={
                    <Tooltip title={"Enable / Disable Sleep Mode"}>
                      <Switch
                        defaultChecked={schedule.period.scaleToZeroEnabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          const updated = { ...selectedPolicyToUpdate };
                          if (updated.spec?.policySchedule?.overrides?.at(index) != undefined) {
                            updated.spec.policySchedule.overrides[index].period.scaleToZeroEnabled = checked;
                          }
                          setSelectedPolicyToUpdate(updated);
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Box>
            </Box>
          );
        }
      )}
      <Box>
        <IconButton style={{ padding: "0px", paddingTop: "15px" }} onClick={() => newPolicyOverrider()} disableRipple>
          <Add style={{ padding: "0px", fontSize: "35px" }} />
        </IconButton>
      </Box>
      <ScaleToZeroAwakeTrigger
        policyMetricGoalsList={metricsConfList}
        key="awake_triggers"
        open={scaleZeroConfigOpen}
        onClose={() => {
          setScaleZeroConfigOpen(false);
        }}
        availableMetricsList={props.availableMetricsList}
        disabled={false}
        onChange={(metricsConfList: components["schemas"]["V1alpha1MetricConfWithValue"][]) => {
          const update = { ...selectedPolicyToUpdate };
          if (update.spec?.policySchedule?.overrides != undefined) {
            update.spec.policySchedule.overrides.map((value, index) => {
              if (index == scheduleIndex) {
                value.period.scaleFromZeroMetricTriggers = metricsConfList;
              }
              return value;
            });
          }
          setSelectedPolicyToUpdate(update);
        }}
        valueTitle={"Threshold"}
      />
    </Box>
  );
}
