import clsx from "clsx";
import { useField, FieldHookConfig } from "formik";
import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MultiSelect from "../MultiSelect";
import Tooltip from "../Tooltip";
import ErrorLabel from "./ErrorLabel";

type Props = {
  label?: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
  options: string[];
  isSearchable?: boolean;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  renderValue?: (selected: (string | undefined)[]) => string;
} & FieldHookConfig<(string | undefined)[]> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormMultiSelect = ({
  label,
  info,
  options,
  className,
  isSearchable,
  isExclude,
  setIsExclude,
  renderValue,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col gap-1 w-fit relative">
      {label && (
        <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
          {label}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
      )}
      <MultiSelect
        selected={field.value ?? []}
        className={clsx(className, "h-[34px] w-[192px]")}
        setSelected={(value) => {
          field.onChange({
            target: {
              name: field.name,
              value,
            },
          });
        }}
        options={options}
        isSearchable={isSearchable}
        isExclude={isExclude}
        setIsExclude={setIsExclude}
        renderValue={renderValue}
      />
      {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
    </div>
  );
};

export default FormMultiSelect;
