import { IconProps } from "./utils/utils";

const ScaleopsIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M7.94879 0.799988V9.97641L15.8963 14.5657V5.38755L7.94879 0.799988Z" fill="#B8B7FD" />
      <path d="M7.94837 0.799988L0 5.38755V14.5657L7.94837 9.97641V0.799988Z" fill="#FF507A" />
      <path d="M0 14.5657L7.94881 19.1546L15.8963 14.5657L7.94881 9.97641L0 14.5657Z" fill="#5353E0" />
      <path d="M0 14.5657L7.94881 19.1546L15.8963 14.5657L7.94881 9.97641L0 14.5657Z" fill="#5353E0" />
      <path d="M15.8968 0.799988V5.38842L19.872 7.68285V3.09399L15.8968 0.799988Z" fill="#B8B7FD" />
      <path d="M15.8963 0.799988L11.9224 3.09399V7.68285L15.8963 5.38842V0.799988Z" fill="#FF507A" />
      <path d="M11.9224 7.68286L15.8968 5.38843L19.872 7.68286L15.8981 9.97729L11.9224 7.68286Z" fill="#5353E0" />
      <path d="M11.9224 7.68286L15.8968 5.38843L19.872 7.68286L15.8981 9.97729L11.9224 7.68286Z" fill="#5353E0" />
    </svg>
  );
};

export default ScaleopsIcon;
