import dayjs from "dayjs";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import { OPTIMIZED_REQUEST } from "../../../../utils/namesUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import FreezeTooltipWarning from "../../../Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { ChartComponentKeys, ChartComponents, componentStyle } from "../utils";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";

const isNumber = (value: number) => {
  return typeof value === "number" && !isNaN(value);
};

interface CustomTooltipProps {
  active?: boolean;
  yTickFormatter?: (tick: string | number) => string;
  payload?: {
    payload: {
      podsAvgUsage: number;
      podsMaxUsage: number;
      podsP90Usage: number;
      currentRequest: number;
      originRequest: number;
      recommendedRequest: number;
      currentLimit: number;
      recommendedLimit: number;
      maxAllowed: number;
      minAllowed: number;
      timestamp: string;
    };
  }[];
  selectedChartComponents: ChartComponents[];
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
}

const CustomTooltip = ({
  active,
  payload,
  selectedChartComponents,
  yTickFormatter,
  tooltipTrigger,
  frozenTooltipType,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const {
      podsAvgUsage,
      podsMaxUsage,
      podsP90Usage,
      currentRequest,
      originRequest,
      recommendedRequest,
      currentLimit,
      // recommendedLimit,
      timestamp,
    } = payload[0].payload;

    const elements = [
      {
        key: ChartComponentKeys.AverageUsage,
        value: podsAvgUsage,
      },
      {
        key: ChartComponentKeys.PercentileUsage,
        value: podsP90Usage,
      },
      {
        key: ChartComponentKeys.MaxUsage,
        value: podsMaxUsage,
      },
      {
        key: ChartComponentKeys.RecommendedRequest,
        value: recommendedRequest,
        displayLabel: OPTIMIZED_REQUEST,
      },
      {
        key: ChartComponentKeys.CurrentRequest,
        value: currentRequest,
      },
      {
        key: ChartComponentKeys.OriginRequest,
        value: originRequest,
      },
      {
        key: ChartComponentKeys.CurrentLimit,
        value: currentLimit,
        isDashed: true,
      },
      // {
      //   key: ChartComponentKeys.RecommendedLimit,
      //   value: recommendedLimit,
      //   isDashed: true,
      // },
    ];

    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        <ChartTooltipTime timestamp={dayjs.unix(Number(timestamp))} />
        {elements.map(({ displayLabel, key, value, isDashed }) => {
          const label = ChartComponents[key];

          if (!selectedChartComponents.includes(label) || !isNumber(value)) return null;

          return (
            <ChartTooltipElement
              color={componentStyle[label].color}
              value={<>{yTickFormatter ? yTickFormatter(value) : value}</>}
              label={displayLabel ?? label}
              isDashed={isDashed}
            />
          );
        })}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
