import { useCallback, useEffect, useState } from "react";
import { ArrayParam, BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { components } from "../../../../api/schema";
import Tooltip from "../../../../components/Tooltip";
import {
  AGGREGATION_PARAMS,
  ALL_WORKLOADS_STRING,
  getAggregationValuesAsString,
  getAggregationValuesAsTooltipContent,
} from "../../../../components/WorkloadsAggregation/utils";
import { TableType } from "../utils";
import useAggregationFilters from "./hooks/useAggregationFilters";
import useShouldAddNamespaceFilter from "./hooks/useShouldAddNamespaceFilter";

interface Props {
  row: components["schemas"]["UtilsWorkloadCostReport"];
}

const WorkloadName = ({ row }: Props) => {
  const shouldAddNamespaceFilter = useShouldAddNamespaceFilter();
  const aggregationFilters = useAggregationFilters();

  const [displayValue, setDisplayValue] = useState<string>("");

  const [, setAnnotationsParams] = useQueryParam("annotations", ArrayParam);
  const [, setIsAnnotationExclude] = useQueryParam("isAnnotationExclude", BooleanParam);
  const [, setLogicalAnnotation] = useQueryParam("logicalAnnotation", BooleanParam);

  const [, setNameSpaceParams] = useQueryParam("namespaces", ArrayParam);
  const [, setIsNamespaceExclude] = useQueryParam("isNamespaceExclude", BooleanParam);

  const [, setClustersParams] = useQueryParam("clusters", ArrayParam);
  const [, setClusterFilters] = useQueryParam("clusterFilters", ArrayParam);
  const [groupByCluster] = useQueryParam("groupByCluster", withDefault(BooleanParam, true));
  const [, setIsClusterExclude] = useQueryParam("isClusterExclude", BooleanParam);

  const [, setLabelsParams] = useQueryParam("labels", ArrayParam);
  const [, setLogicalLabel] = useQueryParam("logicalLabel", BooleanParam);
  const [, setIsLabelExclude] = useQueryParam("isLabelExclude", BooleanParam);

  const [, setIsWorkloadTypeExclude] = useQueryParam("isWorkloadTypeExclude", BooleanParam);

  const [, setSelectedTable] = useQueryParam<TableType>("selectedTable");

  const handleClick = useCallback(() => {
    const annotationArray =
      row.annotations?.map((annotation) => `${String(annotation.key)}=${String(annotation.value)}`) ?? [];

    const labelsArray = row.labels?.map((label) => `${String(label.key)}=${String(label.value)}`) ?? [];
    const clusterName = row.clusterName;

    setAnnotationsParams(annotationArray);
    setNameSpaceParams([row.namespace]);
    setClustersParams([clusterName]);
    setLabelsParams(labelsArray);
    if (groupByCluster) {
      setClusterFilters([clusterName]);
    } else {
      setClusterFilters(undefined);
    }

    setIsAnnotationExclude(undefined);
    setLogicalAnnotation(undefined);
    setIsNamespaceExclude(undefined);
    setIsClusterExclude(undefined);
    setIsLabelExclude(undefined);
    setLogicalLabel(undefined);
    setIsWorkloadTypeExclude(undefined);

    setSelectedTable(TableType.Workloads);
  }, [row]);

  const tooltipContent = getAggregationValuesAsTooltipContent(row as AGGREGATION_PARAMS);

  useEffect(() => {
    const newDisplayValue = getAggregationValuesAsString(row as AGGREGATION_PARAMS, shouldAddNamespaceFilter);
    setDisplayValue(newDisplayValue);
  }, [aggregationFilters, shouldAddNamespaceFilter]);

  return (
    <div className="w-full h-full flex items-center" onClick={handleClick}>
      <Tooltip
        title={tooltipContent}
        className="w-full truncate cursor-pointer"
        maxWidth={600}
        disabled={displayValue === ALL_WORKLOADS_STRING}
      >
        {displayValue}
      </Tooltip>
    </div>
  );
};

export default WorkloadName;
