import MultiSelect from "../../components/MultiSelect";
import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SlackEnabledMultiCluster, SlackEnabledMultiClusterResponse } from "../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import useUpdateSlackConf from "./useUpdateSlackConf";

interface Props {
  widthClass?: string;
  disabled?: boolean;
}

const MultiClusterSlackEnabledSelector = ({ widthClass = "w-[368px]", disabled = false }: Props) => {
  const slackEnabledClusters = SlackEnabledMultiCluster();
  const { data, isLoading } = useQuery<SlackEnabledMultiClusterResponse, Error>({
    queryKey: [slackEnabledClusters.queryKey],
    queryFn: () => slackEnabledClusters.queryFn(),
  });

  const updateSlackConf = useUpdateSlackConf(undefined, true);

  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);
  const [knownClusters, setKnownClusters] = useState<string[]>([]);

  useEffect(() => {
    setSelectedClusters(data?.slackEnabled?.filter((d) => d.enabled).map((d) => d.clusterName) || []);
    setKnownClusters(data?.slackEnabled?.map((d) => d.clusterName) || []);
  }, [data]);

  // useEffect(() => {
  // }, [selectedClusters]);

  return (
    <div>
      <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
        Enable Slack Alerts
      </Typography>
      {isLoading ? (
        <div className="flex items-center justify-center w-full">
          <CircularProgress />
        </div>
      ) : (
        <MultiSelect
          disabled={disabled}
          options={knownClusters}
          selected={selectedClusters}
          setSelected={(selected) => {
            const addedClusters = selected.filter((cluster) => !selectedClusters.includes(cluster as string));
            const removedClusters = selectedClusters.filter((cluster) => !selected.includes(cluster));
            setSelectedClusters(selected as string[]);
            if (removedClusters.length > 0) {
              updateSlackConf.mutate({
                multiCluster: true,
                disabled: true,
                clusters: removedClusters,
              });
            }
            if (addedClusters.length > 0) {
              updateSlackConf.mutate({
                multiCluster: true,
                disabled: false,
                clusters: addedClusters as string[],
              });
            }
          }}
          className={`${widthClass} h-[36px]`}
          isSearchable
          renderValue={(selected: (string | undefined)[]) => {
            if (selected.length === data?.slackEnabled?.length) {
              return "All";
            }
            return `${selected.length} selected`;
          }}
        />
      )}
    </div>
  );
};
export default MultiClusterSlackEnabledSelector;
