import { AlertsSettingsResponse, UpdateMultiAlertsSettingsInput } from "../../api/fetcher";
import useUpdateAlertsSettings from "./useUpdateAlertsSettings";

type paramsTypes = "interval" | "step" | "threshold" | "window" | "enabled" | "severity";

interface Params {
  data: AlertsSettingsResponse | undefined;
  id: string;
  value: number | boolean | string;
  param: paramsTypes;
  isMultiCluster: boolean;
}

const setValueType = (param: paramsTypes, value: number | boolean | string): string => {
  switch (param) {
    case "interval":
    case "step":
    case "threshold":
    case "window":
      return String(Number(value));
    case "enabled":
      return String(Boolean(value));
    case "severity":
      return String(value);
    default:
      return String(value);
  }
};

const useUpdateParam = (onError?: () => void) => {
  const updateAlertsSettingsMulti = useUpdateAlertsSettings(false, onError);

  return ({ data, id, value, param, isMultiCluster }: Params) => {
    const allAlertsExceptAlertToUpdate = data?.alerts?.filter((alert) => alert.name !== id);
    const alertToUpdate = data?.alerts?.find((alert) => alert.name === id);
    if (alertToUpdate?.alertSettings && allAlertsExceptAlertToUpdate) {
      const input: UpdateMultiAlertsSettingsInput = {
        multiCluster: isMultiCluster,
        modifications: [
          {
            key: param,
            name: alertToUpdate.name || "",
            value: {
              raw: setValueType(param, value),
            },
          },
        ],
      };
      return updateAlertsSettingsMulti.mutate(input);
    }
  };
};

export default useUpdateParam;
