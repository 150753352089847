import { IconProps } from "./utils/utils";

const FavoriteIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.9463 7.65875C19.7922 7.18452 19.3319 6.8585 18.6834 6.76428L13.5408 6.01696L11.2409 1.35701C10.9509 0.76934 10.4986 0.432343 10 0.432343C9.50142 0.432343 9.04915 0.769379 8.75914 1.35701L6.45938 6.01692L1.31672 6.76428C0.668194 6.8585 0.207914 7.18448 0.0538097 7.65871C-0.100255 8.13293 0.0804898 8.66724 0.549716 9.12459L4.27095 12.7519L3.39246 17.8738C3.27695 18.5475 3.47316 18.9504 3.65808 19.1698C3.87442 19.4264 4.19001 19.5677 4.54673 19.5677C4.81646 19.5677 5.10365 19.4886 5.40026 19.3326L10.0001 16.9143L14.5998 19.3325C14.8965 19.4885 15.1837 19.5676 15.4535 19.5676C15.8102 19.5676 16.1259 19.4263 16.3422 19.1697C16.5271 18.9504 16.7233 18.5475 16.6078 17.8737L15.7291 12.7519L19.4504 9.12463C19.9196 8.66724 20.1004 8.13297 19.9463 7.65875ZM18.6324 8.28543L14.6905 12.1277C14.5524 12.2623 14.4894 12.4563 14.522 12.6464L15.4527 18.0718C15.4827 18.2466 15.4675 18.3513 15.4533 18.3957C15.418 18.3957 15.3174 18.3858 15.1452 18.2952L10.2728 15.7337C10.1874 15.6888 10.0937 15.6664 10.0001 15.6664C9.90646 15.6664 9.81279 15.6888 9.72744 15.7337L4.85494 18.2953C4.68291 18.3857 4.5824 18.3957 4.54701 18.3957C4.53283 18.3513 4.51763 18.2466 4.54759 18.0718L5.47815 12.6463C5.51073 12.4562 5.44772 12.2623 5.30963 12.1277L1.36778 8.28536C1.24066 8.16145 1.19141 8.0677 1.17687 8.02356C1.21457 7.99641 1.30953 7.94949 1.48528 7.92394L6.93286 7.13229C7.12372 7.10456 7.28869 6.98467 7.37404 6.81174L9.81017 1.87553C9.88869 1.71639 9.96264 1.64056 10.0001 1.61306C10.0376 1.64056 10.1116 1.71639 10.1901 1.87557L12.6263 6.81174C12.7117 6.98467 12.8766 7.10456 13.0675 7.13229L18.515 7.92394C18.6907 7.94945 18.7857 7.99637 18.8234 8.02356C18.8088 8.06774 18.7595 8.16153 18.6324 8.28543Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavoriteIcon;
