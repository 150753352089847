import { FeatureEnabled } from "../../utils/FeaturesHelper";

export function GetMenuItems(extended: boolean) {
  if (FeatureEnabled("DemoVersion")) return DemoMenuHoursItem;
  if (extended) return ExtendedMenuHoursItem;
  return MenuHoursItem;
}

enum MenuHoursItem {
  "Last 12 hours" = "12h",
  "Last 1 day" = "24h",
  "Last 2 days" = "48h",
  "Last 4 days" = "96h",
  "Last 7 days" = "168h",
}

enum ExtendedMenuHoursItem {
  "Last 12 hours" = "12h",
  "Last 1 day" = "24h",
  "Last 2 days" = "48h",
  "Last 4 days" = "96h",
  "Last 7 days" = "168h",
  "Last 30 day" = "720h",
  "Last 90 days" = "2160h",
}

export enum DemoMenuHoursItem {
  "30 minutes" = "30m",
  "1 hour" = "1h",
  // "4 hours" = "4h",
  "12 hours" = "12h",
  "1 day" = "24h",
  "2 days" = "48h",
  "4 days" = "96h",
  "7 days" = "168h",
  "2 weeks" = "336h",
  "1 month" = "672h",
}
