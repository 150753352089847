import { useQuery } from "@tanstack/react-query";
import { GetScaleopsPodsHealth, GetScaleopsPodsHealthResponse } from "../api/fetcher";

const REFETCH_INTERVAL = 60000;

const { queryKey, queryFn } = GetScaleopsPodsHealth();

const useGetHealthCheck = () => {
  const response = useQuery<GetScaleopsPodsHealthResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
    refetchInterval: REFETCH_INTERVAL,
  });

  return response;
};

export default useGetHealthCheck;
