import { useEffect, useState } from "react";
import { ArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { DateType, getFromDate } from "../../../../Analytics/AnalyticsV2/utils";

export type FilterParamObject = {
  [key: string]: string[] | boolean | string | undefined | number;
};

export enum FilterOptions {
  Annotations = "annotations",
  ClusterFilters = "clusterFilters",
  IsAnnotationExclude = "isAnnotationExclude",
  IsClusterExclude = "isClusterExclude",
  IsLabelExclude = "isLabelExclude",
  IsNamespaceExclude = "isNamespaceExclude",
  IsWorkloadTypeExclude = "isWorkloadTypeExclude",
  Labels = "labels",
  LogicalAnnotation = "logicalAnnotation",
  LogicalLabel = "logicalLabel",
  MultiCluster = "groupByCluster",
  Namespaces = "namespaces",
  Tags = "tags",
  WorkloadTypes = "workloadTypes",
  shouldIgnoreWorkloads = "shouldIgnoreWorkloads",
  From = "from",
  To = "to",
  Range = "range",
}

const useWorkloadsFilters = (): FilterParamObject => {
  const [currentEpoch] = useState(Math.floor(Date.now() / 1000) * 1000);

  const [filters, setFilters] = useState<FilterParamObject>({});

  const [annotationsParams] = useQueryParam("annotations", ArrayParam);
  const [clusterFiltersParams] = useQueryParam("clusterFilters", ArrayParam);
  const [isAnnotationExcludeParams] = useQueryParam("isAnnotationExclude", NumberParam);
  const [isClusterExcludeParams] = useQueryParam("isClusterExclude", NumberParam);
  const [isLabelExcludeParams] = useQueryParam("isLabelExclude", NumberParam);
  const [isNamespaceExcludeParams] = useQueryParam("isNamespaceExclude", NumberParam);
  const [isWorkloadTypeExcludeParams] = useQueryParam("isWorkloadTypeExclude", NumberParam);
  const [labelsParams] = useQueryParam("labels", ArrayParam);
  const [logicalAnnotationParams] = useQueryParam("logicalAnnotation", StringParam);
  const [logicalLabelParams] = useQueryParam("logicalLabel", StringParam);
  const [multiClusterParams] = useQueryParam("multiCluster", NumberParam);
  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [tagsParams] = useQueryParam("tags", ArrayParam);
  const [workloadTypesParams] = useQueryParam("workloadTypes", ArrayParam);
  const [shouldIgnoreWorkloadsParams] = useQueryParam("shouldIgnoreWorkloads", NumberParam);
  const [fromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(7, currentEpoch)));
  const [toDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range] = useQueryParam("range", withDefault(StringParam, "7d"));
  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };

  useEffect(() => {
    const filtersObj: FilterParamObject = {};

    filtersObj[FilterOptions.Annotations] = annotationsParams ? annotationsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.ClusterFilters] = clusterFiltersParams
      ? clusterFiltersParams.map((value) => String(value))
      : [];
    filtersObj[FilterOptions.IsAnnotationExclude] = isAnnotationExcludeParams ? !!isAnnotationExcludeParams : undefined;
    filtersObj[FilterOptions.IsClusterExclude] = isClusterExcludeParams ? !!isClusterExcludeParams : undefined;
    filtersObj[FilterOptions.IsLabelExclude] = isLabelExcludeParams ? !!isLabelExcludeParams : undefined;
    filtersObj[FilterOptions.IsNamespaceExclude] = isNamespaceExcludeParams ? !!isNamespaceExcludeParams : undefined;
    filtersObj[FilterOptions.IsWorkloadTypeExclude] = isWorkloadTypeExcludeParams
      ? !!isWorkloadTypeExcludeParams
      : undefined;
    filtersObj[FilterOptions.Labels] = labelsParams ? labelsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.LogicalAnnotation] = logicalAnnotationParams ? logicalAnnotationParams : undefined;
    filtersObj[FilterOptions.LogicalLabel] = logicalLabelParams ? logicalLabelParams : undefined;
    filtersObj[FilterOptions.MultiCluster] = multiClusterParams ? multiClusterParams : undefined;
    filtersObj[FilterOptions.Namespaces] = namespacesParams ? namespacesParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.Tags] = tagsParams ? tagsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.WorkloadTypes] = workloadTypesParams
      ? workloadTypesParams.map((value) => String(value))
      : [];
    filtersObj[FilterOptions.shouldIgnoreWorkloads] = shouldIgnoreWorkloadsParams
      ? shouldIgnoreWorkloadsParams
      : undefined;
    filtersObj[FilterOptions.From] = date.from;
    filtersObj[FilterOptions.To] = date.to;
    filtersObj[FilterOptions.Range] = date.range;

    setFilters(filtersObj);
  }, [
    annotationsParams,
    clusterFiltersParams,
    isAnnotationExcludeParams,
    isClusterExcludeParams,
    isLabelExcludeParams,
    isNamespaceExcludeParams,
    isWorkloadTypeExcludeParams,
    labelsParams,
    logicalAnnotationParams,
    logicalLabelParams,
    multiClusterParams,
    namespacesParams,
    tagsParams,
    workloadTypesParams,
    shouldIgnoreWorkloadsParams,
    fromDate,
    toDate,
    range,
  ]);

  return filters;
};

export default useWorkloadsFilters;
