import { BooleanParam, useQueryParam } from "use-query-params";
import useGetClusterNames from "../MainMenu/useGetClusterNames";
import MultiSelectByQueryParams from "./MultiSelectByQueryParams";

const HAS_TAGS_FILTER = false;
interface Props {
  tagOptions: string[];
}
const MultiClusterFilters = ({ tagOptions }: Props) => {
  const clusterNames = useGetClusterNames();
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);

  return (
    <div className="flex items-center gap-4">
      {HAS_TAGS_FILTER && (
        <MultiSelectByQueryParams
          hasIsExclude={false}
          options={tagOptions}
          queryKey="tags"
          tooltipPrefix={
            isTagsExclude ? (
              <>
                tags <b>exclude: </b>
              </>
            ) : (
              <>
                tags <b>include: </b>
              </>
            )
          }
          hasVirtualizedList
        />
      )}
      <MultiSelectByQueryParams hasIsExclude={false} options={clusterNames} queryKey="clusters" hasVirtualizedList />
    </div>
  );
};

export default MultiClusterFilters;
