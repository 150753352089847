import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetRebalanceStatus, GetRebalanceStatusResponse, RebalanceSpec, SetRebalanceSpec } from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../../pages/Roles/mutations/utils";
import { CONSOLIDATION_TABLE_QUERY_PREFIX } from "./ConsolidateTable";
import useGetRebalanceQueryParams from "./useGetRebalanceQueryParams";
import { ConsolidationState } from "./utils";

const INVALIDATE_QUERY_DELAY_SECONDS = 3;

const setRebalanceSpec = SetRebalanceSpec();
const getRebalanceStatus = GetRebalanceStatus();

const useSetRebalanceSpec = () => {
  const queryClient = useQueryClient();
  const rebalanceQueryParams = useGetRebalanceQueryParams(CONSOLIDATION_TABLE_QUERY_PREFIX);

  return useMutation((spec: RebalanceSpec) => setRebalanceSpec.queryFn(spec), {
    onMutate: (spec: RebalanceSpec) => {
      queryClient.setQueryData(
        [getRebalanceStatus.queryKey, rebalanceQueryParams],
        (oldData: GetRebalanceStatusResponse | undefined) => {
          return {
            ...oldData,
            status: {
              ...oldData?.status,
              state: ConsolidationState.Pending,
            },
            spec: spec,
          };
        }
      );
    },
    onError: () => {
      toast.error(`Error while setting rebalance plan`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Rebalance plan was modified successfully", TOAST_SETTINGS);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([getRebalanceStatus.queryKey]);
      }, INVALIDATE_QUERY_DELAY_SECONDS * 1000);
    },
  });
};

export default useSetRebalanceSpec;
