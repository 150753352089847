import { Typography } from "@mui/material";

const ExploreYourWorkloadMessage = () => {
  return (
    <div className="relative">
      <Typography variant="caption" fontWeight={600}>
        Click to explore your workload
      </Typography>
      <p>Discover the power of ScaleOps by exploring your first workload</p>
    </div>
  );
};

export default ExploreYourWorkloadMessage;
