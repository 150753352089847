import { IconProps } from "./utils/utils";

const RightArrowWithFill = (props: IconProps & { outline?: string }) => {
  const { height = 20, width = 20, className, fill, outline = "black" } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path
          d="M12.5598 8.12658L10.6227 6.40196C9.84964 5.71176 9.78251 4.52557 10.4727 3.75254C11.1629 2.9795 12.3491 2.91237 13.1221 3.60257L18.7459 8.60148C19.5189 9.29015 19.5873 10.4751 18.8986 11.2482C18.8507 11.302 18.7997 11.3529 18.7459 11.4009L13.1221 16.3998C12.7786 16.7071 12.3334 16.8763 11.8724 16.8747C10.8371 16.8762 9.99656 16.0381 9.99508 15.0029C9.9943 14.4671 10.2227 13.9568 10.6227 13.6004L12.5598 11.8758L2.49945 11.8758C1.46413 11.8758 0.624863 11.0365 0.624863 10.0012C0.624863 8.96585 1.46413 8.12658 2.49945 8.12658L12.5598 8.12658Z"
          fill={fill}
        />
        <path
          d="M11.8724 17.4995C10.492 17.5012 9.37162 16.3835 9.37002 15.003C9.36916 14.2886 9.67409 13.608 10.2078 13.133L10.9176 12.5006L2.49945 12.5006C1.11905 12.5006 0 11.3816 0 10.0012C0 8.62078 1.11905 7.50173 2.49945 7.50173L10.9176 7.50173L10.2065 6.86937C9.17534 5.94968 9.085 4.36819 10.0047 3.33701C10.9244 2.30583 12.5059 2.2155 13.537 3.13518L19.1608 8.13409C20.1927 9.05104 20.2858 10.6308 19.3688 11.6627C19.3036 11.7361 19.2342 11.8056 19.1608 11.8708L13.537 16.8697C13.0787 17.2776 12.4859 17.5019 11.8724 17.4995ZM2.49945 8.75145C1.80925 8.75145 1.24973 9.31098 1.24973 10.0012C1.24973 10.6914 1.80925 11.2509 2.49945 11.2509L12.5598 11.2509C12.9049 11.2508 13.1847 11.5305 13.1848 11.8756C13.1848 12.0543 13.1084 12.2245 12.9747 12.3432L11.0388 14.0665C10.5234 14.5255 10.4776 15.3155 10.9366 15.831C10.9378 15.8323 10.9389 15.8336 10.9401 15.8349C11.4115 16.3259 12.1821 16.3696 12.706 15.9349L18.3297 10.936C18.8443 10.4708 18.8843 9.67656 18.4191 9.16199C18.3909 9.13071 18.361 9.10087 18.3297 9.0726L12.706 4.07369C12.1862 3.61953 11.3967 3.67272 10.9426 4.19249C10.4966 4.70285 10.5388 5.47569 11.0376 5.93453L12.9747 7.65915C13.2328 7.88821 13.2564 8.28312 13.0273 8.54127C12.9087 8.67495 12.7385 8.75145 12.5598 8.75142L2.49945 8.75145Z"
          fill={outline}
        />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightArrowWithFill;
