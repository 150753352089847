import { Typography } from "@mui/material";
import Tooltip from "../Tooltip";

export default function MakeRecommendedButton(props: { onClick: () => void }) {
  return (
    <div className="flex justify-center">
      <Tooltip title="Generate a recommendation for optimized node group configuration">
        <button
          className="flex items-center border border-black rounded px-4 bg-guideline-lessDarkPurple hover:bg-guideline-darkPurple text-white h-[42px] hover:cursor-pointer"
          onClick={props.onClick}
        >
          <Typography variant="h6">Make Recommendation</Typography>
        </button>
      </Tooltip>
    </div>
  );
}
