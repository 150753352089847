import { Typography } from "@mui/material";
import FormSingleSelectWithDescription from "../../../components/formComponents/FormSingleSelectWithDescription";

const options = [
  {
    value: "Ongoing",
    label: "Ongoing",
    description: (
      <>
        ScaleOps <b>continuously updates resources</b> to <br />
        ensure the pods get the right amount of resources at all times.
      </>
    ),
  },
  {
    value: "OnCreate",
    label: "Upon pod creation",
    description: (
      <>
        ScaleOps updates pods resources <b>on new pods creation</b>
        <br />
        and never changes them later.
      </>
    ),
  },
];

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  isCustomizedPolicy?: boolean;
  defaultValue?: string | undefined;
}

const ApplyingRecommendationOption = ({ label, name, disabled, isCustomizedPolicy, defaultValue }: Props) => {
  return (
    <div className="flex items-center gap-4 pl-3 mt-2">
      <div className="bg-mainMenuSelected h-[35px] w-[1px]" />
      <Typography variant="body2" className="min-w-[150px]">
        {label}:
      </Typography>
      <FormSingleSelectWithDescription
        name={name}
        options={options}
        disabled={disabled}
        className="w-[200px]"
        areOptionsDisabled={!isCustomizedPolicy}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default ApplyingRecommendationOption;
