import { Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

interface Props {
  onClick: () => void;
  isSelected: boolean;
  label: React.ReactNode;
  hasLeftBorder?: boolean;
  isFirstElement?: boolean;
  isLastElement?: boolean;
  className?: string;
}

const DateBox = ({ onClick, isSelected, label, hasLeftBorder, isFirstElement, isLastElement, className }: Props) => {
  return (
    <div
      onClick={onClick}
      className={clsx(className, "min-w-[50px] px-2 py-1 border border-border cursor-pointer flex justify-center", {
        "border-l-0": !hasLeftBorder,
        "bg-white hover:bg-guideline-lightGray": !isSelected,
        "bg-border": isSelected,
        "rounded-l-full": isFirstElement,
        "rounded-r-full": isLastElement,
      })}
    >
      <Typography fontWeight={isSelected ? "medium" : "normal"} variant="body2">
        {label}
      </Typography>
    </div>
  );
};

export default DateBox;
