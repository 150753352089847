import clsx from "clsx";

export enum ActiveChipVariant {
  Purple = "purple",
  White = "white",
  Yellow = "yellow",
  Orange = "orange",
}

interface Props {
  value: string | number | undefined;
  variant?: ActiveChipVariant;
}

const ActiveChip = ({ value, variant = ActiveChipVariant.Purple }: Props) => {
  if (value === undefined) return null;

  return (
    <div
      className={clsx("absolute top-[-8px] right-[-8px] rounded-full scaleopsShadow text-[10px] px-2 z-9", {
        "border border-border bg-white": variant === ActiveChipVariant.White,
        "text-white bg-primary-purpleBlue ": variant === ActiveChipVariant.Purple,
        "text-black bg-main-yellow": variant === ActiveChipVariant.Yellow,
        "text-black bg-main-orange": variant === ActiveChipVariant.Orange,
      })}
    >
      {value}
    </div>
  );
};

export default ActiveChip;
