import { ListItemText, MenuItem } from "@mui/material";
import SingleSelect from "../../../components/SingleSelect";
import { ObjectParam, useQueryParam } from "use-query-params";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams, ViewPeriodOptions } from "./utils";

const SelectViewPeriod = () => {
  const [urlDates, setUrlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

  return (
    <SingleSelect<string>
      label="View period"
      className="w-[150px]"
      selected={urlDates?.from ? ViewPeriodOptions.Custom : selectedViewPeriod}
      setSelected={(option) => {
        setSelectedViewPeriod(option);
        setUrlDates(undefined);
      }}
      renderOptionsFunc={() =>
        Object.entries(ViewPeriodOptions).map((option) => {
          if (!urlDates && option[1] === ViewPeriodOptions.Custom) return null;

          return (
            <MenuItem value={option[1]} key={option[1]}>
              <ListItemText primary={option[0]} />
            </MenuItem>
          );
        })
      }
    />
  );
};

export default SelectViewPeriod;
