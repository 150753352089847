import { CircularProgress, Typography } from "@mui/material";
import pluralize from "pluralize";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import { SCALEOPS_COLORS } from "../../../colors";
import Button, { BUTTON_VARIANTS } from "../../Button";
import ThreeDotsAnimation from "../../ThreeDotsAnimation";
import Tooltip from "../../Tooltip";
import useStartNodeRebalance from "./useStartNodeRebalance";
import useStopNodeConsolidation from "./useStopNodeConsolidation";
import { ConsolidationState, ROW } from "./utils";

const SHOW_IN_PROGRESS_PERCENTAGE = true;

const BUTTON_WIDTH = "w-[185px]";
const STATUS_BUTTON_CLASS_NAME =
  "flex justify-center items-center gap-3 p-2 h-[42px] border border-strongBorder rounded-lg";

const CancelingInProgress = () => (
  <div className={`${BUTTON_WIDTH} ${STATUS_BUTTON_CLASS_NAME}`}>
    <CircularProgress size={30} style={{ color: SCALEOPS_COLORS.text.darkGray }} />
    <Typography variant="body2">
      Canceling
      <span className="animate-ping">...</span>
    </Typography>
  </div>
);

const ConsolidateInProgress = ({ isProgressPercentage }: { isProgressPercentage: number | undefined }) => (
  <div className={`${BUTTON_WIDTH} ${STATUS_BUTTON_CLASS_NAME}`}>
    <CircularProgress size={30} style={{ color: SCALEOPS_COLORS.text.darkGray }} />
    {isProgressPercentage !== undefined && (
      <Typography variant="body2">
        {SHOW_IN_PROGRESS_PERCENTAGE && <span>{isProgressPercentage}% </span>} in progress
        <span className="animate-ping">...</span>
      </Typography>
    )}
  </div>
);

const RecalculateConsolidationPlan = () => (
  <div className="w-full">
    <div className="h-full w-fit px-4 flex gap-2 items-center justify-center border border-guideline-darkPurple rounded-lg">
      <Typography variant="body2" className="text-guideline-darkPurple">
        Recalculating consolidation plan
      </Typography>
      <ThreeDotsAnimation />
    </div>
  </div>
);

const ConsolidatingMessage = ({ candidates }: { candidates: number }) => (
  <div className="w-full">
    <div className="h-full w-fit px-4 flex gap-2 items-center justify-center border border-guideline-darkPurple rounded-lg">
      <Typography variant="body2" className="text-guideline-darkPurple">
        Consolidating <b>{candidates}</b> nodes
      </Typography>
      <ThreeDotsAnimation />
    </div>
  </div>
);

interface ButtonsContainerProps {
  state: ConsolidationState | undefined;
  numberOfSelectedRows: number;
  selectedRows: ROW[];
  allNodes?: number;
  successfulNodes?: number;
  inProgressNodes?: number;
  numberOfCandidates?: number;
}

const ButtonsContainer = ({
  state,
  numberOfSelectedRows,
  selectedRows,
  allNodes,
  successfulNodes,
  inProgressNodes,
  numberOfCandidates,
}: ButtonsContainerProps) => {
  const { userRole } = useGetUserRole();
  const startNodeRebalance = useStartNodeRebalance();
  const stopNodeConsolidation = useStopNodeConsolidation();

  const isRunning = state === ConsolidationState.Running || state === ConsolidationState.Verify;
  const isCompleted = state === ConsolidationState.Completed;
  const isCanceling = state === ConsolidationState.Canceled;
  const isPending = state === ConsolidationState.Pending;

  let isProgressPercentage = 0;
  if (allNodes) {
    const successfulPercentage = successfulNodes ? Math.round((successfulNodes / allNodes) * 100) : 0;
    const inProgressPercentage = inProgressNodes ? Math.round((inProgressNodes / allNodes / 2) * 100) : 0;
    isProgressPercentage = successfulPercentage + inProgressPercentage;
  }

  return (
    <div className="flex justify-end gap-4">
      {isPending && <RecalculateConsolidationPlan />}
      {isRunning && !!numberOfCandidates && <ConsolidatingMessage candidates={numberOfCandidates} />}
      {isCanceling ? (
        <CancelingInProgress />
      ) : (
        <Button
          label="CANCEL"
          className={BUTTON_WIDTH}
          variant={BUTTON_VARIANTS.mediumGray}
          disabled={!isRunning || userRole !== "Admin"}
          onClick={() => stopNodeConsolidation.mutate()}
        />
      )}
      {isRunning ? (
        <ConsolidateInProgress isProgressPercentage={isProgressPercentage} />
      ) : (
        <Tooltip
          title={
            numberOfSelectedRows
              ? `Consolidate ${numberOfSelectedRows} selected ${pluralize("node", numberOfSelectedRows)}`
              : "Select nodes to consolidate"
          }
          className="relative"
        >
          <Button
            label="CONSOLIDATE"
            className={BUTTON_WIDTH}
            variant={BUTTON_VARIANTS.mediumGreen}
            onClick={() =>
              startNodeRebalance.mutate({
                selectedNodes: selectedRows.filter((row) => !!row.name).map((row) => String(row.name)),
              })
            }
            disabled={
              !numberOfCandidates ||
              isCanceling ||
              isPending ||
              userRole !== "Admin" ||
              isCompleted ||
              !numberOfSelectedRows
            }
          />
          {!!numberOfSelectedRows && (
            <div className="absolute top-[-8px] right-[-10px] rounded-full bg-primary-purpleBlue scaleopsShadow text-white text-[10px] px-2">
              {numberOfSelectedRows}
            </div>
          )}
        </Tooltip>
      )}
    </div>
  );
};

export default ButtonsContainer;
