import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import PolicyCell from "../PolicyCell";

export interface PolicyNameColumnProps {
  overriddenWorkloadsIds: string[];
  isReadOnlyFrontEnd: boolean;
  policyNames: string[];
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

export default function PolicyNameColumn({
  params,
  overriddenWorkloadsIds,
  isReadOnlyFrontEnd,
  policyNames,
  scaleOpsProduct,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
} & PolicyNameColumnProps) {
  return (
    <PolicyCell
      params={params}
      overriddenWorkloadsIds={overriddenWorkloadsIds}
      isReadOnlyFrontEnd={isReadOnlyFrontEnd}
      policyNames={policyNames}
      scaleOpsProduct={scaleOpsProduct}
    />
  );
}
