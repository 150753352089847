import { createServer, Model } from "miragejs";
import dayjs from "dayjs";
import {
  GetNodeDiagnosticsResponse,
  GetWorkloadAnalyticsParams,
  GetWorkloadAnalyticsResponse,
  ResourcesOverTimeResponse,
  GetTopKParams,
  GetTopKResponse,
} from "../api/fetcher";
import { components } from "../api/schema";

const PORT = 8080;

const BASE_REQUEST_URL = "/api/v1/";
const NUMBER_OF_CPU_AND_MEMORY_NODES = 150;
const BASE_MOCK_URL = `http://localhost:${PORT}/`;

enum Role {
  Admin = "Admin",
  Operator = "Operator",
  Viewer = "Viewer",
}

let rolesState: components["schemas"]["RbacGetRolesResponse"] = {
  clustersInfo: [
    {
      name: "cluster1",
      tags: ["tag1", "tag2"],
    },
    {
      name: "cluster2",
      tags: ["tag2", "tag3"],
    },
    {
      name: "cluster3",
      tags: ["tag3", "tag4"],
    },
    {
      name: "cluster4",
      tags: ["tag4", "tag5"],
    },
    {
      name: "cluster5",
      tags: ["tag5", "tag6"],
    },
  ],
  rules: [
    {
      groupName: "group1",
      id: "id1",
      role: Role.Admin,
      targetClusters: ["cluster1"],
      targetTags: ["tag1"],
    },
    {
      groupName: "group2",
      id: "id2",
      role: Role.Operator,
      targetClusters: ["cluster1", "cluster2"],
      targetTags: ["tag1", "tag2"],
    },
    {
      groupName: "group3",
      id: "id3",
      role: Role.Viewer,
      targetClusters: ["cluster1", "cluster2", "cluster3"],
      targetTags: ["tag1", "tag2", "tag3"],
    },
    {
      groupName: "group4",
      id: "id4",
      role: Role.Admin,
      targetClusters: ["cluster1", "cluster2", "cluster3", "cluster4"],
      targetTags: ["tag1", "tag2", "tag3", "tag4"],
    },
  ],
  currentUserGroups: ["group1", "group2"],
};

const randomInt = (from: number, to: number) => Math.floor(Math.random() * (to - from + 1)) + from;

const randomOrUndefined = (from: number, to: number) => {
  return randomInt(from, to) === 1 ? randomInt(0, 10) : undefined;
};

const randomPercentage = () => Math.round(Math.random() * 100) / 100;

const clamp = (value: number, lowLimit: number, highLimit: number) => {
  if (value > highLimit) return highLimit;
  if (value < lowLimit) return lowLimit;
  return value;
};

const TBtoBytes = (tb: number) => tb * 1024 * 1024 * 1024 * 1024;

type WorkloadAnalyticsValue = {
  timestamp?: string | undefined;
  values?:
    | {
        [key: string]: number | undefined;
      }
    | undefined;
};

export const generateMock = (shouldMock: boolean) => {
  if (!shouldMock) return;

  createServer({
    models: {
      roles: Model,
    },
    routes() {
      this.get(`${BASE_MOCK_URL}/api/v1/workload-analytics`, (_, request): GetWorkloadAnalyticsResponse | Error => {
        const { from, to }: GetWorkloadAnalyticsParams = request.queryParams;

        if (isNaN(Number(from)) || isNaN(Number(to)) || !from || !to) {
          return new Error("from or to is not a number");
        }

        let values: WorkloadAnalyticsValue[] | undefined = undefined;
        const STEPS = 100;
        const timestampInterval = Math.round(Math.abs(from - to - 1) / STEPS);

        let currentTimestamp = Number(from);

        const CPU_NODES = [...Array<number>(NUMBER_OF_CPU_AND_MEMORY_NODES)].reduce((acc, _, index) => {
          acc[`nodeCpuUtilization_node_long_long_long_long_name${index + 1}`] = randomPercentage();
          return acc;
        }, {} as { [key: string]: number | undefined });

        const MEMORY_NODES = [...Array<number>(NUMBER_OF_CPU_AND_MEMORY_NODES)].reduce((acc, _, index) => {
          acc[`nodeMemoryUtilization_node${index + 1}`] = randomPercentage();
          return acc;
        }, {} as { [key: string]: number | undefined });

        while (currentTimestamp <= Number(to)) {
          const newValue = {
            timestamp: dayjs(currentTimestamp).format("YYYY-MM-DDTHH:mm:ssZ"),
            values: {
              replicas: randomInt(0, 10),
              readyReplicas: randomInt(0, 10),
              minHpaReplicas: randomInt(0, 10),
              evictions: randomInt(0, 10),
              automated: randomInt(0, 1),
              oom: randomInt(0, 10),
              liveness: randomInt(0, 10),
              throttling: randomInt(0, 10),
              ...CPU_NODES,
              ...MEMORY_NODES,
            },
          };

          values = [...(values || []), newValue];

          currentTimestamp += timestampInterval;
        }

        return { values: values || [] };
      }),
        this.get(
          `${BASE_MOCK_URL}/api/v1/nodegroups/:name/diagnostics`,
          (_, request): GetNodeDiagnosticsResponse | Error => {
            const NUMBER_OF_DATA_POINTS = 10;

            const { from, to } = request.queryParams;

            const unixStartDate = Number(from);
            const unixEndDate = Number(to);

            if (isNaN(unixStartDate) || isNaN(unixEndDate) || !from || !to) {
              return new Error("startDate or endDate is not a number");
            }

            const timestampInterval = Math.round(Math.abs(unixStartDate - unixEndDate - 1) / NUMBER_OF_DATA_POINTS);

            let currentTimestamp = unixStartDate;

            const diagnosticEventsSeries = [];

            while (currentTimestamp <= unixEndDate) {
              const newValue = {
                timestamp: currentTimestamp,
                nodeMemoryPressureEvent: randomOrUndefined(0, 10),
                highCpuUtilization: randomOrUndefined(0, 10),
                highMemoryUtilization: randomOrUndefined(0, 10),
              };

              diagnosticEventsSeries.push(newValue);

              currentTimestamp += timestampInterval;
            }

            return { diagnosticEventsSeries };
          }
        ),
        this.get(`${BASE_MOCK_URL}/api/v1/resourcesOverTime/`, (): ResourcesOverTimeResponse | Error => {
          const unixEndDate = dayjs().unix() * 1000;
          const unixStartDate = dayjs().subtract(30, "day").unix() * 1000; // 30 days ago

          // one day in seconds
          const timestampInterval = 24 * 60 * 24 * 1000;

          let currentTimestamp = unixStartDate;

          const resourcesOverTime = [];

          const cpuRequest = randomInt(4200000, 4200000);
          const memoryRequest = randomInt(TBtoBytes(28), TBtoBytes(28));

          while (currentTimestamp <= unixEndDate) {
            const cpuUsage = randomInt(1000000, 1500000);
            const cpuAllocatable = randomInt(6200000, 6200000);

            const memoryUsage = randomInt(TBtoBytes(6), TBtoBytes(8));
            const memoryAllocatable = randomInt(TBtoBytes(100), TBtoBytes(100));

            const newValue = {
              timestamps: dayjs(currentTimestamp).format("YYYY-MM-DDTHH:mm:ssZ"),
              cpu: {
                usage: cpuUsage,
                request: cpuRequest,
                recommended: cpuUsage * 1.5,
                allocatable: cpuAllocatable,
                recommendedAllocatable: cpuAllocatable * 0.8,
              },
              memory: {
                usage: memoryUsage,
                request: memoryRequest,
                recommended: memoryUsage * 1.5,
                allocatable: memoryAllocatable,
                recommendedAllocatable: memoryAllocatable * 0.8,
              },
            };

            resourcesOverTime.push(newValue);

            currentTimestamp += timestampInterval;
          }

          const downSampledResourcesOverTime = resourcesOverTime.map((resource) => ({
            ...resource,
            cpu: {
              ...resource.cpu,
              recommended: clamp(resource.cpu.recommended, 2000000, 2500000),
            },
            memory: {
              ...resource.memory,
              recommended: clamp(resource.memory.recommended, TBtoBytes(10), TBtoBytes(15)),
            },
          }));

          return {
            resources: downSampledResourcesOverTime,
          };
        }),
        this.get(`${BASE_MOCK_URL}/api/v1/analytics/topk/`, (_, request): GetTopKResponse | Error => {
          const LONG_NAME =
            "an_example_of_a_very_very_very_very_very_very_very_very_very_very_very_very_very_very_very_very_long_name";
          const { from, to, topK, withSum }: GetTopKParams = request.queryParams;

          let numberOfNodes;

          switch (true) {
            case topK && !isNaN(Number(topK)):
              numberOfNodes = Number(topK);
              break;
            default:
              numberOfNodes = 20;
              break;
          }

          if (isNaN(Number(from)) || isNaN(Number(to)) || !from || !to) {
            return new Error("from or to is not a number");
          }

          const STEPS = 10;
          const timestampInterval = Math.round(Math.abs(from - to - 1) / STEPS);

          let currentTimestamp = Number(from);

          let chartEntries: WorkloadAnalyticsValue[] = [];

          while (currentTimestamp <= Number(to)) {
            const values = [...Array<number>(numberOfNodes)].reduce((acc, _, index) => {
              acc[`node_${LONG_NAME}_${index + 1}`] = randomInt(2, 10);
              return acc;
            }, {} as { [key: string]: number | undefined });

            const randomBoolean = 1; // Math.random() >= 0.5;
            let newValue = {
              timestamp: dayjs(currentTimestamp).format("YYYY-MM-DDTHH:mm:ssZ"),
              values: randomBoolean ? values : {},
            };

            if (withSum) {
              newValue = {
                ...newValue,
                values: randomBoolean
                  ? {
                      ...newValue.values,
                      ["#sum"]: Object.values(newValue.values).reduce((acc, value) => (acc || 0) + (value || 0), 0),
                    }
                  : {},
              };
            }

            chartEntries = [...chartEntries, newValue];

            currentTimestamp += timestampInterval;
          }
          return {
            values: chartEntries,
          };
        }),
        this.get(`${BASE_MOCK_URL}/api/v1/auth/rbac/`, () => {
          const output: components["schemas"]["RbacGetRolesResponse"] = rolesState;
          return output;
        }),
        this.post(`${BASE_MOCK_URL}/api/v1/auth/rbac/`, (_, request) => {
          const requestBody: components["schemas"]["CreateRuleApi-FmInput"] = JSON.parse(
            request.requestBody
          ) as components["schemas"]["CreateRuleApi-FmInput"];
          const { groupName, role, targetClusters, targetTags } = requestBody;

          const newId = `id${rolesState.rules ? rolesState.rules.length + 1 : 1}`;
          const newRule = {
            groupName,
            id: newId,
            role,
            targetClusters,
            targetTags,
          };
          rolesState = {
            ...rolesState,
            rules: [...(rolesState.rules ? rolesState.rules : []), newRule],
          };

          return newRule;
        }),
        this.put(`${BASE_MOCK_URL}/api/v1/auth/rbac/:id`, (_, request) => {
          const { id } = request.params;
          const rule = rolesState.rules?.find((rule) => rule.id === id);

          if (!rule) {
            return new Error("Rule not found");
          }

          const requestBody: components["schemas"]["CreateRuleApi-FmInput"] = JSON.parse(
            request.requestBody
          ) as components["schemas"]["CreateRuleApi-FmInput"];

          const newRule = {
            ...rule,
            ...requestBody,
          };

          rolesState = {
            ...rolesState,
            rules: rolesState.rules?.map((rule) => (rule.id === id ? newRule : rule)),
          };

          return newRule;
        }),
        this.delete(`${BASE_MOCK_URL}/api/v1/auth/rbac/:id`, (_, request) => {
          const { id } = request.params;

          rolesState = {
            ...rolesState,
            rules: rolesState.rules?.filter((rule) => rule.id !== id),
          };

          return {};
        }),
        this.passthrough((request) => {
          let requestUrl = request.url.split("?")[0].split(BASE_REQUEST_URL)[1];
          requestUrl = "/api/v1/" + requestUrl;
          if (requestUrl.includes("/diagnostics") && requestUrl.includes("/api/v1/nodegroups/")) return false;
          if (["/api/v1/workload-analytics"].includes(requestUrl)) return false;
          if (requestUrl.includes("/api/v1/resourcesOverTime")) return false;
          if (requestUrl.includes("/api/v1/auth/rbac")) return false;
          if (requestUrl.includes("/api/v1/analytics/topk")) return false;
          return true;
        });
    },
  });
};
