import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { memo, useEffect, useState } from "react";
import { getDashboardTimeSeries, GetDashboardTimeSeriesResponse } from "../../../api/fetcher";
import AverageSpan from "../../../components/AverageSpan";
import PartialBorders from "../../../components/PartialBorders";
import PercentagePieChart from "../../../components/PercentagePieChart";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import useFilterQueryParams from "../../../components/WorkloadStatusByNamespace/useFilterQueryParams";
import { MAIN_SECTION_CLASSNAME } from "../../../utils/styleUtils";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../utils/useGetTimeoutSeconds";
import useHasMultiProductLayout from "../../../utils/useHasMultiProductLayout";
import { SelectedTime } from "./CostChartContainer";
import RunningNumberBox from "./RunningNumberBox";
import WasteVsOptimizedBarCharts from "./WasteVsOptimizedBarCharts";

const MAIN_GAP = "gap-4";
const MAIN_WIDTH = "w-[50%]";

const getXDaysAgoTimestamp = (x: number) => Math.floor(Date.now() / 1000) - 60 * 60 * 24 * x;

interface Props {
  monthlyForecast?: number;
  underProvisionedWorkloads?: number;
  overProvisionedWorkloads?: number;
  totalAutomatedWorkloads?: number;
  totalUnAutomatedWorkloads?: number;
  defaultOptimizedCost?: number;
  monthlySavingsAvailable?: number;
  activeMonthlySavingsAvailable?: number;
  totalUnoptimizedNotEvictableWorkloads?: number;
  predictableWorkloadsPercentage?: number;
  isMulticluster?: boolean;
  disableAnimation?: boolean;
  isLoading?: boolean;
  scaleOpsProduct?: ScaleOpsProduct;
}

const TopOverviewSection: React.FC<Props> = memo(
  ({
    monthlyForecast = NaN,
    underProvisionedWorkloads = NaN,
    overProvisionedWorkloads = NaN,
    totalAutomatedWorkloads = NaN,
    totalUnAutomatedWorkloads = NaN,
    defaultOptimizedCost = 0,
    monthlySavingsAvailable = 0,
    activeMonthlySavingsAvailable = NaN,
    predictableWorkloadsPercentage = NaN,
    isMulticluster,
    disableAnimation,
    isLoading,
    scaleOpsProduct,
  }) => {
    const hasMultiProductLayout = useHasMultiProductLayout();
    const isHPALayout = scaleOpsProduct === ScaleOpsProduct.HPA;

    const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
    const dashboardTimeSeries = getDashboardTimeSeries();
    const defaultCurrentCost = monthlyForecast;

    const [currentCost, setCurrentCost] = useState<number>(defaultCurrentCost);

    const [startTime, setStartTime] = useState<number>();
    const [selectedTime, setSelectedTime] = useState<SelectedTime>(SelectedTime.NOW);
    const filterQueryParams = useFilterQueryParams();

    const { data, isError } = useQuery<GetDashboardTimeSeriesResponse, Error>({
      queryKey: [dashboardTimeSeries.queryKey, startTime, filterQueryParams, timeoutSeconds, scaleOpsProduct],
      queryFn: () =>
        dashboardTimeSeries.queryFn({
          startTime,
          multiCluster: !!isMulticluster,
          filters: filterQueryParams,
          timeoutSeconds: timeoutSeconds,
          scaleOpsProduct,
        }),
      enabled: selectedTime === SelectedTime.LAST_30_DAYS,
      refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
      keepPreviousData: true,
    });

    const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

    useEffect(() => {
      setTimeoutSeconds(timeoutSecondsValue);
    }, [timeoutSecondsValue]);

    const fetchCurrentCost = data?.currentCost;

    useEffect(() => {
      setCurrentCost(
        Math.floor(
          selectedTime === SelectedTime.NOW
            ? defaultCurrentCost
            : fetchCurrentCost !== undefined
            ? fetchCurrentCost
            : defaultCurrentCost
        )
      );
    }, [data, selectedTime, defaultCurrentCost, defaultOptimizedCost]);

    const availableSavings = data?.availableSavings && data?.availableSavings >= 0 ? data?.availableSavings : undefined;
    const wastedSpend =
      selectedTime === SelectedTime.NOW || !availableSavings
        ? (monthlySavingsAvailable / (currentCost ?? defaultCurrentCost)) * 100
        : (availableSavings / (currentCost ?? defaultCurrentCost)) * 100;

    const wastedSpendValue =
      typeof wastedSpend !== "number" ||
      Number.isNaN(wastedSpend) ||
      wastedSpend === Infinity ||
      wastedSpend < 0 ||
      wastedSpend > 100
        ? NaN
        : wastedSpend;

    const is30DaysSelected = selectedTime === SelectedTime.LAST_30_DAYS;

    let pieChartSubtitle: string | undefined;

    switch (true) {
      case !hasMultiProductLayout:
        break;
      case isHPALayout:
        pieChartSubtitle = "HPA workloads";
        break;
      default:
        pieChartSubtitle = "Rightsize workloads";
        break;
    }

    return (
      <div className="flex gap-2">
        <div
          className={clsx(
            {
              "w-2/3": isHPALayout,
            },
            MAIN_SECTION_CLASSNAME
          )}
        >
          <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px]")}>
            <Tab
              name="Live"
              dataTestId="top-overview-live-tab"
              isSelected={selectedTime === SelectedTime.NOW}
              onClick={() => {
                setSelectedTime(SelectedTime.NOW);
                setStartTime(getXDaysAgoTimestamp(1));
              }}
            />
            <Tab
              name="30 days"
              dataTestId="top-overview-30-days-tab"
              isSelected={selectedTime === SelectedTime.LAST_30_DAYS}
              onClick={() => {
                setSelectedTime(SelectedTime.LAST_30_DAYS);
                setStartTime(getXDaysAgoTimestamp(30));
              }}
            />
          </div>
          <div className={clsx(MAIN_GAP, MAIN_WIDTH, "mt-[11px]")}>
            <PartialBorders>
              <div className="flex flex-col gap-4 justify-center items-center">
                <RunningNumberBox
                  value={currentCost ?? defaultCurrentCost}
                  title={
                    is30DaysSelected ? (
                      <>
                        Monthly cost <AverageSpan />
                      </>
                    ) : (
                      <>
                        Cost <span className="text-[12px] text-strongBorder">(monthly)</span>
                      </>
                    )
                  }
                  valueDataTestId="top-overview-monthly-cost-value"
                  numberVariant="h4"
                  prefix="$"
                  numberClassName="text-text-lightBlack"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
                <div className="bg-strongBorder h-[1px] w-[80%]" />
                <RunningNumberBox
                  value={
                    selectedTime === SelectedTime.NOW || !data?.activeSavings
                      ? activeMonthlySavingsAvailable
                      : data?.activeSavings
                  }
                  valueDataTestId="top-overview-current-savings-value"
                  title={
                    is30DaysSelected ? (
                      <>
                        Monthly savings <AverageSpan />
                      </>
                    ) : (
                      <>
                        Active savings <span className="text-[12px] text-strongBorder">(monthly)</span>
                      </>
                    )
                  }
                  numberVariant="h4"
                  prefix="$"
                  numberClassName="text-main-green"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PartialBorders left>
              <div className="flex flex-col justify-center items-center gap-3 relative">
                <RunningNumberBox
                  title={
                    is30DaysSelected ? (
                      <>
                        Monthly wasted spend <AverageSpan />
                      </>
                    ) : (
                      "Wasted spend"
                    )
                  }
                  valueDataTestId="top-overview-wasted-spend-value"
                  value={wastedSpendValue}
                  suffix="%"
                  numberVariant="h2"
                  numberClassName={clsx({ "text-main-red": wastedSpend > 0, "text-text-lightBlack": wastedSpend <= 0 })}
                  className={clsx(MAIN_GAP)}
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
                <WasteVsOptimizedBarCharts
                  waste={wastedSpend}
                  optimized={100 - wastedSpend}
                  disableTooltip={wastedSpendValue <= 0 || Number.isNaN(wastedSpendValue)}
                  disableAnimation={disableAnimation}
                />
              </div>
            </PartialBorders>
          </div>
        </div>
        <div className={clsx("rounded-tl-lg", MAIN_SECTION_CLASSNAME)}>
          {isHPALayout && (
            <div className={MAIN_WIDTH}>
              <PartialBorders right>
                <div className="flex flex-col justify-center items-center gap-3 relative">
                  <RunningNumberBox
                    title="Predictable workloads"
                    valueDataTestId="top-overview-predictable-workloads-value"
                    value={predictableWorkloadsPercentage}
                    suffix="%"
                    numberVariant="h2"
                    numberClassName="text-text-lightBlack"
                    className={clsx(MAIN_GAP)}
                    disableAnimation={disableAnimation}
                    isLoading={isLoading}
                  />
                </div>
              </PartialBorders>
            </div>
          )}
          {!isHPALayout && (
            <div className={clsx(MAIN_GAP, MAIN_WIDTH, "mt-[11px]")}>
              <PartialBorders right>
                <div className="flex flex-col gap-4 justify-center items-center w-full">
                  <div className="flex items-center relative h-full justify-evenly w-full">
                    <RunningNumberBox
                      value={underProvisionedWorkloads}
                      title="Under provisioned"
                      numberVariant="h4"
                      numberClassName="text-main-red"
                      valueDataTestId="top-overview-under-provisioned-value"
                      disableAnimation={disableAnimation}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="bg-strongBorder w-[80%] h-[1px]" />
                  <RunningNumberBox
                    value={overProvisionedWorkloads}
                    title="Over provisioned"
                    valueDataTestId="top-overview-over-provisioned-value"
                    numberVariant="h4"
                    numberClassName="text-main-red"
                    disableAnimation={disableAnimation}
                    isLoading={isLoading}
                  />
                </div>
              </PartialBorders>
            </div>
          )}
          <div className={clsx(MAIN_GAP, MAIN_WIDTH, "mt-[-11px]")}>
            <PercentagePieChart
              xValue={totalAutomatedWorkloads}
              yValue={totalUnAutomatedWorkloads}
              disableAnimation={disableAnimation}
              showLabels
              subtitle={pieChartSubtitle}
            />
          </div>
        </div>
      </div>
    );
  }
);
[];

export default TopOverviewSection;
