import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ObjectParam, StringParam, useQueryParam } from "use-query-params";
import {
  GetPolicyTuningDiagnostics,
  GetPolicyTuningDiagnosticsParams,
  GetPolicyTuningDiagnosticsResponse,
} from "../../../../api/fetcher";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { SELECTED_CONTAINER_KEY } from "../ContainerFilter";
import { getEndDate, POLICY_TUNING_DATES_URL_PARAM } from "../utils";
import DiagnosticsChart from "./DiagnosticsChart";
import { diagnosticChartElements, EventPoint } from "./utils";

const SHOULD_DEDUCE_DATA = true;

const { queryFn, queryKey } = GetPolicyTuningDiagnostics();

const DiagnosticsContainer = ({
  name,
  namespace,
  selectedViewPeriod,
  policyName,
}: GetPolicyTuningDiagnosticsParams & {
  selectedViewPeriod: string;
  policyName?: string | undefined;
}) => {
  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);

  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [parsedData, setParsedData] = useState<EventPoint[] | undefined>(undefined);

  const { data, isLoading } = useQuery<GetPolicyTuningDiagnosticsResponse, Error>({
    queryKey: [
      queryKey,
      name,
      namespace,
      policyName,
      selectedViewPeriod,
      urlDates?.from,
      urlDates?.to,
      selectedContainer,
    ],
    queryFn: () => {
      return queryFn({
        name,
        namespace,
        startDate: urlDates?.from
          ? dayjs(Number(urlDates?.from) * 1000)
              .utc()
              .format("YYYY-MM-DDTHH:mm:00")
          : dayjs.utc(getEndDate()).subtract(Number(selectedViewPeriod), "hour").utc().format("YYYY-MM-DDTHH:mm:00"),
        endDate: urlDates?.to
          ? dayjs(Number(urlDates?.to) * 1000)
              .utc()
              .format("YYYY-MM-DDTHH:mm:00")
          : getEndDate(),
        container: selectedContainer,
      });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      let previousAutoValue: null | number = null;
      const newParsedData = data?.diagnosticEventsSeries?.map((event) => {
        const auto = Number.isNaN(Number(event.auto)) ? previousAutoValue : Number(event.auto);
        previousAutoValue = auto;

        return {
          ...event,
          hasEvents: true,
          auto: (SHOULD_DEDUCE_DATA ? auto : event.auto) ? 1 : null,
          fastReaction: event.cpuFastReaction || event.memoryFastReaction ? 1 : null,
          timestamp: adjustedDayjs(event.timestamp).unix(),
        };
      });
      setParsedData(newParsedData);
    }
  }, [data]);

  return (
    <DiagnosticsChart
      data={parsedData}
      isLoading={isLoading}
      selectedViewPeriod={selectedViewPeriod}
      includedElements={diagnosticChartElements}
    />
  );
};

export default DiagnosticsContainer;
