import clsx from "clsx";
import { Typography } from "@mui/material";
import { GridFilterListIcon } from "@mui/x-data-grid";

interface Props {
  label: string;
  isFiltered?: boolean;
  customIcon?: React.ReactNode;
  className?: string;
}

const CustomFilterButton = ({ className, label, isFiltered, customIcon }: Props) => (
  <div
    className={clsx(className, "flex items-center justify-cente p-2 border rounded-lg border-strongBorder", {
      "bg-text-lightBlack text-white": isFiltered,
    })}
  >
    {customIcon ?? <GridFilterListIcon fontSize={"small"} />}
    <Typography variant="body2">{label}</Typography>
  </div>
);

export default CustomFilterButton;
