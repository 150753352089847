export enum CostReportLinks {
  Compute = "/cost-report/compute",
  Network = "/cost-report/network",
  Gpu = "/cost-report/gpu",
}

export enum CostReportType {
  Compute = "compute",
  Network = "network",
  Gpu = "gpu",
}
