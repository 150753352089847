import { animated, useSpring } from "react-spring";

interface BarProps {
  style: {
    color: string;
    backgroundColor: string;
  };
  value: number;
  top?: number;
  left?: number;
  height?: number;
  width?: number | string;
}

const Bar = ({ style, value, top = 0, left = 0, height = 12, width = "100%" }: BarProps) => {
  const spring = useSpring({
    from: { width: 0 },
    width: value,
  });

  return (
    <div
      className="h-[12px] relative rounded-full"
      style={{
        background: style.backgroundColor,
        opacity: 1,
        width,
      }}
    >
      <animated.div
        style={{
          position: "absolute",
          top,
          left,
          height,
          width: spring.width.to((w) => `${w}%`),
          backgroundColor: style.color,
          //   border: `1px solid ${style.color}`,
          borderRadius: 100,
        }}
      />
    </div>
  );
};

export default Bar;
