import { useQuery } from "@tanstack/react-query";
import { GetClusterGPUInfo, GetClusterGPUInfoResponse } from "../../../api/fetcher";

export default function useGetGpuClusterInfo() {
  const { queryKey, queryFn } = GetClusterGPUInfo();

  return useQuery<GetClusterGPUInfoResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn({}),
  });
}
