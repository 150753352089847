import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import InfoIcon from "../../../../Icons/InfoIcon";
import { useMainContext } from "../../../../MainContext";
import { GetTopK, GetTopKParams, GetTopKResponse } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import CustomLegend from "../../../../components/CustomLegend";
import Loading from "../../../../components/Loading";
import InfoTooltip from "../../../../components/Tooltip";
import useWindowSize from "../../../../components/useWindowSize";
import { getMaxFlatArray } from "../../../../utils/arrayUtils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { DEFAULT_DATE_TIME_FORMAT, getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { MAX_Y_AXIS_DOMAIN } from "../../../../utils/graphUtils";
import {
  DOT_RADIUS,
  DOT_STROKE_WIDTH,
  LINE_CHART_TYPE,
  NO_OUTLINE,
  TOOLTIP_WRAPPER_CLASS_NAME,
} from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData } from "../utils";
import LinkableWorkloadChartTooltipElement from "./LinkableWorkloadChartTooltipElement";
import { ANALYTICS_PAGE_DAILY_SYNC_ID } from "./graphUtils";
import FreezeTooltipWarning from "./hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";

const HAS_LEGEND = false;
const DEFAULT_SELECTED_ELEMENTS = Infinity;
const HEIGHT = "h-[200px]";
const SUM = "sum";
const DATE = "date";
const { queryFn, queryKey } = GetTopK();

type Payload = { value: string | number; name?: string; dataKey: string; stroke: string; sum?: number };

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string; payload: Payload }[];
  label?: string;
  dotColor: string;
  valueFormatter?: (tick: string) => string;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
  keyParser?: (key: string | undefined) => string | undefined;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  dotColor,
  valueFormatter,
  tooltipTrigger,
  frozenTooltipType = FrozenTooltipType.Disabled,
  tooltipId,
  updateActiveTooltips,
  keyParser,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    const elementsToShow = payload?.[0]?.payload;
    const total = elementsToShow[SUM];
    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        <div className="flex justify-between">
          {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_TIME_FORMAT} />}
          {total && (
            <span className="">
              <b>Total:</b> {total}
            </span>
          )}
        </div>
        {Object.keys(elementsToShow)
          .filter((key) => key !== SUM && key.toLowerCase() !== DATE)
          .sort((a, b) => a.localeCompare(b))
          .map((key, index) => {
            const parsedKey = keyParser ? keyParser(key) : key;
            return (
              <LinkableWorkloadChartTooltipElement
                key={index}
                color={key === SUM ? dotColor : SCALEOPS_COLORS.randomColors[index]}
                value={
                  valueFormatter
                    ? valueFormatter(String(elementsToShow[key as keyof typeof elementsToShow]))
                    : elementsToShow[key as keyof typeof elementsToShow]
                }
                label={<div className="max-w-[200px] truncate rtl">{parsedKey}</div>}
                rawLabel={String(parsedKey)}
                disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
              />
            );
          })}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

type Element = {
  key: string;
  label: string;
  color: string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
};

interface Props {
  title: React.ReactNode;
  queryParams: GetTopKParams;
  dotColor: string;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: number[];
  noGroupBy?: boolean;
  YAxisFormatter?: (tick: string) => string;
  isMulticluster?: boolean;
  topK?: number;
  withSum?: boolean;
  infoTooltip?: React.ReactNode;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
  syncId?: string | null;
  children?: React.ReactNode;
  keyParser?: (key: string | undefined) => string | undefined;
}

const TopKMultiDotChart = ({
  title,
  queryParams,
  dotColor,
  showPercentage,
  wrapDivClassName,
  setDate,
  YAxisDomain,
  noGroupBy,
  YAxisFormatter = (tick: string) => tick,
  isMulticluster,
  topK,
  withSum,
  infoTooltip,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType = FrozenTooltipType.Disabled,
  updateActiveTooltips,
  syncId = ANALYTICS_PAGE_DAILY_SYNC_ID,
  children,
  keyParser,
}: Props) => {
  const { currentCluster, didClusterChanged } = useMainContext();

  const filtersQueryParams = useFilterQueryParams();
  const { width: windowWidth } = useWindowSize();

  const [elements] = useState<Element[]>([
    {
      key: SUM,
      label: SUM,
      color: dotColor,
    },
  ]);

  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [chartComponents, setChartComponents] = useState<string[]>([]);
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>(chartComponents);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [viewPeriodInHours, setViewPeriodInHours] = useState<number>(3 * 24);

  const { data, isLoading, error, isError } = useQuery<GetTopKResponse, Error>({
    queryKey: [
      queryKey,
      queryParams,
      filtersQueryParams,
      isMulticluster ? "multicluster" : currentCluster,
      topK ? `topK-${topK}` : undefined,
      withSum ? "withSum" : undefined,
    ],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        topK,
        withSum,
        timeoutSeconds: timeoutSeconds,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
    enabled: isQueryEnabled,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    if (data?.values) {
      const firstDate = adjustedDayjs(data.values[0].timestamp);
      const lastDate = adjustedDayjs(data.values[data.values.length - 1].timestamp);
      setViewPeriodInHours(lastDate.diff(firstDate, "hours"));
    }
  }, [queryKey, queryParams, filtersQueryParams, isMulticluster, topK, withSum]);

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (data?.values) {
      const uniqueItemNames: string[] = [
        ...new Set(data.values.map((item) => item.values && Object.keys(item.values)).flat()),
      ]
        .filter((item) => item !== undefined)
        .map((item) => String(item))
        .sort();
      setChartComponents(uniqueItemNames);
      setSelectedChartComponents(uniqueItemNames.slice(0, DEFAULT_SELECTED_ELEMENTS));
    }
  }, [data]);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  elements.forEach((element) => {
    legendComponentStyle[element.key] = {
      color: element.color,
    };
  });

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [queryParams, filtersQueryParams]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div className={clsx(HEIGHT, "bg-white border border-border rounded min-h-full")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (error && didClusterChanged) {
    toast.error(`${queryParams?.queryKey ?? ""} chart could not load as for this cluster`);
    console.log("error loading chart", title, error);
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    showPercentage,
    false,
    queryParams.from ? Number(queryParams.from) : undefined,
    queryParams.to ? Number(queryParams.to) : undefined,
    undefined,
    noGroupBy,
    false,
    true
  );

  const maxDataPoint = Math.round(
    getMaxFlatArray(
      graphData.map((item) => {
        return Object.values(item)
          .filter((value) => !Number.isNaN(Number(value)))
          .map((value) => Number(value));
      })
    ) * MAX_Y_AXIS_DOMAIN
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix() * 1000;

  return (
    <div
      className={clsx(wrapDivClassName, "bg-white min-h-full border border-border rounded unselectable-svg-text", {
        "pt-8 pb-4": HAS_LEGEND,
        "py-8 pr-8": !HAS_LEGEND,
      })}
    >
      <div className={HEIGHT}>
        <Typography variant="body2" className="w-full flex justify-center items-center gap-1">
          {title}
          {infoTooltip && (
            <InfoTooltip title={infoTooltip} placement="top" maxWidth={600}>
              <InfoIcon width={14} height={14} />
            </InfoTooltip>
          )}
        </Typography>
        <ResponsiveContainer width="100%" height="100%" className="pr-4">
          <ComposedChart
            syncId={syncId ? syncId : undefined}
            data={graphData}
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  to: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
            <Tooltip
              wrapperStyle={NO_OUTLINE}
              content={
                <CustomTooltip
                  valueFormatter={YAxisFormatter}
                  dotColor={dotColor}
                  tooltipTrigger={tooltipTrigger}
                  frozenTooltipType={frozenTooltipType}
                  tooltipId={queryKey}
                  updateActiveTooltips={updateActiveTooltips}
                  keyParser={keyParser}
                />
              }
              trigger={tooltipTrigger}
            />
            {elements.map((element) => {
              const elementData = elements.find((e) => e.key === element.key);
              if (!elementData || !selectedChartComponents.includes(elementData.key)) return null;
              const { key, color, fill } = elementData;

              return (
                <Area
                  type={LINE_CHART_TYPE}
                  strokeWidth={0}
                  dataKey={key}
                  stroke={color}
                  fillOpacity={fill ? 0.4 : 0}
                  radius={DOT_RADIUS}
                  dot={{ r: 1, strokeWidth: DOT_STROKE_WIDTH }}
                  key={key}
                  isAnimationActive={false}
                />
              );
            })}
            <XAxis
              dataKey="date"
              style={{ fontSize: "x-small" }}
              interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
              strokeWidth={2}
              tickFormatter={(value) => {
                const epochValue = adjustedDayjs(String(value)).unix();
                return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriodInHours);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={YAxisDomain ?? [0, maxDataPoint || Infinity]}
              strokeWidth={2}
              tickFormatter={(tick) => YAxisFormatter(String(tick))}
              ticks={[0, Math.round(maxDataPoint / 2), Math.round(maxDataPoint) ?? 1]}
            />
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {HAS_LEGEND && (
        <div className="w-full flex flex-col justify-center items-end">
          <div className="w-[100%] px-[5%] max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md mt-8">
            <CustomLegend<string>
              selectedChartComponents={selectedChartComponents}
              setSelectedChartComponents={setSelectedChartComponents}
              componentStyle={legendComponentStyle}
              ChartComponents={chartComponents
                .map((component) => ({
                  [component]: component,
                }))
                .reduce((a, b) => ({ ...a, ...b }), {})}
              renderNameFunction={(key) => {
                const element = elements.find((e) => e.key === key);
                return element?.label ?? "";
              }}
              className="-mt-1"
              fontWeight={500}
              fontSpanClassName="truncate"
              hasTooltip
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default TopKMultiDotChart;
