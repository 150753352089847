import { BreakdownCostTypes } from "../../../api/fetcher";

export type DailyData = {
  timestamp: string;
  [key: string]: number | string;
};

export type EntireWindowData = {
  name: string;
  cost: number;
  isGpuEnabledWorkload?: boolean;
};

export enum ChartType {
  Daily = "Daily",
  EntireWindow = "Entire window",
}

export enum ChartCostType {
  Cost = "Cost",
  SavingsAvailable = "Available Savings",
}

export const ToBreakdownCostTypes = (costType: ChartCostType, useWithReplicas?: boolean): BreakdownCostTypes => {
  switch (costType) {
    case ChartCostType.Cost:
      return "cost";
    case ChartCostType.SavingsAvailable:
      return useWithReplicas ? "savingsAvailableWithReplicas" : "savingsAvailable";
    default:
      return "cost";
  }
};
