import { IconProps } from "./utils/utils";

const PolicyIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M17.8909 1H4.95825C3.83587 1 2.92273 1.90177 2.92273 3.00659L2.83938 14.3587H0.315505C0.13664 14.3587 0 14.5164 0 14.6947V16.257C0 17.7196 1.17409 18.9157 2.59109 18.9812V19H13.4818V18.9789C13.5627 18.9822 13.6118 19 13.6674 19H13.6752C15.1865 18.9912 16.4047 17.7632 16.3968 16.2567V6.32735H19.6845C19.8634 6.32735 20 6.16592 20 5.98777V3.1212C20.0021 1.95616 19.0596 1.00836 17.8909 1ZM2.73279 18.3543H2.73058C1.57847 18.3543 0.647773 17.405 0.647773 16.2567V15.0045H10.9312V16.2513C10.9286 16.9781 11.2166 17.6759 11.7314 18.1905C11.7861 18.2453 11.8434 18.2735 11.9019 18.3543H2.73279ZM15.749 16.2564C15.749 17.405 14.8273 18.3543 13.6747 18.3543H13.6681C12.5098 18.3459 11.5763 17.4061 11.5789 16.2515V14.6947C11.5799 14.5139 11.4365 14.3651 11.2551 14.3587H3.48716L3.5705 3.00879C3.5705 2.2579 4.19297 1.64574 4.95825 1.64574H16.3618C15.9927 2.04933 15.749 2.55996 15.749 3.12987V16.2564ZM19.3522 3.12687V5.68161H16.3968V3.12924C16.3968 2.32112 17.0711 1.64574 17.8814 1.64574H17.8905C18.7029 1.65378 19.356 2.3145 19.3522 3.12451V3.12687Z" />
      <path d="M8.22085 8.80323L9.89073 9.9449C9.9997 10.02 10.1438 10.02 10.2527 9.9449L11.9176 8.80355C12.4959 8.40897 12.9231 7.58407 12.9231 6.88458V4.57468C12.9265 4.45259 12.8592 4.33935 12.7504 4.28384L10.2078 3.03321C10.1186 2.98893 10.0139 2.98893 9.92473 3.03321L7.38694 4.28036C7.27813 4.33587 7.21092 4.44911 7.21456 4.5712V6.88458C7.21456 7.58407 7.64266 8.40897 8.22085 8.80323ZM7.86233 4.77331L10.0688 3.68462L12.2753 4.77632V6.88458C12.2753 7.37247 11.9555 7.99304 11.5521 8.26806L10.065 9.28511L8.5838 8.26822C8.18037 7.99288 7.86233 7.37247 7.86233 6.88458V4.77331Z" />
      <path d="M9.52097 7.37357C9.58138 7.43525 9.66393 7.4702 9.75028 7.47067H9.75107C9.8371 7.47036 9.9195 7.43588 9.98007 7.37484L11.2685 6.08593C11.3952 5.95941 11.3952 5.75414 11.2687 5.62762C11.1423 5.50094 10.937 5.50094 10.8105 5.62746L9.75218 6.68563L9.32913 6.25847C9.20309 6.13132 8.99797 6.13037 8.87082 6.25642C8.74367 6.38246 8.74288 6.58758 8.86877 6.71473L9.52097 7.37357Z" />
      <path d="M14.0972 10.8715H6.32389C6.14502 10.8715 6 11.0165 6 11.1954C6 11.3742 6.14502 11.5193 6.32389 11.5193H14.0972C14.276 11.5193 14.4211 11.3742 14.4211 11.1954C14.4211 11.0165 14.276 10.8715 14.0972 10.8715Z" />
      <path d="M14.0972 12.3695H6.32389C6.14502 12.3695 6 12.5145 6 12.6934C6 12.8722 6.14502 13.0172 6.32389 13.0172H14.0972C14.276 13.0172 14.4211 12.8722 14.4211 12.6934C14.4211 12.5145 14.276 12.3695 14.0972 12.3695Z" />
    </svg>
  );
};

export default PolicyIcon;
