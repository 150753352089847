import clsx from "clsx";
import React, { ReactNode, useState } from "react";
import { useRouteError } from "react-router-dom";
import ScaleopsIcon from "./Icons/ScaleopsIcon";
import AircraftIcon from "./Icons/AircraftIcon";
import PlanetIcon from "./Icons/PlanetIcon";
import { Typography } from "@mui/material";
import { ApiError } from "openapi-typescript-fetch";

const SKEW_PERCENTAGE = 10;

const snowFlakesStyle = `
.snowflakes {
	width: 100%; 
  height: 1200px;
	position: absolute; top: -90px; left: 0;
}

i {
	display: inline-block;
	-webkit-animation: snowflakes 3s linear 2s 20;
	-moz-animation: snowflakes 3s linear 2s 20;
	position: relative;
}

// i, i:after, i:before { background: white; }

// i:after, i:before {
// 	height: 100%;
// 	width: 100%;
// 	content: ".";
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	-webkit-transform: rotate(120deg);
// }
// i:before { -webkit-transform: rotate(240deg); }

@-webkit-keyframes snowflakes {
	0% {
		-webkit-transform: translate3d(0,0,0) rotate(0deg) scale(0.6);
	}
	100% {
		-webkit-transform: translate3d(15px, 1200px, 0px) rotate(360deg) scale(0.6);
	};
}

.snowflakes i:nth-child(3n) {
	width: 16px; height: 4px;
	-webkit-animation-duration: 4s;
	-webkit-animation-iteration-count: 30;
	-webkit-transform-origin: right -45px;
}

.snowflakes i:nth-child(3n+1) {
	width: 24px; height: 6px;
	-webkit-animation-duration: 6s;
	-webkit-animation-iteration-count: 45;
	-webkit-transform-origin: right -30px;
}

.snowflakes i:nth-child(3n+2) {
	width: 32px; height: 8px;
	-webkit-animation-duration: 8s;
	-webkit-animation-iteration-count: 60;
	-webkit-transform-origin: right -15px;
}
	
/* different delays so they don't all start at the same time */
.snowflakes i:nth-child(7n) {
  opacity:.3;
  -webkit-animation-delay: 0s;
  -webkit-animation-timing-function:ease-in;
}
.snowflakes i:nth-child(7n+1) {
  opacity:.4;
  -webkit-animation-delay: 1s;
  -webkit-animation-timing-function:ease-out;
}
.snowflakes i:nth-child(7n+2) {
  opacity:.5;
  -webkit-animation-delay: 1.5s;
  -webkit-animation-timing-function:linear;
}
.snowflakes i:nth-child(7n+3) {
  opacity:.6;
  -webkit-animation-delay: 2s;
  -webkit-animation-timing-function:ease-in;
}
.snowflakes i:nth-child(7n+4) {
  opacity:.7;
  -webkit-animation-delay: 2.5s;
  -webkit-animation-timing-function:linear;
}
.snowflakes i:nth-child(7n+5) {
  opacity:.8;
  -webkit-animation-delay: 3s;
  -webkit-animation-timing-function:ease-out;
}
.snowflakes i:nth-child(7n+6) {
  opacity:.9;
  -webkit-animation-delay: 3.5s;
  -webkit-animation-timing-function:ease-in;
}
`;

const isJson = (data: object | string) => {
  if (typeof data === "object" && data !== null) {
    return true;
  }
  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
};

type ErrorType = {
  statusText: string;
  message: string;
};

interface Props {
  apiError?: ApiError;
  children?: ReactNode;
}

const ErrorPage = ({ apiError, children }: Props) => {
  let error = useRouteError() as ErrorType;
  const [bodyCount, setBodyCount] = useState(0);

  if (apiError) {
    console.error(apiError);
    if (apiError.data && isJson(apiError.data as object)) {
      error = { message: JSON.stringify(apiError.data) } as ErrorType;
    } else {
      error = { message: apiError.message } as ErrorType;
    }
  }
  console.error(error);

  const [mousePos, setMousePos] = React.useState({
    x: 0,
    y: 0,
  });

  React.useEffect(() => {
    const setFromEvent = (e: MouseEvent) => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      e;
      setMousePos({
        x: Math.round((e.clientX / windowWidth) * 100) / 100,
        y: Math.round((e.clientY / windowHeight) * 100) / 100,
      });
    };
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  const shouldShowSnowFlakes = bodyCount % 2 === 0 && bodyCount > 0;

  return (
    <div
      className="bg-guideline-lightPurple h-[100vh] w-[100vw] flex flex-col justify-center items-center gap-2 unselectable-svg-text"
      onClick={() => {
        setBodyCount(bodyCount + 1);
      }}
    >
      {shouldShowSnowFlakes && (
        <>
          <style>{snowFlakesStyle}</style>
          <div className="snowflakes">
            {[...Array<number>(100)].map((_, i) => (
              <i key={i}>
                <ScaleopsIcon />
              </i>
            ))}
          </div>
        </>
      )}
      <ScaleopsIcon
        width={300}
        height={300}
        className={clsx("z-10", { "animate-pulse opacity-0": shouldShowSnowFlakes })}
        style={{
          transform: `skew(${mousePos.x * SKEW_PERCENTAGE}deg, ${mousePos.y * SKEW_PERCENTAGE}deg)`,
        }}
      />
      <div className="relative">
        <Typography variant="h1" className="text-white">
          Oops!
        </Typography>
        {!shouldShowSnowFlakes && (
          <>
            <PlanetIcon className="absolute scaleopsOrbitAnimation mt-[-200px] z-[20]" width={37} height={37} />
            <AircraftIcon
              className="absolute scaleopsOrbitAnimation2 mt-[-300px] ml-[200px] z-[2] text-primary-pink"
              width={37}
              height={37}
            />
          </>
        )}
      </div>
      <Typography variant="h5" className="text-center">
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="body1" className="max-w-[600px] text-center">
        <span>{error?.statusText || error?.message}</span>
      </Typography>
      {children}
    </div>
  );
};

export default ErrorPage;
