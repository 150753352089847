import { IconProps } from "./utils/utils";

const DecreaseArrowIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M0.234553 14.1923L9.51175 19.8571C9.73577 20.0252 10.136 20.0483 10.4056 19.9087C10.4356 19.8931 10.4633 19.8758 10.4883 19.8571L19.7655 14.1923C20.0621 14.0208 20.0797 13.7317 19.8047 13.5467C19.6684 13.4549 19.4777 13.4021 19.2772 13.4004L14.8827 13.4004L14.8827 6.06064C14.7994 5.7539 14.4016 5.51822 13.9062 5.48196L6.09377 5.48196C5.70311 5.48196 5.60554 5.78649 5.60554 6.06064L5.60554 13.4004L0.722782 13.4004C0.318292 13.4038 -0.00526241 13.6109 6.4724e-05 13.8632C0.00268156 13.9883 0.087449 14.1072 0.234553 14.1923Z" />
      <path d="M6.09387 4.5684L13.9063 4.5684C14.3108 4.5684 14.6387 4.36385 14.6387 4.11155C14.6387 3.85926 14.3108 3.6547 13.9063 3.6547L6.09387 3.6547C5.68938 3.6547 5.36143 3.85926 5.36143 4.11155C5.36143 4.36385 5.68928 4.5684 6.09387 4.5684Z" />
      <path d="M6.09387 2.74106L13.9063 2.74106C14.3108 2.74106 14.6387 2.53651 14.6387 2.28421C14.6387 2.03192 14.3108 1.82736 13.9063 1.82736L6.09387 1.82736C5.68938 1.82736 5.36143 2.03192 5.36143 2.28421C5.36143 2.53651 5.68928 2.74106 6.09387 2.74106Z" />
      <path d="M6.09387 0.913781L13.9063 0.913782C14.3108 0.913782 14.6387 0.709228 14.6387 0.456932C14.6387 0.204636 14.3108 8.37779e-05 13.9063 8.37426e-05L6.09387 8.30596e-05C5.68938 8.30242e-05 5.36143 0.204635 5.36143 0.456931C5.36143 0.709227 5.68928 0.913781 6.09387 0.913781Z" />
    </svg>
  );
};

export default DecreaseArrowIcon;
