export const ICON_SIZE = 14;
export const FILTER_HEIGHT = "32px";

const MAX_FILTER_CHIP_NAME_CHARACTERS = 30;

interface GetTooltipLabelProps {
  name: string;
  selectedOptions: string[];
  chipValueRenderValue?: (option: string) => string;
}

export const getTooltipLabel = ({ name, selectedOptions, chipValueRenderValue }: GetTooltipLabelProps) => {
  const optionsToRender = selectedOptions.map((option) => {
    return chipValueRenderValue ? chipValueRenderValue(option) : option;
  });

  const numberOfCharactersInSelectedOptions = optionsToRender.join("").length;
  const exceedsMaxCharacters = numberOfCharactersInSelectedOptions > MAX_FILTER_CHIP_NAME_CHARACTERS;

  let displayValue = <>{name}</>;

  switch (true) {
    case selectedOptions.length === 0:
      break;
    case exceedsMaxCharacters && selectedOptions.length === 1:
      displayValue = (
        <span>
          {name} is <b>{selectedOptions[0].slice(0, MAX_FILTER_CHIP_NAME_CHARACTERS)}...</b>
        </span>
      );
      break;
    case exceedsMaxCharacters && selectedOptions.length > 1:
      displayValue = (
        <span>
          {name} is one of <b>{selectedOptions.length} options</b>
        </span>
      );
      break;
    case selectedOptions.length === 1:
      displayValue = (
        <span>
          {name} is <b>{optionsToRender[0]}</b>
        </span>
      );
      break;
    case selectedOptions.length > 1:
      displayValue = (
        <p>
          <span>{name}</span> is{" "}
          {selectedOptions.map((option, index) => {
            return (
              <span>
                <b>{option}</b> {index < selectedOptions.length - 1 && "or "}
              </span>
            );
          })}
        </p>
      );
  }

  return displayValue;
};
