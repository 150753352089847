import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { ArrayParam, useQueryParam } from "use-query-params";
import { WastedResourcesTypes } from "./overviewUtils";
import MultiSelectByQueryParams from "../MultiSelectByQueryParams";

const MultiSelectWastedResourcesByQueryParams = () => {
  const [wastedResourcesParam] = useQueryParam("wastedResources", ArrayParam);
  const [isWastedResourcesExclude] = useQueryParam("isWastedResourcesExclude", ArrayParam);

  return (
    <div className="w-[163.5px]">
      <MultiSelectByQueryParams
        options={Object.keys(WastedResourcesTypes)}
        optionRenderFunction={(option, index) => {
          return (
            <MenuItem value={option} key={`${index}-multi-select-option`}>
              <Checkbox checked={!!wastedResourcesParam && wastedResourcesParam.indexOf(option) > -1} />
              <ListItemText primary={WastedResourcesTypes[option as keyof typeof WastedResourcesTypes]} />
            </MenuItem>
          );
        }}
        queryKey="wastedResources"
        name="wasted resources"
        tooltipPrefix={
          isWastedResourcesExclude ? (
            <>
              warning <b>exclude: </b>
            </>
          ) : (
            <>
              warning <b>include: </b>
            </>
          )
        }
        hasLogicalAndOR
        logicalOperatorQueryKey="logicalWastedResources"
        disableSort
      />
    </div>
  );
};

export default MultiSelectWastedResourcesByQueryParams;
