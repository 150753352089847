import numeral from "numeral";
import prettyBytes from "pretty-bytes";
import BeforeAndAfterMetrics from "./BeforeAndAfterMetrics";
import { BannerData } from "./utils";

const Divider = () => <div className="w-[1px] h-[60px] bg-strongBorder" />;

interface Props {
  fontWeight?: number;
}
const BeforeAndAfterBoxesContainer = ({
  costBefore,
  costAfter,
  cpuBefore,
  cpuAfter,
  memoryBefore,
  memoryAfter,
  fontWeight = 700,
}: Props & BannerData) => (
  <>
    <BeforeAndAfterMetrics
      title="COST"
      before={costBefore}
      after={costAfter}
      valueFormatter={(value?: number) => {
        let format = "$0,0.[00]a";
        if (value && value < 1000) {
          format = "$0";
        }
        return numeral(value ?? 0).format(format);
      }}
      fontWeight={fontWeight}
    />
    <Divider />
    <BeforeAndAfterMetrics
      title="CPU"
      before={cpuBefore}
      after={cpuAfter}
      valueFormatter={(value?: number) => numeral(value ?? 0).format("0,0.[00]a")}
      fontWeight={fontWeight}
    />
    <Divider />
    <BeforeAndAfterMetrics
      title="MEMORY"
      before={memoryBefore}
      after={memoryAfter}
      valueFormatter={(value?: number) =>
        prettyBytes(value ? value * 1024 * 1024 * 1024 : 0.0, { bits: false, binary: true, maximumFractionDigits: 2 })
      }
      fontWeight={fontWeight}
    />
  </>
);

export default BeforeAndAfterBoxesContainer;
