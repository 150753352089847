import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { components } from "../../api/schema";
import {
  updateCaConfiguration,
  GetCaConfigurationDeployment,
  GetCaConfigurationDeploymentResponse,
} from "../../api/fetcher";
import { getOptimisticValues } from "./actionsUtils";

const useUpdateCaConfiguration = () => {
  const caConfigurationDeployment = GetCaConfigurationDeployment();
  const queryClient = useQueryClient();
  const caConfiguration = updateCaConfiguration();

  return useMutation(
    (
      params: components["schemas"]["UpdateCaConfigurationInput"] & {
        data: GetCaConfigurationDeploymentResponse | undefined;
      }
    ) =>
      caConfiguration.queryFn({
        configurationKey: params.configurationKey,
        optimize: params.optimize,
        ownerName: params.ownerName,
        podName: params.podName,
        podNamespace: params.podNamespace,
      }),
    {
      onMutate: async (params) => {
        await queryClient.cancelQueries([caConfigurationDeployment.queryKey]);

        const camelCaseConfigurationKey = params.configurationKey?.replace(/-([a-z])/g, function (g) {
          return g[1].toUpperCase();
        });

        const optimisticValues = params.data && getOptimisticValues(String(params.configurationKey), params.data);

        if (camelCaseConfigurationKey)
          queryClient.setQueryData(
            [caConfigurationDeployment.queryKey, params.ownerName, params.podNamespace],
            (oldData: GetCaConfigurationDeploymentResponse | undefined) => {
              return {
                ...oldData,
                [camelCaseConfigurationKey]: params.optimize ? optimisticValues?.on : optimisticValues?.off,
                scaleopsNodesAnnotations: {
                  ...oldData?.scaleopsNodesAnnotations,
                  [params.configurationKey ?? ""]: {
                    value: String(params.optimize ? optimisticValues?.on : optimisticValues?.off),
                    exists: true,
                  },
                },
              };
            }
          );
      },
      onError: (error: Error, params) => {
        toast.error(`Error: ${error.message}`);
        queryClient.invalidateQueries([caConfigurationDeployment.queryKey, params.ownerName, params.podNamespace]);
        console.log(`Error: ${error.message}`);
      },
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([caConfigurationDeployment.queryKey, params.ownerName, params.podNamespace]);
        toast.success("Cluster Autoscaler configuration was updated successfully");
      },
    }
  );
};

export default useUpdateCaConfiguration;
