import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { deletePolicy, getPolicies } from "../../../api/fetcher";

type mutationProps = {
  policyName: string;
};

const useDeletePolicy = () => {
  const policyTuning = getPolicies();
  const deletePol = deletePolicy();
  const queryClient = useQueryClient();

  return useMutation((params: mutationProps) => deletePol.queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to delete policy`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([policyTuning.queryKey]).catch((error) => console.log(error));
      toast.success("Policy was deleted successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useDeletePolicy;
