import CSVWrapper from "./CSVWrapper";
import StaticExportButton from "./StaticExportButton";

export const HAS_EXPORT_TABLE_AS_CSV = true;

type ID = { id: string | number };

interface Props<T> {
  columns: (keyof T)[];
  data: T[];
  columnsToRound?: (keyof T)[];
  columnsToSum?: (keyof T)[];
  columnsToAverage?: (keyof T)[];
  defaultValues?: Record<string, number | string | undefined | null>;
  customColumnNames?: { [key in keyof T]?: string };
  customButtonElement?: JSX.Element;
  filename?: string;
}

const ExportCSV = <T extends ID>({
  columns,
  data,
  columnsToRound,
  columnsToSum,
  columnsToAverage,
  customColumnNames,
  customButtonElement,
  defaultValues,
  filename = "export.csv",
}: Props<T>) => {
  return (
    <CSVWrapper
      columns={columns}
      data={data}
      columnsToRound={columnsToRound}
      columnsToSum={columnsToSum}
      columnsToAverage={columnsToAverage}
      customColumnNames={customColumnNames}
      filename={filename}
      defaultValues={defaultValues}
    >
      {customButtonElement ?? <StaticExportButton />}
    </CSVWrapper>
  );
};

export default ExportCSV;
