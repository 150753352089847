import { useState, useEffect } from "react";
import { ArrayParam, BooleanParam, useQueryParam } from "use-query-params";

export enum FilterOptions {
  Tags = "tags",
  IsTagExclude = "isTagsExclude",
  Clusters = "clusters",
  Namespaces = "namespaces",
  Labels = "labels",
  Annotations = "annotations",
}

export type FilterParamObject = {
  [FilterOptions.Tags]?: string[];
  [FilterOptions.IsTagExclude]?: boolean;
  [FilterOptions.Clusters]?: string[];
  [FilterOptions.Namespaces]?: string[];
  [FilterOptions.Labels]?: string[];
  [FilterOptions.Annotations]?: string[];
};

const useFilterQueryParams = (): FilterParamObject => {
  const [filters, setFilters] = useState<FilterParamObject>({});

  const [tagsParams] = useQueryParam("tags", ArrayParam);
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);
  const [clustersParams] = useQueryParam("clusters", ArrayParam);
  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [labelsParams] = useQueryParam("labels", ArrayParam);
  const [annotationsParams] = useQueryParam("annotations", ArrayParam);

  useEffect(() => {
    const filtersObj: FilterParamObject = {};
    filtersObj[FilterOptions.Tags] = tagsParams ? tagsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.IsTagExclude] = isTagsExclude ? isTagsExclude : undefined;

    filtersObj[FilterOptions.Clusters] = clustersParams ? clustersParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.Namespaces] = namespacesParams ? namespacesParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.Labels] = labelsParams ? labelsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.Annotations] = annotationsParams ? annotationsParams.map((value) => String(value)) : [];

    setFilters(filtersObj);
  }, [tagsParams, isTagsExclude, clustersParams, namespacesParams, labelsParams, annotationsParams]);

  return filters;
};

export default useFilterQueryParams;
