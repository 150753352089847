import { Typography } from "@mui/material";
import React from "react";
import { ScaleOpsProduct } from "../../utils/typesUtils";

interface Props {
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

const PolicyPageTitleAndDescription = ({ scaleOpsProduct }: Props) => {
  let title = "";
  let description: React.ReactNode = null;

  switch (scaleOpsProduct) {
    case ScaleOpsProduct.Rightsize:
      title = "Rightsize Policies management";
      description = (
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Manage your <b>Rightsize Policies</b> and <b>customize them to suit your needs</b>.
          <br />
          Define the number of days the recommendation should be based on, the request and limit headrooms and the
          continuous automation strategy and much more.
        </Typography>
      );
      break;
    case ScaleOpsProduct.HPA:
      title = "HPA Policies management";
      description = (
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Manage your <b>HPA policies</b> and <b>customize them to suit your needs</b>.
          <br />
          Automate HPA workloads based on historical trends in a predictive and static manner, while considering the
          workload history and minimum replicas.
        </Typography>
      );
      break;
    default:
      title = "Policies management";
      description = (
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Manage your policies and <b>customize them to suit your needs</b>.
          <br />
          Define the number of days the recommendation should be based on, the request and limit headrooms and the
          continuous automation strategy and much more.
        </Typography>
      );
      break;
  }

  return (
    <div>
      <Typography variant="h6">{title}</Typography>
      {description}
    </div>
  );
};

export default PolicyPageTitleAndDescription;
