import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetIgnoredNamespaces, UpdateIgnoredNamespaces, UpdateIgnoredNamespacesParams } from "../../api/fetcher";
import { TOAST_SETTINGS } from "../../pages/Ingress/mutations/utils";

const { queryKey } = GetIgnoredNamespaces();
const { queryFn } = UpdateIgnoredNamespaces();

const useIgnoreNamespaces = () => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateIgnoredNamespacesParams) => queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Error saving ignored namespaces: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Ignored namespaces successfully updated!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKey]).catch((error) => console.log(error));
    },
  });
};

export default useIgnoreNamespaces;
