import { Tooltip, Typography } from "@mui/material";
import yaml from "js-yaml";
import { useEffect, useState } from "react";
import { HPAPolicy } from "../../../api/fetcher";
import CustomIcon from "../../../Icons/CustomIcon";
import LockIcon from "../../../Icons/LockIcon";
import MainDownloadIcon from "../../../Icons/MainDownloadIcon";
import { exportStringAsFile, FileExtension } from "../../../utils/exportUtils";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";

interface Props {
  selectedPolicy: HPAPolicy | undefined;
  createNewPolicyTitle: boolean;
}

const HPAPolicyTitle = ({ selectedPolicy, createNewPolicyTitle }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCustomizedPolicy = !createNewPolicyTitle && !isBuiltInPolicy(selectedPolicy) && !isReadyOnlyFrontEnd;

  const [yamlString, setYamlString] = useState<string>("");

  useEffect(() => {
    if (!createNewPolicyTitle) {
      const rowPolicyDataYaml = JSON.parse(JSON.stringify(selectedPolicy ?? "")) as HPAPolicy;
      if (rowPolicyDataYaml?.metadata?.managedFields) {
        delete rowPolicyDataYaml.metadata.managedFields;
      }
      const yamlString = yaml.dump(rowPolicyDataYaml);
      setYamlString(yamlString);
    }
  }, [selectedPolicy, createNewPolicyTitle]);

  return (
    <div className="w-full flex items-center justify-between">
      <Typography variant="body2" className="flex items-center gap-1 grow">
        {!createNewPolicyTitle && !isCustomizedPolicy && <LockIcon />}
        <div className="flex items-center">
          {createNewPolicyTitle ? "Create new policy" : selectedPolicy?.metadata?.name}
        </div>
        {!createNewPolicyTitle && isBuiltInPolicy(selectedPolicy) && (
          <Typography
            variant="caption"
            className="bg-mainMenuSelected rounded-full px-2 text-black flex items-center justify-center gap-1"
          >
            <CustomIcon width={14} height={14} />
            Built in policy
          </Typography>
        )}
      </Typography>
      {!createNewPolicyTitle && (
        <Tooltip
          title={
            <>
              <b>Click to export</b> policy YAML file.
            </>
          }
        >
          <Typography
            variant="caption"
            onClick={() =>
              exportStringAsFile({
                fileString: yamlString,
                fileName: selectedPolicy?.metadata?.name ?? "policy",
                extension: FileExtension.YAML,
              })
            }
            className="cursor-pointer hover:text-strongBorder flex justify-center items-center gap-1 borders border border-white hover:border-strongBorder  rounded-full py-1 px-2"
          >
            <p className="text-[10px]">Export YAML</p>
            <MainDownloadIcon width={12} height={12} />
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

export default HPAPolicyTitle;
