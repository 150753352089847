import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography, TypographyVariant } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import DuplicateIcon from "../Icons/DuplicateIcon";
import HideIcon from "../Icons/HideIcon";
import ShowIcon from "../Icons/ShowIcon";
import Tooltip from "./Tooltip";

export enum THEME {
  dark = "dark",
  light = "light",
}
interface Props {
  title?: string;
  description?: React.ReactNode;
  info?: React.ReactNode;
  codeSnippet: string;
  codeSnippetToCopy?: string;
  className?: string;
  theme?: THEME;
  oneLiner?: boolean;
}

const CodeSnippet = ({
  title,
  description,
  info,
  codeSnippet,
  codeSnippetToCopy,
  className,
  theme = THEME.dark,
  oneLiner = false,
}: Props) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);

  const iconClassName = clsx({
    "hover:text-primary-lightPurpleBlue": theme === THEME.dark,
    "hover:text-guideline-darkPurple": theme === THEME.light,
  });

  const codeToDisplay = showToken && codeSnippetToCopy ? codeSnippetToCopy : codeSnippet;

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  const onClick = () => {
    setShowCopied(true);
    let codeToCopy = codeSnippetToCopy ?? codeSnippet;
    codeToCopy = codeToCopy.trim();
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className={clsx(className, "flex flex-col gap-4")}>
      {title && (
        <Typography variant="body1" fontWeight={700} className="flex gap-2 item-center">
          {title}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
      )}
      {description && <Typography variant="body2">{description}</Typography>}
      <div
        className={clsx("rounded-md text-sm font-mono overflow-x-auto hide-scroll-bar cursor-pointer p-2 relative", {
          "bg-black text-white": theme === THEME.dark,
          "bg-background-chip text-black": theme === THEME.light,
          "px-6": !oneLiner,
          "px-2": oneLiner,
        })}
        onClick={onClick}
      >
        {!oneLiner && (
          <div className="w-full flex justify-end gap-2 h-[20px]">
            {codeSnippetToCopy && (
              <Tooltip title="Show/Hide">
                <div
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    setShowToken(!showToken);
                  }}
                >
                  {showToken ? <ShowIcon className={iconClassName} /> : <HideIcon className={iconClassName} />}
                </div>
              </Tooltip>
            )}
            <DuplicateIcon className={iconClassName} />
          </div>
        )}
        <div className="flex items-center w-full justify-between text- ">
          <div className={clsx("whitespace-pre-wrap", { "pt-1 px-6 pb-6": !oneLiner, "px-1 text-xs": oneLiner })}>
            {codeToDisplay.trim()}
          </div>
          {oneLiner && <DuplicateIcon className={iconClassName} />}
        </div>
        <div
          className={clsx("bg-[rgba(255,255,255,0.8)] absolute text-black rounded-md fade-in-and-out ", {
            hidden: !showCopied,
            "p-2 bottom-[2px] right-[2px]": !oneLiner,
            "right-8 top-2 px-2": oneLiner,
          })}
        >
          <Typography
            variant={
              clsx({
                body2: !oneLiner,
                caption: oneLiner,
              }) as TypographyVariant
            }
          >
            Copied to clipboard
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CodeSnippet;
