import { IconProps } from "./utils/utils";

const RightArrowIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M1.53332 12.5417L10.8666 12.5417L9.06664 14.3417C8.46664 14.9417 8.46664 15.9417 9.06664 16.5417L10.4666 18.0084C11.0666 18.6084 12.0666 18.6084 12.6666 18.0084L19.5333 11.1417C19.8 10.8084 20 10.4084 20 10.0084C20 9.54168 19.8667 9.14172 19.5333 8.80836L12.6666 2.00836C12.4 1.74168 12 1.54168 11.5333 1.54168C11.1333 1.54168 10.7333 1.675 10.4666 2.00836L9.06664 3.40836C8.46664 4.00836 8.46664 5.00836 9.06664 5.60836L11 7.475L1.53332 7.475C0.66668 7.475 8.96083e-07 8.20836 8.20319e-07 9.075L6.51303e-07 11.0083C5.75536e-07 11.875 0.66668 12.5417 1.53332 12.5417ZM1.26668 9.075L1.33332 9.075C1.33332 8.94168 1.46664 8.80832 1.53332 8.80832L12.6 8.80832C12.8667 8.80832 13.1333 8.675 13.2 8.40832C13.3333 8.14164 13.2667 7.875 13.0667 7.67496L10 4.67496C9.93332 4.60828 9.93332 4.47496 10 4.3416L11.4 2.9416C11.4667 2.87492 11.6 2.80828 11.7334 2.9416L18.6 9.80836C18.6666 9.875 18.6666 9.94168 18.6666 10.0083C18.6666 10.075 18.6666 10.1416 18.6 10.2083L11.7333 17.075C11.6666 17.1416 11.6 17.1416 11.6 17.1416C11.5333 17.1416 11.4667 17.1416 11.4667 17.075L10 15.675C9.93332 15.6084 9.93332 15.4084 10 15.3417L12.9333 12.3417C13.1333 12.1417 13.2 11.8751 13.0666 11.6084C12.9333 11.3417 12.7333 11.2084 12.4666 11.2084L1.46664 11.2084C1.4 11.2084 1.26664 11.2084 1.26664 11.075L1.26664 9.075L1.26668 9.075Z" />
    </svg>
  );
};

export default RightArrowIcon;
