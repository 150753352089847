import { IconProps } from "./utils/utils";

const UpAndDownCircleIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M10 0C4.48438 0 0 4.48438 0 10C0 15.5156 4.48438 20 10 20C15.5156 20 20 15.5156 20 10C20 4.48438 15.5156 0 10 0ZM10 18.75C5.17578 18.75 1.25 14.8242 1.25 10C1.25 5.17578 5.17578 1.25 10 1.25C14.8242 1.25 18.75 5.17578 18.75 10C18.75 14.8242 14.8242 18.75 10 18.75ZM15.3242 11.5156C15.5664 11.7578 15.5664 12.1562 15.3242 12.3984L13.3242 14.3984C13.207 14.5156 13.0469 14.582 12.8828 14.582C12.7187 14.582 12.5586 14.5156 12.4414 14.3984L10.4453 12.3984C10.2031 12.1523 10.2031 11.7578 10.4453 11.5156C10.6914 11.2734 11.0859 11.2734 11.3281 11.5156L12.2578 12.4453V6.03906C12.2578 5.69531 12.5391 5.41406 12.8828 5.41406C13.2266 5.41406 13.5078 5.69531 13.5078 6.03906V12.4453L14.4375 11.5156C14.6836 11.2734 15.082 11.2734 15.3242 11.5156ZM9.55469 7.59766C9.80078 7.83984 9.80078 8.23828 9.55469 8.48047C9.43359 8.60156 9.27344 8.66406 9.11328 8.66406C8.95313 8.66406 8.79297 8.60156 8.67188 8.48047L7.73828 7.55078V13.957C7.73828 14.3008 7.45703 14.582 7.11328 14.582C6.76953 14.582 6.48828 14.3008 6.48828 13.957V7.55078L5.55469 8.48047C5.30859 8.72266 4.91406 8.72266 4.67187 8.48047C4.42969 8.23438 4.42969 7.83984 4.67187 7.59766L6.67188 5.60156C6.91406 5.35937 7.3125 5.35937 7.55469 5.60156L9.55469 7.59766Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UpAndDownCircleIcon;
