export function findSuffix(quantity: string): string {
  let ix = quantity.length - 1;
  while (ix >= 0 && !/[.0-9]/.test(quantity.charAt(ix))) {
    ix--;
  }
  return ix === -1 ? "" : quantity.substring(ix + 1);
}

export function quantityToScalar(quantity: string) {
  if (!quantity) {
    return 0;
  }
  const suffix = findSuffix(quantity);
  if (suffix === "") {
    const num = Number(quantity).valueOf();
    if (isNaN(num)) {
      throw new Error("Unknown quantity " + quantity);
    }
    return num;
  }

  let output;
  switch (suffix) {
    case "n":
      output = Number(quantity.substr(0, quantity.length - 1)).valueOf() / 1_000_000_000.0;
      break;
    case "u":
      output = Number(quantity.substr(0, quantity.length - 1)).valueOf() / 1_000_000.0;
      break;
    case "m":
      output = Number(quantity.substr(0, quantity.length - 1)).valueOf() / 1000.0;
      break;
    case "k":
      output = Number(quantity.substr(0, quantity.length - 1)) * Number(1000);
      break;
    case "M":
      output = Number(quantity.substr(0, quantity.length - 1)) * Number(1000 * 1000);
      break;
    case "G":
      output = Number(quantity.substr(0, quantity.length - 1)) * Number(1000 * 1000 * 1000);
      break;
    case "T":
      output = Number(quantity.substr(0, quantity.length - 1)) * Number(1000 * 1000 * 1000) * Number(1000);
      break;
    case "P":
      output = Number(quantity.substr(0, quantity.length - 1)) * Number(1000 * 1000 * 1000) * Number(1000 * 1000);
      break;
    case "E":
      output =
        Number(quantity.substr(0, quantity.length - 1)) * Number(1000 * 1000 * 1000) * Number(1000 * 1000 * 1000);
      break;
    case "Ki":
      output = Number(quantity.substr(0, quantity.length - 2)) * Number(1024);
      break;
    case "Mi":
      output = Number(quantity.substr(0, quantity.length - 2)) * Number(1024 * 1024);
      break;
    case "Gi":
      output = Number(quantity.substr(0, quantity.length - 2)) * Number(1024 * 1024 * 1024);
      break;
    case "Ti":
      output = Number(quantity.substr(0, quantity.length - 2)) * Number(1024 * 1024 * 1024) * Number(1024);
      break;
    case "Pi":
      output = Number(quantity.substr(0, quantity.length - 2)) * Number(1024 * 1024 * 1024) * Number(1024 * 1024);
      break;
    case "Ei":
      output =
        Number(quantity.substr(0, quantity.length - 2)) * Number(1024 * 1024 * 1024) * Number(1024 * 1024 * 1024);
      break;
    default:
      output = 0;
  }

  return Number(output.toFixed(3));
}
