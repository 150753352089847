import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { DeleteHPAPolicy, DeleteHPAPolicyParams, GetHPAPolicies, GetHPAPoliciesResponse } from "../../../api/fetcher";

const { queryFn: deleteHPAQueryFn } = DeleteHPAPolicy();
const { queryKey: getHPAPoliciesQueryKey } = GetHPAPolicies();

const useDeleteHPAPolicy = () => {
  const queryClient = useQueryClient();

  return useMutation((params: DeleteHPAPolicyParams) => deleteHPAQueryFn(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries([getHPAPoliciesQueryKey]);
      const previousPolicies = queryClient.getQueryData<GetHPAPoliciesResponse>([getHPAPoliciesQueryKey]);
      if (previousPolicies) {
        queryClient.setQueryData([getHPAPoliciesQueryKey], {
          policies: previousPolicies.policies?.filter(
            (policy) => policy.metadata?.name && policy.metadata.name !== params.name
          ),
        });
      }
    },
    onError: (error: Error) => {
      console.log(`Error deleting HPA policy: ${error.message}`);
      toast.error(`Failed to delete HPA policy`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([getHPAPoliciesQueryKey]).catch((error) => console.log(error));
      toast.success("HPA Policy was deleted successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useDeleteHPAPolicy;
