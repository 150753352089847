import MultiSelect from "../../../components/MultiSelect";
import CustomFilterButton from "../../../components/CustomFilterButton";

interface NamespaceProps {
  selectedNamespaceNames: (string | undefined)[];
  setSelectedNamespaces: (namespaceNamespaces: (string | undefined)[]) => void;
  isFiltered: boolean;
  options: string[];
}

const MultiSelectNamespacesComponent = ({
  selectedNamespaceNames,
  setSelectedNamespaces,
  isFiltered,
  options,
}: NamespaceProps) => {
  return (
    <MultiSelect
      selected={selectedNamespaceNames}
      setSelected={setSelectedNamespaces as (props: (string | undefined)[]) => void}
      options={options}
      isSearchable
      className="mt-[-20px] w-[132px]"
      customIcon={<CustomFilterButton label="namespaces" isFiltered={isFiltered} />}
      hasVirtualizedList
    />
  );
};

export default MultiSelectNamespacesComponent;
