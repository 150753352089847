import { IconProps } from "./utils/utils";

const OOMIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="1" />
      <g>
        <path
          d="M14.2383 17H5.77998C4.89505 17 4.17578 16.2807 4.17578 15.3958V4.60419C4.17578 3.71926 4.89505 2.99999 5.77998 2.99999H12.4999C12.8931 2.99999 13.2717 3.14344 13.5657 3.40481L15.304 4.94952C15.6465 5.25457 15.8425 5.69154 15.8425 6.1488V15.3958C15.8425 16.2807 15.1232 17 14.2383 17ZM5.77998 3.87499C5.37805 3.87499 5.05078 4.20226 5.05078 4.60419V15.3958C5.05078 15.7977 5.37805 16.125 5.77998 16.125H14.2383C14.6402 16.125 14.9675 15.7977 14.9675 15.3958V6.1488C14.9675 5.94115 14.8782 5.74227 14.7225 5.60342L12.9841 4.05871C12.8505 3.94036 12.6784 3.87499 12.4999 3.87499H5.77998Z"
          fill="white"
        />
        <path
          d="M6.36328 6.5C6.12178 6.5 5.92578 6.304 5.92578 6.0625V5.1875C5.92578 4.946 6.12178 4.75 6.36328 4.75C6.60478 4.75 6.80078 4.946 6.80078 5.1875V6.0625C6.80078 6.304 6.60478 6.5 6.36328 6.5Z"
          fill="white"
        />
        <path
          d="M8.11328 6.5C7.87178 6.5 7.67578 6.304 7.67578 6.0625V5.1875C7.67578 4.946 7.87178 4.75 8.11328 4.75C8.35478 4.75 8.55078 4.946 8.55078 5.1875V6.0625C8.55078 6.304 8.35478 6.5 8.11328 6.5Z"
          fill="white"
        />
        <path
          d="M9.86328 6.5C9.62178 6.5 9.42578 6.304 9.42578 6.0625V5.1875C9.42578 4.946 9.62178 4.75 9.86328 4.75C10.1048 4.75 10.3008 4.946 10.3008 5.1875V6.0625C10.3008 6.304 10.1048 6.5 9.86328 6.5Z"
          fill="white"
        />
        <path
          d="M11.6133 6.5C11.3718 6.5 11.1758 6.304 11.1758 6.0625V5.1875C11.1758 4.946 11.3718 4.75 11.6133 4.75C11.8548 4.75 12.0508 4.946 12.0508 5.1875V6.0625C12.0508 6.304 11.8548 6.5 11.6133 6.5Z"
          fill="white"
        />
        <path
          d="M13.655 15.25H6.36328C6.12178 15.25 5.92578 15.054 5.92578 14.8125V10.4375C5.92578 10.196 6.12178 10 6.36328 10H13.655C13.8965 10 14.0925 10.196 14.0925 10.4375V14.8125C14.0925 15.054 13.8965 15.25 13.655 15.25ZM6.80078 14.375H13.2175V10.875H6.80078V14.375Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="14" height="14" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OOMIcon;
