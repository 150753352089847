import { IconProps } from "./utils/utils";

const LogIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg viewBox="0 0 1707 1707" height={height} width={width} className={className} {...props}>
      <g id="Layer_x0020_1">
        <path
          d="m998 1512c-99 0-170-128-87-226 54-63 168-46 194 40 9 29-35 42-43 13-16-52-86-59-117-23-31 37-34 81-7 118 16 23 54 53 126 11v-30h-63c-30 0-30-45 0-45 124 0 109-16 109 88 0 7-4 15-11 19-40 25-73 35-101 35zm456-414h-1255c-50 0-91 41-91 90v383c0 50 41 90 91 90h1309c50 0 90-40 90-90v-383c0-49-40-90-90-90zm-22-45v-725h-188c-52 0-95-43-95-95v-188h-698c-97 0-176 80-176 177v831zm45-744v744h31c75 0 136 61 136 135v383c0 75-61 136-136 136h-1309c-75 0-136-61-136-136v-383c0-74 61-135 136-135h31v-831c0-122 99-222 221-222h721c7 0 12 3 17 7l280 280c6 6 10 13 8 22zm-282-231v155c0 27 22 49 49 49h156zm73 846h-798c-30 0-30-46 0-46h798c30 0 30 46 0 46zm0-175h-798c-30 0-30-45 0-45h798c30 0 30 45 0 45zm0-174h-798c-30 0-30-46 0-46h798c30 0 30 46 0 46zm-574 936c-81 0-124-66-124-131 0-34 11-66 31-90 22-27 55-42 93-42 81 0 123 66 123 132 0 34-10 65-30 89-23 28-55 42-93 42zm0-218c-54 0-78 44-78 87s24 86 78 86c24 0 44-9 58-26 13-15 20-37 20-60 0-43-24-87-78-87zm567 218c-93 0-117-99-71-99 12 0 23 10 23 22 0 42 97 42 97 0 0-19-9-24-53-32-36-6-90-16-90-77 0-42 42-77 94-77 94 0 118 100 72 100-13 0-23-10-23-23 0-41-97-41-97 0 0 19 9 24 52 32 36 7 90 17 90 77 0 43-42 77-94 77zm-274-1095c-20 0-30-25-16-39l91-91-91-92c-21-21 11-53 32-32l108 107c8 9 8 24 0 33l-108 107c-4 4-10 7-16 7zm-239 0c-6 0-12-3-16-7l-107-107c-9-9-9-24 0-33l107-107c21-21 53 11 32 32l-91 92 91 91c14 14 4 39-16 39zm91 4c-15 0-26-15-22-29l58-216c7-29 51-17 43 11l-57 217c-3 10-12 17-22 17zm-329 1091h-136c-13 0-23-10-23-22v-218c0-30 46-30 46 0v195h113c30 0 30 45 0 45z"
          fill="rgb(0,0,0)"
        ></path>
      </g>
    </svg>
  );
};

export default LogIcon;
