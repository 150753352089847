import { IconProps } from "./utils/utils";

const LogoutIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.3744 9.37533H11.4579C11.1129 9.37533 10.8329 9.09534 10.8329 8.75035C10.8329 8.40536 11.1129 8.12537 11.4579 8.12537H19.3744C19.7194 8.12537 19.9994 8.40536 19.9994 8.75035C19.9994 9.09534 19.7194 9.37533 19.3744 9.37533Z" />
        <path d="M16.2495 12.5002C16.0894 12.5002 15.9295 12.4393 15.8077 12.317C15.5636 12.0727 15.5636 11.6769 15.8077 11.4327L18.4911 8.74957L15.8077 6.06624C15.5636 5.82211 15.5636 5.42631 15.8077 5.18218C16.052 4.93789 16.4478 4.93789 16.692 5.18218L19.8169 8.30708C20.061 8.55121 20.061 8.94701 19.8169 9.19115L16.692 12.316C16.5694 12.4393 16.4095 12.5002 16.2495 12.5002Z" />
        <path d="M6.66651 20C6.48814 20 6.31892 19.975 6.14986 19.9225L1.13491 18.2517C0.452561 18.0134 0 17.3775 0 16.6668V1.66728C0 0.748115 0.747505 0.000610352 1.66667 0.000610352C1.84488 0.000610352 2.0141 0.025634 2.18331 0.0781226L7.19811 1.74891C7.88061 1.98724 8.33302 2.62306 8.33302 3.33379V18.3333C8.33302 19.2525 7.58567 20 6.66651 20ZM1.66667 1.25057C1.43749 1.25057 1.24996 1.4381 1.24996 1.66728V16.6668C1.24996 16.8443 1.36913 17.0092 1.53911 17.0684L6.53056 18.7317C6.56641 18.7433 6.6131 18.75 6.66651 18.75C6.89569 18.75 7.08306 18.5625 7.08306 18.3333V3.33379C7.08306 3.15633 6.96389 2.99139 6.79392 2.93219L1.80246 1.26888C1.76661 1.25728 1.71992 1.25057 1.66667 1.25057Z" />
        <path d="M12.7079 6.66712C12.3629 6.66712 12.0829 6.38713 12.0829 6.04214V2.29226C12.0829 1.71809 11.6155 1.25057 11.0414 1.25057H1.66667C1.32168 1.25057 1.04169 0.970581 1.04169 0.625591C1.04169 0.2806 1.32168 0.000610352 1.66667 0.000610352H11.0414C12.3055 0.000610352 13.3329 1.02811 13.3329 2.29226V6.04214C13.3329 6.38713 13.0529 6.66712 12.7079 6.66712Z" />
        <path d="M11.0414 17.5001H7.70805C7.36306 17.5001 7.08307 17.2201 7.08307 16.8751C7.08307 16.5301 7.36306 16.2501 7.70805 16.2501H11.0414C11.6156 16.2501 12.0829 15.7826 12.0829 15.2084V11.4585C12.0829 11.1135 12.3629 10.8336 12.7079 10.8336C13.0529 10.8336 13.3329 11.1135 13.3329 11.4585V15.2084C13.3329 16.4726 12.3055 17.5001 11.0414 17.5001Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
