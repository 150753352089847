import { useQuery } from "@tanstack/react-query";
import { GetActiveIssues, GetActiveIssuesResponse } from "../../api/fetcher";
import AlertBar, { AlertBarTheme } from "../AlertBar";

const titles: { [key: string]: string } = {
  missingNodeGroups: "Missing node group",
  frequentClusterEvictions: "Frequent cluster evictions",
  frequentWorkloadEvictions: "Frequent workload evictions",
  unstableRecommendation: "Unstable recommendation",
  misconfiguredAutomatedHPA: "Misconfigured automated HPA",
  nodeLifecycleTypeUnknown: "Undetectable node lifecycle",
};

const ActiveIssuesAlerts = () => {
  const { queryFn, queryKey } = GetActiveIssues();
  const { data } = useQuery<GetActiveIssuesResponse, Error>({
    queryKey: [queryKey],
    queryFn,
    refetchInterval: 1000 * 60,
  });

  const activeIssues = Object.keys(data || {});
  return (
    (activeIssues.length > 0 && (
      <>
        {activeIssues.map((issue) => (
          <AlertBar
            key={issue}
            title={"Active Issue"}
            customDescription={titles[issue]}
            theme={AlertBarTheme.MildRed}
            withSnapshotWrapper
            wrapperClassName={"shadow-lg"}
          />
        ))}
      </>
    )) ||
    null
  );
};

export default ActiveIssuesAlerts;
