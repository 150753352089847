import { IconProps } from "./utils/utils";

const RefreshIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.2897 2.75895C18.8599 2.71977 18.4799 3.03637 18.4407 3.46605L18.3372 4.60156C16.581 1.84352 13.5324 0 10 0C6.19997 0 2.8511 2.16883 1.18868 5.42398C0.99243 5.80824 1.14485 6.27883 1.52911 6.47508C1.91345 6.67133 2.38395 6.51895 2.5802 6.13465C3.98712 3.3798 6.81239 1.5625 10 1.5625C12.9054 1.5625 15.5273 3.07543 17.0294 5.44563L15.9155 4.65004C15.5643 4.3993 15.0764 4.48063 14.8257 4.83172C14.5749 5.18285 14.6563 5.67074 15.0074 5.92152L18.3853 8.33406C18.8804 8.68699 19.5632 8.36391 19.6174 7.76926L19.9967 3.60793C20.0359 3.1782 19.7193 2.79812 19.2897 2.75895Z" />
        <path d="M18.4709 13.5249C18.0866 13.3287 17.616 13.4811 17.4198 13.8653C16.0129 16.6202 13.1876 18.4375 9.99999 18.4375C7.09464 18.4375 4.47268 16.9246 2.97057 14.5544L4.08452 15.35C4.43565 15.6007 4.92354 15.5194 5.17432 15.1683C5.42507 14.8171 5.34374 14.3292 4.99264 14.0785L1.61471 11.6659C1.12569 11.3166 0.437332 11.63 0.382644 12.2307L0.00330816 16.3921C-0.0358715 16.8217 0.28073 17.2018 0.710378 17.241C1.14061 17.2801 1.52018 16.9632 1.55932 16.5339L1.66284 15.3984C3.41901 18.1565 6.4676 20 9.99999 20C13.8 20 17.1489 17.8312 18.8113 14.576C19.0076 14.1917 18.8551 13.7212 18.4709 13.5249Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RefreshIcon;
