import { Form, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { HPAPolicy } from "../../../api/fetcher";
import Button from "../../../components/Button";
import RightDrawer from "../../../components/RightDrawer";
import { MenuItem } from "../../../components/SideMenu/SideMenu";
import Tooltip from "../../../components/Tooltip";
import LockIcon from "../../../Icons/LockIcon";
import UpAndDownCircleIcon from "../../../Icons/UpAndDownCircleIcon";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import useCreateHPAPolicy from "../hooks/useCreateHPAPolicy";
import useUpdateHPAPolicy from "../hooks/useUpdateHPAPolicy";
import { defaultHPAPolicyValues, HPAMutationType, policyNameValidation } from "../utils";
import GeneralWorkloadOptimization from "./FormFields/GeneralWorkloadOptimization";
import MinReplicasAllowed from "./FormFields/MinReplicasAllowed";
import NewPolicyName from "./FormFields/NewPolicyName";
import PredictableWorkloadOptimization from "./FormFields/PredictableWorkloadOptimization";
import RequiredWindowPercentage from "./FormFields/RequiredWindowPercentage";
import HPAPolicyTitle from "./HPAPolicyTitle";

enum MenuItemType {
  HPA = "HPA optimization",
}

const menuItems: MenuItem[] = [
  {
    id: MenuItemType.HPA,
    title: MenuItemType.HPA,
    icon: <UpAndDownCircleIcon />,
  },
];

interface Props {
  mutationType: HPAMutationType;
  existingPolicyNames: string[];
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPolicyToEdit?: HPAPolicy | undefined;
  setSelectedPolicyToEdit?: React.Dispatch<React.SetStateAction<HPAPolicy | undefined>>;
}

const EditAndCreateHPAPolicy = ({
  mutationType = HPAMutationType.UPDATE,
  existingPolicyNames,
  isOpen,
  setIsOpen,
  selectedPolicyToEdit,
  setSelectedPolicyToEdit,
}: Props) => {
  const updateHPAPolicy = useUpdateHPAPolicy();
  const createHPAPolicy = useCreateHPAPolicy();

  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCustomizedPolicy = !isBuiltInPolicy(selectedPolicyToEdit) && !isReadyOnlyFrontEnd;

  const handleClose = () => {
    setIsOpen && setIsOpen(false);
    setSelectedPolicyToEdit && setSelectedPolicyToEdit(undefined);
  };

  return (
    <RightDrawer
      title={
        <HPAPolicyTitle
          selectedPolicy={selectedPolicyToEdit}
          createNewPolicyTitle={mutationType === HPAMutationType.CREATE}
        />
      }
      onClose={handleClose}
      isOpen={isOpen}
      menuItems={menuItems}
      defaultSelectedMenuItemId={MenuItemType.HPA}
    >
      <Formik
        initialValues={{
          newPolicyName: "",
          percentilePercentage:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.percentilePercentage ??
            defaultHPAPolicyValues.predictableWorkloadsPercentile,
          lookAheadDuration:
            selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction?.lookAheadDuration ??
            defaultHPAPolicyValues.lookAheadDuration,
          replicasPercentilePercentage:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.percentilePercentage ??
            defaultHPAPolicyValues.generalWorkloadsPercentile,
          replicasWindow:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.window ??
            defaultHPAPolicyValues.generalWorkloadsWindow,
          requiredWindowCoverageDuration:
            selectedPolicyToEdit?.spec?.updatePolicy?.requiredWindowCoverageDuration ??
            defaultHPAPolicyValues.requiredWindowCoverageDuration,
          minAllowed:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.minAllowed ?? defaultHPAPolicyValues.minAllowed,
        }}
        validationSchema={Yup.object({
          newPolicyName:
            mutationType === HPAMutationType.CREATE
              ? policyNameValidation(existingPolicyNames)
              : Yup.mixed().notRequired(),
          percentilePercentage: Yup.number().required("Required"),
          lookAheadDuration: Yup.string().required("Required"),
          replicasPercentilePercentage: Yup.number().required("Required"),
          replicasWindow: Yup.string().required("Required"),
          requiredWindowCoverageDuration: Yup.string().required("Required"),
          minAllowed: Yup.number().required("Required"),
        })}
        onSubmit={(values) => {
          if (mutationType === HPAMutationType.UPDATE) {
            updateHPAPolicy.mutate({
              name: String(selectedPolicyToEdit?.metadata?.name),
              policy: {
                ...selectedPolicyToEdit,
                spec: {
                  ...selectedPolicyToEdit?.spec,
                  policyOptimize: {
                    ...selectedPolicyToEdit?.spec?.policyOptimize,
                    minReplicas: {
                      ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas,
                      predictableWorkloads: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads,
                        percentilePercentage: values.percentilePercentage,
                      },
                      generalWorkloads: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads,
                        percentilePercentage: values.replicasPercentilePercentage,
                        window: values.replicasWindow,
                      },
                      minAllowed: values.minAllowed,
                    },
                    replicas: {
                      ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas,
                      prediction: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction,
                        lookAheadDuration: values.lookAheadDuration,
                      },
                    },
                  },
                  updatePolicy: {
                    ...selectedPolicyToEdit?.spec?.updatePolicy,
                    requiredWindowCoverageDuration: values.requiredWindowCoverageDuration,
                  },
                },
              },
            });
          }

          if (mutationType === HPAMutationType.CREATE) {
            createHPAPolicy.mutate({
              policy: {
                ...selectedPolicyToEdit,
                metadata: {
                  ...selectedPolicyToEdit?.metadata,
                  name: values.newPolicyName,
                  resourceVersion: undefined,
                  creationTimestamp: undefined,
                  uid: undefined,
                  generation: undefined,
                },
                spec: {
                  ...selectedPolicyToEdit?.spec,
                  policyOptimize: {
                    ...selectedPolicyToEdit?.spec?.policyOptimize,
                    minReplicas: {
                      ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas,
                      predictableWorkloads: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads,
                        percentilePercentage: values.percentilePercentage,
                      },
                      generalWorkloads: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads,
                        percentilePercentage: values.replicasPercentilePercentage,
                        window: values.replicasWindow,
                      },
                      minAllowed: values.minAllowed,
                    },
                    replicas: {
                      ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas,
                      prediction: {
                        ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction,
                        lookAheadDuration: values.lookAheadDuration,
                      },
                    },
                  },
                  updatePolicy: {
                    ...selectedPolicyToEdit?.spec?.updatePolicy,
                    requiredWindowCoverageDuration: values.requiredWindowCoverageDuration,
                  },
                },
              },
            });
          }

          handleClose();
        }}
      >
        {(formik) => {
          useEffect(() => {
            formik.validateForm();
          }, []);

          return (
            <Form className="h-full w-[700px] px-[30px] pt-4 flex flex-col justify-start">
              <div className="px-[30px] flex-grow flex flex-col overflow-hidden h-[calc(100vh-160px)] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chipActive scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
                {mutationType === HPAMutationType.CREATE ? <NewPolicyName /> : null}
                <PredictableWorkloadOptimization isCustomizedPolicy={isCustomizedPolicy} />
                <GeneralWorkloadOptimization isCustomizedPolicy={isCustomizedPolicy} />
                <RequiredWindowPercentage isCustomizedPolicy={isCustomizedPolicy} />
                <MinReplicasAllowed isCustomizedPolicy={isCustomizedPolicy} />
              </div>
              <div className="flex justify-end gap-4 p-5 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
                <Button onClick={handleClose} label="Cancel" type="button" />
                {isCustomizedPolicy && <Button type="submit" label="Save" disabled={!formik.isValid} />}
                {!isCustomizedPolicy && (
                  <div className="flex items-center">
                    <Tooltip
                      title={
                        <>
                          <b>Default policies can't be edited</b>.<br />
                          You can only edit customized policies
                        </>
                      }
                    >
                      <LockIcon width={24} height={24} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </RightDrawer>
  );
};

export default EditAndCreateHPAPolicy;
