import { IconProps } from "./utils/utils";

const CircleUpArrowIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M10 0C4.48609 0 0 4.48609 0 10C0 15.5139 4.48609 20 10 20C15.5139 20 20 15.5139 20 10C20 4.48609 15.5139 0 10 0ZM10 18.75C5.17516 18.75 1.25 14.8248 1.25 10C1.25 5.17516 5.17516 1.25 10 1.25C14.8248 1.25 18.75 5.17516 18.75 10C18.75 14.8248 14.8248 18.75 10 18.75ZM13.5669 8.93313C13.8111 9.17734 13.8111 9.57281 13.5669 9.81687C13.4448 9.93891 13.2848 10 13.125 10C12.9652 10 12.8052 9.93891 12.6831 9.81687L10.625 7.75875V13.75C10.625 14.0955 10.3452 14.375 10 14.375C9.65484 14.375 9.375 14.0955 9.375 13.75V7.75875L7.31687 9.81687C7.07266 10.0611 6.67719 10.0611 6.43313 9.81687C6.18906 9.57266 6.18891 9.17719 6.43313 8.93313L9.55813 5.80813C9.80234 5.56391 10.1978 5.56391 10.4419 5.80813L13.5669 8.93313Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleUpArrowIcon;
