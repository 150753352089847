import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeleteIngress, GetExistingIngress, GetIngressesResponse } from "../../../api/fetcher";
import { toast } from "react-toastify";
import { INGRESS_PROGRESS, TOAST_SETTINGS } from "./utils";
import { Dispatch } from "react";

const { queryKey: getQueryKey } = GetExistingIngress();
const { queryFn: deleteQueryFn } = DeleteIngress();

interface Props {
  setIngressStatus: Dispatch<React.SetStateAction<INGRESS_PROGRESS>>;
}
export const useDeleteIngress = ({ setIngressStatus }: Props) => {
  const queryClient = useQueryClient();

  return useMutation(() => deleteQueryFn(), {
    onMutate: () => {
      queryClient.setQueryData([getQueryKey], (oldData: GetIngressesResponse | undefined) => {
        return {
          ...oldData,
          exists: false,
          existingIsBeingDeleted: true,
        };
      });
    },
    onError: (error: Error & { reason?: string }) => {
      console.log(`Error: ${error.message}`);
      setIngressStatus(INGRESS_PROGRESS.FAILED_TO_DELETE);
      toast.error(`Error Deleting Ingress ${error?.reason ?? ""}`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Ingress was deleted successfully!", TOAST_SETTINGS);
      setIngressStatus(INGRESS_PROGRESS.DELETED);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getQueryKey]);
    },
  });
};

export default useDeleteIngress;
