import { IconProps } from "./utils/utils";

const UnFilterIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M14.1086 0C11.9969 0 10.2088 1.40815 9.63577 3.33322H2.0988C1.39474 3.33322 0.981074 4.1277 1.38455 4.70504C1.3908 4.71407 1.17506 4.4195 6.45275 11.6206C6.55876 11.7749 6.61478 11.9553 6.61478 12.1427V19.0836C6.61478 19.8428 7.4829 20.2648 8.07856 19.8162L10.3283 18.1163C10.6647 17.8657 10.8655 17.4657 10.8655 17.0456V12.1427C10.8655 11.9553 10.9216 11.7749 11.0276 11.6205L12.8439 9.14196C15.818 9.97942 18.7739 7.73486 18.7739 4.65797C18.7739 2.08955 16.6811 0 14.1086 0ZM10.0777 10.9343C9.84975 11.2455 9.69366 11.6803 9.69366 12.1428C9.69366 17.4741 9.72233 17.1068 9.62804 17.1767C9.55343 17.2322 10.0513 16.8568 7.78665 18.568V12.1427C7.78665 11.7126 7.65669 11.2987 7.41079 10.9456C7.40419 10.9362 7.60309 11.2077 2.69071 4.50504H9.44605C9.38913 6.25706 10.3103 7.82381 11.7381 8.66833L10.0777 10.9343ZM14.1086 8.14412C12.1823 8.14412 10.6151 6.58026 10.6151 4.65797C10.6151 2.73572 12.1823 1.17187 14.1086 1.17187C16.0349 1.17187 17.602 2.73572 17.602 4.65797C17.6021 6.58026 16.0349 8.14412 14.1086 8.14412Z" />
        <path d="M15.6327 3.13662C15.4041 2.90756 15.0331 2.90725 14.8041 3.13584L14.1086 3.8301L13.4131 3.13584C13.1841 2.90721 12.8131 2.90748 12.5845 3.13662C12.3558 3.36561 12.3562 3.73663 12.5852 3.96526L13.2792 4.65803L12.5852 5.3508C12.3562 5.57944 12.3558 5.95045 12.5845 6.17944C12.8123 6.40772 13.1833 6.40955 13.4131 6.18022L14.1086 5.48596L14.8041 6.18022C15.0324 6.40811 15.4034 6.4092 15.6327 6.17944C15.8614 5.95045 15.861 5.57944 15.632 5.3508L14.938 4.65803L15.632 3.96526C15.861 3.73663 15.8614 3.36561 15.6327 3.13662Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnFilterIcon;
