import { Dayjs } from "dayjs";
import { adjustedDayjs } from "../utils/dateAndTimeUtils";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";

interface Props {
  timestamp: string | number | Dayjs;
  timeFormat?: string;
  fontWeight?: number;
  fontSize?: string;
}
const ChartTooltipTime = ({
  timestamp,
  timeFormat = DEFAULT_DATE_TIME_FORMAT,
  fontWeight = 600,
  fontSize = "10px",
}: Props) => {
  return <p style={{ fontWeight, fontSize }}>{adjustedDayjs(timestamp).format(timeFormat)}</p>;
};

export default ChartTooltipTime;
