import { IconProps } from "./utils/utils";

const EditIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.4592 12.5638C19.1841 12.5638 18.9611 12.7868 18.9611 13.062V17.4846C18.9601 18.3096 18.2918 18.9781 17.4668 18.9789H3.49052C2.66553 18.9781 1.99718 18.3096 1.99621 17.4846V4.50449C1.99718 3.67971 2.66553 3.01116 3.49052 3.01018H7.91313C8.18826 3.01018 8.41124 2.7872 8.41124 2.51208C8.41124 2.23715 8.18826 2.01398 7.91313 2.01398H3.49052C2.11567 2.01553 1.00156 3.12965 1 4.50449V17.4848C1.00156 18.8596 2.11567 19.9737 3.49052 19.9753H17.4668C18.8416 19.9737 19.9558 18.8596 19.9573 17.4848V13.062C19.9573 12.7868 19.7343 12.5638 19.4592 12.5638Z" />
        <path d="M19.76 0.732339C18.8846 -0.143039 17.4654 -0.143039 16.5901 0.732339L7.70358 9.61882C7.64268 9.67972 7.59871 9.75521 7.57575 9.8381L6.40715 14.057C6.35909 14.23 6.40793 14.4152 6.53479 14.5423C6.66185 14.6691 6.84708 14.718 7.02005 14.6701L11.239 13.5013C11.3218 13.4783 11.3973 13.4344 11.4582 13.3735L20.3445 4.4868C21.2185 3.61083 21.2185 2.19279 20.3445 1.31683L19.76 0.732339ZM8.78891 9.94259L16.0618 2.6695L18.4074 5.01506L11.1343 12.2881L8.78891 9.94259ZM8.32038 10.8828L10.1943 12.7569L7.60221 13.475L8.32038 10.8828ZM19.6402 3.78245L19.1119 4.31071L16.7662 1.96495L17.2946 1.43669C17.7808 0.950454 18.5692 0.950454 19.0555 1.43669L19.6402 2.02118C20.1256 2.508 20.1256 3.29582 19.6402 3.78245Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
