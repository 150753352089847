import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { DateType, getFromDate } from "../../../../Analytics/AnalyticsV2/utils";

export type FilterParamObject = {
  [key: string]: string[] | boolean | string | undefined | number;
};

export const useMultiClusterUseQueryParam = () =>
  useQueryParam(FilterOptions.MultiCluster, withDefault(BooleanParam, true));

export enum FilterOptions {
  Clusters = "clusters",
  groupByCluster = "groupByCluster",
  GroupByNamespace = "groupByNamespace",
  MultiCluster = "groupByCluster",
  shouldIgnoreWorkloads = "shouldIgnoreWorkloads",
  Tags = "tags",
  GroupByAnnotations = "groupByAnnotations",
  GroupByLabels = "groupByLabels",
  From = "from",
  To = "to",
  Range = "range",
}

const useAggregationFilters = (): FilterParamObject => {
  const [currentEpoch] = useState(Math.floor(Date.now() / 1000) * 1000);

  const [filters, setFilters] = useState<FilterParamObject>({});

  const [clustersParams] = useQueryParam(FilterOptions.Clusters, ArrayParam);
  const [GroupByNamespaceParams] = useQueryParam(FilterOptions.GroupByNamespace, withDefault(BooleanParam, true));
  const [multiClusterParams] = useMultiClusterUseQueryParam();
  const [shouldIgnoreWorkloadsParams] = useQueryParam(FilterOptions.shouldIgnoreWorkloads, BooleanParam);
  const [tagsParams] = useQueryParam(FilterOptions.Tags, ArrayParam);
  const [groupByAnnotationsParams] = useQueryParam(FilterOptions.GroupByAnnotations, ArrayParam);
  const [groupByLabelsParams] = useQueryParam(FilterOptions.GroupByLabels, ArrayParam);
  const [fromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(7, currentEpoch)));
  const [toDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range] = useQueryParam("range", withDefault(StringParam, "7d"));

  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };

  useEffect(() => {
    const filtersObj: FilterParamObject = {};

    filtersObj[FilterOptions.Clusters] = clustersParams ? clustersParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.GroupByNamespace] = GroupByNamespaceParams ? GroupByNamespaceParams : undefined;
    filtersObj[FilterOptions.MultiCluster] = multiClusterParams ? multiClusterParams : undefined;
    filtersObj[FilterOptions.shouldIgnoreWorkloads] = shouldIgnoreWorkloadsParams
      ? shouldIgnoreWorkloadsParams
      : undefined;
    filtersObj[FilterOptions.Tags] = tagsParams ? tagsParams.map((value) => String(value)) : [];
    filtersObj[FilterOptions.GroupByAnnotations] = groupByAnnotationsParams
      ? groupByAnnotationsParams.map((value) => String(value))
      : [];
    filtersObj[FilterOptions.GroupByLabels] = groupByLabelsParams
      ? groupByLabelsParams.map((value) => String(value))
      : [];
    filtersObj[FilterOptions.From] = date.from;
    filtersObj[FilterOptions.To] = date.to;
    filtersObj[FilterOptions.Range] = date.range;

    setFilters(filtersObj);
  }, [
    clustersParams,
    GroupByNamespaceParams,
    multiClusterParams,
    shouldIgnoreWorkloadsParams,
    tagsParams,
    groupByAnnotationsParams,
    groupByLabelsParams,
    fromDate,
    toDate,
    range,
  ]);

  return filters;
};

export default useAggregationFilters;
