import { IconProps } from "./utils/utils";

const MagicStars = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;

  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.875 0.562561C7.04759 0.562561 7.1875 0.702467 7.1875 0.875061C7.1875 3.80908 9.56598 6.18756 12.5 6.18756C12.6726 6.18756 12.8125 6.32747 12.8125 6.50006C12.8125 6.67265 12.6726 6.81256 12.5 6.81256C9.56598 6.81256 7.1875 9.19105 7.1875 12.1251C7.1875 12.2977 7.04759 12.4376 6.875 12.4376C6.70241 12.4376 6.5625 12.2977 6.5625 12.1251C6.5625 9.19105 4.18402 6.81256 1.25 6.81256C1.07741 6.81256 0.9375 6.67265 0.9375 6.50006C0.9375 6.32747 1.07741 6.18756 1.25 6.18756C4.18402 6.18756 6.5625 3.80908 6.5625 0.875061C6.5625 0.702467 6.70241 0.562561 6.875 0.562561ZM6.875 2.78097C6.28358 4.52705 4.90198 5.90864 3.15591 6.50006C4.90198 7.09148 6.28358 8.47308 6.875 10.2192C7.46642 8.47308 8.84802 7.09148 10.5941 6.50006C8.84802 5.90864 7.46642 4.52705 6.875 2.78097Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.53125 11.1876C9.70384 11.1876 9.84375 11.3275 9.84375 11.5001C9.84375 12.9671 11.033 14.1563 12.5 14.1563C12.6726 14.1563 12.8125 14.2962 12.8125 14.4688C12.8125 14.6415 12.6726 14.7813 12.5 14.7813C11.033 14.7813 9.84375 15.9705 9.84375 17.4376C9.84375 17.6102 9.70384 17.7501 9.53125 17.7501C9.35866 17.7501 9.21875 17.6102 9.21875 17.4376C9.21875 15.9705 8.0295 14.7813 6.5625 14.7813C6.38991 14.7813 6.25 14.6415 6.25 14.4688C6.25 14.2962 6.38991 14.1563 6.5625 14.1563C8.0295 14.1563 9.21875 12.9671 9.21875 11.5001C9.21875 11.3275 9.35866 11.1876 9.53125 11.1876ZM9.53125 12.8994C9.20647 13.5872 8.64969 14.144 7.96186 14.4688C8.64969 14.7937 9.20647 15.3504 9.53125 16.0382C9.85603 15.3504 10.4128 14.7937 11.1006 14.4688C10.4128 14.144 9.85603 13.5872 9.53125 12.8994Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3906 6.73444C15.3906 6.56184 15.2507 6.42194 15.0781 6.42194C14.9055 6.42194 14.7656 6.56184 14.7656 6.73444C14.7656 8.41719 13.4015 9.78131 11.7188 9.78131C11.5462 9.78131 11.4062 9.92122 11.4062 10.0938C11.4062 10.2664 11.5462 10.4063 11.7188 10.4063C13.4015 10.4063 14.7656 11.7704 14.7656 13.4532C14.7656 13.6258 14.9055 13.7657 15.0781 13.7657C15.2507 13.7657 15.3906 13.6258 15.3906 13.4532C15.3906 11.7704 16.7547 10.4063 18.4375 10.4063C18.6102 10.4063 18.75 10.2664 18.75 10.0938C18.75 9.92122 18.6102 9.78131 18.4375 9.78131C16.7547 9.78131 15.3906 8.41719 15.3906 6.73444ZM13.2033 10.0938C14.0378 9.72451 14.7088 9.05345 15.0781 8.21903C15.4474 9.05345 16.1184 9.72451 16.953 10.0938C16.1184 10.4631 15.4474 11.1342 15.0781 11.9686C14.7088 11.1342 14.0378 10.4631 13.2033 10.0938Z"
        />
      </g>
    </svg>
  );
};

export default MagicStars;
