import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateIngress, CreateIngressInput, GetExistingIngress, GetIngressesResponse } from "../../../api/fetcher";
import { toast } from "react-toastify";
import { INGRESS_PROGRESS, TOAST_SETTINGS } from "./utils";
import { Dispatch } from "react";

const { queryKey: getQueryKey } = GetExistingIngress();
const { queryFn: postQueryFn } = CreateIngress();

interface Props {
  setScaleopsURL: (url: string | undefined) => void;
  setIngressStatus: Dispatch<React.SetStateAction<INGRESS_PROGRESS>>;
}
export const useCreateIngress = (props: Props) => {
  const { setScaleopsURL, setIngressStatus } = props;
  const queryClient = useQueryClient();

  return useMutation((params: CreateIngressInput) => postQueryFn(params), {
    onMutate: () => {
      queryClient.setQueryData([getQueryKey], (oldData: GetIngressesResponse | undefined) => {
        return {
          ...oldData,
          exists: true,
          existingIsBeingDeleted: false,
        };
      });
    },
    onError: (error: Error & { reason?: string }) => {
      console.log(`Error: ${error.message}`);
      setIngressStatus(INGRESS_PROGRESS.FAILED_TO_CREATE);
      toast.error(`Error creating ingress ${error?.reason ?? ""} ${error.message}`, TOAST_SETTINGS);
    },
    onSuccess: (response) => {
      setScaleopsURL(response.url);
      setIngressStatus(INGRESS_PROGRESS.CREATED_IN_KUBERNETES);
      toast.success("Ingress was created successfully!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getQueryKey]);
    },
  });
};

export default useCreateIngress;
