export function SpliceIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
  const res: T[][] = [];
  while (arr.length > 0) {
    const chunk: T[] = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

export const arrToStringArr = (arr: (string | null)[] | null | undefined) => arr?.map((arr) => String(arr));

export const convertArrayToStringArr = (arr: (string | null)[] | null | undefined) =>
  arr?.filter((label) => label).map((label) => String(label));

export const getMaxFlatArray = <T extends Array<number>>(arr: T[]) => {
  return arr.reduce((acc, val) => {
    const flatValues = val.flat();
    return Math.max(acc, ...flatValues);
  }, 0);
};
