import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SCALEOPS_COLORS } from "../../../colors";
import { customNumberFormatter, DAY_MONTH_FORMAT } from "../../../utils/formatterUtils";
import DailyChartTooltip from "./DailyChartTooltip";
import { DailyData } from "./utils";

interface Props {
  data: DailyData[];
  uniqueKeys: string[];
  elementOrder: string[];
}

const DailyChart = ({ data, uniqueKeys, elementOrder }: Props) => {
  const hasNoData = !data || data.length === 0;

  const numberOfXTickLines = data.length || undefined;

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Tooltip content={<DailyChartTooltip />} cursor={{ fill: SCALEOPS_COLORS.background.chip }} />
        <XAxis
          dataKey="timestamp"
          style={{ fontSize: "x-small" }}
          strokeWidth={2}
          tickFormatter={(value) => {
            return hasNoData ? "" : dayjs(String(value)).format(DAY_MONTH_FORMAT);
          }}
          tickLine={hasNoData ? false : undefined}
          tickCount={numberOfXTickLines}
        />
        <YAxis
          style={{ fontSize: "x-small" }}
          strokeWidth={2}
          tickFormatter={(tick: number) => {
            return hasNoData || tick === undefined ? "" : `$${String(customNumberFormatter(tick))}`;
          }}
          tickLine={hasNoData ? false : undefined}
        />
        {elementOrder.map((key, index) => {
          let color;

          if (index < SCALEOPS_COLORS.randomColors.length) {
            color = SCALEOPS_COLORS.randomColors[index];
          } else {
            const newIndex = index % SCALEOPS_COLORS.randomColors.length;
            color = SCALEOPS_COLORS.randomColors[newIndex];
          }

          return (
            <Bar
              dataKey={key}
              stackId="a"
              fill={color}
              key={key}
              radius={index === uniqueKeys.length - 1 ? [5, 5, 0, 0] : undefined}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DailyChart;
