import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { components } from "../../../api/schema";
import { updatePolicy, getPolicies } from "../../../api/fetcher";

type mutationProps = {
  policyName: string;
  policy: components["schemas"]["V1alpha1Policy"];
};

const useUpdatePolicy = () => {
  const policyTuning = getPolicies();
  const updatePol = updatePolicy();
  const queryClient = useQueryClient();

  return useMutation((params: mutationProps) => updatePol.queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to update policy`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([policyTuning.queryKey]).catch((error) => console.log(error));
      toast.success("Policy was updated successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useUpdatePolicy;
