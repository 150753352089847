import { useState } from "react";
import clsx from "clsx";
import { roundedTabsDivWrapper } from "../../utils/styleUtils";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import ExplorerReport from "./HistoryReport";
import SummaryReport from "./SummaryReport";

enum TabOptions {
  Summary = "Summary",
  History = "History",
}

interface Props {
  hasTabs?: boolean;
  isMultiCluster?: boolean;
}

const ReportsContainer = ({ hasTabs = false, isMultiCluster }: Props) => {
  const [selectedTab, setSelectedTab] = useState(TabOptions.Summary);

  return (
    <div
      className={clsx("relative", {
        "mt-[41px]": hasTabs,
        [roundedTabsDivWrapper]: hasTabs,
        "bg-white rounded-lg p-8": !hasTabs,
      })}
    >
      {hasTabs && (
        <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-[0px]", { hidden: !hasTabs })}>
          {Object.entries(TabOptions).map(([key, value]) => {
            return (
              <Tab
                key={key}
                isSelected={selectedTab === value}
                onClick={() => {
                  setSelectedTab(value);
                }}
                name={value}
                dataTestId={`cpu-and-memory-tab-${key}-tab`}
              />
            );
          })}
        </div>
      )}
      {selectedTab === TabOptions.History && <ExplorerReport />}
      {selectedTab === TabOptions.Summary && <SummaryReport isMultiCluster={isMultiCluster} />}
    </div>
  );
};

export default ReportsContainer;
