import { Policy, RenderMetrics } from "./PoliciesTable";
import React from "react";
import { Grid, InputLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment/moment";
import FormControl from "@mui/material/FormControl";
import DaySelection from "../DaySelection";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { adjustedDayjs } from "../../utils/dateAndTimeUtils";

interface PolicyDetailsProps {
  policy?: Policy;
}
export default function PolicyDetails(props: PolicyDetailsProps) {
  const { policy } = props;

  // if (editable) {
  //   return <FormView policy={policy} />;
  // } else {
  return <InfoView policy={policy} />;
  // }
}

export const Header = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: "16px",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

function InfoView(props: { policy?: Policy }) {
  const { policy } = props;
  return (
    <Grid container spacing={1} sx={{ marginTop: "3px" }}>
      <Grid item xs={6}>
        <Grid container spacing={1} direction="column" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Header>SLAs:</Header>
          </Grid>
          <Grid item>{RenderMetrics(policy?.sla)}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Header>Experimentation Schedule:</Header>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl sx={{ marginTop: "10px" }} variant="standard">
            <InputLabel shrink htmlFor="days" filled={true}>
              Days
            </InputLabel>
            <DaySelection sx={{ marginTop: "20px" }} id="days" fullWidth selectedDays={[1, 2, 3, 4, 5]} />
          </FormControl>
          <TimePicker
            onChange={(value) => {
              console.log(value);
            }}
            label="Time"
            ampm={false}
            inputFormat={"HH:mm"}
            value={adjustedDayjs("15:00", "HH:mm")}
            renderInput={(params) => (
              <TextField
                id="timeOfDay"
                name="timeOfDay"
                InputLabelProps={{ shrink: true, filled: true }}
                size="small"
                variant={"standard"}
                sx={{ width: "78px", marginLeft: "5px", marginTop: "10px", paddingTop: "8px" }}
                {...params}
              />
            )}
          />
          <TextField
            size="small"
            id="duration"
            variant={"standard"}
            label={"Duration"}
            sx={{ width: "88px", marginLeft: "15px", marginTop: "10px", paddingTop: "8px" }}
            value={moment.duration(policy?.schedule, "minutes").humanize()}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
