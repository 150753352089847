import { useFormikContext } from "formik";
import FormInput from "../../../components/formComponents/FormInput";
import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const MaximumResourceBoundaries = ({ isCustomizedPolicy }: Props) => {
  const { values } = useFormikContext<{
    cpuMaxAllowed: number;
    memoryMaxAllowed: number;
    nodeCapping: boolean;
  }>();

  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Maximum resource requests boundaries"
        description="Determine the maximum CPU and memory requests."
      />
      <div className="flex flex-wrap gap-4">
        <FormInput
          label="Max CPU"
          name="cpuMaxAllowed"
          type="number"
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || values.nodeCapping}
          errorLabelClassName="w-[350px]"
          disableChange={!isCustomizedPolicy}
        />
        <FormInput
          label="Max memory (GiB)"
          name="memoryMaxAllowed"
          type="number"
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || values.nodeCapping}
          errorLabelClassName="w-[350px]"
          disableChange={!isCustomizedPolicy}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        <FormSwitch
          name="nodeCapping"
          label={
            <span className="text-black">
              Set maximum resource requests according to <b>max node size.</b>
            </span>
          }
          info={
            <>
              ScaleOps will automatically set the <b>maximum resources boundaries</b> while considering the{" "}
              <b>node capacity</b>.
            </>
          }
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!isCustomizedPolicy}
          additionalOnChange={(value) => {
            if (value) {
              values.cpuMaxAllowed = 0;
              values.memoryMaxAllowed = 0;
            }
          }}
        />
      </div>
    </div>
  );
};

export default MaximumResourceBoundaries;
