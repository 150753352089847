import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetClustersResponse, getClusters } from "../../api/fetcher";

const clusterFetcher = getClusters();

const useGetTagsFromClusters = () => {
  const [tags, setTags] = useState<string[]>([]);

  const { data, error, isError } = useQuery<GetClustersResponse, Error>({
    queryKey: [clusterFetcher.queryKey],
    queryFn: () => clusterFetcher.queryFn(),
  });

  useEffect(() => {
    if (data?.clusters && data.clusters.length > 0) {
      const tagsObjToSet = data.clusters?.reduce((acc, cluster) => {
        const clusterName = cluster.name;
        if (clusterName && cluster.tags && cluster.tags.length > 0) {
          acc[clusterName] = cluster.tags;
          return acc;
        }
        return acc;
      }, {} as Record<string, string[]>);

      setTags([...new Set(Object.values(tagsObjToSet)?.flat())].sort());
    }
  }, [data]);

  if (isError) {
    console.log("Error fetching clusters: ", error);
  }

  return tags;
};

export default useGetTagsFromClusters;
