import { components } from "../../../api/schema";

export type DisqualifiedNodes = {
  nodeName?: string;
  reason?: string;
  stage?: string;
};

export enum ConsolidationState {
  Running = "Running",
  Completed = "Completed",
  Ready = "Ready",
  Canceled = "Canceled",
  Failed = "Failed",
  Verify = "Verify",
  Pending = "Pending",
}

export type BannerData = {
  costBefore?: number;
  costAfter?: number;
  cpuBefore?: number;
  cpuAfter?: number;
  memoryBefore?: number;
  memoryAfter?: number;
  isInProgress: boolean;
  isLoading: boolean;
};

export type NodeRow = {
  status: ConsolidationState | undefined;
  cpu?: number | undefined;
  maxPods?: number | undefined;
  memory?: number | undefined;
  monthlyCost?: number | undefined;
  spot?: boolean | undefined;
  type?: string | undefined;
  nodePool?: string | undefined;
  nodeGroup?: string | undefined;
};

export type ROW = components["schemas"]["Node_detailsNodeData"] & {
  status?: string;
  name?: string;
  nodePool?: string;
  nodeGroup?: string;
};
interface GetAfterValueProps {
  beforeValue?: number;
  valueToReduce?: number;
}

export const getAfterValue = ({ beforeValue, valueToReduce }: GetAfterValueProps): number => {
  beforeValue = Number(beforeValue) > 0 ? Number(beforeValue) : 0;
  valueToReduce = Number(valueToReduce) > 0 ? Number(valueToReduce) : 0;

  let output = beforeValue - valueToReduce;
  if (output < 0) output = 0;

  return output;
};
