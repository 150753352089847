import { Typography } from "@mui/material";
import InfoIcon from "../../../Icons/InfoIcon";
import { PodTaint } from "../../../api/fetcher";
import Tooltip from "../../Tooltip";

const ICON_SIZE = 11;

interface Props {
  topic: string;
  title: string;
  items: PodTaint[];
  isLoading?: boolean;
}
const TaintsChip = ({ topic, title, items }: Props) => {
  const tooltipContent = !items.length ? (
    <Typography variant="caption">
      This {topic.toLocaleLowerCase()} has no <b>{title.toLocaleLowerCase()}</b>.
    </Typography>
  ) : (
    <Typography variant="caption">
      <b className="underline">
        {topic} {title.toLocaleLowerCase()}:
      </b>
      <div className={"max-h-[330px] overflow-y-auto"}>
        <>
          {items.map((item) => (
            <ul className="list-disc px-3 text-[12px]">
              <li key={item.effect}>
                <p>Effect: {item.effect}</p>
                <p>Key: {item.key}</p>
                <p>Value: {item.value}</p>
              </li>
            </ul>
          ))}
        </>
      </div>
    </Typography>
  );

  return (
    <Tooltip
      title={tooltipContent}
      className="bg-background-chip w-fit flex items-center justify-center px-3 rounded-lg text-black cursor-default"
      maxWidth={600}
      placement="bottom"
    >
      <Typography variant="caption">{title}</Typography>
      <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
    </Tooltip>
  );
};
export default TaintsChip;
