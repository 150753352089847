import { components } from "../api/schema";
import * as policyUtils from "./policyUtils";

export function recommendationTargetType(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): string {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.targetRef != undefined) {
        if (recommendation.spec.targetRef.kind != undefined) {
          return recommendation.spec.targetRef.kind;
        }
      }
    }
  }

  return "";
}

export function recommendationTargetName(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): string {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.targetRef != undefined) {
        if (recommendation.spec.targetRef.name != undefined) {
          return recommendation.spec.targetRef.name;
        }
      }
    }
  }

  return "";
}

export function recommendationName(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): string {
  if (recommendation != undefined) {
    if (recommendation.metadata != undefined) {
      if (recommendation.metadata.name != undefined) {
        return recommendation.metadata.name;
      }
    }
  }

  return "";
}

export function recommendationTypeName(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): string {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.targetRef != undefined) {
        if (recommendation.spec.targetRef.kind != undefined && recommendation.spec.targetRef.name != undefined) {
          return recommendation.spec.targetRef.kind + "/" + recommendation.spec.targetRef.name;
        }
      }
    }
  }

  return "";
}

export function recommendationNamespace(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): string {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.targetRef != undefined) {
        if (recommendation.spec.targetRef.namespace != undefined) {
          return recommendation.spec.targetRef.namespace;
        }
      }
    }
  }

  return "";
}

export function overridden(recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined): boolean {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridden != undefined) {
        return recommendation.spec.overridden;
      }
    }
  }

  return false;
}

// return [window, isOverriden]
export function window(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  resource: string
): [string, boolean] {
  if (overridden(recommendation)) {
    if (recommendation?.spec?.overridePolicies?.rightSizePolicy?.windowByResource != undefined) {
      const value = recommendation.spec.overridePolicies.rightSizePolicy.windowByResource[resource];
      if (value != undefined && value != "") {
        return [value, true];
      }
    }
  }

  return [policyUtils.policyHistoryWindow(policy, resource), false];
}

// return [window, isOverriden]
export function windowMemory(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  resource: string
): [string, boolean] {
  if (overridden(recommendation)) {
    const value = recommendation?.spec?.overridePolicies?.rightSizePolicy?.windowByResource;
    if (value != undefined && value["memory"] != undefined && value["memory"] != "") {
      return [value["memory"], true];
    }
  }

  return [policyUtils.policyHistoryWindow(policy, resource), false];
}

// return [cpuPercentile, isOverriden]
export function cpuPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyCPUPercentile(policy), false];
}

// return [memoryRequestPercentile, isOverriden]
export function memoryRequestPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"].percentilePercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"].percentilePercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyMemoryPercentile(policy), false];
}

export function cpuLimitDisabled(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): boolean {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"] != undefined) {
                if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].noLimit != undefined)
                  return recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].noLimit;
              }
            }
          }
        }
      }
    }
  }

  return policyUtils.policyCpuLimitsNoLimit(policy);
}

export function memoryLimitDisabled(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): boolean {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"] != undefined) {
                if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].noLimit != undefined)
                  return recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].noLimit;
              }
            }
          }
        }
      }
    }
  }

  return policyUtils.policyMemoryLimitsNoLimit(policy);
}

// return [cpuRequestHeadroom, isOverriden]
export function cpuRequestHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"].headroomPercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["cpu"].headroomPercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyCpuHeadroom(policy), false];
}

// returns [cpuLimitHeadroom, isOverriden]
export function cpuLimitHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].headroomPercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].headroomPercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyCpuLimitsHeadroom(policy), false];
}

// returns [cpuLimitPercentile, isOverriden]
export function cpuLimitPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].percentilePercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["cpu"].percentilePercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyCpuLimitsPercentile(policy), false];
}

// returns [memoryLimitPercentile, isOverriden]
export function memoryLimitPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].percentilePercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].percentilePercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyMemoryLimitsPercentile(policy), false];
}

// returns [memoryRequestHeadroom, is-overriden]
export function memoryRequestHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"].headroomPercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs["memory"].headroomPercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyMemoryHeadroom(policy), false];
}

// returns [memoryLimitHeadroom, isOverriden]
export function memoryLimitHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): [number, boolean] {
  if (overridden(recommendation)) {
    if (recommendation != undefined) {
      if (recommendation.spec != undefined) {
        if (recommendation.spec.overridePolicies != undefined) {
          if (recommendation.spec.overridePolicies.rightSizePolicy != undefined) {
            if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs != undefined) {
              if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"] != undefined) {
                if (
                  recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].headroomPercentage !=
                  undefined
                )
                  return [
                    recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs["memory"].headroomPercentage,
                    true,
                  ];
              }
            }
          }
        }
      }
    }
  }

  return [policyUtils.policyMemoryLimitsHeadroom(policy), false];
}

export function recommendationDisableOverride(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      recommendation.spec.overridePolicies = {
        scaleOutPolicy: undefined,
      };
    }
  }
}

export function updateOverride(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  overridden: boolean
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      recommendation.spec.overridden = overridden;
    }
    if (!overridden) {
      // reset values, so upon re-override, we'll have default policy values and not the old overrides
      if (recommendation.spec?.overridePolicies?.rightSizePolicy?.windowByResource != undefined)
        recommendation.spec.overridePolicies.rightSizePolicy.windowByResource = {};
      if (recommendation.spec?.overridePolicies?.rightSizePolicy?.limitConfigs != undefined)
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs = {};
      if (recommendation.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs != undefined)
        recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs = {};
    }
  }
}

export function updateGitSync(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  gitSync: components["schemas"]["V1alpha1GitSync"]
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      recommendation.spec.gitSync = gitSync;
    }
  }
}

export function recommendationUpdateOverrideMinReplicas(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  updatedValue: number
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.scaleOutPolicy == undefined) {
        recommendation.spec.overridePolicies.scaleOutPolicy = {};
      }

      recommendation.spec.overridePolicies.scaleOutPolicy.minReplicas = updatedValue;
    }
  }
}

export function recommendationUpdateOverrideMaxReplicas(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  updatedValue: number
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.scaleOutPolicy == undefined) {
        recommendation.spec.overridePolicies.scaleOutPolicy = {};
      }

      recommendation.spec.overridePolicies.scaleOutPolicy.maxReplicas = updatedValue;
    }
  }
}

export function recommendationUpdateOverrideWindow(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  updatedValue: string,
  resource: string
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy = {};
      }

      if (recommendation.spec.overridePolicies.rightSizePolicy.windowByResource == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.windowByResource = {};
      }

      recommendation.spec.overridePolicies.rightSizePolicy.windowByResource[resource] = updatedValue;
    }
  }
  return recommendation;
}

export function recommendationUpdateOverrideRequestPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  resourceName: string,
  updatedValue: number | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs = {};
      }
      recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs[resourceName] = {
        ...recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs[resourceName],
        percentilePercentage: updatedValue,
      };
    }
  }
  return recommendation;
}

export function recommendationUpdateOverrideLimitPercentile(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  resourceName: string,
  updatedValue: number | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs = {};
      }
      if (updatedValue != undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName] = {
          ...recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName],
          percentilePercentage: updatedValue,
          noLimit: false,
          keepLimit: false,
        };
      } else {
        // updatedValue == undefined
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName] = {};
      }
    }
  }
  return recommendation;
}

export function recommendationUpdateOverrideRequestHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  resourceName: string,
  updatedValue: number | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs = {};
      }
      recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs[resourceName] = {
        ...recommendation.spec.overridePolicies.rightSizePolicy.requestsConfigs[resourceName],
        headroomPercentage: updatedValue,
      };
    }
  }
  return recommendation;
}

export function recommendationUpdateOverrideLimitHeadroom(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  resourceName: string,
  updatedValue: number | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy = {};
      }
      if (recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs == undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs = {};
      }
      if (updatedValue != undefined) {
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName] = {
          ...recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName],
          headroomPercentage: updatedValue,
          noLimit: false,
          keepLimit: false,
        };
      } else {
        // updatedValue == undefined
        recommendation.spec.overridePolicies.rightSizePolicy.limitConfigs[resourceName] = {};
      }
    }
  }
  return recommendation;
}

export function scaleOutEnabled(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
  // policy: components["schemas"]["V1alpha1Policy"] | undefined
): boolean {
  return recommendation?.spec?.overridePolicies?.scaleOutPolicy?.enabled || policyUtils.getScaleoutEnabled(/*policy*/);
}

export function scaleOutMinReplicas(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number {
  return (
    recommendation?.spec?.overridePolicies?.scaleOutPolicy?.minReplicas || policyUtils.getScaleoutMinReplicas(policy)
  );
}

export function scaleOutMaxReplicas(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number {
  return (
    recommendation?.spec?.overridePolicies?.scaleOutPolicy?.maxReplicas || policyUtils.getScaleoutMaxReplicas(policy)
  );
}

export function getScaleoutMetricGoals(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): components["schemas"]["V1alpha1MetricConfWithValue"][] {
  if (overridden(recommendation)) {
    if (recommendation?.spec?.overridePolicies?.scaleOutPolicy?.metricGoals != undefined) {
      return recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals;
    }
  }
  // Note - if overridden, policy value (i.e triggers array) isn't relevant
  return policyUtils.getScaleoutMetricGoals(policy);
}

export function addScaleoutMetricGoal(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  metricGoal: components["schemas"]["V1alpha1MetricConfWithValue"]
) {
  overrideMetricGoalArrayInNeeded(policy, recommendation);

  if (recommendation?.spec?.overridePolicies?.scaleOutPolicy != undefined) {
    if (recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals != undefined) {
      recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals.push(metricGoal);
    } else {
      recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals = [metricGoal];
    }
  }
}

// TODO empty arr translated to undefined?
export function deleteScaleoutMetricGoal(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined,
  index: number
) {
  overrideMetricGoalArrayInNeeded(policy, recommendation);

  if (recommendation?.spec?.overridePolicies?.scaleOutPolicy != undefined) {
    if (recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals != undefined) {
      if (index < recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals.length) {
        recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals.splice(index, 1);
      }
    }
  }
}

// Override the whole metricTriggers array (copy) if not overrided already
export function overrideMetricGoalArrayInNeeded(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
) {
  if (recommendation != undefined) {
    if (recommendation.spec != undefined) {
      if (recommendation.spec.overridePolicies == undefined) {
        recommendation.spec.overridePolicies = {};
      }
      if (recommendation.spec.overridePolicies.scaleOutPolicy == undefined) {
        recommendation.spec.overridePolicies.scaleOutPolicy = {};
      }
    }
  }
  if (recommendation?.spec?.overridePolicies?.scaleOutPolicy != undefined) {
    if (recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals == undefined) {
      const orig = policyUtils.getScaleoutMetricGoals(policy);
      if (orig != undefined) {
        recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals = JSON.parse(
          JSON.stringify(orig)
        ) as typeof orig;
      } else {
        recommendation.spec.overridePolicies.scaleOutPolicy.metricGoals = [];
      }
    }
  }
}

export function getRecommendationCreationTime(
  recommendation: components["schemas"]["V1alpha1Recommendation"] | undefined
): Date | undefined {
  if (recommendation?.metadata?.creationTimestamp != undefined) {
    const ISO_8601_ts = recommendation.metadata.creationTimestamp as unknown as string;
    return new Date(ISO_8601_ts);
  }
}
