import React, { Dispatch } from "react";

import { Button, DialogContent, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { components } from "../../../api/schema";
import * as policyUtils from "../../../utils/policyUtils";
import SchedulePolicyContent from "./SchedulePolicyContent";
import "../../../theme";
import RightsizeTabContent from "./RightsizeTabContent";

// TODO: refactor this file @barel22
export default function TabContent(props: {
  errorsDict: { [errorType: string]: React.ReactNode };
  selectedPolicyToUpdate: components["schemas"]["V1alpha1Policy"];
  setSelectedPolicyToUpdate: Dispatch<components["schemas"]["V1alpha1Policy"] | undefined>;
  isNewPolicy: boolean;
  policies: components["schemas"]["V1alpha1Policy"][];
  priorityClasses: components["schemas"]["V1PriorityClass"][];
  availableMetricsList: components["schemas"]["V1alpha1MetricConf"][];
  type: string | undefined;
  setType: Dispatch<string | undefined>;
  selectedPolicy: components["schemas"]["V1alpha1Policy"] | undefined;
  setSelectedPolicy: Dispatch<components["schemas"]["V1alpha1Policy"]>;
  setIsEditPolicyDrawerOpen: Dispatch<boolean>;
}) {
  const {
    selectedPolicyToUpdate,
    setSelectedPolicyToUpdate,
    availableMetricsList,
    type,
    setType,
    selectedPolicy,
    setSelectedPolicy,
    setIsEditPolicyDrawerOpen,
  } = props;

  const [policyName, setPolicyName] = React.useState(policyUtils.policyName(selectedPolicyToUpdate));

  return (
    <Box>
      <DialogContent style={{ height: "100%" }}>
        {type == undefined && (
          <Box>
            <TextField
              value={policyName}
              onChange={(e) => {
                const newValue = e.target.value
                  .toLowerCase()
                  .replace(" ", "-")
                  .replace(/ /g, "-")
                  .replace(/^[^a-zA-Z]+/, "")
                  .replace(/[^a-zA-Z0-9-]/g, "");
                setPolicyName(newValue);
                policyUtils.updatePolicyName(props.selectedPolicyToUpdate, newValue);
                policyUtils.updatePolicyName(selectedPolicy, newValue);
              }}
              style={{ marginTop: "0px" }}
              error={props.errorsDict["nameError"] != undefined}
              helperText={"Please enter a valid name."}
              margin="dense"
              id="name"
              label={"Name"}
              variant="standard"
              defaultValue={policyUtils.policyName(props.selectedPolicyToUpdate)}
              disabled={!props.isNewPolicy} // TODO(Vlad): When disabled not show this field and make sure that the dialog value would be "Policy + name"
            />
            <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <Button
                style={{ backgroundColor: "transparent", textTransform: "none", width: "49%" }}
                variant="contained"
                size="large"
                onClick={() => {
                  const policyWithoutSchedule = selectedPolicyToUpdate;
                  delete policyWithoutSchedule?.spec?.policySchedule;
                  setSelectedPolicyToUpdate(policyWithoutSchedule);
                  setSelectedPolicy(policyWithoutSchedule);
                  setIsEditPolicyDrawerOpen(true);
                }}
              >
                <IconButton disableRipple style={{ display: "table" }}>
                  <Box style={{ display: "flex" }}>
                    <AutoAwesomeMotionIcon />
                    <Typography style={{ paddingLeft: "5px" }}>Policy</Typography>
                  </Box>
                  <Typography fontSize={14} style={{ paddingLeft: "30px" }}>
                    Defines workload configurations
                  </Typography>
                </IconButton>
              </Button>
              <Button
                style={{ backgroundColor: "transparent", textTransform: "none", width: "49%" }}
                variant="contained"
                size="large"
                onClick={() => {
                  policyUtils.updatePolicyType(selectedPolicyToUpdate, "Schedule");
                  policyUtils.policyAddDefaultScheduleProperties(selectedPolicyToUpdate);
                  setType("Schedule");
                }}
              >
                <IconButton disableRipple style={{ display: "table" }}>
                  <Box style={{ display: "flex" }}>
                    <ScheduleIcon />
                    <Typography style={{ paddingLeft: "5px" }}>Policy Schedule</Typography>
                  </Box>
                  <Typography fontSize={14} style={{ paddingLeft: "30px" }}>
                    Schedules your configurations
                  </Typography>
                </IconButton>
              </Button>
            </Box>
          </Box>
        )}
        {type == "Optimize" && (
          <RightsizeTabContent errorsDict={props.errorsDict} selectedPolicyToUpdate={props.selectedPolicyToUpdate} />
        )}
        {type == "Schedule" && (
          <SchedulePolicyContent
            selectedPolicyToUpdate={selectedPolicyToUpdate}
            setSelectedPolicyToUpdate={setSelectedPolicyToUpdate}
            availableMetricsList={availableMetricsList || []}
            policies={props.policies}
          />
        )}
      </DialogContent>
    </Box>
  );
}
