import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getConfig, GetConfigResponse } from "../../../api/fetcher";
import AuthOptionBox from "./AuthOptionBox";
import NoAuthenticationContainer from "./NoAuthenticationContainer";
import SimpleAuthContainer from "./SimpleAuthContainer";
import SSOWithAuthorization from "./SSOWithAuthorization";
import SSOWithoutAuthorization from "./SSOWithoutAuthorization";
import TopContainer from "./TopContainer";
import { AuthMethod } from "./utils";

const configApi = getConfig();

const PermissionsContainer = () => {
  const [currentAuthMethod, setCurrentAuthMethod] = useState<AuthMethod | undefined>(undefined);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState<AuthMethod | undefined>(undefined);

  const { data } = useQuery<GetConfigResponse, Error>({
    queryKey: [configApi.queryKey],
    queryFn: configApi.queryFn,
  });

  useEffect(() => {
    if (data) {
      const provider = data?.ssoConf?.provider;
      const rabacEnabled = !!data?.rbacEnabled;

      switch (true) {
        case provider === "none":
          setCurrentAuthMethod(AuthMethod.DISABLED);
          break;
        case provider === "simpleAuth":
          setCurrentAuthMethod(AuthMethod.BUILT_IN);
          break;
        case rabacEnabled:
          setCurrentAuthMethod(AuthMethod.SSO_WITH_AUTHORIZATION);
          break;
        default:
          setCurrentAuthMethod(AuthMethod.SSO_WITHOUT_AUTHORIZATION);
          break;
      }
    }
  }, [data]);

  useEffect(() => {
    if (!selectedAuthMethod) {
      setSelectedAuthMethod(currentAuthMethod);
    }
  }, [currentAuthMethod]);

  return (
    <div className="flex flex-col gap-10">
      <TopContainer authType={currentAuthMethod} />
      <div className="flex flex-wrap gap-[30px]">
        <AuthOptionBox
          Title="DISABLED"
          description={
            <>
              <b>No authentication</b> and <b>authorization</b>
            </>
          }
          isSelected={selectedAuthMethod === AuthMethod.DISABLED}
          onClick={() => {
            setSelectedAuthMethod(AuthMethod.DISABLED);
          }}
        />
        <AuthOptionBox
          Title="SCALEOPS BUILT-IN"
          description={
            <>
              <b>Built-in authentication</b> and <b>authorization</b>
            </>
          }
          isSelected={selectedAuthMethod === AuthMethod.BUILT_IN}
          onClick={() => {
            setSelectedAuthMethod(AuthMethod.BUILT_IN);
          }}
        />
        <AuthOptionBox
          Title="SSO"
          description={
            <>
              AWS, Google, Okta, Azure, <b>and more</b>.
            </>
          }
          isSelected={selectedAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION}
          onClick={() => {
            setSelectedAuthMethod(AuthMethod.SSO_WITHOUT_AUTHORIZATION);
          }}
        />
        <AuthOptionBox
          Title="SSO & AUTHORIZATION"
          description={
            <>
              <b>SSO authentication</b> and <b>authorization</b>.
            </>
          }
          isSelected={selectedAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION}
          onClick={() => {
            setSelectedAuthMethod(AuthMethod.SSO_WITH_AUTHORIZATION);
          }}
        />
      </div>
      {currentAuthMethod && (
        <>
          {selectedAuthMethod === AuthMethod.DISABLED && (
            <NoAuthenticationContainer currentAuthMethod={currentAuthMethod} />
          )}
          {selectedAuthMethod === AuthMethod.BUILT_IN && <SimpleAuthContainer currentAuthMethod={currentAuthMethod} />}
          {selectedAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION && (
            <SSOWithoutAuthorization currentAuthMethod={currentAuthMethod} />
          )}
          {selectedAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION && (
            <SSOWithAuthorization currentAuthMethod={currentAuthMethod} />
          )}
        </>
      )}
    </div>
  );
};

export default PermissionsContainer;
