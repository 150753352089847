import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { LABEL_BOX_CLASS_WRAPPER, METRIC_BOX_CLASS_NAME } from "./utils";

interface Props {
  strategyName: string;
  strategyType: string;
  maxSurge?: number | null;
  maxUnavailable?: number | null;
  className?: string;
  children?: React.ReactNode;
}

const RolloutUpdateStrategyBadge = ({
  strategyName,
  strategyType,
  maxSurge,
  maxUnavailable,
  className,
  children,
}: Props) => {
  if (!strategyName || !strategyType) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          <b>{strategyType}</b>
          {maxSurge != undefined && <li>{`maxSurge: ${maxSurge}`}</li>}
          {maxUnavailable != undefined && <li>{`maxUnavailable: ${maxUnavailable}`}</li>}
        </Typography>
      }
      disabled={maxSurge === undefined && maxUnavailable === undefined}
      placement="left"
      maxWidth={400}
    >
      <div className={clsx(METRIC_BOX_CLASS_NAME, LABEL_BOX_CLASS_WRAPPER, className)}>
        {strategyName}: <b>{strategyType}</b> {children}
      </div>
    </Tooltip>
  );
};

export default RolloutUpdateStrategyBadge;
