export enum FilterName {
  Search = "search",
  Automated = "automated",
  UnAutomated = "un-automated",
  OverProvisioned = "over provisioned",
  UnderProvisioned = "under provisioned",
  namespaces = "namespaces",
  labels = "labels",
  annotations = "annotations",
}

export const QUERY_NAME = {
  [FilterName.Search]: "searchTerms",
  [FilterName.Automated]: "isAutomated",
  [FilterName.UnAutomated]: "isUnAutomated",
  [FilterName.OverProvisioned]: "overProvisionedFilter",
  [FilterName.UnderProvisioned]: "underProvisionedFilter",
  [FilterName.namespaces]: "namespaces",
  [FilterName.labels]: "labels",
  [FilterName.annotations]: "annotations",
};

export const LOGICAL_QUERY_NAME = {
  [FilterName.labels]: "logicalLabel",
  [FilterName.annotations]: "logicalAnnotation",
};

export const defaultFilters = [FilterName.Search];
