import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetClustersResponse, getClusters as getClustersFunction } from "../api/fetcher";

const getClusters = getClustersFunction();

const useGetClusterNames = () => {
  const [clusterNames, setCLustersName] = useState<string[]>([]);

  const { data, error, isError } = useQuery<GetClustersResponse, Error>({
    queryKey: [getClusters.queryKey],
    queryFn: () => getClusters.queryFn(),
  });

  useEffect(() => {
    if (data?.clusters) {
      setCLustersName(data.clusters.map((cluster) => cluster.name).filter((name) => name) as string[]);
    }
  }, [data]);

  if (isError) {
    console.log("Error getting cluster names", error);
  }

  return clusterNames;
};

export default useGetClusterNames;
