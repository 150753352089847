import { MenuItem } from "@mui/material";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import Tooltip from "../../../components/Tooltip";

const OPTIONS = {
  "5 lines": 5,
  "10 lines": 10,
  "20 lines": 20,
};

export const NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME = "numberOfElements";
export const DEFAULT_OPTION = OPTIONS["5 lines"];

interface Props {
  disabled?: boolean;
}

const NumberOfElementsFilter = ({ disabled }: Props) => {
  const [numberOfElements, setNumberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, DEFAULT_OPTION)
  );

  return (
    <Tooltip title={"All ScaleOps components are displayed"} disabled={!disabled} maxWidth={600}>
      <SingleSelect<number>
        disabled={disabled}
        size={SingleSelectSize.Regular}
        className="w-[100px]"
        selected={numberOfElements}
        setSelected={(option) => {
          setNumberOfElements(option);
        }}
        renderOptionsFunc={() =>
          Object.entries(OPTIONS).map((option) => {
            return (
              <MenuItem value={option[1]} key={option[1]}>
                <p className="text-[14px]">{option[0]}</p>
              </MenuItem>
            );
          })
        }
      />
    </Tooltip>
  );
};

export default NumberOfElementsFilter;
