import { IconProps } from "./utils/utils";

const PlainCubeIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.8225 4.17357C19.8199 4.16406 19.8156 4.15554 19.812 4.14669C19.8048 4.128 19.797 4.10997 19.7865 4.09292C19.7806 4.08341 19.7743 4.07488 19.7674 4.06603C19.7563 4.05095 19.7438 4.03718 19.7297 4.02439C19.7215 4.01652 19.7134 4.00931 19.7042 4.00243C19.6989 3.99849 19.695 3.99357 19.6897 3.98997C19.6776 3.98177 19.6642 3.97718 19.6514 3.97062C19.6448 3.96734 19.6399 3.96243 19.633 3.95948L10.1249 0.0250818C10.0442 -0.00836059 9.95441 -0.00836059 9.87376 0.0250818L0.365639 3.95948C0.358754 3.96243 0.353508 3.96734 0.346951 3.97062C0.334164 3.97685 0.321049 3.98177 0.309246 3.98964C0.304 3.99325 0.300066 3.99816 0.29482 4.0021C0.28564 4.00898 0.277771 4.01652 0.269574 4.02407C0.255804 4.03685 0.243345 4.05062 0.23187 4.0657C0.224985 4.07456 0.218755 4.08308 0.212854 4.09259C0.202362 4.10964 0.194493 4.12767 0.18728 4.14636C0.183673 4.15554 0.179411 4.16374 0.176788 4.17324C0.169247 4.2021 0.164001 4.23193 0.164001 4.26242V15.7377C0.164001 15.8705 0.244001 15.9902 0.366295 16.0407L9.87441 19.9751C9.87868 19.9767 9.88327 19.9761 9.88753 19.9777C9.92392 19.9911 9.9613 20 9.99999 20C10.0387 20 10.0761 19.9911 10.1124 19.9777C10.1167 19.9761 10.1213 19.9767 10.1256 19.9751L19.6337 16.0407C19.756 15.9902 19.836 15.8705 19.836 15.7377V4.26242C19.836 4.23193 19.8307 4.2021 19.8225 4.17357ZM9.67212 19.1813L0.819734 15.5187V4.75291L9.67212 8.41583V19.1813ZM9.99999 7.84207L1.34924 4.26242L9.99999 0.682781L18.6507 4.26242L9.99999 7.84207ZM19.1802 15.5187L10.3279 19.1816V8.41583L19.1802 4.75291V15.5187Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlainCubeIcon;
