import { useQuery } from "@tanstack/react-query";
import { GetNodeEvents, GetNodeEventsResponse } from "../../../api/fetcher";
import Events from "../../Events";

interface Props {
  nodeName: string | undefined;
  minHeight?: number;
}

const NodeEvents = ({ nodeName, minHeight }: Props) => {
  const { queryFn, queryKey } = GetNodeEvents();
  const { data, isLoading } = useQuery<GetNodeEventsResponse, Error>({
    queryKey: [queryKey, nodeName],
    queryFn: () => queryFn(nodeName ?? ""),
    enabled: !!nodeName,
    refetchInterval: 60 * 1000,
  });

  const events = ((data as GetNodeEventsResponse)?.events || []).map((event) => ({
    time: event.lastTimestamp,
    message: event.message,
  }));

  return <Events events={events} entityName={"node"} minHeight={minHeight} isLoading={isLoading} />;
};

export default NodeEvents;
