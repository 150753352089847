import { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { getDataGridSx } from "../../utils/styleUtils";
import useUpdateConsolidation from "./useUpdateConsolidation";
import { RowType, getColumns, OrderEntry, getOriginalValue, getOriginalOrder } from "./actionsUtils";
import { getNodeGroupInfoWithFilters } from "./NodeGroups";

const MAX_ROWS = 5;

const columns: GridColumns<RowType> = getColumns();

const ProvisionersActions = ({ hasHrLine }: { hasHrLine?: boolean | undefined }) => {
  const [rows, setRows] = useState<RowType[] | undefined>(undefined);
  const [order, setOrder] = useState<OrderEntry[] | undefined>(undefined);

  const updateConsolidation = useUpdateConsolidation();

  const { isError, data, isLoading } = getNodeGroupInfoWithFilters();

  useEffect(() => {
    if (data) {
      const rowsData: RowType[] =
        data?.provisioners?.map((provisioner) => {
          const isDisabled =
            (!provisioner.annotation || !Object.keys(provisioner.annotation).includes("scaleops/provisioner")) &&
            !!provisioner.consolidate;

          const description = isDisabled ? (
            <>
              <b>{provisioner.name} provisioner's</b> consolidation was not optimized by <b>ScaleOps</b>.
            </>
          ) : (
            <>
              Optimize <b>{provisioner.name}</b> consolidation provisioner to increase savings and reduce waste.
            </>
          );

          const id = `'${provisioner.name}'-consolidation`;

          return {
            name: <>{provisioner.name}/consolidation</>,
            id: id,
            description: description,
            values: {
              current: !!provisioner.consolidate,
              recommended: true,
            },
            isChecked: !!provisioner.consolidate,
            originalOrder: Number(getOriginalValue("originalOrder", id, order)),
            isDisabled,
            onChange: (value) => {
              updateConsolidation.mutate({
                provisionerName: provisioner.name,
                optimize: value,
              });
            },
          };
        }) ?? [];

      // rowsData = rowsData.filter((row) => !row.isDisabled);

      setRows(rowsData);
    }
  }, [data, order]);

  useEffect(() => {
    if (rows && rows.length > 0 && !order) {
      setOrder(
        rows.map((r) => {
          return {
            id: r.id,
            originalOrder: getOriginalOrder(r),
          };
        })
      );
    }
  }, [rows]);

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg flex-grow flex items-center justify-center min-w-[700px] h-[300px] w-[100%] left-[0px] top-[0px]">
        <CircularProgress />
      </div>
    );
  }

  if (isError || !data?.provisioners || data?.provisioners.length === 0) {
    return null;
  }

  return (
    <div className="pb-[40px] flex flex-col gap-4 bg-white rounded-lg w-[100%] px-[30px] pt-[30px]">
      <div>
        <Typography variant="body1" fontWeight={700}>
          Karpenters Recommendations
        </Typography>
        <Typography variant="body2">Optimize your cluster savings using Karpenter.</Typography>
      </div>
      <div className="py-4">
        <DataGrid
          sx={{
            ...getDataGridSx(),
          }}
          rows={rows ?? []}
          columns={columns}
          autoHeight={true}
          rowHeight={80}
          disableSelectionOnClick
          hideFooter={rows && rows.length > MAX_ROWS ? false : true}
          disableColumnMenu
          sortModel={[
            {
              field: "originalOrder",
              sort: "asc",
            },
          ]}
        />
      </div>
      {hasHrLine && <hr className="mt-[20px]" />}
    </div>
  );
};

export default ProvisionersActions;
