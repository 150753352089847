import { useQuery } from "@tanstack/react-query";
import MultiSelect from "../../../components/MultiSelect";
import { getLabels, GetLabelsResponse } from "../../../api/fetcher";
import CustomFilterButton from "../../../components/CustomFilterButton";

interface LabelsProps {
  selectedLabelNames: (string | undefined)[];
  setSelectedLabelNames: (labelNames: (string | undefined)[]) => void;
  isFiltered: boolean;
}

const MultiSelectLabelsComponent = ({ selectedLabelNames, setSelectedLabelNames, isFiltered }: LabelsProps) => {
  const labels = getLabels();
  const { data } = useQuery<GetLabelsResponse, Error>([labels.queryKey], () => labels.queryFn());
  return (
    <MultiSelect
      selected={selectedLabelNames}
      setSelected={setSelectedLabelNames as (props: (string | undefined)[]) => void}
      options={Object.values(data?.labels || [])}
      isSearchable
      sort="asc"
      className="mt-[-20px] w-[83px]"
      customIcon={<CustomFilterButton label="labels" isFiltered={isFiltered} />}
      hasVirtualizedList
    />
  );
};

export default MultiSelectLabelsComponent;
