import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetRebalanceStatus,
  GetRebalanceStatusResponse,
  RebalanceOnce,
  RebalanceOnceParams,
} from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../../pages/Roles/mutations/utils";
import { ConsolidationState } from "./utils";

const startNodeRebalance = RebalanceOnce();
const getRebalanceStatus = GetRebalanceStatus();

const useStartNodeRebalance = () => {
  const queryClient = useQueryClient();
  return useMutation((params: RebalanceOnceParams) => startNodeRebalance.queryFn(params), {
    onMutate: () => {
      queryClient.setQueryData([getRebalanceStatus.queryKey], (oldData: GetRebalanceStatusResponse | undefined) => {
        return {
          ...oldData,
          status: {
            ...oldData?.status,
            state: ConsolidationState.Running,
          },
        };
      });
    },
    onError: () => {
      toast.error(`Error starting consolidation`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Consolidation started", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getRebalanceStatus.queryKey]);
    },
  });
};

export default useStartNodeRebalance;
