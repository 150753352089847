import { Typography } from "@mui/material";
import clsx from "clsx";
import LockIcon from "../Icons/LockIcon";
import Switch from "./Switch";

export enum THEMES {
  Purple = "purple",
  White = "white",
}

interface Props {
  title: React.ReactNode;
  handleClick: () => void;
  checked: boolean;
  disabled?: boolean;
  notAllowed?: boolean;
  theme?: THEMES;
}

const SwitchBox = ({ title, handleClick, checked, disabled, notAllowed, theme = THEMES.Purple }: Props) => {
  return (
    <div
      className={clsx("flex items-center px-4 border h-[42px] rounded", {
        "bg-guideline-darkPurple hover:bg-guideline-darkPurpleHover text-white": theme === THEMES.Purple,
        "bg-white text-black hover:bg-background-ghostWhite": theme === THEMES.White,
        "border-black": !disabled,
        "border-border text-text-disable opacity-30 cursor-not-allowed": disabled,
        "opacity-50 cursor-not-allowed": notAllowed,
        "cursor-pointer": !notAllowed && !disabled,
      })}
      onClick={disabled ? undefined : handleClick}
    >
      <Typography variant="body2" fontWeight={500}>
        {title}
      </Typography>
      <Switch
        checked={checked}
        onChange={() => {
          return;
        }}
        disabled={disabled}
        sx={{
          marginRight: -1.4,
        }}
      />
      {notAllowed && (
        <div className="absolute right-[1.6rem]">
          <LockIcon />
        </div>
      )}
    </div>
  );
};

export default SwitchBox;
