import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetPolicyTuningConfigParams, getDashboardByNamespace, updatePolicyTuning } from "../../../../api/fetcher";

type mutationProps = {
  name: string;
  namespace: string;
  policyName: string;
};

const useUpdateRecommendation = () => {
  const queryClient = useQueryClient();
  const policyTuning = updatePolicyTuning();
  const getPolicyTuningConfigParams = GetPolicyTuningConfigParams();

  return useMutation((params: mutationProps) => policyTuning.queryFn(params), {
    onError: (error: Error) => {
      toast.error("An error occurred while updating workload");
      console.log(`Error: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([getPolicyTuningConfigParams.queryKey]).catch((error) => console.log(error));
      toast.success("Saved successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([getDashboardByNamespace().queryKey]);
    },
  });
};

export default useUpdateRecommendation;
