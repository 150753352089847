import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetRbac, PatchRbac, PatchRbacParam } from "../../../api/fetcher";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "./utils";

const { queryKey: getQueryKey } = GetRbac();
const { queryFn: updateQueryFn } = PatchRbac();

export const usePatchRole = () => {
  const queryClient = useQueryClient();

  return useMutation((params: PatchRbacParam) => updateQueryFn(params), {
    onError: (error: Error & { reason?: string }) => {
      console.log(` Error: ${error.message}`);

      const forbidden = error?.reason && error.reason === "Forbidden" ? " (Forbidden)" : "";
      toast.error(`Error updating role${forbidden}`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Role was updated successfully!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getQueryKey]);
    },
  });
};
