import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetAggregations, GetAggregationsResponse } from "../../../../../api/fetcher";
import ExportCSV from "../../../../../components/exportCSV/ExportCSV";
import ExportCSVOptionButton from "../../../../../components/ExportCSVOptionButton";
import { Field } from "../../utils";
import useAggregationFilters from "../hooks/useAggregationFilters";
import useHpaOptimizationEnabled from "../../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";

const BUTTON_TITLE = "Aggregated cost report";
const { queryKey, queryFn } = GetAggregations();

type CSVExportType = {
  id: string;
  clusterName: string;
  totalCost: number;
  activeSavings: number;
  savingsAvailable: number;
  spot: number;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  amountOfWorkloads: number;
};

const ExportAggregationCSV = ({ isGpu }: { isGpu?: boolean }) => {
  const filters = useAggregationFilters();

  const [rows, setRows] = useState<GetAggregationsResponse["aggregatedWorkloads"]>([]);

  const enableHpaOptimization = useHpaOptimizationEnabled();

  const { data, isLoading, isError } = useQuery<GetAggregationsResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        gpuWorkloadsOnly: isGpu,
        multiCluster: true,
      }),
    enabled: Object.keys(filters).length > 0,
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      const dataToSet = data?.aggregatedWorkloads.map((wl) => {
        const totalWorkloads = wl.spot + wl.onDemand;

        // Spot %
        let spotPercentage = Math.round((wl.spot / totalWorkloads) * 100);
        if (isNaN(spotPercentage) || spotPercentage < 0 || spotPercentage > 100) spotPercentage = 0;

        // On-demand %
        let onDemandPercentage = Math.round((wl.onDemand / totalWorkloads) * 100);
        if (isNaN(onDemandPercentage) || onDemandPercentage < 0 || onDemandPercentage > 100) onDemandPercentage = 0;

        return {
          ...wl,
          spot: spotPercentage,
          onDemand: onDemandPercentage,
          savingsAvailable: enableHpaOptimization ? wl.savingsAvailableWithReplicas : wl.savingsAvailable,
          activeSavings: enableHpaOptimization ? wl.activeSavingsWithReplicas: wl.activeSavings
        };
      });

      setRows(dataToSet);
    }
  }, [data]);

  if (isLoading || isError) {
    return <ExportCSVOptionButton title={BUTTON_TITLE} disabled={true} />;
  }

  let columns = [
    Field.id,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    Field.replicas,
    Field.amountOfWorkloads,
  ] as (keyof CSVExportType)[];

  let columnsToRound = [
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    Field.replicas,
    Field.amountOfWorkloads,
  ] as (keyof CSVExportType)[];

  let columnsToSum = [
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.cpu,
    Field.memory,
    Field.replicas,
    Field.amountOfWorkloads,
  ] as (keyof CSVExportType)[];

  const customColumnNames = {
    [Field.id]: "Name",
    [Field.clusterName]: "Cluster",
    [Field.totalCost]: "Total Cost",
    [Field.savingsAvailable]: "Savings Available",
    [Field.activeSavings]: "Active Savings",
    [Field.spot]: "Spot %",
    [Field.onDemand]: "On-demand %",
    [Field.cpu]: "CPU request",
    [Field.memory]: "Memory request",
    [Field.replicas]: "Number of Pods",
    [Field.amountOfWorkloads]: "Amount of Workloads",
  } as { [key in keyof CSVExportType]?: string };

  if (isGpu) {
    columns = columns.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    columnsToSum = columnsToSum.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    columnsToRound = columnsToRound.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    delete customColumnNames[Field.savingsAvailable];
    delete customColumnNames[Field.activeSavings];
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        customButtonElement={<ExportCSVOptionButton title={BUTTON_TITLE} />}
        filename="aggregated_cost_report.csv"
        columns={columns}
        data={
          rows.map((row) => ({
            id: row.id,
            clusterName: row.clusterName,
            totalCost: row.totalCost,
            savingsAvailable: row.savingsAvailable,
            activeSavings: row.activeSavings,
            spot: row.spot,
            onDemand: row.onDemand,
            ownerCpuRequest: row.ownerCpuRequest,
            ownerMemoryRequest: row.ownerMemoryRequest,
            replicas: row.replicas,
            amountOfWorkloads: row.amountOfWorkloads,
          })) as CSVExportType[]
        }
        columnsToRound={columnsToRound}
        columnsToSum={columnsToSum}
        columnsToAverage={[Field.spot, Field.onDemand]}
        customColumnNames={customColumnNames}
      />
    </div>
  );
};

export default ExportAggregationCSV;
