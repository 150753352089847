import { Select, Typography } from "@mui/material";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useSelectSearch from "./useSelectSearch";

export enum SingleSelectSize {
  "Small",
  "Medium",
  "MediumWithSmallFont",
  "Regular",
}

const style = {
  [SingleSelectSize.Regular]: {
    main: {
      width: "w-48",
      height: "h-10",
    },
    arrowIconSize: undefined,
    selectedOption: {
      fontSize: "undefined",
    },
  },
  [SingleSelectSize.Medium]: {
    main: {
      width: "w-36",
      height: "h-8",
    },
    arrowIconSize: "18px",
    selectedOption: {
      fontSize: "14px",
    },
  },
  [SingleSelectSize.MediumWithSmallFont]: {
    main: {
      width: "w-36",
      height: "h-8",
    },
    arrowIconSize: "18px",
    selectedOption: {
      fontSize: "12px",
    },
  },
  [SingleSelectSize.Small]: {
    main: {
      width: "w-28",
      height: "h-6",
    },
    arrowIconSize: "18px",
    selectedOption: {
      fontSize: "12px",
    },
  },
};
interface Props<SelectedType> {
  label?: React.ReactNode;
  selected: SelectedType;
  setSelected: (props: SelectedType) => void;
  isSearchable?: boolean;
  className?: string;
  labelClassName?: string;
  textColor?: string;
  renderOptionsFunc: (search?: string) => React.ReactNode;
  renderValue?: (value: SelectedType) => React.ReactNode;
  disabled?: boolean;
  size?: SingleSelectSize;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  selectedColor?: string;
  arrowIconColor?: string;
  onOpen?: () => void;
  onClose?: () => void;
  sx?: React.CSSProperties;
}

const SingleSelect = <SelectedType extends string | undefined | null | number>({
  selected,
  setSelected,
  label,
  className,
  labelClassName,
  isSearchable,
  renderOptionsFunc,
  renderValue,
  textColor = "",
  disabled,
  size = SingleSelectSize.Regular,
  isExclude,
  setIsExclude,
  selectedColor,
  arrowIconColor,
  onOpen,
  onClose,
  sx,
}: Props<SelectedType>) => {
  const { search, searchDiv, includeExcludeDiv } = useSelectSearch(isSearchable, isExclude, setIsExclude, "px-[14px]");

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <Typography
          className={clsx(labelClassName, { "text-text-lightBlack": !disabled, "text-text-disable": disabled })}
        >
          {label}
        </Typography>
      )}
      <Select
        value={selected}
        defaultValue={selected}
        onChange={(event) => {
          setSelected(event.target.value as SelectedType);
        }}
        sx={{
          color: textColor,
          "& .MuiListItemText-primary": {
            fontSize: style[size].selectedOption.fontSize,
            color: selectedColor,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          // border
          "& .MuiSelect-icon": {
            fill: disabled ? undefined : arrowIconColor,
          },
          "& .MuiSvgIcon-root": {
            width: style[size].arrowIconSize,
          },
          "& .Mui-disabled": {
            opacity: "0.6",
          },
          ...sx,
        }}
        className={clsx(className, style[size].main.width, style[size].main.height)}
        IconComponent={(props) => <KeyboardArrowDownIcon className="mr-2" {...props} />}
        disabled={disabled}
        renderValue={renderValue}
        onOpen={onOpen}
        onClose={onClose}
      >
        {isSearchable && searchDiv}
        {includeExcludeDiv && <div className="mb-4">{includeExcludeDiv}</div>}
        {renderOptionsFunc(search)}
      </Select>
    </div>
  );
};

export default SingleSelect;
