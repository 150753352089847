import React, { ReactElement } from "react";
import { Box, Divider, FormControlLabel, Input, Paper, Slider, Switch, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Property } from "csstype";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { components } from "../../api/schema";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
} from "recharts";
import { FeatureEnabled } from "../../utils/FeaturesHelper";
import { tickNumberFormatter } from "../../utils/graphUtils";
import Tooltip from "../Tooltip";
import { OverviewChartColors, TabOptions, RequestAndUsageByTimestampKey } from "./overviewUtils";
import CustomOverviewTooltip from "./CustomOverviewTooltip";
import StackedOverviewChart from "./StackedOverviewChart";

export enum ChartType {
  Line = "Line chart",
  StackedBar = "Bar chart",
}

export function mainTitleOfWindow(title: string) {
  return (
    <Typography
      style={{
        fontSize: "30px",
        marginTop: "0px",
        marginBottom: "10px",
        marginLeft: "-20px",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  );
}

export function windowBlock(title: string, content: ReactElement) {
  return (
    <Container style={{ width: "100%", padding: 0 }} maxWidth={false}>
      {title && mainTitleOfWindow(title)}
      {content}
    </Container>
  );
}

export function getOverviewContent(colorProperty: Property.Color | undefined, value: string) {
  return (
    <Typography
      style={{
        fontSize: "50px",
        fontWeight: "600",
        color: colorProperty,
        textAlign: "center",
      }}
    >
      {value}
    </Typography>
  );
}

export function getNeutralOverviewContent(value: string) {
  return getOverviewContent("rgb(255, 204, 67)", value);
}

export function getPositiveOverviewContent(value: string) {
  return getOverviewContent("rgb(16, 174, 110)", value);
}

export function getNegativeOverviewContent(value: string) {
  return getOverviewContent("#F7426F", value);
}

export function getOverviewContentWithColor(value: string, color: string) {
  return getOverviewContent(color, value);
}

export function getContentPaperWithMinHeight(
  label: string,
  content: ReactElement,
  widthPaper: string,
  minHeight: string
) {
  return (
    <Paper style={{ width: widthPaper, minHeight: minHeight, borderRadius: "5px" }}>
      <Typography
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "16px",
        }}
      >
        {label}
      </Typography>
      {content}
    </Paper>
  );
}

export function getContentPaper(
  label: string | React.ReactNode,
  content: ReactElement,
  widthPaper: string,
  tooltipMessage = ""
) {
  return (
    <div>
      <Typography className="text-center pt-4">
        {label}
        {tooltipMessage != "" && (
          <Tooltip title={tooltipMessage}>
            <InfoOutlinedIcon style={{ fontSize: "small", marginLeft: "2px", marginBottom: "5px" }} />
          </Tooltip>
        )}
      </Typography>
      {content}
    </div>
  );
}

export function getEmptyPaper(content: ReactElement, widthPaper: string) {
  return <Paper style={{ width: widthPaper, minHeight: "100%", borderRadius: "5px" }}>{content}</Paper>;
}

export function getBoxBar(width: string, insideBarContent: string, colorProperty: Property.Color | undefined) {
  return (
    <Box
      display="inline-block"
      border="1px"
      borderRadius="5px"
      style={{ backgroundColor: colorProperty, height: "30px", width: width, minWidth: "100px" }}
    >
      {width != "0%" && (
        <Typography
          style={{
            fontWeight: "500",
            fontSize: "16px",
            paddingTop: "3px",
            paddingLeft: "10px",
          }}
        >
          {insideBarContent}
        </Typography>
      )}
    </Box>
  );
}

export function getBesideBarValue(value: string, colorProperty: Property.Color | undefined) {
  return (
    <Typography
      style={{
        paddingLeft: "10px",
        fontSize: "20px",
        fontWeight: "600",
        color: colorProperty,
      }}
    >
      {value}
    </Typography>
  );
}

export function getPolicyConfiguration(
  title: string,
  contentDetails: ReactElement | string,
  content: ReactElement,
  infoToolTip: string | undefined = undefined
) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "50px", paddingTop: "10px" }}>
        <div style={{ width: "47%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography style={{ fontWeight: 600, paddingRight: "3px" }}>{title}</Typography>
            {infoToolTip != undefined && (
              <Tooltip title={infoToolTip}>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          <Typography style={{ fontSize: "smaller" }}>{contentDetails}</Typography>
        </div>
        <div style={{ width: "49%", fontSize: "smaller" }}>
          <div style={{ fontSize: "smaller" }}>{content}</div>
        </div>
      </div>
      <Divider style={{ paddingTop: "20px" }}></Divider>
    </div>
  );
}

export function getTextFieldForPolicyWindow(
  label: string,
  defaultValue: number | string | undefined,
  onBlurFunction: (setFunction: number | string) => void,
  errorText: React.ReactNode,
  disabled = false,
  width = "150px",
  step = "0.5"
) {
  return (
    <div>
      <TextField
        size="small"
        sx={{ width: width }}
        label={<div style={{ fontSize: "smaller" }}>{label}</div>}
        type={typeof defaultValue}
        defaultValue={defaultValue}
        onBlur={(event) => {
          onBlurFunction(event.target.value);
        }}
        error={errorText != undefined}
        disabled={disabled}
        inputProps={{
          step: step,
        }}
      />
    </div>
  );
}

export function getSwitchForPolicyWindow(
  label: string,
  defaultValue: boolean,
  onCheckChanged: (setPolicyValue: boolean) => void,
  onChangedFunction: (setPolicyValue: boolean) => void
) {
  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            defaultChecked={defaultValue}
            onChange={(value, checked) => {
              onCheckChanged(checked);
              onChangedFunction(checked);
            }}
          />
        }
        label={
          <Typography fontSize="small" style={{ width: "max-content" }}>
            {label}
          </Typography>
        }
      />
    </div>
  );
}

export function getSlider(
  label: string,
  value: number,
  onChangedFunction: (setPolicyValue: number) => void,
  disabled = false,
  min = 0,
  step = 1,
  max = 100
) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div>
          <Typography style={{ fontSize: "small", color: disabled ? "rgba(0, 0, 0, 0.38)" : undefined }}>
            {label} (%)
          </Typography>
          <Slider
            style={{ width: "200px" }}
            value={value}
            onChange={(event, value) => {
              onChangedFunction(value as number);
            }}
            aria-labelledby="input-slider"
            min={min}
            max={max}
            step={step}
            disabled={disabled}
          />
        </div>
        <div style={{ paddingLeft: "15px", paddingTop: "5px" }}>
          <Input
            style={{ width: "40px" }}
            value={value}
            onBlur={() => {
              const newValue = value;

              if (newValue > max) {
                onChangedFunction(max);
              } else if (newValue < min) {
                onChangedFunction(min);
              } else {
                onChangedFunction(newValue);
              }
            }}
            onChange={(event) => {
              const policyValue = Number(event.target.value);

              if (isNaN(policyValue)) {
                onChangedFunction(max);
              } else {
                onChangedFunction(policyValue);
              }
            }}
            inputProps={{
              min: { min },
              max: { max },
              step: { step },
              type: "string",
              "aria-labelledby": "input-slider",
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

export function getTypographyWithTitleAndColor(
  title: string,
  content: ReactElement,
  unit: string,
  colorProperty: Property.Color | undefined
) {
  return (
    <div>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: colorProperty,
          textAlign: "left",
          paddingLeft: "20px",
        }}
      >
        {title}
      </Typography>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Typography
          style={{
            fontSize: "25px",
            fontWeight: "400",
            textAlign: "left",
            paddingLeft: "40px",
          }}
        >
          {content}
        </Typography>
        <Typography
          style={{
            fontSize: "10px",
            fontWeight: "200",
            textAlign: "left",
            paddingLeft: "10px",
            paddingTop: "15px",
          }}
        >
          {unit}
        </Typography>
      </div>
    </div>
  );
}

export function resourceOverTimeGraph(
  resource: string,
  tab: string,
  resourcesOverTime1w:
    | {
        averageCpuRequest?: number;
        averageCpuUsage?: number;
        averageMemoryRequest?: number;
        averageMemoryUsage?: number;
        resources?: components["schemas"]["UtilsResourceAggregations"][];
      }
    | undefined,
  resourcesOverTime1m:
    | {
        averageCpuRequest?: number;
        averageCpuUsage?: number;
        averageMemoryRequest?: number;
        averageMemoryUsage?: number;
        resources?: components["schemas"]["UtilsResourceAggregations"][];
      }
    | undefined,
  resourcesOverTime1d:
    | {
        averageCpuRequest?: number;
        averageCpuUsage?: number;
        averageMemoryRequest?: number;
        averageMemoryUsage?: number;
        resources?: components["schemas"]["UtilsResourceAggregations"][];
      }
    | undefined,
  selectedChartType: ChartType
) {
  return (
    <div className="h-[85%] relative">
      <div style={{ width: "99%", height: "99%", display: "flex", justifyContent: "space-between" }}>
        <div style={{ float: "right", width: "100%" }}>
          {tab === TabOptions.Day && (
            <ResourcesOverTime
              resource={resource}
              costOverTime={resourcesOverTime1d?.resources || []}
              selectedTab={tab}
              selectedChartType={selectedChartType}
            />
          )}
          {tab === TabOptions.Week && (
            <ResourcesOverTime
              resource={resource}
              costOverTime={resourcesOverTime1w?.resources || []}
              selectedTab={tab}
              selectedChartType={selectedChartType}
            />
          )}
          {tab === TabOptions.Month && (
            <ResourcesOverTime
              resource={resource}
              costOverTime={resourcesOverTime1m?.resources || []}
              selectedTab={tab}
              selectedChartType={selectedChartType}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function ResourcesOverTime(props: {
  costOverTime: components["schemas"]["UtilsResourceAggregations"][] | undefined;
  resource: string;
  selectedTab: TabOptions;
  selectedChartType: ChartType;
}) {
  const { costOverTime, resource, selectedTab, selectedChartType } = props;

  const ticksToDisplay: string[] = [];

  const requestAndUsageByTimestampKey: RequestAndUsageByTimestampKey[] = [];

  costOverTime?.forEach((element) => {
    if (resource === "cpu") {
      requestAndUsageByTimestampKey.push({
        request: Math.round(((element.cpu?.request || 0) / 1000) * 10) / 10,
        usage: Math.round(((element.cpu?.usage || 0) / 1000) * 10) / 10,
        allocatable: Math.round(((element.cpu?.allocatable || 0) / 1000) * 10) / 10,
        recommended: Math.round(((element.cpu?.recommended || 0) / 1000) * 10) / 10,
        timestampAggregators: element.timestampAggregators,
        timestamps: element.timestamps,
      });
    }

    if (resource === "memory") {
      requestAndUsageByTimestampKey.push({
        request: Math.round(((element.memory?.request || 0) / 1024 / 1024 / 1024) * 10) / 10,
        usage: Math.round(((element.memory?.usage || 0) / 1024 / 1024 / 1024) * 10) / 10,
        allocatable: Math.round(((element.memory?.allocatable || 0) / 1024 / 1024 / 1024) * 10) / 10,
        recommended: Math.round(((element.memory?.recommended || 0) / 1024 / 1024 / 1024) * 10) / 10,
        timestampAggregators: element.timestampAggregators,
        timestamps: element.timestamps,
      });
    }

    if (element != undefined && element.timestampAggregators != undefined) {
      ticksToDisplay.push(element.timestampAggregators);
    }
  });

  const unique = ticksToDisplay.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  return (
    <>
      {selectedChartType === ChartType.Line || selectedTab === TabOptions.Day ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={requestAndUsageByTimestampKey} style={{ paddingTop: "20px" }}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey={"timestampAggregators"} name={"Interval"} ticks={unique} style={{ fontSize: "x-small" }} />
            <YAxis style={{ fontSize: "x-small" }} width={40} tickFormatter={tickNumberFormatter} />
            <RechartsTooltip content={<CustomOverviewTooltip selectedTabOption={selectedTab} />} />
            <Area
              type="monotone"
              stackId="1"
              dataKey={"usage"}
              name={"usage"}
              stroke={OverviewChartColors.Usage}
              fill={OverviewChartColors.Usage}
              unit={resource === "memory" ? "GiB" : undefined}
            />
            <Area
              type="monotone"
              stackId="2"
              dataKey={"request"}
              name={"request"}
              stroke={OverviewChartColors.Request}
              fill={OverviewChartColors.Request}
              fillOpacity={0.0}
              unit={resource === "memory" ? "GiB" : undefined}
            />
            {!FeatureEnabled("DemoVersion") && (
              <Area
                type="monotone"
                stackId="3"
                dataKey={"allocatable"}
                name={"allocatable"}
                stroke={OverviewChartColors.Allocatable}
                fill={OverviewChartColors.Allocatable}
                fillOpacity={0.0}
                unit={resource === "memory" ? "GiB" : undefined}
              />
            )}
            <Legend wrapperStyle={{ fontSize: "12px", bottom: "-24px" }} fontSize={8} />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <StackedOverviewChart selectedTab={selectedTab} data={requestAndUsageByTimestampKey} />
      )}
    </>
  );
}
