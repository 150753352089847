import { SCALEOPS_COLORS } from "../colors";
import { IconProps } from "./utils/utils";

const SelectedCheckBoxIcon = (props: IconProps & { checked?: boolean; indeterminate?: boolean }) => {
  const { height = 20, width = 20, className, checked, indeterminate } = props;

  let path = null;

  switch (true) {
    case indeterminate:
      path = <path d="M5 10H15" stroke="black" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />;
      break;
    case checked:
      path = (
        <path
          d="M15.3333 6L7.99999 13.3333L4.66666 10"
          stroke="black"
          stroke-width="1.6666"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      );
      break;
    default:
      break;
  }

  return (
    <svg
      height={height}
      width={width}
      className={className}
      fill={SCALEOPS_COLORS.background.checkbox}
      viewBox="0 0 20 20"
      {...props}
    >
      <rect x="1.5" y="1.5" width="17" height="17" rx="3.5" stroke="black" stroke-width="3" />
      {path}
    </svg>
  );
};

export default SelectedCheckBoxIcon;
