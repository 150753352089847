import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { CreateHPAPolicy, CreateHPAPolicyParams, GetHPAPolicies, GetHPAPoliciesResponse } from "../../../api/fetcher";

const { queryFn: createHPAQueryFn } = CreateHPAPolicy();
const { queryKey: getHPAPoliciesQueryKey } = GetHPAPolicies();

const useCreateHPAPolicy = () => {
  const queryClient = useQueryClient();

  return useMutation((params: CreateHPAPolicyParams) => createHPAQueryFn(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries([getHPAPoliciesQueryKey]);
      const previousPolicies = queryClient.getQueryData<GetHPAPoliciesResponse>([getHPAPoliciesQueryKey]);

      if (previousPolicies?.policies && params.policy) {
        const newPolicyWithoutBuiltInLabel = {
          ...params.policy,
          metadata: {
            ...params.policy.metadata,
            labels: undefined,
            annotations: undefined,
          },
        };

        queryClient.setQueryData<GetHPAPoliciesResponse>([getHPAPoliciesQueryKey], {
          policies: [...previousPolicies.policies, newPolicyWithoutBuiltInLabel],
        });
      }
    },
    onError: (error: Error) => {
      console.log(`Error creating HPA policy: ${error.message}`);
      toast.error(`Failed to create HPA policy`);
    },
    onSuccess: () => {
      toast.success("HPA Policy was created successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
    onSettled: () => {
      queryClient
        .invalidateQueries([getHPAPoliciesQueryKey])
        .catch((error) => console.log("Error invalidating query after creating HPA policy: ", error));
    },
  });
};

export default useCreateHPAPolicy;
