import { IconProps } from "./utils/utils";

const IncreaseArrowIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M19.7654 5.8077L10.4883 0.142933C10.2642 -0.0252448 9.86404 -0.0483292 9.59441 0.091343C9.56441 0.106907 9.53665 0.124162 9.5117 0.142933L0.234504 5.8077C-0.0621349 5.9792 -0.079705 6.26828 0.195251 6.45331C0.331608 6.54506 0.522263 6.59794 0.722826 6.59957H5.11726V13.9394C5.20063 14.2461 5.59839 14.4818 6.09381 14.518H13.9062C14.2969 14.518 14.3945 14.2135 14.3945 13.9394V6.59957H19.2772C19.6817 6.59624 20.0053 6.38907 19.9999 6.13677C19.9973 6.01167 19.9126 5.89275 19.7654 5.8077Z" />
      <path d="M13.9061 15.4316H6.09372C5.68923 15.4316 5.36128 15.6362 5.36128 15.8884C5.36128 16.1407 5.68923 16.3453 6.09372 16.3453H13.9061C14.3106 16.3453 14.6386 16.1407 14.6386 15.8884C14.6386 15.6362 14.3107 15.4316 13.9061 15.4316Z" />
      <path d="M13.9061 17.2589H6.09372C5.68923 17.2589 5.36128 17.4635 5.36128 17.7158C5.36128 17.9681 5.68923 18.1726 6.09372 18.1726H13.9061C14.3106 18.1726 14.6386 17.9681 14.6386 17.7158C14.6386 17.4635 14.3107 17.2589 13.9061 17.2589Z" />
      <path d="M13.9061 19.0862H6.09372C5.68923 19.0862 5.36128 19.2908 5.36128 19.5431C5.36128 19.7954 5.68923 19.9999 6.09372 19.9999H13.9061C14.3106 19.9999 14.6386 19.7954 14.6386 19.5431C14.6386 19.2908 14.3107 19.0862 13.9061 19.0862Z" />
    </svg>
  );
};

export default IncreaseArrowIcon;
