import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { Typography } from "@mui/material";
import { getDataGridSx } from "../../utils/styleUtils";
import MemoryIcon from "../../Icons/MemoryIcon";
import { NodeCostRow, RecommendedCostDataSum, roundToTwoDecimal } from "./nodesCostUtils";
import CPUIcon from "../../Icons/CPUIcon";

const chipClass = "border border-border px-2 rounded-lg flex items-center gap-1";

const columns: GridColDef[] = [
  {
    field: "count",
    headerName: "Qty",
    minWidth: 70,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, NodeCostRow, string>) => {
      return (
        <Typography fontWeight={500} className="flex gap-1 w-full">
          <div className="grow text-left">{params.value}</div>
          <div className="grow text-right">X</div>
        </Typography>
      );
    },
  },
  {
    field: "instanceType",
    headerName: "Instance Type",
    flex: 10,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, NodeCostRow, string>) => {
      return (
        <div className="flex gap-2 flex-wrap">
          <Typography variant="body2">{params.value}</Typography>
          <Typography variant="caption" className="flex flex-wrap gap-2 text-text-darkGray">
            <p className={chipClass}>
              <CPUIcon width={10} height={10} /> CPU: {params.row.cpuCores}
            </p>
            <p className={chipClass}>
              {" "}
              <MemoryIcon width={10} height={10} /> Memory: {roundToTwoDecimal(params.row.memoryGiB)} GiB
            </p>
            <p className={chipClass}>{params.row.spot ? "Spot" : "On-demand"}</p>
          </Typography>
        </div>
      );
    },
  },
  {
    field: "monthlyCost",
    headerName: "Cost",
    flex: 3,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, NodeCostRow, string>) => {
      const roundedValue = Math.round(params.row.monthlyCost * 100) / 100;
      return (
        <div>
          ${roundedValue}{" "}
          <Typography variant="caption" className="text-text-darkGray">
            /mo
          </Typography>
        </div>
      );
    },
  },
];

export enum Theme {
  GREEN = "green",
  RED = "red",
}

interface Props {
  rows: NodeCostRow[];
  recommendedCostDataSum?: RecommendedCostDataSum;
  theme: Theme;
  title?: string;
  baseHeight?: number;
}

const RecommendedNodesCostTables = ({ rows, recommendedCostDataSum, theme, title, baseHeight = 0 }: Props) => {
  return (
    <div
      style={{
        height: 60 * baseHeight + 30,
      }}
    >
      {title && (
        <Typography className="text-text-darkGray pl-2 pb-1" variant="body2">
          {title}
        </Typography>
      )}
      <DataGrid
        pagination={true}
        headerHeight={30}
        rowHeight={60}
        // autoHeight={true}
        sx={{
          ...getDataGridSx(),
          borderRadius: "10px 10px 0px 0px",
          "& .MuiDataGrid-row:hover": {
            background: "none !important",
          },
        }}
        columns={columns}
        initialState={{
          pagination: {
            pageSize: 5,
          },
        }}
        rows={rows}
        disableSelectionOnClick
        hideFooter={rows.length <= 5}
      />
      {recommendedCostDataSum && (
        <div
          className={clsx("border rounded-lg rounded-t-none border-t-0 border-[#C4C4C4] flex px-7 py-3", {
            "text-main-green": theme === Theme.GREEN,
            "text-main-red": theme === Theme.RED,
          })}
        >
          <Typography variant="body2" className={`flex items-end grow gap-2`}>
            <p className={chipClass}>
              <CPUIcon width={12} height={12} />
              CPU: {recommendedCostDataSum.cpuCores}
            </p>
            <p className={chipClass}>
              <MemoryIcon width={12} height={12} />
              Memory: {roundToTwoDecimal(recommendedCostDataSum.memoryGiB)} GiB
            </p>
          </Typography>
          <Typography variant="h6">
            <span style={{ fontWeight: 600 }}>${roundToTwoDecimal(recommendedCostDataSum.monthlyCost)}</span>
            <Typography variant="caption"> /mo</Typography>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default RecommendedNodesCostTables;
