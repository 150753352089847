import { useQuery } from "@tanstack/react-query";
import { GetResourceQuotaExists, GetResourceQuotaExistsResponse } from "../../../../api/fetcher";

const { queryFn, queryKey } = GetResourceQuotaExists();

const useHasResourceQuota = () => {
  const { data } = useQuery<GetResourceQuotaExistsResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });

  return !!data?.exists;
};

export default useHasResourceQuota;
