import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import CircleCheckIcon from "../../../Icons/CircleCheckIcon";
import * as React from "react";
import CloseIcon from "../../../Icons/CloseIcon";

const ICON_SIZE = 20;
export default function GpuRequestColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  const workloadType = params?.row?.smartPolicyWorkloadType;

  if (!workloadType) {
    return <CloseIcon width={ICON_SIZE} height={ICON_SIZE} />;
  }

  if (workloadType === "Unevictable by PDB" || workloadType === "Unevictable by Annotation") {
    return <CircleCheckIcon className="text-main-green" />;
  }

  return <CloseIcon width={ICON_SIZE} height={ICON_SIZE} />;
}
