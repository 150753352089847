import { MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetHPAPolicies, GetHPAPoliciesResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import { SCALEOPS_COLORS } from "../../../colors";
import SingleSelect from "../../../components/SingleSelect";
import Tooltip from "../../../components/Tooltip";
import MagicWand from "../../../Icons/MagicWand";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import { useWorkloadsContext } from "../../../WorkloadsContext";

const DEFAULT_SMART_POLICY = "production";

const { queryFn, queryKey } = GetHPAPolicies();

interface Props {
  selectedHPAPolicy: string | undefined;
  setSelectedPolicy: (policy: string | undefined) => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
}

const SelectHPAPolicy = ({ selectedHPAPolicy, setSelectedPolicy, selectedWorkload }: Props) => {
  const { overriddenWorkloads } = useWorkloadsContext();
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const [displayPolicyName, setDisplayPolicyName] = useState<string | undefined>(undefined);

  const { data } = useQuery<GetHPAPoliciesResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  useEffect(() => {
    const overriddenWorkloadPolicyName = overriddenWorkloads.find(
      (workload) => workload.id === selectedWorkload.id
    )?.hpaPolicyName;

    setDisplayPolicyName(selectedHPAPolicy ?? overriddenWorkloadPolicyName ?? selectedWorkload.hpaPolicyName);
  }, [selectedHPAPolicy, overriddenWorkloads, selectedWorkload]);

  const isSmartPolicy = displayPolicyName === DEFAULT_SMART_POLICY;

  return (
    <Tooltip
      title={selectedHPAPolicy}
      disabled={!isReadyOnlyFrontEnd || !selectedHPAPolicy || !selectedHPAPolicy.length}
      maxWidth={500}
    >
      <SingleSelect<string | undefined>
        sx={{
          color: isSmartPolicy ? "black" : undefined,
          background: isSmartPolicy ? SCALEOPS_COLORS.guideline.lightPurple : SCALEOPS_COLORS.guideline.lightBlue,
        }}
        disabled={isReadyOnlyFrontEnd}
        label="HPA Policy name"
        className="bg-[#efefff5b]"
        labelClassName="text-guidelines-darkBlue"
        arrowIconColor="black"
        selectedColor="black"
        selected={String(displayPolicyName)}
        renderValue={() => {
          return (
            <Typography variant="body2" fontWeight={500} className="w-full truncate pt-[1px] flex items-center gap-2">
              {isSmartPolicy && <MagicWand width={14} height={14} className="text-guideline-darkBlue" />}
              <span className="truncate">{displayPolicyName?.trim()}</span>
            </Typography>
          );
        }}
        setSelected={setSelectedPolicy}
        renderOptionsFunc={() =>
          data?.policies?.map((policy) => (
            <MenuItem key={policy.metadata?.name} value={policy.metadata?.name}>
              <Typography variant="body2" fontWeight={500} className="flex items-center gap-2">
                {policy.metadata?.name}
                {policy.metadata?.name === DEFAULT_SMART_POLICY && (
                  <div className="flex gap-2 bg-guideline-lightPurple px-2 py-1 rounded-full items-center ml-2">
                    <MagicWand color="black" width={18} height={18} />
                    <p className="text-black text-[12px] bolder font-light">Auto-Detected</p>
                  </div>
                )}
              </Typography>
            </MenuItem>
          ))
        }
      />
    </Tooltip>
  );
};

export default SelectHPAPolicy;
