import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import { ObjectParam, useQueryParam } from "use-query-params";
import {
  GetNetworkCostEnabled,
  GetNetworkCostEnabledResponse,
  GetWorkloadNetwork,
  GetWorkloadNetworkResponse,
} from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import NetworkIcon from "../../../../Icons/NetworkIcon";
import WarningIcon from "../../../../Icons/WarningIcon";
import { useMainContext } from "../../../../MainContext";
import { HAS_WORKLOAD_NETWORK_CHARTS } from "../../../../utils/developmentFeatureFlags";
import NetworkAlerts from "../../../Cost/Network/NetworkAlerts";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import Table from "./Table";
import TopMetrics from "./TopMetrics";
import WorkloadNetworkChartsContainer from "./WorkloadNetworkCharts/WorkloadNetworkChartsContainer";

const ERROR_AND_LOADING_CLASS_NAME = "flex w-full min-h-[40vh] justify-center items-center gap-2";

const BASED_TIME = 1000; // ms

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
}

const getWorkloadNetwork = GetWorkloadNetwork();

const WorkloadNetwork = ({ selectedWorkload, setSelectedWorkload }: Props) => {
  const { currentCluster } = useMainContext();

  const [selectedViewPeriod] = useViewPeriodQueryParams();
  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);

  const { queryFn: getNetworkCostEnabledQueryFn, queryKey: getNetworkCostEnabledQueryKey } = GetNetworkCostEnabled();

  const { data, error, isLoading, isFetching } = useQuery<GetWorkloadNetworkResponse, Error>({
    queryKey: [getWorkloadNetwork.queryKey, urlDates, selectedWorkload, selectedViewPeriod],
    queryFn: () =>
      getWorkloadNetwork.queryFn({
        name: selectedWorkload.workloadName,
        namespace: selectedWorkload.namespace,
        workloadType: selectedWorkload.type,
        from: urlDates?.from
          ? dayjs(Number(urlDates?.from) * BASED_TIME).unix() * BASED_TIME
          : dayjs.utc().subtract(Number(selectedViewPeriod), "hour").unix() * BASED_TIME,
        to: urlDates?.to
          ? dayjs(Number(urlDates?.to) * BASED_TIME).unix() * BASED_TIME
          : dayjs.utc().unix() * BASED_TIME,
      }),
    keepPreviousData: true,
    enabled: !!selectedWorkload,
  });

  const { data: getNetworkCostEnabledData } = useQuery<GetNetworkCostEnabledResponse, Error>({
    queryFn: () =>
      getNetworkCostEnabledQueryFn({
        multiCluster: true,
      }),
    queryKey: [getNetworkCostEnabledQueryKey],
    enabled: !!currentCluster,
  });

  if (error) {
    console.log("Error loading Workload Network tab", error);
    return (
      <Typography variant="body2" className={clsx("text-main-red", ERROR_AND_LOADING_CLASS_NAME)}>
        <WarningIcon width={14} height={14} />
        <span>Error loading Workload Network tab</span>
      </Typography>
    );
  }

  if (isLoading && !data) {
    return (
      <div className={ERROR_AND_LOADING_CLASS_NAME}>
        <CircularProgress />
      </div>
    );
  }

  const isNetworkCostEnabledForCurrentCluster =
    !!currentCluster && !!getNetworkCostEnabledData?.networkCostEnabled?.[currentCluster];

  return (
    <div className="flex flex-col gap-[22px] mb-[20px]">
      <NetworkAlerts />
      {isNetworkCostEnabledForCurrentCluster && (
        <div className="border rounded-lg border-border w-full p-4 flex items-center gap-8">
          <NetworkIcon width={30} height={30} />
          <Typography variant="body2" className="grow">
            <b>Workload Network</b>
            <p className="max-w-[100%]">Explore your workload network cost, destinations and replicas.</p>
          </Typography>
          <div className="h-16 w-[1px] bg-border" />
          <SelectViewPeriod />
        </div>
      )}
      {isNetworkCostEnabledForCurrentCluster && <TopMetrics data={data} isLoading={isLoading} />}
      {isNetworkCostEnabledForCurrentCluster && HAS_WORKLOAD_NETWORK_CHARTS && <WorkloadNetworkChartsContainer />}
      {isNetworkCostEnabledForCurrentCluster && (
        <Table
          data={data?.destinations || []}
          isLoading={isLoading || isFetching}
          setSelectedWorkload={setSelectedWorkload}
        />
      )}
    </div>
  );
};

export default WorkloadNetwork;
