import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const LoadingPage = ({ children }: Props) => {
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <CircularProgress size="5em" />
      {children}
    </div>
  );
};

export default LoadingPage;
