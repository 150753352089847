import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  GetCaConfiguration,
  GetCaConfigurationDeploymentResponse,
  GetCaConfigurationDeployment,
} from "../../api/fetcher";
import NodesActions from "./NodesActions";
import ProvisionersActions from "./ProvisionersActions";
import { FeatureEnabled } from "../../utils/FeaturesHelper";
import { GetConfig } from "../../utils/ConfigHelper";

const NodesActionsContainer = () => {
  const { queryFn, queryKey } = GetCaConfiguration();
  const { queryFn: queryFnDeployment, queryKey: queryKeyDeployment } = GetCaConfigurationDeployment();
  const [ownerName, setOwnerName] = useState<string | undefined>(undefined);
  const [podNamespace, setPodNamespace] = useState<string | undefined>(undefined);

  const { data } = useQuery({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
    refetchOnWindowFocus: false,
  });

  const { data: deploymentData, isLoading: deploymentIsLoading } = useQuery<
    GetCaConfigurationDeploymentResponse,
    Error
  >({
    queryKey: [queryKeyDeployment, ownerName, podNamespace],
    queryFn: () =>
      queryFnDeployment({
        name: ownerName,
        namespace: podNamespace,
      }),
    enabled: !!ownerName && !!podNamespace,
  });

  useEffect(() => {
    if (data) {
      setOwnerName(data.ownerName);
      setPodNamespace(data.podNamespace);

      if (deploymentData && !deploymentData.ownerName && !deploymentData.podNamespace) {
        deploymentData.ownerName = data.ownerName;
        deploymentData.podNamespace = data.podNamespace;
      }
    }
  }, [data, deploymentData]);

  if (FeatureEnabled("ShowNodeSimulation")) return null;

  // display if:
  // 1. CAS found OR
  // 2. cloudProvider is Azure && we're not on prem
  const showNodeActions = data?.caFound || (data?.cloudProvider?.toLowerCase() === "azure" && !GetConfig().onPrem);

  return (
    <>
      <ProvisionersActions />
      {showNodeActions && (
        <NodesActions isLoading={deploymentIsLoading && !data} data={deploymentData} rawConfigurationData={data} />
      )}
    </>
  );
};

export default NodesActionsContainer;
