import { Dialog } from "@mui/material";
import { Box } from "@mui/system";

import MetricConfList, { MetricConfProps } from "./Metrics";

export interface ScaleToZeroAwakeTriggerProps extends MetricConfProps {
  open: boolean;
  onClose: () => void;
}
export default function ScaleToZeroAwakeTrigger(props: ScaleToZeroAwakeTriggerProps) {
  const { policyMetricGoalsList, onChange, disabled, availableMetricsList, open, onClose, valueTitle } = props;

  return (
    <Dialog
      hideBackdrop
      open={open}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ p: "30px" }}>
        <MetricConfList
          policyMetricGoalsList={policyMetricGoalsList}
          onChange={onChange}
          disabled={disabled}
          availableMetricsList={availableMetricsList || []}
          valueTitle={valueTitle}
        />
      </Box>
    </Dialog>
  );
}
