import { components } from "../api/schema";
import { getCustomerPrefix, getToken } from "../utils/configUtils";
import useGetVersion from "../pages/Cost/hooks/useGetVersion";
import { helmSetToString } from "../pages/Roles/Permission/commandsUtils";

interface HelmSetupProps {
  conf: components["schemas"]["ConfGetConfResponse"];
  showToken: boolean;
}

export const helmSetup = ({ conf, showToken }: HelmSetupProps) => {
  const displayScaleOpsToken = showToken ? getToken(conf) : getToken(conf).replace(/./g, "*");
  const { data } = useGetVersion();
  const repoToDisplay = data?.helmCommandConfig?.repositoryURL ?? "<SCALEOPS_REPOSITORY>";
  const extraHelmSets = helmSetToString(data?.helmCommandConfig?.extraHelmSets);

  return `helm upgrade --install --create-namespace -n scaleops-system \\
  --repo ${repoToDisplay} \\
  --username scaleops --password ${displayScaleOpsToken} \\
  --set scaleopsToken=${displayScaleOpsToken} \\
  --set clusterName=${getCustomerPrefix(conf)}-$(kubectl config current-context) \\
  --set parentURL=${encodeURI(window.location.protocol + "//" + window.location.host)} \\
  ${extraHelmSets} scaleops scaleops
`.replaceAll("  ", " ");
};
