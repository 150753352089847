import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AskForPremium, GetVersion } from "../api/fetcher";
import useGetParentCluster from "../MainMenu/useGetParentCluster";
import { TOAST_SETTINGS } from "../pages/Roles/mutations/utils";

const askForPremium = AskForPremium();
const getVersion = GetVersion();

const useAskForPremium = () => {
  const parentCluster = useGetParentCluster();
  const queryClient = useQueryClient();

  return useMutation(() => askForPremium.queryFn({ customCluster: parentCluster }), {
    onError: () => {
      toast.error(`Error while create upgrade reqyest`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Upgrade request created successfully", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getVersion.queryKey]);
    },
  });
};

export default useAskForPremium;
