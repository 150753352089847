import { useQuery } from "@tanstack/react-query";
import { GetDCGMExporterInfo, GetDCGMExporterInfoResponse } from "../../../api/fetcher";

export default function useGetDcgmExporterInfo() {
  const { queryKey, queryFn } = GetDCGMExporterInfo();

  return useQuery<GetDCGMExporterInfoResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn({}),
  });
}
