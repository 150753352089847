import { Typography } from "@mui/material";
import CheeseIcon from "../../Icons/CheeseIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { GetConfig } from "../../utils/ConfigHelper";
import { documentationUrl } from "../../utils/urlUtils";
import { StrategyRow } from "../WorkloadStatusByNamespace/AutomationTooltip";

const automateUnevictableTooltipElement = (token: string | undefined) => ({
  icon: <CheeseIcon />,
  name: "Upon pod creation strategy",
  description: (
    <>
      ScaleOps will{" "}
      <span className="text-main-green font-semibold">never actively enforce updates for running pods</span>.
    </>
  ),
  link: documentationUrl({
    path: "guides/policies",
    subPath: "update-strategy",
    token,
  }),
});

const AutomateUnevictableTooltip = () => {
  const config = GetConfig();

  return (
    <div>
      <Typography variant="body2" fontWeight={600}>
        Automation strategy
      </Typography>
      <Typography variant="caption" className="flex gap-[4px] items-center">
        <ScaleopsIcon width={10} height={10} />
        <span
          className="text-guideline-darkPurple"
          style={{
            fontWeight: 600,
          }}
        >
          ScaleOps
        </span>
        will automate the pod as follow:
      </Typography>
      <div className="mt-3">
        <StrategyRow {...automateUnevictableTooltipElement(config.token)} />
      </div>
    </div>
  );
};

export default AutomateUnevictableTooltip;
