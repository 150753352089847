import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const FastReaction = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Fast reaction"
        description="ScaleOps optimization analyzes historical data and acts immediately upon rapid bursts that require an update in resources when needed."
      />
      <FormSwitch
        name="fastReaction"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
      />
    </div>
  );
};
export default FastReaction;
