import clsx from "clsx";
import { useField, FieldHookConfig } from "formik";
import { MenuItem, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SingleSelect from "../SingleSelect";
import Tooltip from "../Tooltip";
import ErrorLabel from "./ErrorLabel";
import React from "react";

type Props = {
  label?: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
  options: {
    value: string | number;
    label: string;
    icon?: JSX.Element | string;
    description?: React.ReactNode;
  }[];
  isSearchable?: boolean;
  defaultValue?: string | number;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  areOptionsDisabled?: boolean;
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormSingleSelectWithDescription = ({
  label,
  info,
  options,
  className,
  isSearchable,
  isExclude,
  setIsExclude,
  areOptionsDisabled,
  defaultValue,
  ...props
}: Props) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col gap-1 w-fit relative">
      {label && (
        <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
          {label}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
      )}
      <SingleSelect<string | number>
        selected={field.value}
        className={clsx(className, "h-[34px] w-[192px]")}
        setSelected={(value) => {
          field.onChange({
            target: {
              name: field.name,
              value,
            },
          });
        }}
        renderOptionsFunc={() =>
          options.map((option) => (
            <MenuItem
              value={option.value}
              key={option.value}
              disabled={areOptionsDisabled}
              sx={{
                "&.Mui-disabled": {
                  opacity: 1,
                  color: "black",
                },
              }}
            >
              <div className="py-4">
                <p>{option.icon}</p>
                <p className="flex">
                  {option.label}
                  {option.value === defaultValue && (
                    <div className="flex gap-2 bg-main-green px-2 rounded-lg items-center ml-2">
                      <p className="text-white text-[12px] bolder">recommended</p>
                    </div>
                  )}
                </p>
                <p className="text-mainMenuBackgroundHover text-[12px]">{option.description}</p>
              </div>
            </MenuItem>
          ))
        }
        renderValue={(value) => {
          const option = options.find((option) => option.value === value);
          if (!option) return null;
          return (
            <div className="flex items-center">
              <p>{option.icon}</p>
              <p>{option.label}</p>
            </div>
          );
        }}
        disabled={!!props.disabled}
        isSearchable={isSearchable}
        isExclude={isExclude}
        setIsExclude={setIsExclude}
      />
      {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
    </div>
  );
};

export default FormSingleSelectWithDescription;
