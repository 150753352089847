import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GetAnalyticsGraph, GetAnalyticsGraphResponse, GetAnalyticsGraphResponseParams } from "../../../../api/fetcher";
import Loading from "../../../../components/Loading";
import { DAY_MONTH_FORMAT } from "../../../../utils/formatterUtils";
import { CHART_WRAPPER_CLASS_NAME, NO_OUTLINE } from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData } from "../utils";
import CustomTooltip from "./CustomTooltip";
import { ANALYTICS_PAGE_DAILY_SYNC_ID } from "./graphUtils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";

const HEIGHT = "h-[300px]";

interface Props {
  title: string;
  color: string;
  label: string;
  queryParams: GetAnalyticsGraphResponseParams;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  tooltipValueSuffix?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: [number, number];
  isDemoVersion?: boolean;
  xAxisInterval?: number;
  isMulticluster?: boolean;
}

const LineChart = ({
  title,
  color,
  queryParams,
  label,
  showPercentage,
  wrapDivClassName,
  tooltipValueSuffix,
  setDate,
  YAxisDomain,
  isDemoVersion,
  xAxisInterval,
  isMulticluster,
}: Props) => {
  const { queryFn, queryKey } = GetAnalyticsGraph();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetAnalyticsGraphResponse, Error>({
    queryKey: [queryKey, queryParams, filtersQueryParams, isMulticluster ? "multicluster" : undefined],
    queryFn: () =>
      queryFn({ ...queryParams, multiCluster: isMulticluster, timeoutSeconds: timeoutSeconds, ...filtersQueryParams }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });
  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [queryParams, filtersQueryParams]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div className={HEIGHT}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    showPercentage,
    false,
    queryParams.from,
    queryParams.to,
    queryParams.groupBy,
    isDemoVersion,
    true
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix() * 1000;

  return (
    <div className={clsx(wrapDivClassName, HEIGHT, CHART_WRAPPER_CLASS_NAME, "w-[100%] border border-border rounded")}>
      <Typography variant="body2" className="w-full text-center">
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          syncId={ANALYTICS_PAGE_DAILY_SYNC_ID}
          data={graphData}
          onMouseDown={(e) => {
            e.activeLabel &&
              setSelectPosition({
                ...selectPosition,
                from: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              setSelectPosition({
                ...selectPosition,
                to: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <Tooltip
            content={<CustomTooltip color={color} tooltipValueSuffix={tooltipValueSuffix} />}
            wrapperStyle={NO_OUTLINE}
          />
          <Area
            type="linear"
            strokeWidth={2}
            dataKey={queryParams?.types && queryParams?.types.length > 0 ? queryParams.types[0] : label}
            name={label}
            stroke={color}
            fill={color}
            fillOpacity={0.4}
          />
          <XAxis
            dataKey="date"
            style={{ fontSize: "x-small" }}
            interval={Math.floor(graphData.length / (xAxisInterval ?? 10))}
            strokeWidth={2}
            tickFormatter={(value) => dayjs(String(value)).format(DAY_MONTH_FORMAT)}
          />
          <YAxis style={{ fontSize: "x-small" }} domain={YAxisDomain} strokeWidth={2} />
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke={color}
              fill={color}
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
