import { Typography } from "@mui/material";
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import UnFilterIcon from "../../../../Icons/UnFilterIcon";

const ClearWorkloadsFilters = () => {
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerms", StringParam);
  const [annotationsParams, setAnnotationsParams] = useQueryParam("annotations", ArrayParam);
  const [clusterFiltersParams, setClusterFiltersParams] = useQueryParam("clusterFilters", ArrayParam);
  const [, setIsAnnotationExcludeParams] = useQueryParam("isAnnotationExclude", NumberParam);
  const [, setIsClusterExcludeParams] = useQueryParam("isClusterExclude", NumberParam);
  const [, setIsLabelExcludeParams] = useQueryParam("isLabelExclude", NumberParam);
  const [, setIsNamespaceExcludeParams] = useQueryParam("isNamespaceExclude", NumberParam);
  const [, setIsWorkloadTypeExcludeParams] = useQueryParam("isWorkloadTypeExclude", NumberParam);
  const [labelsParams, setLabelsParams] = useQueryParam("labels", ArrayParam);
  const [, setLogicalAnnotationParams] = useQueryParam("logicalAnnotation", NumberParam);
  const [, setLogicalLabelParams] = useQueryParam("logicalLabel", NumberParam);
  const [multiClusterParams, setMultiClusterParams] = useQueryParam("multiCluster", NumberParam);
  const [namespacesParams, setNamespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [tagsParams, setTagsParams] = useQueryParam("tags", ArrayParam);
  const [workloadTypesParams, setWorkloadTypesParams] = useQueryParam("workloadTypes", ArrayParam);
  const [shouldIgnoreWorkloadsParams, setShouldIgnoreWorkloadsParams] = useQueryParam(
    "shouldIgnoreWorkloads",
    NumberParam
  );

  const clearFilters = () => {
    setSearchTerm(undefined);
    setAnnotationsParams([]);
    setClusterFiltersParams([]);
    setIsAnnotationExcludeParams(undefined);
    setIsClusterExcludeParams(undefined);
    setIsLabelExcludeParams(undefined);
    setIsNamespaceExcludeParams(undefined);
    setIsWorkloadTypeExcludeParams(undefined);
    setLabelsParams([]);
    setLogicalAnnotationParams(undefined);
    setLogicalLabelParams(undefined);
    setMultiClusterParams(undefined);
    setNamespacesParams([]);
    setTagsParams([]);
    setWorkloadTypesParams([]);
    setShouldIgnoreWorkloadsParams(undefined);
  };

  const shouldHideButton =
    !searchTerm &&
    !annotationsParams?.length &&
    !clusterFiltersParams?.length &&
    !labelsParams?.length &&
    !namespacesParams?.length &&
    !tagsParams?.length &&
    !workloadTypesParams?.length &&
    !shouldIgnoreWorkloadsParams &&
    !multiClusterParams;

  if (shouldHideButton) return null;

  return (
    <div className="w-fit">
      <Typography
        variant="caption"
        onClick={clearFilters}
        className="hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
      >
        <UnFilterIcon width={15} height={15} />
        <p>Clear all filters</p>
      </Typography>
    </div>
  );
};

export default ClearWorkloadsFilters;
