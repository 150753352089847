import * as React from "react";
import NodeGroups from "./NodeGroups";

export default function Autoscaler() {
  return (
    <div className="min-w-[1200px]">
      <NodeGroups />
    </div>
  );
}
