import { useEffect, useState } from "react";
import { useMainContext } from "../MainContext";
import { ScaleOps, ScaleOpsClient } from "./api";

const useGetApi = () => {
  const [api, setApi] = useState<ScaleOpsClient>(ScaleOps);
  const { currentCluster } = useMainContext();

  useEffect(() => {
    setApi(ScaleOps);
  }, [currentCluster]);

  return api;
};

export default useGetApi;
