import { CircularProgress, ListItemText, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRbac, GetRbacResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CodeSnippet, { THEME as CodeSnippetTheme } from "../../../components/CodeSnippet";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import { GetConfig } from "../../../utils/ConfigHelper";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import PermissionsDocsLink from "../Permission/PermissionsDocsLink";
import { getIntegrationCommand } from "./commandsUtils";
import { AuthMethod, SSOIntegration, SSOIntegrationDocLink } from "./utils";
import withDisabled, { TooltipContent } from "../../../components/withDisabled";
import SSOWithAuthUserTable from "./SSOWithAuthUserTable";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import useGetVersion from "../../Cost/hooks/useGetVersion";

export const HAS_ROLES = true;

const WRAPPER_CLASS_NAME = "pb-10";

const DockLink = ({ selectedIntegration }: { selectedIntegration: SSOIntegration }) => (
  <PermissionsDocsLink
    relativePath={SSOIntegrationDocLink[selectedIntegration]}
    linkTitle={`${capitalizeFirstLetter(selectedIntegration)} docs`}
  />
);

export type Rule = components["schemas"]["RbacRule"];

const { queryKey, queryFn } = GetRbac();

const SSOWithAuthUserTableWithDisabled = withDisabled(SSOWithAuthUserTable);

interface SSOContainerProps {
  currentAuthMethod: AuthMethod;
}

const SSOWithAuthorization = ({ currentAuthMethod }: SSOContainerProps) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCurrentAuthMethod = currentAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION;

  const [selectedIntegration, setSelectedIntegration] = useState<SSOIntegration>(SSOIntegration.Okta);

  const [rows, setRows] = useState<Rule[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [clusters, setClusters] = useState<string[]>([]);
  const conf = GetConfig();
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });

  const { data: versionData } = useGetVersion();

  useEffect(() => {
    if (JSON.stringify(ssoConf) !== JSON.stringify(conf.ssoConf))
      setSsoConf(
        conf.ssoConf || {
          enabled: false,
          provider: "none",
        }
      );
  }, [conf]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!HAS_ROLES) {
      navigate("/");
    }
  }, []);

  const { data, isError, error, isLoading } = useQuery<GetRbacResponse>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });

  useEffect(() => {
    if (data?.rules) {
      setRows(data.rules);
      const uniqueTags =
        [...new Set(data.clustersInfo?.map((c) => c.tags).flat())].filter((e) => !!e).map((t) => String(t)) ?? [];
      setTags(uniqueTags.sort());
      const uniqueClusters =
        [...new Set(data.clustersInfo?.map((c) => c.name).flat())].filter((e) => !!e).map((t) => String(t)) ?? [];
      setClusters(uniqueClusters.sort());
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className={clsx(WRAPPER_CLASS_NAME, "flex items-center justify-center h-[500px]")}>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    toast.error("Error fetching roles");
    console.log("Error fetching roles", error);

    return (
      <Typography className={(clsx(WRAPPER_CLASS_NAME), "text-red-500")} variant="body2">
        Error fetching roles
      </Typography>
    );
  }

  return (
    <>
      {!isCurrentAuthMethod && (
        <div className={"flex flex-col gap-4"}>
          <CodeSnippet
            description={
              <div className="flex flex-col gap-4">
                <Typography variant="body2" className="text-text-lightBlack">
                  Configure <b>SSO authentication</b> and <b>authorization</b> on your cluster.
                </Typography>
                <div className="flex gap-2 items-center">
                  <Typography variant="caption" className="text-text-lightBlack">
                    SSO integration:
                  </Typography>
                  <SingleSelect<SSOIntegration>
                    selected={selectedIntegration}
                    setSelected={(option) => {
                      setSelectedIntegration(option);
                    }}
                    renderOptionsFunc={() =>
                      Object.values(SSOIntegration).map((option) => {
                        return (
                          <MenuItem value={option} key={option}>
                            <ListItemText
                              primary={capitalizeFirstLetter(option)}
                              sx={{
                                fontSize: "12px",
                              }}
                            />
                          </MenuItem>
                        );
                      })
                    }
                    size={SingleSelectSize.Small}
                  />
                </div>
              </div>
            }
            theme={CodeSnippetTheme.light}
            codeSnippet={getIntegrationCommand({
              currentIntegration: currentAuthMethod,
              selectedIntegration: AuthMethod.SSO_WITH_AUTHORIZATION,
              selectedSsoIntegration: selectedIntegration,
              repository: versionData?.helmCommandConfig?.repositoryURL,
              token: conf.token,
              hideToken: true,
            })}
            className="w-full"
          />
          <div className="flex flex-col">
            <DockLink selectedIntegration={selectedIntegration} />
            <PermissionsDocsLink
              relativePath="/sso-and-login-security/roles-management/"
              linkTitle={`Roles Management docs`}
            />
          </div>
        </div>
      )}
      <div className={"flex flex-col gap-4"}>
        <div>
          <Typography variant="body2" className="text-text-lightBlack">
            {isCurrentAuthMethod ? (
              <span>Manage and control your permissions usings groups, roles, clusters and namespaces.</span>
            ) : (
              <span>
                After configuring <b>SSO authentication</b> and <b>authorization</b>, You will be able to manage and
                control your permissions usings groups, roles, clusters and namespaces.{" "}
              </span>
            )}
          </Typography>
        </div>
        <SSOWithAuthUserTableWithDisabled
          rows={rows}
          clusters={clusters}
          tags={tags}
          ssoConf={ssoConf}
          disabled={!isCurrentAuthMethod || isReadyOnlyFrontEnd}
          disabledTooltipContent={TooltipContent.None}
        />
      </div>
    </>
  );
};

export default SSOWithAuthorization;
