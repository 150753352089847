import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetSlackConf, UpdateSlackConfMulti, UpdateSlackConfMultiInput } from "../../api/fetcher";
import { toast } from "react-toastify";
import { useMultiClusterParams } from "./ClusterMultiSelect";

export const useUpdateSlackConf = (onError?: () => void, manualSetMultiClusterParams = false) => {
  const updateSlackConf = UpdateSlackConfMulti();
  const getSlackConf = GetSlackConf();
  const queryClient = useQueryClient();
  const multiClusterParams = useMultiClusterParams();

  return useMutation(
    (params: UpdateSlackConfMultiInput) => {
      if (params.multiCluster && !manualSetMultiClusterParams) {
        params.clusters = multiClusterParams.clusters;
        params.tags = multiClusterParams.tags;
      }
      return updateSlackConf.queryFn(params);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([getSlackConf.queryKey]);
      },
      onError: (error) => {
        console.log("Error while updating slack configuration", error);
        toast.error("Error while updating slack configuration");
        onError && onError();
      },
      onSuccess: () => {
        toast.success("Slack configuration updated");
      },
    }
  );
};

export default useUpdateSlackConf;
