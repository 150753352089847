import { IconProps } from "./utils/utils";

const AddIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M10 0C4.48645 0 0 4.48566 0 10C0 15.5143 4.48645 20 10 20C15.5143 20 20 15.5143 20 10C20 4.48566 15.5136 0 10 0ZM10 18.4508C5.34004 18.4508 1.54918 14.66 1.54918 10C1.54918 5.34004 5.34004 1.54918 10 1.54918C14.66 1.54918 18.4508 5.34004 18.4508 10C18.4508 14.66 14.66 18.4508 10 18.4508Z" />
        <path d="M13.873 9.1557H6.12703C5.69945 9.1557 5.35242 9.50273 5.35242 9.93031C5.35242 10.3579 5.69945 10.7049 6.12703 10.7049H13.873C14.3005 10.7049 14.6476 10.3579 14.6476 9.93031C14.6476 9.50273 14.3005 9.1557 13.873 9.1557Z" />
        <path d="M10.7746 13.8033L10.7746 6.05732C10.7746 5.62975 10.4276 5.28271 9.99999 5.28271C9.57241 5.28271 9.22538 5.62975 9.22538 6.05732L9.22538 13.8033C9.22538 14.2308 9.57241 14.5779 9.99999 14.5779C10.4276 14.5779 10.7746 14.2308 10.7746 13.8033Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddIcon;
