import clsx from "clsx";

interface Props {
  icon: JSX.Element;
  className?: string;
}
const CircleIconWrapper = ({ icon, className }: Props) => (
  <div className={clsx(className, "flex items-center align-middle rounded-full p-2 scaleopsShadow")}>{icon}</div>
);

export default CircleIconWrapper;
