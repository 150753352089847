import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { FilterOptions } from "./useAggregationFilters";

const useShouldAddNamespaceFilter = () => {
  const [shouldAddNamespaceFilter, setShouldAddNamespaceFilter] = useState<boolean>(false);

  const [groupByNamespaceParams] = useQueryParam(FilterOptions.GroupByNamespace, withDefault(BooleanParam, true));
  const [groupByCluster] = useQueryParam(FilterOptions.groupByCluster, withDefault(BooleanParam, true));
  const [groupByLabelsParams] = useQueryParam(FilterOptions.GroupByLabels, ArrayParam);
  const [groupByAnnotationsParams] = useQueryParam(FilterOptions.GroupByAnnotations, ArrayParam);

  useEffect(() => {
    if (!!groupByNamespaceParams && !groupByCluster && !groupByLabelsParams && !groupByAnnotationsParams) {
      setShouldAddNamespaceFilter(true);
    } else {
      setShouldAddNamespaceFilter(false);
    }
  }, [groupByCluster, groupByNamespaceParams, groupByLabelsParams, groupByAnnotationsParams]);

  return shouldAddNamespaceFilter;
};

export default useShouldAddNamespaceFilter;
