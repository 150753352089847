import { useQuery } from "@tanstack/react-query";
import { GetCustomerToken, GetCustomerTokenResponse } from "../../../api/fetcher";

export default function useGetGpuMetrics() {
  const { queryKey, queryFn } = GetCustomerToken();

  return useQuery<GetCustomerTokenResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });
}
