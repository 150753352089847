import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetClustersResponse, getClusters } from "../api/fetcher";

const FEATURE_DISABLED = true;
export const MIN_TIMEOUT_SECOND = 5;
const MAX_TIMEOUT_SECOND = 60;
const FACTOR = 1.5;

interface Props {
  isError: boolean;
  data?: { clusters?: string[] | undefined } | undefined;
  isDisabled?: boolean;
}

const clustersApi = getClusters();

const useGetTimeoutSeconds = ({ data, isError, isDisabled }: Props) => {
  if (isDisabled || FEATURE_DISABLED) {
    return undefined;
  }

  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(5);

  const { data: clustersData } = useQuery<GetClustersResponse, Error>({
    queryKey: [clustersApi.queryKey],
    queryFn: clustersApi.queryFn,
    refetchInterval: 10 * 1000,
  });

  const updateTimeoutSeconds = (success: boolean) => {
    if (success) {
      setTimeoutSeconds(MIN_TIMEOUT_SECOND);
    } else {
      const newTimeoutSeconds = Math.min(timeoutSeconds * FACTOR, MAX_TIMEOUT_SECOND);
      setTimeoutSeconds(newTimeoutSeconds);
    }
  };

  useEffect(() => {
    if (!isError && data?.clusters && data.clusters?.length === clustersData?.clusters?.length) {
      updateTimeoutSeconds(true);
    } else {
      updateTimeoutSeconds(false);
    }
  }, [data, isError]);

  return timeoutSeconds;
};

export default useGetTimeoutSeconds;
