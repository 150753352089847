import { memo, useEffect } from "react";
import { toast } from "react-toastify";

interface Props {
  message: string;
}
const DefaultFallback = memo(({ message }: Props) => {
  useEffect(() => {
    toast.error(message, {
      position: "top-center",
    });
  }, [message]);

  return null;
});

export default DefaultFallback;
