import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ObjectParam, useQueryParam } from "use-query-params";
import InfoIcon from "../../../Icons/InfoIcon";
import { GetNodeAnalytics, GetNodeAnalyticsParams, GetNodeAnalyticsResponse } from "../../../api/fetcher";
import Loading from "../../../components/Loading";
import InfoTooltip from "../../../components/Tooltip";
import {
  TooltipTrigger,
  UpdateActiveTooltips,
} from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import CustomTooltip from "../../../pages/Overview/PolicyTuning/WorkloadAnalytics/CustomTooltip";
import { CHART_HEIGHT } from "../../../pages/Overview/PolicyTuning/WorkloadAnalytics/utils";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../utils/formatterUtils";
import { DOT_RADIUS, DOT_STROKE_WIDTH, LINE_CHART_TYPE, NO_OUTLINE } from "../../../utils/styleUtils";
import { NODE_ANALYTICS_SYNCH_ID, NODE_OVERVIEW_DATES_URL_PARAM, useNodesViewPeriodQueryParams } from "../Utils";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";

export type LineChartDataRecord = Record<string, number | string | undefined>;

interface Props {
  title: string;
  color: string;
  label: string;
  displayLabel?: string;
  queryParams: GetNodeAnalyticsParams;
  tooltipValueSuffix?: string;
  YAxisDomain?: [number, number];
  xAxisInterval?: number;
  wrapDivClassName?: string;
  hasBooleanValue?: boolean;
  booleanOptions?: {
    true: string;
    false: string;
  };
  dataParser?: (data: LineChartDataRecord[]) => LineChartDataRecord[];
  YTickLine?: boolean;
  infoTooltipContent?: React.ReactNode;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  dotted?: boolean;
  YAxisAllowDecimals?: boolean;
  YAxisPadding?: { top?: number; bottom?: number };
}

const LineChart = ({
  title,
  color,
  label,
  displayLabel,
  queryParams,
  tooltipValueSuffix,
  YAxisDomain,
  xAxisInterval,
  wrapDivClassName,
  hasBooleanValue,
  booleanOptions,
  dataParser,
  YTickLine = true,
  infoTooltipContent,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType,
  updateActiveTooltips,
  dotted,
  YAxisAllowDecimals,
  YAxisPadding,
}: Props) => {
  const { queryFn, queryKey } = GetNodeAnalytics();
  const [, setDates] = useQueryParam(NODE_OVERVIEW_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useNodesViewPeriodQueryParams();

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const { data, isLoading, error, isError } = useQuery<GetNodeAnalyticsResponse, Error>({
    queryKey: [queryKey, queryParams.from, queryParams.to, queryParams.name, queryParams.types],
    queryFn: () => queryFn(queryParams),
  });

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div className={clsx(wrapDivClassName, CHART_HEIGHT, "px-4 py-16 bg-white border border-border rounded")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  let graphData: LineChartDataRecord[] = data.values
    ? data.values.map((item) => ({
        timestamp: item.timestamp,
        ...(item.values || {}),
      }))
    : [];
  graphData = dataParser ? dataParser(graphData) : graphData;
  const firstXPointString = String(graphData[0]?.timestamp);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix();
  const lastXPointString = String(graphData[graphData.length - 1]?.timestamp);
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix();

  return (
    <div
      className={clsx(
        wrapDivClassName,
        CHART_HEIGHT,
        "px-4 py-8 bg-white border border-border rounded unselectable-svg-text"
      )}
    >
      <Typography variant="body2" className="w-full flex justify-center items-center gap-1" style={{ fontWeight: 600 }}>
        <span>{title}</span>
        {infoTooltipContent && (
          <InfoTooltip title={infoTooltipContent}>
            <InfoIcon width={14} height={14} />
          </InfoTooltip>
        )}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          data={graphData}
          syncId={NODE_ANALYTICS_SYNCH_ID}
          onMouseDown={(e) => {
            if (e.activeLabel && !disabledZoom) {
              setSelectPosition({
                ...selectPosition,
                from: adjustedDayjs(e.activeLabel).unix(),
                fromX: e.activeLabel,
              });
            }
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              !disabledZoom &&
              setSelectPosition({
                ...selectPosition,
                to: adjustedDayjs(e.activeLabel).unix(),
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <Tooltip
            wrapperStyle={NO_OUTLINE}
            content={
              <CustomTooltip
                color={color}
                tooltipValueSuffix={tooltipValueSuffix}
                hasBooleanValue={hasBooleanValue}
                booleanOptions={booleanOptions}
                label={label}
                displayLabel={displayLabel}
                frozenTooltipType={frozenTooltipType}
                tooltipTrigger={tooltipTrigger}
                tooltipId={queryKey}
                updateActiveTooltips={updateActiveTooltips}
              />
            }
            trigger={tooltipTrigger}
          />
          <Area
            type={LINE_CHART_TYPE}
            stackId="1"
            strokeWidth={dotted ? 0 : 2}
            dataKey={label}
            name={label}
            stroke={color}
            fill={color}
            fillOpacity={0.4}
            radius={dotted ? DOT_RADIUS : 0}
            dot={{ r: dotted ? 1 : 0, strokeWidth: dotted ? DOT_STROKE_WIDTH : 0 }}
            isAnimationActive={!dotted}
          />
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke={color}
              fill={color}
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
          <XAxis
            dataKey="timestamp"
            interval={Math.floor(graphData.length / (xAxisInterval ?? 5))}
            strokeWidth={2}
            style={{ fontSize: "x-small" }}
            tickFormatter={(value) => {
              const epochValue = adjustedDayjs(String(value)).unix();
              return getTimeFormatFromEpochAndPeriodInHours(epochValue, selectedViewPeriod);
            }}
          />
          <YAxis
            padding={YAxisPadding}
            allowDecimals={YAxisAllowDecimals}
            style={{ fontSize: "x-small" }}
            domain={YAxisDomain}
            tickLine={YTickLine}
            tickFormatter={YTickLine === false ? () => "" : undefined}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
