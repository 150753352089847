import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { GetWorkloadYamlResponse, GetWorkloadYamlParams, GetWorkloadYaml } from "../../api/fetcher";
import LogIcon from "../../Icons/LogIcon";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  namespace: string;
  name: string;
  kind: string;
  className?: string;
}

const WorkloadYaml = ({ namespace, name, kind }: Props) => {
  const { queryFn, queryKey } = GetWorkloadYaml();
  const { data, isLoading, error } = useQuery<GetWorkloadYamlResponse, Error>({
    queryKey: [queryKey, name],
    queryFn: () => queryFn({ namespace: namespace, name: name, kind: kind } as GetWorkloadYamlParams),
    enabled: !!name,
    refetchInterval: 60 * 1000,
  });

  return (
    <div className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <LogIcon width={30} height={30} />
        <Typography variant="body2">
          <b>Workload's yaml</b>
          <p>Explore workload's full yaml data</p>
        </Typography>
      </div>
      {isLoading && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          <CircularProgress />
        </div>
      )}
      {error && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          Error while loading node's yaml: {error.message}
        </div>
      )}
      {!isLoading && (
        <div className={"border border-border rounded p-4"}>
          <pre className={"table"}>{data?.yaml}</pre>
        </div>
      )}
    </div>
  );
};
export default WorkloadYaml;
