import React from "react";
import Tooltip from "./Tooltip";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { SCALEOPS_COLORS } from "../colors";

const SIZE = "25px";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&:not(.Mui-selected)": {
      backgroundColor: "rgba(22,68,96,0.02)",
    },
    "&:not(:first-of-type)": {
      borderRadius: "50%",
    },
    "&:first-of-type": {
      borderRadius: "50%",
    },
  },
}));

export const convertDayNumberToDayOfWeek = (dayNum: number): string => {
  switch (dayNum) {
    case 0: {
      return "S";
    }
    case 1: {
      return "M";
    }
    case 2: {
      return "T";
    }
    case 3: {
      return "W";
    }
    case 4: {
      return "T";
    }
    case 5: {
      return "F";
    }
    case 6: {
      return "S";
    }
    default: {
      return "";
    }
  }
};

export const convertDayNumberToDayOfWeekLong = (dayNum: number): string => {
  switch (dayNum) {
    case 0: {
      return "Sunday";
    }
    case 1: {
      return "Monday";
    }
    case 2: {
      return "Tuesday";
    }
    case 3: {
      return "Wednesday";
    }
    case 4: {
      return "Thursday";
    }
    case 5: {
      return "Friday";
    }
    case 6: {
      return "Saturday";
    }
    default: {
      return "";
    }
  }
};
export default function DaySelection(props: {
  id?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: number[]) => void;
  selectedDays: number[] | undefined;
  disabled?: boolean;
  hasSelectAtLeastOneTooltip?: boolean;
}) {
  const { onChange, selectedDays, disabled, hasSelectAtLeastOneTooltip } = props;

  return (
    <StyledToggleButtonGroup
      fullWidth={props.fullWidth}
      id={props.id}
      sx={props.sx}
      size="small"
      onChange={onChange}
      value={selectedDays}
      disabled={disabled}
    >
      {[0, 1, 2, 3, 4, 5, 6].map((index) => {
        const isSelected = selectedDays?.includes(index);
        return (
          <ToggleButton
            sx={{
              width: SIZE,
              height: SIZE,
              "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                marginLeft: 0,
              },
            }}
            style={{
              fontWeight: 300,
              border: "1px solid black",
              backgroundColor: isSelected ? SCALEOPS_COLORS.main.linkBlue : "white",
              color: isSelected ? "white" : "black",
            }}
            value={index}
            aria-label={convertDayNumberToDayOfWeekLong(index)}
          >
            <Tooltip
              title="A minimum of one day must be selected."
              disabled={!hasSelectAtLeastOneTooltip || !isSelected || (selectedDays && selectedDays.length > 1)}
            >
              <div className="w-[24px]">
                <Typography variant="caption">{convertDayNumberToDayOfWeek(index)}</Typography>
              </div>
            </Tooltip>
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
}
