import { IconProps } from "./utils/utils";

const MemoryIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M16.0547 20H3.97141C2.70721 20 1.67969 18.9725 1.67969 17.7083V2.29172C1.67969 1.02753 2.70721 0 3.97141 0H13.5713C14.133 0 14.6739 0.204926 15.0938 0.578308L17.5772 2.78503C18.0664 3.22083 18.3464 3.84506 18.3464 4.49829V17.7083C18.3464 18.9725 17.3189 20 16.0547 20ZM3.97141 1.25C3.39722 1.25 2.92969 1.71753 2.92969 2.29172V17.7083C2.92969 18.2825 3.39722 18.75 3.97141 18.75H16.0547C16.6289 18.75 17.0964 18.2825 17.0964 17.7083V4.49829C17.0964 4.20166 16.9688 3.91754 16.7464 3.71918L14.263 1.51245C14.0721 1.34338 13.8263 1.25 13.5713 1.25H3.97141Z" />
        <path d="M4.80469 5C4.45969 5 4.17969 4.72 4.17969 4.375V3.125C4.17969 2.78 4.45969 2.5 4.80469 2.5C5.14969 2.5 5.42969 2.78 5.42969 3.125V4.375C5.42969 4.72 5.14969 5 4.80469 5Z" />
        <path d="M7.30469 5C6.95969 5 6.67969 4.72 6.67969 4.375V3.125C6.67969 2.78 6.95969 2.5 7.30469 2.5C7.64969 2.5 7.92969 2.78 7.92969 3.125V4.375C7.92969 4.72 7.64969 5 7.30469 5Z" />
        <path d="M9.80469 5C9.45969 5 9.17969 4.72 9.17969 4.375V3.125C9.17969 2.78 9.45969 2.5 9.80469 2.5C10.1497 2.5 10.4297 2.78 10.4297 3.125V4.375C10.4297 4.72 10.1497 5 9.80469 5Z" />
        <path d="M12.3047 5C11.9597 5 11.6797 4.72 11.6797 4.375V3.125C11.6797 2.78 11.9597 2.5 12.3047 2.5C12.6497 2.5 12.9297 2.78 12.9297 3.125V4.375C12.9297 4.72 12.6497 5 12.3047 5Z" />
        <path d="M15.2214 17.5H4.80469C4.45969 17.5 4.17969 17.22 4.17969 16.875V10.625C4.17969 10.28 4.45969 10 4.80469 10H15.2214C15.5664 10 15.8464 10.28 15.8464 10.625V16.875C15.8464 17.22 15.5664 17.5 15.2214 17.5ZM5.42969 16.25H14.5964V11.25H5.42969V16.25Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MemoryIcon;
