import { Typography } from "@mui/material";
import clsx from "clsx";
import CustomCheckBox, { THEME as CHECKBOX_THEME } from "../../../components/CustomCheckBox";

interface Props {
  Title: string;
  description: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

const AuthOptionBox = ({ Title, description, isSelected, onClick }: Props) => {
  return (
    <div
      className={clsx("px-[40px] py-[25px] w-[340px] border rounded-lg relative flex gap-5 items-center", {
        "border-strongerBorder hover:bg-guideline-lightGray cursor-pointer": !isSelected,
        "border-primary-purpleBlue bg-guideline-lighterPurple cursor-default": isSelected,
      })}
      onClick={onClick}
    >
      <div className="absolute top-[15px] right-[15px]">
        <CustomCheckBox
          value={isSelected}
          onClick={() => {
            console.log(`selected ${Title}`);
          }}
          theme={CHECKBOX_THEME.PURPLE}
        />
      </div>
      <div>
        <Typography
          variant="body1"
          fontWeight={700}
          className={clsx({
            "text-text-lightBlack": !isSelected,
            "text-primary-purpleBlue": isSelected,
          })}
        >
          {Title}
        </Typography>
        <div
          className={clsx({
            "text-text-lightBlack": !isSelected,
            "text-primary-black": isSelected,
          })}
          style={{
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default AuthOptionBox;
