import { IconProps } from "./utils/utils";

const ReadOnlyIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M17.0711 2.92891C15.1823 1.04016 12.6711 0 10 0C7.32891 0 4.8177 1.04016 2.92895 2.92891C1.04016 4.8177 0 7.32891 0 10C0 12.6711 1.04016 15.1823 2.92895 17.0711C4.8177 18.9598 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.8177 17.0711 2.92891ZM16.2424 3.75754C17.9098 5.425 18.8281 7.64195 18.8281 10C18.8281 12.1732 18.0476 14.2262 16.6201 15.84L11.8883 11.1082L15.4481 7.5484C16.2419 6.75457 16.2419 5.46289 15.4481 4.66906C14.6543 3.87523 13.3626 3.87523 12.5688 4.66906L9.00898 8.22891L4.15996 3.37992C5.77379 1.95238 7.82676 1.17188 10 1.17188C12.3581 1.17188 14.575 2.09016 16.2424 3.75754ZM9.83762 9.05754L12.3512 6.54398L13.5732 7.76605L11.0596 10.2796L9.83762 9.05754ZM10.231 11.1082L7.1343 14.2049L5.86484 14.2523L5.91223 12.9829L9.00891 9.88613L10.231 11.1082ZM14.6195 6.71977L14.4018 6.93738L13.1798 5.71531L13.3974 5.4977C13.7343 5.16082 14.2825 5.16082 14.6195 5.4977C14.7827 5.66094 14.8725 5.87789 14.8725 6.10875C14.8725 6.33961 14.7827 6.55656 14.6195 6.71977ZM3.75758 16.2425C2.09016 14.575 1.17188 12.3581 1.17188 10C1.17188 7.84992 1.93566 5.81738 3.33434 4.21152L8.18035 9.05754L4.7584 12.4795L4.64676 15.4705L7.63773 15.3588L11.0597 11.9368L15.7886 16.6657C14.1827 18.0643 12.1501 18.8281 10 18.8281C7.64191 18.8281 5.425 17.9098 3.75758 16.2425Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReadOnlyIcon;
