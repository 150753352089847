import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { OverviewChartColors, TabOptions, RequestAndUsageByTimestampKey, ParsedData, groupData } from "./overviewUtils";
import CustomOverviewTooltip from "./CustomOverviewTooltip";
import StackedOverviewChartTick from "./StackedOverviewChartTick";
import LegendPayloads, { stripsPattern } from "./LegendPayloads";

interface Props {
  selectedTab: TabOptions;
  data: RequestAndUsageByTimestampKey[];
  showWaste?: boolean;
  showRecommended?: boolean;
  showAllocatable?: boolean;
}

const StackedOverviewChart = ({ selectedTab, data, showWaste, showRecommended, showAllocatable }: Props) => {
  let graphData: ParsedData[] = [];

  switch (selectedTab) {
    case TabOptions.Week:
      graphData = groupData(data);
      break;
    case TabOptions.Month:
      graphData = groupData(data, true);
      break;
    default:
      graphData = data.map((item) => ({
        date: "",
        timestamps: item.timestamps,
        timestampAggregators: item.timestampAggregators,
        usage: item.usage,
        request: item.request,
        recommended: item.recommended,
        allocatable: item.allocatable,
      }));
      break;
  }

  graphData.forEach((item) => {
    let value = item.request - item.usage;
    value = Math.round((value + Number.EPSILON) * 100) / 100;
    item.waste = value >= 0 ? value : 0;
  });

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <XAxis dataKey="date" style={{ fontSize: "x-small" }} interval={0} tick={<StackedOverviewChartTick />} />
          <YAxis style={{ fontSize: "x-small" }} />
          <Tooltip
            content={
              <CustomOverviewTooltip
                selectedTabOption={selectedTab}
                showWaste={showWaste}
                showRecommended={showRecommended}
                showAllocatable={showAllocatable}
              />
            }
            cursor={{ fill: "#f4f4f4" }}
          />
          <Bar dataKey="usage" stackId="a" fill={OverviewChartColors.Usage} stroke={OverviewChartColors.Usage} />
          {showRecommended && (
            <Bar
              dataKey="recommended"
              stackId="a"
              fill={OverviewChartColors.Recommended}
              stroke={OverviewChartColors.Recommended}
            />
          )}
          {showWaste && (
            <>
              <Bar dataKey="waste" stackId="a" fill={"url(#color-stripe)"} />
              <defs>{stripsPattern}</defs>
            </>
          )}
          <Bar dataKey="request" stackId="a" fill={OverviewChartColors.Request} stroke={OverviewChartColors.Request} />
          {showAllocatable && (
            <Bar
              dataKey="allocatable"
              stackId="a"
              fill={OverviewChartColors.Allocatable}
              stroke={OverviewChartColors.Allocatable}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
      <LegendPayloads showRecommended={showRecommended} showAllocatable={showAllocatable} />
    </>
  );
};

export default StackedOverviewChart;
