import { useQuery } from "@tanstack/react-query";
import { GetGPUNodesInfo, GetGPUNodesInfoResponse } from "../../../api/fetcher";
import useWorkloadsFilters from "../Tables/Workloads/hooks/useWorkloadsFilters";

export default function useGetGpuNodesInfo() {
  const workloadFilters = useWorkloadsFilters();
  const { queryKey, queryFn } = GetGPUNodesInfo();

  return useQuery<GetGPUNodesInfoResponse, Error>({
    queryKey: [queryKey, workloadFilters],
    queryFn: () =>
      queryFn({
        ...workloadFilters,
        stepSizeMinutes: 10,
        multiCluster: true,
      }),
  });
}
