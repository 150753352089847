import { CircularProgress } from "@mui/material";

interface Props {
  className?: string;
}
const GraphLoading = ({ className }: Props) => (
  <div className={className}>
    <CircularProgress size={20} />
  </div>
);

export default GraphLoading;
