import { useState } from "react";

export type ExternalLegend = {
  selectedChartComponents: string[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<string[]>>;
  legendComponentStyle: { [key: string]: { color: string } };
  setLegendComponentStyle: React.Dispatch<React.SetStateAction<{ [key: string]: { color: string } }>>;
  chartComponents: Record<string, string>;
  setChartComponents: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

interface Props {
  externalLegend?: ExternalLegend;
}

const useGetComposeChartState = ({ externalLegend }: Props) => {
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>([]);
  const [legendComponentStyle, setLegendComponentStyle] = useState<{ [key: string]: { color: string } }>({});
  const [chartComponents, setChartComponents] = useState<Record<string, string>>({});

  if (externalLegend) {
    return externalLegend;
  }

  return {
    selectedChartComponents,
    setSelectedChartComponents,
    legendComponentStyle,
    setLegendComponentStyle,
    chartComponents,
    setChartComponents,
  };
};

export default useGetComposeChartState;
