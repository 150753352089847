import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import FormSwitch from "../../../components/formComponents/FormSwitch";

// interface Props {
// }

const CpuInteger = () => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Integer CPU"
        description="Enforce CPU recommendations to be integer values by rounding up after calculating based on workload demand and trends."
      />
      <div className="flex items-center gap-4">
        <FormSwitch
          name="cpuInteger"
          // disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          // disableChange={!isCustomizedPolicy}
          label="Enable CPU Integer"
        />
      </div>
    </div>
  );
};
export default CpuInteger;
