import { IconProps } from "./utils/utils";

const DisableIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9941 4.47962 15.5204 0.00590124 10 0ZM4.44577 3.46281C5.99679 2.1465 7.96575 1.42538 10 1.4286C12.0259 1.42797 13.9859 2.14834 15.5293 3.46076L3.45787 15.5322C0.39781 11.9265 0.84011 6.52287 4.44577 3.46281ZM15.5543 16.5372C14.0033 17.8535 12.0343 18.5747 10 18.5714C7.97412 18.5721 6.01411 17.8517 4.47075 16.5393L16.5422 4.46782C19.6022 8.07352 19.1599 13.4772 15.5543 16.5372Z" />
    </svg>
  );
};

export default DisableIcon;
