import { Typography } from "@mui/material";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import SSOIcon from "../../../Icons/SSOIcon";
import { AuthMethod } from "./utils";

const ICON_WRAPPER_SIZE = 90;
const ICON_SIZE = 60;

interface Props {
  authType: AuthMethod | undefined;
}

const TopContainer = ({ authType }: Props) => {
  return (
    <div className="flex gap-10 relative h-fit">
      <div
        className="bg-guideline-darkPurple rounded-full flex items-center justify-center"
        style={{
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          width: ICON_WRAPPER_SIZE,
          height: ICON_WRAPPER_SIZE,
        }}
      >
        <SSOIcon width={ICON_SIZE} height={ICON_SIZE} fill="white" />
      </div>
      <div className="flex flex-col justify-center gap-1">
        <Typography variant="h6" fontWeight={700}>
          Authentication & Authorization
        </Typography>
        <Typography variant="body2" className="text-text-lightBlack flex items-center gap-2">
          <span>Current authentication method</span> <RightArrowIcon />{" "}
          <span>
            <b>{authType}</b>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default TopContainer;
