import FormInput from "../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";

const NewPolicyName = () => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription title="Policy name" description="Set your new policy name." />
      <FormInput name="newPolicyName" type="text" required className="w-[80px]" />
    </div>
  );
};

export default NewPolicyName;
