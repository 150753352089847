import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetRbac, PostRbac, PostRbacParam } from "../../../api/fetcher";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "./utils";

const { queryKey: getQueryKey } = GetRbac();
const { queryFn: postQueryFn } = PostRbac();

export const useCreateRole = () => {
  const queryClient = useQueryClient();

  return useMutation((params: PostRbacParam) => postQueryFn(params), {
    onError: (error: Error & { reason?: string }) => {
      console.log(`Error: ${error.message}`);

      const forbidden = error?.reason && error.reason === "Forbidden" ? " (Forbidden)" : "";
      toast.error(`Error creating role${forbidden}`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Role was created successfully!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getQueryKey]);
    },
  });
};

export default useCreateRole;
