import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetSingleAnalytics, GetSingleAnalyticsResponse } from "../../../api/fetcher";
import Loading from "../../../components/Loading";
import PercentagePieChart, { Title } from "../../../components/PercentagePieChart";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "./useFilterQueryParams";
import { DateType } from "./utils";

interface Props {
  date: DateType;
  isMulticluster?: boolean;
}

const AutomatedPieChartWrapper = ({ date, isMulticluster }: Props) => {
  const { queryFn, queryKey } = GetSingleAnalytics();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const filtersQueryParams = useFilterQueryParams();

  const {
    data: totalAutomatedWorkloadsData,
    error: totalAutomatedWorkloadsError,
    isError: totalAutomatedWorkloadsIsError,
    isLoading: totalAutomatedWorkloadsIsLoading,
  } = useQuery<GetSingleAnalyticsResponse, Error>({
    queryKey: [
      queryKey,
      date,
      "totalAutomatedWorkloads",
      isMulticluster ? "multicluster" : undefined,
      filtersQueryParams,
    ],
    queryFn: () =>
      queryFn({
        ...date,
        type: "totalAutomatedWorkloads",
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        timeoutSeconds: timeoutSeconds,
      }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const {
    data: totalNumberOfWorkloadsData,
    error: totalNumberOfWorkloadsError,
    isError: totalNumberOfWorkloadsIsError,
    isLoading: totalNumberOfWorkloadsIsLoading,
  } = useQuery<GetSingleAnalyticsResponse, Error>({
    queryKey: [
      queryKey,
      date.from,
      date.to,
      "totalNumberOfWorkloads",
      isMulticluster ? "multicluster" : undefined,
      filtersQueryParams,
    ],
    queryFn: () =>
      queryFn({
        ...date,
        type: "totalNumberOfWorkloads",
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        timeoutSeconds: timeoutSeconds,
      }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({
    data: totalNumberOfWorkloadsData,
    isError: totalNumberOfWorkloadsIsError,
    isDisabled: !isMulticluster,
  });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!totalAutomatedWorkloadsData && !!totalNumberOfWorkloadsData) {
      setIsQueryEnabled(false);
    }
  }, [totalAutomatedWorkloadsData, totalNumberOfWorkloadsData]);

  useEffect(() => {
    if (!isQueryEnabled) {
      setIsQueryEnabled(true);
    }
  }, [totalAutomatedWorkloadsData, totalNumberOfWorkloadsData, filtersQueryParams]);

  if (totalAutomatedWorkloadsIsError || totalNumberOfWorkloadsIsError) {
    console.log(totalAutomatedWorkloadsError, totalNumberOfWorkloadsError);
  }

  if (totalAutomatedWorkloadsIsLoading || totalNumberOfWorkloadsIsLoading) {
    return <Loading hasTitle={false} hasFullWrapper />;
  }

  const totalAutomatedWorkloads = totalAutomatedWorkloadsData?.value || 0;
  const totalUnAutomatedWorkloads = (totalNumberOfWorkloadsData?.value || 0) - totalAutomatedWorkloads || 0;

  return (
    <>
      <PercentagePieChart
        xValue={totalAutomatedWorkloads}
        yValue={totalUnAutomatedWorkloads}
        title={Title.AverageAutomated}
      />
    </>
  );
};

export default AutomatedPieChartWrapper;
