import { TriggerSlackAlertTest, TriggerSlackAlertTestParams } from "../../api/fetcher";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

export const useTriggerSlackAlert = () => {
  const { queryFn } = TriggerSlackAlertTest();
  return useMutation(
    (params: TriggerSlackAlertTestParams) => {
      return queryFn(params);
    },
    {
      onError: () => {
        toast.error("Failed to triggered a test alert");
      },
      onSuccess: () => {
        toast.success("Successfully triggered a test alert");
      },
    }
  );
};
