import { IconProps } from "./utils/utils";

const HeartIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M14.56 1.81347C17.5583 1.82807 19.9854 4.2551 20 7.25349C20 12.7468 10 18.1868 10 18.1868C10 18.1868 0 12.6668 0 7.25349C0 4.24908 2.43555 1.81347 5.44002 1.81347C7.27915 1.79913 8.99854 2.72416 10 4.26679C11.0084 2.73166 12.7233 1.80903 14.56 1.81347Z" />
    </svg>
  );
};

export default HeartIcon;
