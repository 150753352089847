import { IconProps } from "./utils/utils";

const DuplicateIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.4177 19.7215C19.3861 19.7215 19.3544 19.7165 19.324 19.7063C13.224 17.7241 6.77594 17.7241 0.675938 19.7063C0.567078 19.7418 0.44809 19.7127 0.367078 19.6317C0.286065 19.5506 0.258217 19.4317 0.292394 19.3228C2.27467 13.2228 2.27467 6.77469 0.292394 0.674687C0.256951 0.565826 0.286065 0.446839 0.367078 0.365826C0.44809 0.284814 0.567078 0.256966 0.675938 0.291143C6.77594 2.27342 13.224 2.27342 19.324 0.291143C19.4329 0.2557 19.5519 0.284814 19.6329 0.365826C19.7139 0.446839 19.7418 0.565826 19.7076 0.674687C17.7253 6.77469 17.7253 13.2228 19.7076 19.3228C19.743 19.4317 19.7139 19.5506 19.6329 19.6317C19.5747 19.6899 19.4975 19.7215 19.4177 19.7215ZM1.04682 1.04684C1.93037 3.9519 2.3772 6.96076 2.3772 10C2.3772 13.0392 1.9291 16.0481 1.04682 18.9532C3.95189 18.0696 6.96075 17.6228 9.99999 17.6228C13.0392 17.6228 16.0481 18.0709 18.9532 18.9532C18.0696 16.0481 17.6228 13.0392 17.6228 10C17.6228 6.96076 18.0709 3.9519 18.9532 1.04684C16.0481 1.93038 13.0392 2.37722 9.99999 2.37722C6.96075 2.37722 3.95189 1.93038 1.04682 1.04684Z" />
        <path d="M12.8443 7.26202C12.7671 7.26202 12.6886 7.23291 12.6291 7.17341C12.5101 7.05443 12.5101 6.86202 12.6291 6.7443L15.1607 4.21265C15.2797 4.09367 15.4721 4.09367 15.5899 4.21265C15.7088 4.33164 15.7088 4.52405 15.5899 4.64177L13.0582 7.17341C13 7.23291 12.9215 7.26202 12.8443 7.26202Z" />
        <path d="M15.1797 6.45569C15.1683 6.45569 15.1569 6.45443 15.1455 6.45316C14.9784 6.43417 14.8595 6.28354 14.8784 6.11645L15.0316 4.77088L13.686 4.92405C13.5189 4.94303 13.3683 4.82278 13.3493 4.65696C13.3303 4.48987 13.4506 4.33924 13.6164 4.32025L15.3405 4.12405C15.4329 4.11392 15.524 4.14557 15.5898 4.21139C15.6557 4.27721 15.6873 4.36835 15.6772 4.46076L15.481 6.18607C15.4632 6.3405 15.3329 6.45569 15.1797 6.45569Z" />
        <path d="M7.15566 7.262C7.07844 7.262 6.99996 7.23289 6.94047 7.1734L4.40882 4.64175C4.28983 4.52276 4.28983 4.33036 4.40882 4.21264C4.52781 4.09492 4.72021 4.09365 4.83793 4.21264L7.36958 6.74428C7.48857 6.86327 7.48857 7.05568 7.36958 7.1734C7.31135 7.23289 7.23287 7.262 7.15566 7.262Z" />
        <path d="M4.82018 6.45568C4.66829 6.45568 4.53664 6.34175 4.51892 6.18606L4.32272 4.46201C4.31259 4.3696 4.34424 4.27846 4.41006 4.21264C4.47588 4.14682 4.56702 4.11517 4.65943 4.1253L6.38348 4.3215C6.55056 4.34049 6.66955 4.49112 6.65057 4.65821C6.63284 4.81264 6.5012 4.92783 6.3493 4.92783C6.33791 4.92783 6.32651 4.92656 6.31512 4.9253L4.96955 4.77213L5.12272 6.1177C5.1417 6.28479 5.02145 6.43543 4.85563 6.45441C4.84297 6.45441 4.83158 6.45568 4.82018 6.45568Z" />
        <path d="M15.3759 15.8772C15.2987 15.8772 15.2202 15.8481 15.1607 15.7886L12.6291 13.257C12.5101 13.138 12.5101 12.9456 12.6291 12.8279C12.7481 12.7089 12.9405 12.7089 13.0582 12.8279L15.5899 15.3595C15.7088 15.4785 15.7088 15.6709 15.5899 15.7886C15.5316 15.8468 15.4531 15.8772 15.3759 15.8772Z" />
        <path d="M15.3759 15.8772C15.3645 15.8772 15.3532 15.876 15.3418 15.8747L13.6177 15.6785C13.4506 15.6595 13.3316 15.5089 13.3506 15.3418C13.3696 15.1747 13.5202 15.0557 13.6873 15.0747L15.0329 15.2279L14.8797 13.8823C14.8608 13.7152 14.981 13.5646 15.1468 13.5456C15.3139 13.5266 15.4645 13.6468 15.4835 13.8127L15.6797 15.5367C15.6899 15.6291 15.6582 15.7203 15.5924 15.7861C15.5329 15.8443 15.4557 15.8772 15.3759 15.8772Z" />
        <path d="M4.62401 15.8772C4.5468 15.8772 4.46831 15.8481 4.40882 15.7886C4.28983 15.6696 4.28983 15.4772 4.40882 15.3595L6.94047 12.8279C7.05945 12.7089 7.25186 12.7089 7.36958 12.8279C7.4873 12.9468 7.48857 13.1393 7.36958 13.257L4.83793 15.7886C4.77971 15.8468 4.70123 15.8772 4.62401 15.8772Z" />
        <path d="M4.62421 15.8772C4.54446 15.8772 4.46725 15.8456 4.40902 15.7886C4.3432 15.7228 4.31155 15.6316 4.32168 15.5392L4.51788 13.8152C4.53687 13.6481 4.6875 13.5291 4.85459 13.5481C5.02168 13.5671 5.14067 13.7177 5.12168 13.8848L4.96978 15.2278L6.31535 15.0747C6.48244 15.0557 6.63307 15.1759 6.65206 15.3418C6.67105 15.5089 6.55079 15.6595 6.38497 15.6785L4.65839 15.8747C4.647 15.8759 4.6356 15.8772 4.62421 15.8772Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_171">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DuplicateIcon;
