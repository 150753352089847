import Menu from "@mui/material/Menu";
import { useEffect, useState } from "react";
import MenuCategory from "./MenuCategory";
import { MenuCategoryType } from "./utils";

const MENU_ID = "basic-menu";
const BUTTON_ID = "basic-button";

interface Props {
  categories: MenuCategoryType[];
  button?: React.ReactNode;
  minMenuHeight?: number;
  preMenuCategorySection?: React.ReactNode;
  onOpenAndClose?: (isOpen: boolean) => void;
}

const DropdownMenu = ({ categories, button, minMenuHeight, preMenuCategorySection, onOpenAndClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openedCategory, setOpenedCategory] = useState<string | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!open) {
      const firstCategory = categories[0]?.categoryName as string;
      const defaultCategory = categories.find((category) => category.isOpenByDefault)?.categoryName as string;
      setOpenedCategory(defaultCategory || firstCategory);
    }
  }, [categories, open]);

  useEffect(() => {
    onOpenAndClose?.(open);
  }, [open, onOpenAndClose]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        aria-controls={open ? MENU_ID : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {button}
      </div>
      <Menu
        id={MENU_ID}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": BUTTON_ID,
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.8)",
          },
          "& .MuiList-root": {
            padding: 0,
          },
        }}
      >
        <div
          className="py-4 px-6 min-w-[28.0625rem] scrollbar-thin scrollbar-thumb-primary-300 relative"
          style={{
            minHeight: minMenuHeight,
          }}
        >
          {preMenuCategorySection}
          {categories.map((category, index) => (
            <MenuCategory
              key={`category-${index}`}
              handleClose={handleClose}
              {...category}
              showBottomBorder={index !== categories.length - 1 && categories.length > 1}
              openedCategory={openedCategory}
              setOpenedCategory={setOpenedCategory}
            />
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
