import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, ToggleButton } from "@mui/material";
import { Workload } from "./WorkloadsTable";
import theme from "../../theme";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import PolicyDetails, { Header } from "./PolicyDetails";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { GraphRow } from "../HPAView";
import moment from "moment";
import { Markdown } from "./PoliciesTable";

interface WorkloadTuningProps {
  workload?: Workload;
  updateWorkload: (workload: Partial<Workload>) => Promise<Workload>;
  onClose: () => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function WorkloadTuning(props: WorkloadTuningProps) {
  const { workload } = props;

  const buttonWidth = "125px";

  return (
    <Dialog open={workload != undefined} onClose={props.onClose} maxWidth="xl" fullWidth={true}>
      <DialogTitle
        id="workload-details"
        style={{ backgroundColor: theme.palette.primary.main, color: "white", height: "55px" }}
      >
        Workload Details
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} rowSpacing={2} sx={{ marginTop: "2px" }}>
          <Grid item key={JSON.stringify(workload?.id) + "-main-overview-policy-details"} xs={12}>
            <Item key={JSON.stringify(workload?.id) + "PolicyDetails"}>
              Policy Details:
              <PolicyDetails policy={workload?.policy} />
            </Item>
          </Grid>
          <Grid item key={JSON.stringify(workload?.id) + "-main-overview-triggers"} xs={12}>
            <Item>
              Triggers:
              <Grid container spacing={1} sx={{ marginTop: "3px" }}>
                {workload?.triggers.map((trigger) => {
                  let changes = <></>;
                  let xs = 12;
                  if (workload?.enabled) {
                    xs = 3;
                    changes = (
                      <>
                        <Grid
                          item
                          key={JSON.stringify(workload?.id) + "-triggers-recommendation-" + JSON.stringify(trigger)}
                          xs={xs}
                        >
                          <Grid container direction="column" justifyContent="space-between" alignItems="center">
                            <Grid item>Recommendation:</Grid>
                            <Grid item>
                              <Markdown>
                                <code>{trigger.recommendation}</code>
                              </Markdown>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          key={JSON.stringify(workload?.id) + "-triggers-coast-change-" + JSON.stringify(trigger)}
                          xs={xs}
                        >
                          Cost Change:{" "}
                          <Header color={+trigger.coastChange.replace("$", "") < 0 ? "green" : ""}>
                            {trigger.coastChange}
                          </Header>
                        </Grid>
                        <Grid
                          item
                          key={JSON.stringify(workload?.id) + "-triggers-goal-metric-" + JSON.stringify(trigger)}
                          xs={xs}
                        >
                          Goal Metric Change:{" "}
                          <Header color={trigger.goalMetricChange != "No Change" ? "green" : ""}>
                            {trigger.goalMetricChange}
                          </Header>
                        </Grid>
                      </>
                    );
                  }
                  return (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item key={JSON.stringify(workload?.id) + "-triggers-internal"} xs={xs}>
                          <Grid container direction="column" justifyContent="space-between" alignItems="center">
                            <Grid item>Current:</Grid>
                            <Grid item>
                              <Markdown>
                                <code>{trigger.current}</code>
                              </Markdown>
                            </Grid>
                          </Grid>
                        </Grid>
                        {changes}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <HPAGraph
                key={JSON.stringify([workload?.id, workload?.enabled])}
                data={workload?.data || []}
                lines={
                  workload?.policy?.sla
                    .map((sla) => {
                      const base: {
                        name: string;
                        displayName?: string;
                        stroke?: string;
                      }[] = [{ name: "base", displayName: (sla.name || "") + " Base" }];
                      if (workload?.enabled) {
                        base.push({
                          name: "tuned",
                          displayName: (sla.name || "") + " Tuned",
                          stroke: "#15a821",
                        });
                      }
                      return base;
                    })
                    .flat() || []
                }
              />
            </Item>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ToggleButton
          value={true}
          selected={workload?.enabled}
          onChange={() => {
            if (workload != undefined) {
              const checked = !workload.enabled;
              workload.enabled = checked;
              props
                .updateWorkload({ id: workload.id, enabled: checked })
                .then((value) => {
                  console.log("successfully updated", value);
                })
                .catch((reason) => {
                  console.error(reason);
                });
            }
          }}
          size={"small"}
          sx={{
            marginTop: "1px",
            paddingTop: "6px",
            paddingBottom: "6px",
            minWidth: buttonWidth,
          }}
        >
          {workload?.enabled ? "Enabled" : "Disabled"}
        </ToggleButton>
        <Button
          disabled={!workload?.enabled}
          sx={{
            backgroundColor: "info.main",
            "&:hover": {
              backgroundColor: "info.main",
              color: "rgba(41,40,52,0.65)",
            },
            "&:disabled": {
              backgroundColor: "#5353E069",
              color: "rgba(41,40,52,0.34)",
            },
            minWidth: buttonWidth,
          }}
          variant="contained"
          type="button"
        >
          Apply
        </Button>
        <Button
          disabled={!workload?.enabled}
          sx={{
            backgroundColor: "#10AE6E",
            "&:hover": {
              backgroundColor: "#10AE6E",
              color: "rgba(41,40,52,0.34)",
            },
            "&:disabled": {
              backgroundColor: "rgba(16,174,110,0.32)",
              color: "rgba(41,40,52,0.34)",
            },
            minWidth: buttonWidth,
          }}
          variant="contained"
          type="button"
        >
          Automate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface HPAGraphProps {
  // We use any until we set everything up
  // eslint-disable-next-line
  data: GraphRow[];
  dataKey?: string;
  lines: {
    name: string;
    displayName?: string;
    stroke?: string;
  }[];
}
type MapType = {
  [id: string]: number;
};
function HPAGraph(props: HPAGraphProps) {
  const { data, lines } = props;
  const [opacity, setOpacity] = useState(
    lines.reduce((map, obj) => {
      map[obj.name] = 1;
      return map;
    }, {} as MapType)
  );
  // eslint-disable-next-line
  const handleMouseEnter = (o: any & { dataKey?: string }) => {
    updateOpacity(o, 0.1);
  };
  // eslint-disable-next-line
  const handleMouseLeave = (o: any & { dataKey?: string }) => {
    updateOpacity(o, 1);
  };

  // eslint-disable-next-line
  const updateOpacity = (o: any & { dataKey?: string }, value: number) => {
    if ("dataKey" in o) {
      const { dataKey } = o as { dataKey?: string };
      if (dataKey != undefined) {
        opacity[dataKey] = value;
        for (const key in opacity) {
          if (key == dataKey) {
            opacity[key] = 1;
          } else {
            opacity[key] = value;
          }
        }
        setOpacity({ ...opacity });
      }
    }
  };

  return (
    <Box height={"300px"}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <XAxis
            name="Time"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickCount={11}
            tickFormatter={(unixTime) => moment(unixTime as number).format("hh:mm")}
            dataKey={"time"}
          />
          <YAxis />
          <Tooltip labelFormatter={(label) => moment(label as number).format("hh:mm:ss")} />
          <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
          {lines.map((line) => {
            return (
              <Line
                key={line.name}
                type="monotone"
                name={line.displayName}
                dataKey={line.name}
                stroke={line.stroke || "#8884d8"}
                activeDot={{ r: 8 }}
                strokeOpacity={opacity[line.name]}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
