import { GetVersion, GetVersionResponse } from "../../../api/fetcher";
import { useQuery } from "@tanstack/react-query";

export default function useGetVersion() {
  const { queryKey, queryFn } = GetVersion();

  return useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });
}
