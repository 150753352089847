import { IconProps } from "./utils/utils";

const CreativeInfoIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M16.0937 5.3125C16.0937 5.52811 16.2688 5.70312 16.4844 5.70312C16.7 5.70312 16.875 5.52811 16.875 5.3125C16.875 5.09689 16.7 4.92188 16.4844 4.92188C16.2688 4.92188 16.0937 5.09689 16.0937 5.3125Z" />
        <path d="M17.6562 9.99999C17.6562 10.5003 17.606 10.9997 17.5071 11.4844C17.464 11.6957 17.6004 11.902 17.8117 11.9452C17.8381 11.9507 17.8644 11.9533 17.8903 11.9533C18.072 11.9533 18.2348 11.8256 18.2727 11.6406C18.3821 11.1046 18.4375 10.5527 18.4375 9.99999C18.4375 8.76998 18.1708 7.58636 17.6445 6.48193C17.5517 6.28723 17.3187 6.20453 17.1238 6.2973C16.929 6.39007 16.8464 6.62323 16.9392 6.81808C17.415 7.81661 17.6562 8.88717 17.6562 9.99999Z" />
        <path d="M11.9531 13.5156H11.5625V8.4375C11.5625 8.22174 11.3876 8.04688 11.1719 8.04688H8.04686C7.8311 8.04688 7.65623 8.22174 7.65623 8.4375V10C7.65623 10.2158 7.8311 10.3906 8.04686 10.3906H8.43748V13.5156H8.04686C7.8311 13.5156 7.65623 13.6905 7.65623 13.9062V15.4687C7.65623 15.6845 7.8311 15.8594 8.04686 15.8594H11.9531C12.1689 15.8594 12.3437 15.6845 12.3437 15.4687V13.9062C12.3437 13.6905 12.1689 13.5156 11.9531 13.5156ZM11.5625 15.0781H8.43748V14.2969H8.82811C9.04387 14.2969 9.21873 14.122 9.21873 13.9062V10C9.21873 9.78424 9.04387 9.60937 8.82811 9.60937H8.43748V8.82812H10.7812V13.9062C10.7812 14.122 10.9562 14.2969 11.1719 14.2969H11.5625V15.0781Z" />
        <path d="M9.99999 7.26562C10.8615 7.26562 11.5625 6.56463 11.5625 5.70312C11.5625 4.84161 10.8615 4.14062 9.99999 4.14062C9.13848 4.14062 8.43749 4.84161 8.43749 5.70312C8.43749 6.56463 9.13848 7.26562 9.99999 7.26562ZM9.99999 4.92187C10.4307 4.92187 10.7812 5.27237 10.7812 5.70312C10.7812 6.13388 10.4307 6.48437 9.99999 6.48437C9.56924 6.48437 9.21874 6.13388 9.21874 5.70312C9.21874 5.27237 9.56924 4.92187 9.99999 4.92187Z" />
        <path d="M9.99999 0C4.61029 0 1.77554e-06 4.60159 1.77554e-06 9.99999C1.77554e-06 11.844 0.528413 13.8051 1.41937 15.2884L0.0199908 19.4859C-0.0267011 19.6261 0.00976739 19.781 0.114443 19.8855C0.219575 19.9907 0.374605 20.0265 0.514222 19.9798L4.71176 18.5806C6.19491 19.4717 8.15597 20 9.99999 20C15.3911 20 20 15.3978 20 9.99999C20 4.60891 15.3979 0 9.99999 0ZM9.99999 19.2187C8.23104 19.2187 6.35085 18.6967 4.97039 17.8224C4.87258 17.7604 4.75067 17.7441 4.63775 17.7818L1.0083 18.9917L2.21817 15.3622C2.2554 15.2508 2.24045 15.1288 2.17758 15.0296C1.30325 13.6493 0.781251 11.7689 0.781251 9.99999C0.781251 5.00289 5.00289 0.781249 9.99999 0.781249C14.9971 0.781249 19.2187 5.00289 19.2187 9.99999C19.2187 14.9971 14.9971 19.2187 9.99999 19.2187Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreativeInfoIcon;
