import { ApiResponse } from "openapi-typescript-fetch";
import { useEffect, useState } from "react";
import { ScaleOps } from "../api/api";
import { GetBetaFeaturesResponse, GetUIFeaturesResponse } from "../api/fetcher";
import { HAS_MULTI_PRODUCT_LAYOUT } from "./developmentFeatureFlags";
import useStateWithSessionStorage from "./useStateWithSessionStorage";
import { IsSnapshotServer } from "./FeaturesHelper";

const DISABLE_QUERY_PARAMS_FOR_DEVELOPMENT_PURPOSES = false;

const useHasMultiProductLayout = (): boolean => {
  const api = ScaleOps();

  const [betaFeaturesData, setBetaFeaturesData] = useState<GetBetaFeaturesResponse | undefined>(undefined);
  const [uiFeaturesData, setUIFeaturesData] = useState<GetUIFeaturesResponse | undefined>(undefined);

  const [hasMultiProductLayout] = useStateWithSessionStorage<boolean>({
    sessionStorageKey: "hasMultiProductLayout",
    defaultValue: false,
    valueFormatter: (value) => value === "true",
  });

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/settings/beta-features")
      .method("get")
      .create()({})
      .then((response: ApiResponse<GetBetaFeaturesResponse>) => {
        setBetaFeaturesData(response.data);
      });

    api
      .getFetcher()
      .path("/api/v1/settings/ui-features")
      .method("get")
      .create()({})
      .then((response: ApiResponse<GetUIFeaturesResponse>) => {
        setUIFeaturesData(response.data);
      });
  }, []);

  return !!(
    IsSnapshotServer() ||
    HAS_MULTI_PRODUCT_LAYOUT ||
    hasMultiProductLayout ||
    (!DISABLE_QUERY_PARAMS_FOR_DEVELOPMENT_PURPOSES &&
      betaFeaturesData?.enabled &&
      betaFeaturesData?.betaFeatures?.hpaOptimization &&
      uiFeaturesData?.uiFeatures?.hpaOptimization)
  );
};

export default useHasMultiProductLayout;
