import { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SCALEOPS_COLORS } from "../../../colors";
import { customNumberFormatter } from "../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../utils/styleUtils";
import EntireWindowTooltip from "./EntireWindowTooltip";
import { EntireWindowData } from "./utils";

const MAX_X_AXIS_CHARACTERS = 23;

type XAxisTooltip = { value: string | number; coordinate: number; index: number } | null;

const AxisTooltip = ({ tooltip }: { tooltip: XAxisTooltip }) => {
  if (tooltip?.index === undefined) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: tooltip.coordinate - 50,
        bottom: 50,
        width: 100,
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
        whiteSpace: "nowrap",
        zIndex: 999,
      }}
    >
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>{tooltip.value}</div>
    </div>
  );
};
interface Props {
  data: EntireWindowData[];
}

const EntireWindowChart = ({ data }: Props) => {
  const [numberOfUniqueNames, setNumberOfUniqueNames] = useState<number>(0);
  const hasNoData = !data || data.length === 0;

  const [tooltip, setTooltip] = useState<XAxisTooltip>(null);
  const numberOfXTickLines = data.length || undefined;

  useEffect(() => {
    setNumberOfUniqueNames(new Set(data.map((d) => d.name)).size);
  }, [data]);

  return (
    <div className="w-full h-full">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip content={<EntireWindowTooltip />} cursor={{ fill: SCALEOPS_COLORS.background.chip }} />
          <XAxis
            dataKey="name"
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            tickFormatter={(value: string) => {
              if (hasNoData) return "";
              const displayValue = String(value);
              let maxCharacters = MAX_X_AXIS_CHARACTERS - numberOfUniqueNames;
              maxCharacters = maxCharacters < 1 || !maxCharacters ? MAX_X_AXIS_CHARACTERS : maxCharacters;
              return displayValue.length > maxCharacters
                ? `...${displayValue.toLowerCase().slice(-maxCharacters)}`
                : value.toLocaleLowerCase();
            }}
            tickLine={hasNoData ? false : undefined}
            tickCount={numberOfXTickLines}
            onMouseEnter={(params) => {
              setTooltip(params as unknown as XAxisTooltip);
            }}
            onMouseLeave={() => setTooltip(null)}
          />
          <YAxis
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            tickFormatter={(tick: number) => {
              return hasNoData || tick === undefined ? "" : `$${String(customNumberFormatter(tick))}`;
            }}
            tickLine={hasNoData ? false : undefined}
          />
          <Bar dataKey="cost" fill={SCALEOPS_COLORS.primary.lightPurpleBlue} radius={[5, 5, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
      <AxisTooltip tooltip={tooltip} />
    </div>
  );
};

export default EntireWindowChart;
