import FormSingleSelect, { RenderValueType } from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { defaultHPAPolicyValues } from "../../utils";

const options: [string, string][] = [
  ["10m", "10m"],
  ["20m", "20m"],
  ["30m", "30m"],
  ["1h", "1h"],
];
const minRreplicaPrectailOptions: [string, number][] = [
  ["60%", 60],
  ["70%", 70],
  ["80%", 80],
  ["90%", 90],
  ["100%", 100],
];

interface Props {
  isCustomizedPolicy?: boolean;
}

const PredictableWorkloadOptimization = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <div className="flex flex-col gap-4">
        <FormTitleAndDescription
          title="Optimize predictable workloads"
          description="Automate to optimize the minimum replicas and scale early for predictable workloads."
        />
      </div>
      <div className="flex flex-wrap gap-7">
        <FormSingleSelect
          label="Min replica percentile"
          name="percentilePercentage"
          options={minRreplicaPrectailOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultHPAPolicyValues.predictableWorkloadsPercentile}
          renderValueType={RenderValueType.Percentage}
        />
        <FormSingleSelect
          label="Look ahead duration"
          className="w-[100px]"
          name="lookAheadDuration"
          options={options}
          defaultValue={defaultHPAPolicyValues.lookAheadDuration}
          areOptionsDisabled={!isCustomizedPolicy}
        />
      </div>
    </div>
  );
};

export default PredictableWorkloadOptimization;
