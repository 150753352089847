import { IconProps } from "./utils/utils";

const HistoryIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M9.90937 10.8156L13.6594 13.6281C13.9356 13.8353 14.3272 13.7794 14.5344 13.5031C14.7416 13.2269 14.6856 12.8353 14.4094 12.6281L10.9375 10V4.6875C10.9375 4.34219 10.6578 4.0625 10.3125 4.0625C9.96719 4.0625 9.6875 4.34219 9.6875 4.6875V10.3125C9.6875 10.52 9.77437 10.7069 9.90937 10.8156Z" />
        <path d="M10.625 0.625C6.05032 0.625 2.14376 3.92687 1.38126 8.4375L1.14376 8.08438C0.950319 7.79781 0.561569 7.72219 0.275006 7.91562C-0.0115563 8.10906 -0.0871813 8.49781 0.106256 8.78438L1.35626 10.6594C1.45907 10.8156 1.62657 10.9178 1.81251 10.9375H1.87501C2.04063 10.9369 2.19907 10.8706 2.31563 10.7531L3.87813 9.19062C4.12219 8.94656 4.12219 8.55031 3.87813 8.30625C3.63407 8.06219 3.23782 8.06219 2.99376 8.30625L2.60626 8.69687C3.32594 4.2675 7.50001 1.26062 11.9291 1.98031C16.3581 2.7 19.3656 6.87375 18.6459 11.3031C18.0069 15.2362 14.6097 18.1256 10.625 18.125C8.11188 18.1706 5.73501 16.985 4.25938 14.95C4.05907 14.6688 3.66876 14.6028 3.38751 14.8031C3.10626 15.0034 3.04032 15.3937 3.24063 15.675C4.94938 18.0412 7.70688 19.4228 10.625 19.375C15.8028 19.375 20 15.1778 20 10C20 4.82219 15.8028 0.625 10.625 0.625Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HistoryIcon;
