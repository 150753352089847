import React, { useContext, createContext, useState, useMemo } from "react";
import { RecommendedCostDataSum } from "./components/autoscalers/nodesCostUtils";

export const initialValues = {
  wasUpdateFromData: false,
  skipNodesWithLocalStorage: true,
  skipNodesWithSystemPods: true,
  scaleDownUtilizationThreshold: undefined,
  podNamespace: undefined,
  podName: undefined,
  ownerName: undefined,
  caFound: false,
  cloudProvider: undefined,
  podStatus: undefined,
  expander: undefined,
  scaleopsNodesAnnotations: undefined,
  scaleDownUnneededTime: undefined,
  nodeCost: {
    recommendedCostDataSum: undefined,
    currentCostDataSum: undefined,
  },
  didClusterChanged: false,
};

export type NodesActionStorage = {
  wasUpdateFromData: boolean;
  skipNodesWithLocalStorage: boolean;
  skipNodesWithSystemPods: boolean;
  scaleDownUtilizationThreshold: number | undefined;
  podNamespace: string | undefined;
  podName: string | undefined;
  ownerName: string | undefined;
  caFound: boolean;
  cloudProvider: string | undefined;
  podStatus: string | undefined;
  expander: string | undefined;
  scaleopsNodesAnnotations?:
    | {
        [key: string]:
          | {
              exist?: boolean | undefined;
              value?: string | undefined;
            }
          | undefined;
      }
    | undefined;
  scaleDownUnneededTime: string | undefined;
  didClusterChanged: boolean;
};

type ContextType = {
  currentCluster: string | undefined;
  setCurrentCluster: (currentCluster: string | undefined) => void;
  nodesActionStorage: NodesActionStorage;
  setNodesActionStorage: (NodesActionStorage: NodesActionStorage) => void;
  shouldUpdateLocalContext: boolean;
  setShouldUpdateLocalContext: (shouldUpdateLocalContext: boolean) => void;
  mainComponentScrollTop: number | undefined;
  setMainComponentScrollTop: (mainComponentScrollTop: number | undefined) => void;
  nodeCost: {
    recommendedCostDataSum: RecommendedCostDataSum | undefined;
    currentCostDataSum: RecommendedCostDataSum | undefined;
  };
  setNodeCost: (nodeCost: {
    recommendedCostDataSum: RecommendedCostDataSum | undefined;
    currentCostDataSum: RecommendedCostDataSum | undefined;
  }) => void;
  didClusterChanged: boolean;
  setDidClusterChanged: (didClusterChanged: boolean) => void;
};

const MainContext = createContext<ContextType>({
  nodesActionStorage: initialValues,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNodesActionStorage: () => {},
  shouldUpdateLocalContext: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShouldUpdateLocalContext: () => {},
  mainComponentScrollTop: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMainComponentScrollTop: () => {},
  nodeCost: {
    recommendedCostDataSum: undefined,
    currentCostDataSum: undefined,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNodeCost: () => {},
  currentCluster: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentCluster: () => {},
  didClusterChanged: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDidClusterChanged: () => {},
});

interface Props {
  children: React.ReactNode;
}

const MainContextProvider = ({ children }: Props) => {
  const [currentCluster, setCurrentCluster] = useState<string | undefined>(undefined);
  const [didClusterChanged, setDidClusterChanged] = useState<boolean>(false);
  const [mainComponentScrollTop, setMainComponentScrollTop] = useState<number | undefined>(undefined);
  const [nodesActionStorage, setNodesActionStorage] = useState<NodesActionStorage>(initialValues);
  const [shouldUpdateLocalContext, setShouldUpdateLocalContext] = useState<boolean>(true);
  const [nodeCost, setNodeCost] = useState<{
    recommendedCostDataSum: RecommendedCostDataSum | undefined;
    currentCostDataSum: RecommendedCostDataSum | undefined;
  }>({
    recommendedCostDataSum: undefined,
    currentCostDataSum: undefined,
  });

  const providerValues = useMemo(
    () => ({
      currentCluster,
      setCurrentCluster,
      nodesActionStorage,
      setNodesActionStorage,
      shouldUpdateLocalContext,
      setShouldUpdateLocalContext,
      mainComponentScrollTop,
      setMainComponentScrollTop,
      nodeCost,
      setNodeCost,
      didClusterChanged,
      setDidClusterChanged,
    }),
    [nodesActionStorage, shouldUpdateLocalContext, mainComponentScrollTop, nodeCost, currentCluster, didClusterChanged]
  );

  return <MainContext.Provider value={providerValues}>{children}</MainContext.Provider>;
};

const useMainContext = () => useContext(MainContext);

export { MainContextProvider, useMainContext };
