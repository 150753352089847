import { useEffect, useState } from "react";
import { SCALEOPS_COLORS } from "../colors";

const SHARED_CLASS_NAME = "rounded-full animate-bounce";

interface Props {
  dotSize?: number;
  color?: string;
}

const ThreeDotsAnimation = ({ dotSize = 6, color }: Props) => {
  const [dotDimensions, setDotDimensions] = useState<{
    width?: number;
    height?: number;
  }>({});

  useEffect(() => {
    setDotDimensions({
      width: dotSize,
      height: dotSize,
    });
  }, [dotSize, color]);

  return (
    <div className="flex gap-1">
      <div
        className={SHARED_CLASS_NAME}
        style={{
          animationDelay: "-0.3s",
          backgroundColor: color ?? SCALEOPS_COLORS.primary.pink,
          ...dotDimensions,
        }}
      />
      <div
        className={SHARED_CLASS_NAME}
        style={{
          ...dotDimensions,
          animationDelay: "-0.15s",
          backgroundColor: color ?? SCALEOPS_COLORS.primary.purpleBlue,
        }}
      />
      <div
        className={SHARED_CLASS_NAME}
        style={{
          ...dotDimensions,
          backgroundColor: color ?? SCALEOPS_COLORS.primary.lightPurpleBlue,
        }}
      />
    </div>
  );
};

export default ThreeDotsAnimation;
