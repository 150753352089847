import { components } from "../../api/schema";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Link,
  Snackbar,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Dispatch, HTMLAttributes, SetStateAction, SyntheticEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Save, Sync } from "@mui/icons-material";
import { updateGitSync, recommendationName } from "../../utils/recommendationUtils";
import { AlertColor } from "@mui/material/Alert/Alert";
import { ApiResponse } from "openapi-typescript-fetch/dist/cjs/types";
import { LoadingButton } from "@mui/lab";
import theme from "../../theme";
import { Octokit } from "octokit";
import { components as githubComponents } from "@octokit/openapi-types";
import useGetApi from "../../api/useGetApi";

export default function GitConfigPopup(
  props: {
    workload: components["schemas"]["UtilsWorkload"] | undefined;
    setWorkload: Dispatch<SetStateAction<components["schemas"]["UtilsWorkload"] | undefined>>;
  } & HTMLAttributes<HTMLDivElement>
) {
  const {
    workload,
    setWorkload,
  }: {
    workload: components["schemas"]["UtilsWorkload"] | undefined;
    setWorkload: React.Dispatch<React.SetStateAction<components["schemas"]["UtilsWorkload"] | undefined>>;
  } = props;

  const api = useGetApi();
  const [githubApi, setGithubApi] = useState<Octokit | undefined>(undefined);

  const [githubSelectedRepo, setGithubSelectedRepo] = useState<string | undefined>(undefined);
  const [githubDefaultBranch, setGithubDefaultBranch] = useState<string | undefined>(undefined);
  const [githubValuesFile, setGithubValuesFile] = useState<string | undefined>(undefined);
  const [githubAutoSync, setGithubAutoSync] = useState<boolean | undefined>(undefined);
  const [githubConfig, setGithubConfig] = useState<components["schemas"]["GitConfig"] | undefined>(undefined);

  const [githubRepos, setGithubRepos] = useState<githubComponents["schemas"]["repository"][]>([]);
  const [githubValuesFiles, setGithubValuesFiles] = useState<string[]>([]);

  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<JSX.Element>();
  const [toastSeverity, setToastSeverity] = useState<AlertColor>("success");
  const [saving, setSaving] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [gitSyncSettings, setGitSyncSettings] = useState<components["schemas"]["V1alpha1GitSync"] | undefined>(
    workload?.gitSync
  );

  const [recommendation, setRecommendation] = useState<components["schemas"]["V1alpha1Recommendation"] | undefined>(
    undefined
  );

  const populateRepos = async () => {
    const repoIterator = githubApi?.paginate.iterator(githubApi?.rest.repos.listForAuthenticatedUser, {
      per_page: 100,
    });

    if (repoIterator) {
      const gitHubRepos: githubComponents["schemas"]["repository"][] = [];
      for await (const { data: repos } of repoIterator) {
        gitHubRepos.push(...repos);
        setGithubRepos(gitHubRepos);
      }
    }
  };

  useEffect(() => {
    if (workload !== undefined) {
      api
        .getFetcher()
        .path("/api/v1/recommendation/{namespace}/{name}")
        .method("get")
        .create()({
          namespace: workload.namespace,
          name: `${workload.type.toLocaleLowerCase()}-${workload.workloadName}`,
        })
        .then((response: ApiResponse<components["schemas"]["RecommendationsGetResponse"]>) => {
          setRecommendation(response.data.recommendation);
        });
    }
  }, [api, workload]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/git/config")
      .method("get")
      .create()({})
      .then((response: ApiResponse<components["schemas"]["GitGetGitConfResponse"]>) => {
        if (response.ok) {
          setGithubConfig(response.data.config);
          if (response.data.config?.token !== undefined && response.data.config?.token !== "") {
            setGithubApi(new Octokit({ auth: response.data.config?.token }));
          }
          return;
        }
        throw new Response("Could Not Load Git Config From API", { status: 412 });
      })
      .catch((reason) => {
        console.error(reason);
        throw new Response("Could Not Load Git Config From API", { status: 412 });
      });
  }, [api]);

  useEffect(() => {
    if (githubApi != undefined) {
      populateRepos()
        .then(() => {
          console.log("successfully populated repos");
        })
        .catch((err) => {
          console.error(err, "failed to populate repos");
        });
    }
  }, [githubApi]);

  useEffect(() => {
    searchYAMLs()
      .then(() => console.log("successfully found repos"))
      .catch((reason) => console.error(reason, "failed to search for YAMLs"));
  }, [githubSelectedRepo]);

  useEffect(() => {
    setGithubSelectedRepo(workload?.gitSync?.repo);
    setGithubDefaultBranch(workload?.gitSync?.sourceBranch);
    setGithubValuesFile(workload?.gitSync?.valuesFilePath);
    setGithubAutoSync(workload?.gitSync?.autoSync);
  }, [workload]);

  const handleClose = () => {
    setOpenToast(false);
    setWorkload(undefined);
  };

  const showToast = (success: boolean, message: JSX.Element | string) => {
    setToastSeverity(success ? "success" : "error");
    setToastMessage(<>{message}</>);
    setOpenToast(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaving(true);
    const gitSync: components["schemas"]["V1alpha1GitSync"] = {
      autoSync: githubAutoSync,
      repo: githubSelectedRepo,
      sourceBranch: githubDefaultBranch,
      valuesFilePath: githubValuesFile,
    };
    updateGitSync(recommendation, gitSync);
    api
      .getFetcher()
      .path("/api/v1/recommendation/{name}")
      .method("put")
      .create()({
        name: recommendationName(recommendation),
        recommendation: recommendation,
      })
      .then((result: ApiResponse<components["schemas"]["RecommendationsUpdateResponse"]>) => {
        if (result.ok) {
          showToast(true, "successfully updated GIT sync");
        } else {
          showToast(false, result.statusText);
        }
      })
      .catch((reason) => {
        console.log(reason);
        showToast(false, "failed to update git sync");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateFieldsInGitSyncSettings = (fieldsToUpdate: Partial<components["schemas"]["V1alpha1GitSync"]>) => {
    if (gitSyncSettings != undefined) {
      setGitSyncSettings({
        ...gitSyncSettings,
        ...fieldsToUpdate,
      });
    } else {
      setGitSyncSettings(fieldsToUpdate);
    }
  };

  const syncNow = () => {
    setSyncing(true);
    api
      .getFetcher()
      .path("/git/sync")
      .method("post")
      .create()({
        targetRef: {
          name: workload?.workloadName,
          namespace: workload?.namespace,
          kind: workload?.type.toLowerCase(),
        },
      })
      .then((response) => {
        if (response.ok) {
          showToast(
            true,
            <>
              successfully updated GIT sync, <Link href={response.data.PrLink}>PR</Link>
            </>
          );
        } else {
          showToast(false, "failed to update GIT");
        }
      })
      .catch((e) => {
        console.error(e, "failed to update git");
        showToast(false, "failed to update GIT");
      })
      .finally(() => {
        setSyncing(false);
      });
  };

  const selectedRepo = (event: SyntheticEvent, value: string | null | undefined) => {
    setGithubSelectedRepo(value || undefined);
    const defaultBranch = githubRepos.find((repo: githubComponents["schemas"]["repository"]) => {
      return repo.full_name == value;
    })?.default_branch;
    setGithubDefaultBranch(defaultBranch);
    updateFieldsInGitSyncSettings({
      repo: value || undefined,
      sourceBranch: defaultBranch,
    });
  };
  const constructWorkloadNames = (workloadName: string): string[] => {
    const result = [workloadName];
    if (githubConfig?.nameReplaceCharSource != undefined && githubConfig.nameReplaceCharTarget != undefined) {
      result.push(workloadName.replace(githubConfig.nameReplaceCharSource, githubConfig.nameReplaceCharTarget));
    }
    return result;
  };

  const searchGithub = async (name: string) => {
    const valueFilesPaths: string[] = [];
    if (githubApi != undefined && githubSelectedRepo != undefined) {
      const repoIterator = githubApi.paginate.iterator(githubApi.rest.search.code, {
        q: name + " resources language:YAML repo:" + githubSelectedRepo,
        per_page: 100,
      });

      for await (const { data: items } of repoIterator) {
        const searchResults: githubComponents["schemas"]["code-search-result-item"][] = items;
        const paths = searchResults.map((i) => i.path);
        valueFilesPaths.push(...paths);
      }
    }
    return valueFilesPaths;
  };

  const searchYAMLs = async () => {
    if (githubSelectedRepo != undefined && workload?.workloadName != undefined && githubApi != undefined) {
      let valueFilesPaths: string[] = [];
      for (const name of constructWorkloadNames(workload.workloadName)) {
        const result = await searchGithub(name);
        valueFilesPaths.push(...result);
      }
      valueFilesPaths = valueFilesPaths.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setGithubValuesFiles(valueFilesPaths);
      if (valueFilesPaths.length > 0) {
        setGithubValuesFile(valueFilesPaths[0]);
      }
    } else {
      return Promise.resolve();
    }
  };

  const setSelectedValuesFile = (event: SyntheticEvent, value: string | null | undefined) => {
    updateFieldsInGitSyncSettings({
      sourceBranch: value || undefined,
    });
  };
  return (
    <Dialog
      id={props.id}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
      open={workload != undefined}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <DialogTitle
          id="scm-sync"
          style={{ backgroundColor: theme.palette.primary.main, color: "white", height: "55px" }}
        >
          Git Sync
        </DialogTitle>
        <DialogContent sx={{ mt: 1, minWidth: "500px" }}>
          <Autocomplete
            options={githubRepos.map((repo: githubComponents["schemas"]["repository"]) => repo.full_name)}
            disablePortal
            fullWidth
            key={githubSelectedRepo || "repo"}
            id="repo"
            placeholder="ORG/REPO"
            defaultValue={githubSelectedRepo}
            value={githubSelectedRepo}
            onChange={selectedRepo}
            renderInput={(params) => (
              <TextField required margin="dense" label="Repository" name="repo" variant="standard" {...params} />
            )}
          />
          <TextField
            required
            fullWidth
            name="sourceBranch"
            id="sourceBranch"
            margin="dense"
            placeholder="main"
            key={githubDefaultBranch || "sourceBranch"}
            label="Source Branch"
            defaultValue={githubDefaultBranch}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              updateFieldsInGitSyncSettings({
                sourceBranch: e.target.value,
              });
            }}
            variant="standard"
          />
          <Autocomplete
            options={githubValuesFiles}
            disablePortal
            fullWidth
            freeSolo
            key={githubValuesFile}
            id="valuesFilePath"
            defaultValue={githubValuesFile}
            onChange={setSelectedValuesFile}
            renderInput={(params) => (
              <TextField
                required
                margin="dense"
                label="Values File Path"
                name="valuesFilePath"
                variant="standard"
                {...params}
              />
            )}
          />
          <FormControlLabel
            label="AutoSync"
            control={
              <Checkbox
                key={githubAutoSync ? "autoSync-true" : "autoSync-false"}
                value="autoSync"
                name="autoSync"
                id="autoSync"
                defaultChecked={githubAutoSync}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setGithubAutoSync(checked);
                  updateFieldsInGitSyncSettings({
                    autoSync: checked,
                  });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
          >
            Close
          </Button>
          <LoadingButton
            startIcon={<Save />}
            loadingPosition="start"
            type="submit"
            variant="contained"
            loading={saving}
          >
            Save
          </LoadingButton>
          <LoadingButton
            type="button"
            variant="outlined"
            loading={syncing}
            startIcon={<Sync />}
            loadingPosition="start"
            onClick={() => {
              syncNow();
            }}
          >
            Sync Now
          </LoadingButton>
        </DialogActions>
      </Box>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openToast} autoHideDuration={3000}>
          <Alert severity={toastSeverity} sx={{ width: "100%" }}>
            {toastMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </Dialog>
  );
}
