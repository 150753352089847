import { IconProps } from "./utils/utils";

const EyeIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M19.6564 9.56555C19.616 9.51441 18.647 8.29877 16.9856 7.07822C14.7656 5.44716 12.35 4.58502 9.99993 4.58502C7.65 4.58502 5.23438 5.44716 3.01426 7.07822C1.35295 8.29872 0.383892 9.51441 0.34348 9.56555L0 9.99997L0.343524 10.4344C0.383936 10.4856 1.35299 11.7013 3.01431 12.9218C5.23443 14.5528 7.65004 15.415 9.99998 15.415C12.35 15.415 14.7656 14.5528 16.9856 12.9218C18.647 11.7013 19.616 10.4856 19.6564 10.4344L20 9.99997L19.6564 9.56555ZM9.99993 13.7934C7.90823 13.7934 6.20646 12.0917 6.20646 9.99997C6.20646 9.67225 6.24823 9.35412 6.32673 9.05057L5.46066 8.90482C5.3758 9.25618 5.33079 9.62291 5.33079 9.99997C5.33079 11.3511 5.90795 12.5699 6.82836 13.4233C5.64354 12.9856 4.63604 12.3714 3.87566 11.816C2.93361 11.128 2.22761 10.4321 1.82511 9.99997C2.22778 9.56774 2.9337 8.87193 3.87566 8.18392C4.63604 7.62849 5.64358 7.01434 6.82836 6.57664L7.40936 7.23155C8.08792 6.59617 8.99922 6.20649 9.99993 6.20649C12.0916 6.20649 13.7934 7.90822 13.7934 9.99997C13.7934 12.0917 12.0916 13.7934 9.99993 13.7934ZM16.1242 11.816C15.3637 12.3714 14.3563 12.9856 13.1715 13.4233C14.0919 12.5699 14.669 11.3512 14.669 9.99992C14.669 8.64868 14.0919 7.42997 13.1715 6.57659C14.3563 7.0143 15.3637 7.62844 16.1242 8.18388C17.0663 8.87193 17.7723 9.56783 18.1748 9.99988C17.7721 10.4322 17.0662 11.128 16.1242 11.816Z" />
      <path d="M7.58593 9.26257C7.51474 9.49589 7.47617 9.7434 7.47617 10C7.47617 11.3938 8.60608 12.5238 9.99992 12.5238C11.3937 12.5238 12.5236 11.3939 12.5236 10C12.5236 8.60617 11.3937 7.47626 9.99992 7.47626C9.32102 7.47626 8.70521 7.74482 8.25157 8.18091L9.49642 9.58407L7.58593 9.26257Z" />
    </svg>
  );
};

export default EyeIcon;
