import { components } from "../../../api/schema";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import YouHaveReadOnlyAccess from "../../YouHaveReadOnlyAccess";

interface Props {
  params: components["schemas"]["UtilsWorkload"];
}

const AutomationButtonDisabledTooltip = ({ params }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  return (
    <>
      {isReadyOnlyFrontEnd && <YouHaveReadOnlyAccess />}
      {!params?.isEditable && (
        <p>
          You don't have <b>permissions</b> to save this workload.
        </p>
      )}
      {params?.isAutomationExcluded && (
        <p>
          This workload is <b>excluded</b> from <b>namespace automation</b>.
        </p>
      )}
      {params?.namespace === "scaleops-system" && (
        <p>
          <b>scaleops-system</b> workloads are excluded from automation.
        </p>
      )}
      {params?.isAutoForced && (
        <p>
          <b>Forced automation</b> is applied to this workload and <b>prevents any changes</b>.{" "}
          <span className="text-text-darkGray italic">(scaleops.sh/force-auto)</span>
        </p>
      )}
    </>
  );
};

export default AutomationButtonDisabledTooltip;
