import { IconProps } from "./utils/utils";

const CalendarIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M17.4219 1.5625H15.625V0.625C15.625 0.279844 15.3452 0 15 0C14.6548 0 14.375 0.279844 14.375 0.625V1.5625H5.625V0.625C5.625 0.279844 5.3452 0 5 0C4.6548 0 4.375 0.279844 4.375 0.625V1.5625H2.57812C1.15652 1.5625 0 2.71902 0 4.14062V17.4219C0 18.8435 1.15652 20 2.57812 20H17.4219C18.8435 20 20 18.8435 20 17.4219V4.14062C20 2.71902 18.8435 1.5625 17.4219 1.5625ZM2.57812 2.8125H4.375V3.4375C4.375 3.78266 4.6548 4.0625 5 4.0625C5.3452 4.0625 5.625 3.78266 5.625 3.4375V2.8125H14.375V3.4375C14.375 3.78266 14.6548 4.0625 15 4.0625C15.3452 4.0625 15.625 3.78266 15.625 3.4375V2.8125H17.4219C18.1542 2.8125 18.75 3.40828 18.75 4.14062V5.625H1.25V4.14062C1.25 3.40828 1.84578 2.8125 2.57812 2.8125ZM17.4219 18.75H2.57812C1.84578 18.75 1.25 18.1542 1.25 17.4219V6.875H18.75V17.4219C18.75 18.1542 18.1542 18.75 17.4219 18.75Z" />
      </g>
      <defs>
        <clipPath id="clip0_306_2">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
