import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { components } from "../../../api/schema";
import { savePolicy, getPolicies } from "../../../api/fetcher";

type mutationProps = {
  policy: components["schemas"]["V1alpha1Policy"];
};

const useSavePolicy = () => {
  const policyTuning = getPolicies();
  const savePol = savePolicy();
  const queryClient = useQueryClient();

  return useMutation((params: mutationProps) => savePol.queryFn(params), {
    onError: (error: Error & { reason?: string }) => {
      const forbidden = error?.reason && error.reason === "Forbidden" ? " (Forbidden)" : "";
      toast.error(`Error creating policy${forbidden}`);
      console.log(` Error: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([policyTuning.queryKey]).catch((error) => console.log(error));
      toast.success("Policy was created successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useSavePolicy;
