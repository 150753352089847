import { Typography } from "@mui/material";
import numeral from "numeral";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { SCALEOPS_COLORS } from "../colors";
import InfoIcon from "../Icons/InfoIcon";
import RunningNumberBox from "../pages/Overview/TopOverviewSection/RunningNumberBox";
import Tooltip from "./Tooltip";

const PIE_SIZE = 152;

interface interfaceMetricLabelProps {
  label: string;
  value: number;
  color: string;
}

const InterfaceMetricLabel = ({ label, value, color }: interfaceMetricLabelProps) => {
  return (
    <div className=" flex justify-start items-center gap-2">
      <div
        className="rounded-full h-2.5 w-5"
        style={{
          background: color,
        }}
      />
      <Typography variant="body2">
        {label} {Number.isNaN(value) ? "-" : numeral(value).format("0,0")}
      </Typography>
    </div>
  );
};

export const Title = {
  Automated: <span>Automated</span>,
  AverageAutomated: (
    <span className="flex flex-col">
      <span>Average</span>
      <span>automated</span>
    </span>
  ),
};

interface Props {
  xValue?: number;
  yValue?: number;
  xLabel?: string;
  yLabel?: string;
  showLabels?: boolean;
  disableAnimation?: boolean;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
  tooltipContent?: React.ReactNode;
}

const PercentagePieChart = ({
  xValue = NaN,
  yValue = NaN,
  xLabel = "automated",
  yLabel = "un-automated",
  showLabels = true,
  disableAnimation,
  subtitle = "workloads",
  title = Title.Automated,
  tooltipContent,
}: Props) => {
  const totalNumberOfWorkloads = xValue + yValue;
  const data = [
    { name: "Automated", value: xValue, color: SCALEOPS_COLORS.main.green },
    { name: "Inefficient", value: yValue, color: SCALEOPS_COLORS.main.info },
  ];

  let automatedPercentage = (xValue / (xValue + yValue)) * 100;
  automatedPercentage = Number(automatedPercentage);

  switch (true) {
    case totalNumberOfWorkloads === 0:
      automatedPercentage = 100;
      break;
    case automatedPercentage > 100:
      automatedPercentage = 100;
      break;
    case automatedPercentage < 0:
      automatedPercentage = 0;
      break;
    default:
      break;
  }

  // NOTE: do not remove. this section prevent pie chart from breaking when there are no workloads at all @barel22
  const pieData = [
    {
      name: "Automated",
      value: xValue === 0 && yValue === 0 ? 1 : xValue,
      color: SCALEOPS_COLORS.main.green,
    },
    {
      name: "Inefficient",
      value: yValue,
      color: SCALEOPS_COLORS.main.info,
    },
  ];

  return (
    <div className="flex items-center justify-center relative h-full">
      <div className="flex items-center justify-center relative">
        <div
          style={{
            transform: "scaleX(-1)",
            height: PIE_SIZE,
            width: PIE_SIZE,
          }}
        >
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="value"
                data={pieData}
                outerRadius="100%"
                innerRadius="80%"
                animationBegin={0}
                animationDuration={1000}
                animationEasing="ease-in-out"
                paddingAngle={0}
                isAnimationActive={!disableAnimation}
                startAngle={-270}
              >
                {data.map((cell, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={cell.color}
                      stroke="transparent"
                      style={{
                        outline: "none",
                      }}
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="absolute pt-2">
          <RunningNumberBox
            value={automatedPercentage}
            title={title}
            suffix="%"
            valueDataTestId="top-overview-automated-percentage-value"
            disableAnimation={disableAnimation}
          />
        </div>
      </div>
      {showLabels && (
        <div className="bg-pin-500 flex flex-col gap-2 items-start justify-center w-fit min-w-[160px]">
          <div>
            <Typography variant="h6" fontWeight={400}>
              {Number.isNaN(totalNumberOfWorkloads) ? "-" : numeral(totalNumberOfWorkloads).format("0,0")}
            </Typography>
            <Typography variant="body2" className="flex items-center">
              <span>{subtitle}</span>
              {tooltipContent && (
                <Tooltip title={tooltipContent} maxWidth={900}>
                  <InfoIcon width={14} height={14} />
                </Tooltip>
              )}
            </Typography>
          </div>
          <div className="flex flex-col items-start justify-center gap-2">
            <InterfaceMetricLabel label={xLabel} value={xValue} color={SCALEOPS_COLORS.main.green} />
            <InterfaceMetricLabel label={yLabel} value={yValue} color={SCALEOPS_COLORS.main.info} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PercentagePieChart;
