import { useEffect, useState } from "react";

interface Props<T> {
  sessionStorageKey: string;
  defaultValue?: T;
  valueFormatter?: (value: string) => T;
}

const useStateWithSessionStorage = <T extends string | undefined | boolean | null | number>({
  sessionStorageKey,
  defaultValue,
  valueFormatter,
}: Props<T>) => {
  const [value, setValue] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    const sessionStorageValue = sessionStorage.getItem(sessionStorageKey);
    if (sessionStorageValue) {
      setValue(valueFormatter ? valueFormatter(sessionStorageValue) : (sessionStorageValue as T));
    } else {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (value) {
      sessionStorage.setItem(sessionStorageKey, String(value));
    }
  }, [value]);

  return [value, setValue] as [T, (value: T) => void];
};

export default useStateWithSessionStorage;
