import { components } from "../api/schema";
import { GetConfig } from "./ConfigHelper";

export const getCustomerPrefix = (conf: components["schemas"]["ConfGetConfResponse"]): string => {
  if (conf?.customerName !== undefined && conf?.customerName.length > 0) {
    return conf.customerName.toLowerCase().split(" ").join("-").trim();
  } else if (conf?.clusterName !== undefined && conf?.clusterName.length > 0) {
    return conf.clusterName.split("-")[0];
  }
  return "scaleops-cluster";
};

export const getToken = (conf: components["schemas"]["ConfGetConfResponse"]): string => {
  return conf?.token ?? "<SCALEOPS_TOKEN>";
};

export function isGitSyncEnabled(): boolean {
  const config = GetConfig();
  if (config.repoUpdaterEnabled != undefined) {
    return Boolean(config.repoUpdaterEnabled);
  }
  return false;
}
