const TEXT_STYLE = {
  x: 0,
  textAnchor: "middle",
  fill: "#666",
  transform: "rotate(0)",
  fontSize: "10px",
  fontFamily: "sans-serif",
};

interface Props {
  x?: number;
  y?: number;
  payload?: {
    value?: string;
  };
}

const StackedOverviewChartTick = (props: Props) => {
  const { x, y, payload } = props;

  if (!payload?.value) {
    return null;
  }

  const [from, to] = payload?.value?.split(" - ") ?? ["", ""];

  if (!x || !y || !payload) {
    return null;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text y={-10} dy={16} {...TEXT_STYLE}>
        {from}
      </text>
      {to && (
        <text y={-12} dy={30} {...TEXT_STYLE}>
          to {to}
        </text>
      )}
    </g>
  );
};

export default StackedOverviewChartTick;
