import { Typography } from "@mui/material";

interface Props {
  title?: React.ReactNode;
}

const MenuHorizontalRule = ({ title }: Props) => (
  <div className="relative h-4 w-full flex justify-center items-center">
    <hr className="border-text-lightBlack w-full" />
    {title && (
      <Typography
        variant="caption"
        className="text-white left-auto right-auto top-auto button-auto p-2.5 bg-mainMenuBackground absolute z-20"
      >
        {title}
      </Typography>
    )}
  </div>
);

export default MenuHorizontalRule;
