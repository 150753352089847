import { IconProps } from "./utils/utils";

const HealingIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="1" />
      <g>
        <path
          d="M9.99999 17.2211C9.80068 17.2211 9.60852 17.1489 9.45877 17.0178C8.89321 16.5232 8.34794 16.0585 7.86686 15.6485L7.8644 15.6464C6.45396 14.4445 5.23599 13.4065 4.38855 12.384C3.44124 11.2409 3 10.157 3 8.97304C3 7.82268 3.39445 6.7614 4.11062 5.98456C4.83534 5.19853 5.82975 4.76562 6.911 4.76562C7.71914 4.76562 8.45923 5.02112 9.11068 5.52495C9.43944 5.77927 9.73744 6.09052 9.99999 6.45357C10.2626 6.09052 10.5605 5.77927 10.8894 5.52495C11.5408 5.02112 12.2809 4.76562 13.0891 4.76562C14.1702 4.76562 15.1647 5.19853 15.8895 5.98456C16.6056 6.7614 17 7.82268 17 8.97304C17 10.157 16.5588 11.2409 15.6115 12.3839C14.7641 13.4065 13.5462 14.4444 12.136 15.6462C11.6541 16.0568 11.1079 16.5223 10.5411 17.018C10.3915 17.1489 10.1992 17.2211 9.99999 17.2211ZM6.911 5.58572C6.06153 5.58572 5.28117 5.92474 4.71347 6.54041C4.13733 7.16536 3.81999 8.02925 3.81999 8.97304C3.81999 9.96884 4.19009 10.8594 5.01991 11.8607C5.82196 12.8285 7.01493 13.8451 8.39621 15.0223L8.39878 15.0244C8.88167 15.436 9.42908 15.9025 9.99881 16.4007C10.572 15.9016 11.1202 15.4343 11.6041 15.0221C12.9853 13.8449 14.1781 12.8285 14.9802 11.8607C15.8099 10.8594 16.18 9.96884 16.18 8.97304C16.18 8.02925 15.8626 7.16536 15.2865 6.54041C14.7189 5.92474 13.9384 5.58572 13.0891 5.58572C12.4668 5.58572 11.8955 5.78354 11.391 6.17361C10.9414 6.52139 10.6283 6.96103 10.4446 7.26865C10.3502 7.42683 10.184 7.52126 9.99999 7.52126C9.81595 7.52126 9.64975 7.42683 9.55533 7.26865C9.37183 6.96103 9.05866 6.52139 8.60898 6.17361C8.10451 5.78354 7.53318 5.58572 6.911 5.58572Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_441_6">
          <rect width="14" height="14" fill="white" transform="translate(3 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HealingIcon;
