import { IconProps } from "./utils/utils";

const DeleteIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_1_171)">
        <path d="M12.7587 7.24603C12.5 7.24603 12.2903 7.45571 12.2903 7.71442V16.5668C12.2903 16.8254 12.5 17.0352 12.7587 17.0352C13.0174 17.0352 13.2271 16.8254 13.2271 16.5668V7.71442C13.2271 7.45571 13.0174 7.24603 12.7587 7.24603Z" />
        <path d="M7.23181 7.24603C6.9731 7.24603 6.76343 7.45571 6.76343 7.71442V16.5668C6.76343 16.8254 6.9731 17.0352 7.23181 17.0352C7.49052 17.0352 7.70019 16.8254 7.70019 16.5668V7.71442C7.70019 7.45571 7.49052 7.24603 7.23181 7.24603Z" />
        <path d="M3.2037 5.95413V17.4941C3.2037 18.1762 3.45381 18.8167 3.89072 19.2763C4.32562 19.7372 4.93086 19.9988 5.56427 19.9999H14.4262C15.0598 19.9988 15.6651 19.7372 16.0998 19.2763C16.5367 18.8167 16.7868 18.1762 16.7868 17.4941V5.95413C17.6553 5.7236 18.2181 4.88454 18.1019 3.99333C17.9856 3.1023 17.2264 2.43577 16.3277 2.43559H13.9297V1.85011C13.9324 1.35776 13.7377 0.884988 13.3892 0.537177C13.0406 0.189549 12.5671 -0.00402466 12.0748 5.06485e-07H7.9157C7.42335 -0.00402466 6.94984 0.189549 6.6013 0.537177C6.25276 0.884988 6.05809 1.35776 6.06083 1.85011V2.43559H3.66275C2.76404 2.43577 2.00493 3.1023 1.88857 3.99333C1.77238 4.88454 2.33518 5.7236 3.2037 5.95413ZM14.4262 19.0632H5.56427C4.76345 19.0632 4.14046 18.3752 4.14046 17.4941V5.9953H15.85V17.4941C15.85 18.3752 15.227 19.0632 14.4262 19.0632ZM6.9976 1.85011C6.99449 1.60622 7.09036 1.37148 7.26344 1.19932C7.43634 1.02715 7.67163 0.932558 7.9157 0.936766H12.0748C12.3189 0.932558 12.5541 1.02715 12.727 1.19932C12.9001 1.3713 12.996 1.60622 12.9929 1.85011V2.43559H6.9976V1.85011ZM3.66275 3.37236H16.3277C16.7934 3.37236 17.1708 3.74981 17.1708 4.21544C17.1708 4.68108 16.7934 5.05853 16.3277 5.05853H3.66275C3.19711 5.05853 2.81966 4.68108 2.81966 4.21544C2.81966 3.74981 3.19711 3.37236 3.66275 3.37236Z" />
        <path d="M9.99524 7.24603C9.73653 7.24603 9.52686 7.45571 9.52686 7.71442V16.5668C9.52686 16.8254 9.73653 17.0352 9.99524 17.0352C10.2539 17.0352 10.4636 16.8254 10.4636 16.5668V7.71442C10.4636 7.45571 10.2539 7.24603 9.99524 7.24603Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_171">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
