import { Bar, BarChart, XAxis, YAxis } from "recharts";
import Tooltip from "../../../components/Tooltip";

const WASTE_COLOR = "#FF6161";
const OTHER_COLOR = "#e1eaf9";

const defaultTooltipContentFnc = (waste: number) => (
  <>
    <b className="text-main-red">{Math.round(waste)}%</b> of your total cloud spend{" "}
    <b className="text-main-red">is being wasted.</b>
    <br />
    <br />
    <b>Automate your workloads</b> to reduce your waste.
  </>
);
interface Props {
  waste?: number;
  optimized?: number;
  disableTooltip?: boolean;
  disableAnimation?: boolean;
  isLoading?: boolean;
  tooltipContentFnc?: (waste: number) => React.ReactNode;
}

const WasteVsOptimizedBarCharts = ({
  waste = 0,
  optimized = 0,
  disableTooltip,
  disableAnimation,
  isLoading,
  tooltipContentFnc = defaultTooltipContentFnc,
}: Props) => {
  return (
    <Tooltip title={tooltipContentFnc(waste)} placement="bottom" className="relative" disabled={disableTooltip}>
      <BarChart
        width={200}
        height={30}
        data={[
          {
            name: "waste",
            waste: Number.isNaN(waste) ? 0 : waste,
            optimized: Number.isNaN(optimized) ? 0 : optimized,
          },
        ]}
        layout="vertical"
      >
        <Bar dataKey="waste" stackId="a" fill={WASTE_COLOR} isAnimationActive={!disableAnimation} />
        <Bar dataKey="optimized" stackId="a" fill={OTHER_COLOR} isAnimationActive={!disableAnimation} />
        <XAxis type="number" hide={true} />
        <YAxis type="category" dataKey="a" hide={true} />
      </BarChart>
      {isLoading && (
        <>
          <div className="absolute w-[200px] h-[18px] bg-white top-[6px]" />
          <div className="absolute w-[200px] h-[18px] rounded-sm bg-border top-[6px] animate-pulse" />
        </>
      )}
    </Tooltip>
  );
};

export default WasteVsOptimizedBarCharts;
