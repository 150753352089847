import { IconProps } from "./utils/utils";

const DuplicateIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M5.41665 15H5.83331V15.4167C5.83331 15.9692 6.05281 16.4991 6.44351 16.8898C6.83421 17.2805 7.36411 17.5 7.91665 17.5H15.4166C15.9692 17.5 16.4991 17.2805 16.8898 16.8898C17.2805 16.4991 17.5 15.9692 17.5 15.4167V7.91668C17.5 7.36414 17.2805 6.83424 16.8898 6.44354C16.4991 6.05284 15.9692 5.83334 15.4166 5.83334H15V5.41668C15 4.86414 14.7805 4.33424 14.3898 3.94354C13.9991 3.55284 13.4692 3.33334 12.9166 3.33334H5.41665C4.86411 3.33334 4.33421 3.55284 3.94351 3.94354C3.55281 4.33424 3.33331 4.86414 3.33331 5.41668V12.9167C3.33331 13.4692 3.55281 13.9991 3.94351 14.3898C4.33421 14.7805 4.86411 15 5.41665 15ZM15.4166 6.66668C15.7482 6.66668 16.0661 6.79837 16.3005 7.03279C16.535 7.26721 16.6666 7.58516 16.6666 7.91668V15.4167C16.6666 15.7482 16.535 16.0661 16.3005 16.3006C16.0661 16.535 15.7482 16.6667 15.4166 16.6667H7.91665C7.58513 16.6667 7.26718 16.535 7.03276 16.3006C6.79834 16.0661 6.66665 15.7482 6.66665 15.4167V15H12.9166C13.4692 15 13.9991 14.7805 14.3898 14.3898C14.7805 13.9991 15 13.4692 15 12.9167V6.66668H15.4166ZM4.16665 5.41668C4.16665 5.08516 4.29834 4.76721 4.53276 4.53279C4.76718 4.29837 5.08513 4.16668 5.41665 4.16668H12.9166C13.2482 4.16668 13.5661 4.29837 13.8005 4.53279C14.035 4.76721 14.1666 5.08516 14.1666 5.41668V12.9167C14.1666 13.2482 14.035 13.5661 13.8005 13.8006C13.5661 14.035 13.2482 14.1667 12.9166 14.1667H5.41665C5.08513 14.1667 4.76718 14.035 4.53276 13.8006C4.29834 13.5661 4.16665 13.2482 4.16665 12.9167V5.41668Z" />
    </svg>
  );
};

export default DuplicateIcon;
