import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetHPAPolicies, GetHPAPoliciesResponse, UpdateHPAPolicy, UpdateHPAPolicyParams } from "../../../api/fetcher";

const { queryFn: createHPAQueryFn } = UpdateHPAPolicy();
const { queryKey: getHPAPoliciesQueryKey } = GetHPAPolicies();

const useUpdateHPAPolicy = () => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateHPAPolicyParams) => createHPAQueryFn(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries([getHPAPoliciesQueryKey]);
      const previousPolicies = queryClient.getQueryData<GetHPAPoliciesResponse>([getHPAPoliciesQueryKey]);

      if (previousPolicies?.policies && params.policy) {
        const policyName = params.policy.metadata?.name;

        queryClient.setQueryData<GetHPAPoliciesResponse>([getHPAPoliciesQueryKey], {
          policies: previousPolicies.policies.map((policy) => {
            if (policy.metadata?.name === policyName && params.policy) {
              return params.policy;
            }
            return policy;
          }),
        });
      }
    },
    onError: (error: Error) => {
      console.log(`Error updating HPA policy: ${error.message}`);
      toast.error(`Failed to create HPA policy`);
    },
    onSuccess: () => {
      toast.success("HPA Policy was updated successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
    onSettled: () => {
      queryClient
        .invalidateQueries([getHPAPoliciesQueryKey])
        .catch((error) => console.log("Error invalidating query after updating HPA policy: ", error));
    },
  });
};

export default useUpdateHPAPolicy;
