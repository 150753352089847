import * as Yup from "yup";

export const defaultIntervalOptions = {
  "Every 5 min": 5,
  "Every 10 min": 10,
  "Every 30 min": 30,
  "Every 1 hour": 60,
  "Every 4 hours": 60 * 4,
  "Every 8 hours": 60 * 8,
  "Every 12 hours": 60 * 12,
  "Every 1 day": 60 * 24,
};

export const intervalOptions = {
  AbnormalRequestIncrease: {
    ...defaultIntervalOptions,
    // "Every 3 days": 60 * 24 * 3,
  },
  default: defaultIntervalOptions,
};

export const getIntervalOptions = (alertType: string | undefined) => {
  const hasCustomOptions = alertType && Object.keys(intervalOptions).includes(alertType);

  if (hasCustomOptions) {
    return intervalOptions[alertType as keyof typeof intervalOptions];
  }

  return intervalOptions.default;
};

export const getSeverityOptions = () => {
  return {
    Low: "Low",
    Medium: "Medium",
    High: "High",
    Critical: "Critical",
  };
};

export const defaultSampleWindowOptions = {
  "5 min": 5,
  "10 min": 10,
  "15 min": 15,
  "20 min": 20,
  "25 min": 25,
  "30 min": 30,
  "1 hour": 60,
  "4 hours": 60 * 4,
  "12 hours": 60 * 12,
  "1 day": 60 * 24,
};

const sampleWindowOptions = {
  AbnormalRequestIncrease: {
    ...defaultSampleWindowOptions,
    "Every 3 days": 60 * 24 * 3,
  },
  default: defaultSampleWindowOptions,
};

export const getSampleWindowOptions = (alertType: string | undefined) => {
  const hasCustomOptions = alertType && Object.keys(sampleWindowOptions).includes(alertType);

  if (hasCustomOptions) {
    return sampleWindowOptions[alertType as keyof typeof sampleWindowOptions];
  }

  return sampleWindowOptions.default;
};

export const getThresholdPercentageOptions = () => {
  const options: Record<string, number> = {};
  const stepSize = 5;

  for (let i = 5; i <= 100; i = i + stepSize) {
    options[`${i}%`] = i / 100;
  }

  return options;
};

export const getThresholdAbsoluteOptions = () => {
  const options: Record<string, number> = {};
  const stepSize = 5;

  for (let i = 5; i <= 100; i = i + stepSize) {
    options[`${i}`] = i;
  }
  // Asaf, please don't hate me
  options["3"] = 3;

  return options;
};

export const thresholdPrefix = {
  UnderProvisioned: "of workloads",
  OverProvisioned: "of workloads",
  OutOfMemory: "OOM events",
  CpuThrottling: "of CPU time",
  AbnormalRequestIncrease: "increase in resource request",
  HighNodeUtilization: "% utilization",
  "ResourceQuota-limits.cpu": "of hard quota",
  "ResourceQuota-limits.memory": "of hard quota",
  "ResourceQuota-pods": "of hard quota",
  "ResourceQuota-replicationcontrollers": "of hard quota",
  "ResourceQuota-requests.cpu": "of hard quota",
  "ResourceQuota-count/replicasets.apps": "of hard quota",
  "ResourceQuota-requests.memory": "of hard quota",
};

export type Row = {
  id: string;
  name: string;
  interval?: number;
  slackFrequency?: number;
  window?: number;
  threshold?: number;
  enabled?: boolean;
  description?: string;
  severity?: string;
  isScaleopsAlert?: boolean;
};

export type RawRowsDataType = {
  name: string;
  enabled: boolean;
  parameters: {
    interval: number;
    threshold: number;
    windows: number;
  };
};

export const RawRowsData: RawRowsDataType[] = [
  {
    name: "notifier-1",
    enabled: false,
    parameters: {
      interval: 5,
      threshold: 0.2,
      windows: 240,
    },
  },
  {
    name: "notifier-2",
    enabled: true,
    parameters: {
      interval: 15,
      threshold: 150,
      windows: 30,
    },
  },
  {
    name: "notifier-3",
    enabled: false,
    parameters: {
      interval: 60,
      threshold: 10,
      windows: 15,
    },
  },
  {
    name: "notifier-4",
    enabled: true,
    parameters: {
      interval: 240,
      threshold: 0.2,
      windows: 5,
    },
  },
];

export const STRING_VALIDATION = Yup.string().required("Required").min(1, "Must be greater than 1");

export const formatAlertName = (name: string) => {
  const result = name.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const adjustThreshold = (
  isScaleopsAlert: boolean | undefined,
  value: number | undefined
): number | undefined => {
  // Adjust the value for scaleops alerts where the threshold is inverted in the UI but not in the backend
  return isScaleopsAlert && value ? Math.round((1 - value) * 100) / 100 : value;
};
