import * as React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { IntlProvider } from "react-intl";
import "./index.css";

const rootElement = document.getElementById("root");
const root = rootElement != null ? createRoot(rootElement) : undefined;

root?.render(
  <ThemeProvider theme={theme}>
    <IntlProvider locale="en" defaultLocale="en">
      <style>{`
      .scaleops-success {
        color: ${theme.palette.success.main};
      }
      .scaleops-error {
        color: ${theme.palette.error.main};
      }
    `}</style>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </IntlProvider>
  </ThemeProvider>
);
