import { Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import ScaleopsIcon from "../Icons/ScaleopsIcon";
import Button, { BUTTON_VARIANTS } from "./Button";
import FormInput from "./formComponents/FormInput";

const BackgroundPattern = () => (
  <>
    <ScaleopsIcon width={"30vw"} height={"30vw"} className="position fixed top-[-20px] left-0 opacity-25" />
    <ScaleopsIcon width={"50vw"} height={"50vw"} className="position fixed bottom-[-20vw] right-0 opacity-10" />
  </>
);

const fetchCong = () => {
  fetch("/conf").then((res) => {
    if (res.ok) {
      window.location.href = "/";
    }
  });
};

const LoginPage = () => {
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showForgetPasswordInstructions, setShowForgetPasswordInstructions] = useState(false);

  useEffect(() => {
    fetchCong();
  }, []);

  return (
    <div className="bg-[#5a588a] w-full h-[100vh] relative flex justify-center items-center">
      <BackgroundPattern />
      <div className="bg-[rgba(255,255,255,0.9)] w-fit h-fit p-16 border-2 rounded-lg border-white mt-[-10%]">
        <Formik
          initialValues={{ userName: "", password: "" }}
          validationSchema={Yup.object({
            userName: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            fetch("/auth/callback", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }).then((res) => {
              if (res.status === 200) {
                const url = new URL(res.url);
                const storedToken = url.searchParams.get("token");
                if (storedToken) {
                  localStorage.setItem("__scaleops_token", storedToken);
                  window.location.href = "/";
                }
              } else {
                fetchCong();
                setShowInvalidMessage(true);
              }
            });
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="flex flex-col gap-8 relative z-9">
                  <div>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      className="text-center flex justify-center items-center gap-2"
                    >
                      Welcome to ScaleOps
                    </Typography>
                  </div>
                  <FormInput
                    label="Username"
                    name="userName"
                    placeholder="Enter username"
                    type="text"
                    required
                    inputWidth={300}
                  />
                  <FormInput
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    type="password"
                    required
                    inputWidth={300}
                    showHidePasswordToggle
                  />
                  <Button
                    type="submit"
                    label="Login"
                    disabled={!(formik.isValid && formik.dirty)}
                    variant={BUTTON_VARIANTS.mediumPurple}
                  />
                  {showInvalidMessage && (
                    <Typography variant="caption" className="text-red-500 text-center">
                      Invalid username or password. Please try again.
                    </Typography>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        <div>
          <div className="flex flex-col gap-2 items-start max-w-[298px] mt-2">
            <button
              className="text-[12px] text-main-linkBlue hover:text-guideline-darkBlue cursor-pointer"
              onClick={() => setShowForgetPasswordInstructions(!showForgetPasswordInstructions)}
            >
              Forgot your password?
            </button>
            {showForgetPasswordInstructions && (
              <>
                <Typography variant="caption" className="text-text-lightBlack">
                  Please contact your company's <b>administrator</b> or reach out to <b>ScaleOps support</b> for
                  restoring your password.
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
