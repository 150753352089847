import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getConfig, GetConfigResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CodeSnippet, { THEME } from "../../../components/CodeSnippet";
import PermissionsDocsLink from "./PermissionsDocsLink";
import { getIntegrationCommand } from "./commandsUtils";
import { AuthMethod } from "./utils";
import useGetVersion from "../../Cost/hooks/useGetVersion";

const configApi = getConfig();

interface Props {
  currentAuthMethod: AuthMethod;
}

const NoAuthenticationContainer = ({ currentAuthMethod }: Props) => {
  const isCurrentSelectedMethod = currentAuthMethod === AuthMethod.DISABLED;
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });

  const { data } = useQuery<GetConfigResponse, Error>({
    queryKey: [configApi.queryKey],
    queryFn: configApi.queryFn,
    enabled: !isCurrentSelectedMethod,
  });

  const { data: versionData } = useGetVersion();

  useEffect(() => {
    if (JSON.stringify(ssoConf) !== JSON.stringify(data?.ssoConf))
      setSsoConf(
        data?.ssoConf || {
          enabled: false,
          provider: "none",
        }
      );
  }, [data]);

  if (isCurrentSelectedMethod)
    return (
      <>
        <Typography variant="body2" className="text-text-lightBlack">
          You currently have <b>no authentication</b> and <b>authorization</b> on your cluster.
        </Typography>
      </>
    );

  return (
    <div className="flex flex-col gap-4">
      <CodeSnippet
        description={
          <Typography variant="body2" className="text-text-lightBlack">
            Run the code below to <b>disable authentication and authorization</b> on your cluster.
          </Typography>
        }
        codeSnippet={getIntegrationCommand({
          currentIntegration: currentAuthMethod,
          selectedIntegration: AuthMethod.DISABLED,
          token: data?.token,
          hideToken: true,
          repository: versionData?.helmCommandConfig?.repositoryURL,
        })}
        codeSnippetToCopy={getIntegrationCommand({
          currentIntegration: currentAuthMethod,
          selectedIntegration: AuthMethod.DISABLED,
          token: data?.token,
          repository: versionData?.helmCommandConfig?.repositoryURL,
        })}
        theme={THEME.light}
      />
      <PermissionsDocsLink relativePath="/sso-and-login-security/" linkTitle="SSO & Login Security docs" />
    </div>
  );
};

export default NoAuthenticationContainer;
