import { SCALEOPS_COLORS } from "../../colors";
import { adjustedDayjs } from "../../utils/dateAndTimeUtils";

export const CURRENT_COLOR = "#3B8BFF";
export const RECOMMENDED_COLOR = SCALEOPS_COLORS.main.green;
export const CURRENT_FILL = "url(#color-stripe)";
export const RECOMMENDED_FILL = "#bdf1dc";
export const RECOMMENDED_FILL_OPACITY = 0.4;
export const MASK_FILL = "white";
export const MASK_FILL_OPACITY = 1;
export const MASK_STROKE_OPACITY = 0;

export const WASTE_COLOR = "#FF6161";

export type GraphDataPoint = {
  timestamp: string;
  currentCost: number;
  costWasteMask?: number;
  recommendedCost: number;
  currentNodes: number;
  nodesWasteMask?: number;
  recommendedNodes: number;
};

export type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload: GraphDataPoint;
  }[];
  elementsToRender: ChartElement[];
  selectedTab: TabOptions;
};

export enum TabOptions {
  Cost = "Cost",
  Nodes = "Nodes",
}

export const regularRec = (color: string) => (
  <div
    className="w-4 h-2"
    style={{
      backgroundColor: color,
    }}
  />
);

export const StripsSvg = (
  <svg width="20" height="10">
    <defs>
      <pattern id="pattern-stripe" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
        <rect width="2" height="4" fill="red" opacity={0.4} />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#pattern-stripe)" />
  </svg>
);

export type ChartElement = {
  dataKey: string;
  legendName: string;
  stroke: string;
  strokeWidth: number;
  strokeOpacity: number;
  fill: string;
  fillOpacity: number;
};

export enum ChartElementKey {
  currentCost = "currentCost",
  costWasteMask = "costWasteMask",
  recommendedCost = "recommendedCost",
  currentNodes = "currentNodes",
  nodesWasteMask = "nodesWasteMask",
  recommendedNodes = "recommendedNodes",
}

export const chartElements: ChartElement[] = [
  {
    dataKey: ChartElementKey.currentCost,
    legendName: "Current cost",
    stroke: CURRENT_COLOR,
    strokeWidth: 1,
    strokeOpacity: 1,
    fill: CURRENT_FILL,
    fillOpacity: RECOMMENDED_FILL_OPACITY,
  },
  {
    dataKey: ChartElementKey.costWasteMask,
    legendName: "Cost waste mask",
    stroke: CURRENT_COLOR,
    strokeWidth: 1,
    strokeOpacity: MASK_STROKE_OPACITY,
    fill: MASK_FILL,
    fillOpacity: MASK_FILL_OPACITY,
  },
  {
    dataKey: ChartElementKey.recommendedCost,
    legendName: "Recommended cost",
    stroke: RECOMMENDED_COLOR,
    strokeWidth: 2,
    strokeOpacity: 1,
    fill: RECOMMENDED_FILL,
    fillOpacity: 1,
  },
  {
    dataKey: ChartElementKey.currentNodes,
    legendName: "Current nodes",
    stroke: CURRENT_COLOR,
    strokeWidth: 1,
    strokeOpacity: 1,
    fill: CURRENT_FILL,
    fillOpacity: RECOMMENDED_FILL_OPACITY,
  },
  {
    dataKey: ChartElementKey.nodesWasteMask,
    legendName: "Nodes waste mask",
    stroke: CURRENT_COLOR,
    strokeWidth: 1,
    strokeOpacity: MASK_STROKE_OPACITY,
    fill: MASK_FILL,
    fillOpacity: MASK_FILL_OPACITY,
  },
  {
    dataKey: ChartElementKey.recommendedNodes,
    legendName: "Recommended nodes",
    stroke: RECOMMENDED_COLOR,
    strokeWidth: 2,
    strokeOpacity: 1,
    fill: RECOMMENDED_FILL,
    fillOpacity: 1,
  },
];

const ONE_DAY = 60 * 60 * 24;

const randomNumber = (start: number, end: number) => {
  return Math.floor(Math.random() * (end - start) + start);
};

export const getDemoDataInput = (numberOfPoints: number, gapInSeconds: number = ONE_DAY) => {
  const data: GraphDataPoint[] = [];

  let currentDate = adjustedDayjs().format("YYYY-MM-DD HH:mm:ss Z");

  for (let i = 0; i < numberOfPoints; i++) {
    const timestamp = currentDate;
    const currentCost = randomNumber(150, 300);
    const recommendedCost = currentCost - randomNumber(20, 140);
    const currentNodes = randomNumber(20, 30);
    const recommendedNodes = currentNodes - randomNumber(5, 15);

    data.push({
      timestamp,
      currentCost,
      recommendedCost,
      currentNodes,
      recommendedNodes,
    });

    currentDate = adjustedDayjs(currentDate).subtract(gapInSeconds, "second").format("YYYY-MM-DD HH:mm:ss Z");
  }

  return data;
};

export type NodeCostRow = {
  count: number;
  cpuCores: number;
  monthlyCost: number;
  instanceType: string;
  maxPods: number;
  memoryGiB: number;
  spot: boolean;
};

export type RecommendedCostDataSum = {
  count: number;
  cpuCores: number;
  monthlyCost: number;
  maxPods: number;
  memoryGiB: number;
};

export const sumData = (data: NodeCostRow[]): RecommendedCostDataSum =>
  data.reduce(
    (acc, curr) => {
      return {
        count: acc.count + curr.count,
        cpuCores: acc.cpuCores + curr.cpuCores * curr.count,
        monthlyCost: acc.monthlyCost + curr.monthlyCost,
        maxPods: acc.maxPods + curr.maxPods * curr.count,
        memoryGiB: acc.memoryGiB + curr.memoryGiB * curr.count,
      };
    },
    {
      count: 0,
      cpuCores: 0,
      monthlyCost: 0,
      maxPods: 0,
      memoryGiB: 0,
    }
  );

export const roundToTwoDecimal = (n: number) => Math.round(n * 100) / 100;
