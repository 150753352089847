import { useQuery } from "@tanstack/react-query";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetUIFeaturesQuery,
  GetUIFeaturesResponse,
} from "../api/fetcher";
import { HAS_MULTI_PRODUCT_LAYOUT } from "./developmentFeatureFlags";
import useStateWithSessionStorage from "./useStateWithSessionStorage";
import { IsSnapshotServer } from "./FeaturesHelper";

const DISABLE_QUERY_PARAMS_FOR_DEVELOPMENT_PURPOSES = false;

const uiFeaturesQuery = GetUIFeaturesQuery();
const betaFeaturesQuery = GetBetaFeaturesQuery();

const useHasMultiProductLayoutWithReactQuery = (): boolean => {
  const [hasMultiProductLayout] = useStateWithSessionStorage<boolean>({
    sessionStorageKey: "hasMultiProductLayout",
    defaultValue: false,
    valueFormatter: (value) => value === "true",
  });

  const { data: uiFeaturesData } = useQuery<GetUIFeaturesResponse, Error>({
    queryKey: [uiFeaturesQuery.queryKey],
    queryFn: uiFeaturesQuery.queryFn,
  });

  const { data: betaFeaturesData } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });

  return !!(
    IsSnapshotServer() ||
    HAS_MULTI_PRODUCT_LAYOUT ||
    hasMultiProductLayout ||
    (!DISABLE_QUERY_PARAMS_FOR_DEVELOPMENT_PURPOSES &&
      betaFeaturesData?.enabled &&
      betaFeaturesData?.betaFeatures?.hpaOptimization &&
      uiFeaturesData?.uiFeatures?.hpaOptimization)
  );
};

export default useHasMultiProductLayoutWithReactQuery;
