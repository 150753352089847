import { IconProps } from "./utils/utils";

const TrendIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="1" />
      <g>
        <mask id="mask0_446_109" maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
          <path d="M3 3H17V17H3V3Z" fill="white" />
        </mask>
        <g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.8291 6.08752C17.0569 6.31533 17.0569 6.68467 16.8291 6.91248L11.2875 12.4541C11.0597 12.6819 10.6903 12.6819 10.4625 12.4541L7.95833 9.94995L3.99581 13.9125C3.76801 14.1403 3.39866 14.1403 3.17085 13.9125C2.94305 13.6847 2.94305 13.3153 3.17085 13.0875L7.54585 8.71252C7.77366 8.48471 8.14301 8.48471 8.37081 8.71252L10.875 11.2167L16.0042 6.08752C16.232 5.85971 16.6013 5.85971 16.8291 6.08752Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3333 6.5C12.3333 6.17783 12.5945 5.91666 12.9167 5.91666H16.4167C16.7389 5.91666 17 6.17783 17 6.5V10C17 10.3222 16.7389 10.5833 16.4167 10.5833C16.0945 10.5833 15.8333 10.3222 15.8333 10V7.08333H12.9167C12.5945 7.08333 12.3333 6.82216 12.3333 6.5Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width="14" height="14" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrendIcon;
