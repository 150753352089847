import { useQuery } from "@tanstack/react-query";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetUIFeaturesQuery,
  GetUIFeaturesResponse,
} from "../../api/fetcher";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../utils/queryParams";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";

const ENABLE_FOR_DEV_PURPOSE = false;

const uiFeaturesQuery = GetUIFeaturesQuery();
const betaFeaturesQuery = GetBetaFeaturesQuery();

const ENABLE_HPA_CUTOMER_VIEW = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_CUSTOMER_VIEW);
const useHpaOptimizationEnabled = () => {
  const { data: uiFeaturesData } = useQuery<GetUIFeaturesResponse, Error>({
    queryKey: [uiFeaturesQuery.queryKey],
    queryFn: uiFeaturesQuery.queryFn,
  });

  const { data: betaFeaturesData } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });

  if (ENABLE_FOR_DEV_PURPOSE) return true;

  return (
    (ENABLE_HPA_CUTOMER_VIEW && !IsSnapshotServer()) ||
    (betaFeaturesData?.enabled &&
      betaFeaturesData?.betaFeatures?.hpaOptimization &&
      uiFeaturesData?.uiFeatures?.hpaOptimization)
  );
};

export default useHpaOptimizationEnabled;
