import { animated, useSpring } from "react-spring";
import { SCALEOPS_COLORS } from "../colors";

const BAR_ATTRS = {
  width: "100%",
  height: "100%",
  rx: "3px",
  ry: "50px",
};

interface Props {
  percentage: number;
  width?: number;
  height?: number;
  additionalPercentage?: number;
}

const PercentageMeter = ({ percentage, width = 300, height = 15, additionalPercentage = 0 }: Props) => {
  percentage = percentage > 100 ? 100 : percentage;
  percentage = percentage < 0 ? 0 : percentage;

  const springs = useSpring({
    from: { width: 0 },
    to: { width: 1 },
  });

  return (
    <div
      style={{
        width,
        height,
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <rect {...BAR_ATTRS} fill={SCALEOPS_COLORS.background.chipHover} />
        {additionalPercentage && (
          <animated.rect
            {...BAR_ATTRS}
            fill={SCALEOPS_COLORS.guideline.lighterGreen}
            style={{
              clipPath: springs.width.to((w) => `inset(0 ${100 - w * (percentage + additionalPercentage)}% 0 0)`),
            }}
          />
        )}
        <animated.rect
          {...BAR_ATTRS}
          fill={SCALEOPS_COLORS.main.green}
          style={{
            clipPath: springs.width.to((w) => `inset(0 ${100 - w * percentage}% 0 0)`),
          }}
        />
      </svg>
    </div>
  );
};

export default PercentageMeter;
