import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetClustersResponse, getClusters } from "../api/fetcher";
import { useMainContext } from "../MainContext";

const { queryKey, queryFn } = getClusters();

const useGetUserRole = () => {
  const { currentCluster } = useMainContext();
  const [userRole, setUserRole] = useState<string | undefined>();

  const { data, isError } = useQuery<GetClustersResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: () => queryFn(),
  });

  useEffect(() => {
    const cluster = data?.clusters?.find((cluster) => cluster.name === currentCluster);
    setUserRole(cluster?.userRole);
  }, [data, currentCluster]);

  return { userRole, isError };
};

export default useGetUserRole;
