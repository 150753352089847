import HPAPoliciesTable from "./HPAPoliciesTable/HPAPoliciesTable";

const HPAPoliciesView = () => {
  return (
    <div className="bg-white rounded-lg p-12">
      <HPAPoliciesTable />
    </div>
  );
};

export default HPAPoliciesView;
