import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getClusters, GetClustersResponse } from "../api/fetcher";
import { components } from "../api/schema";

const { queryKey, queryFn } = getClusters();

const useGetClusters = () => {
  const [clusters, setClusters] = useState<components["schemas"]["MultiClusterInfo"][]>([]);

  const { data } = useQuery<GetClustersResponse, Error>({
    queryKey: [queryKey],
    queryFn,
  });

  useEffect(() => {
    let clustersList = data?.clusters ?? [];

    clustersList = clustersList.sort((a, b) => {
      if (a.isParent) {
        return -1;
      }
      if (b.isParent) {
        return 1;
      }
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

    setClusters(clustersList);
  }, [data]);

  return clusters;
};

export default useGetClusters;
