import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetAdmissionOptions,
  GetAdmissionOptionsResponse,
  getDashboardByNamespace,
  GetNotEvictable,
  GetNotEvictableGraphs,
  SetAdmissionOptions,
  SetAdmissionOptionsParams,
} from "../../../api/fetcher";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";

const TOAST_SETTINGS = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1000,
  style: {
    width: 400,
  },
};

const { queryKey: getDashboardByNamespaceQueryKey } = getDashboardByNamespace();
const { queryFn: setAdmissionOptionsQueryFn, queryKey: setAdmissionOptionsQueryKey } = SetAdmissionOptions();
const { queryKey: getNotEvictableQueryKey } = GetNotEvictable();
const { queryKey: admissionOptionsQueryKey } = GetAdmissionOptions();
const { queryKey: getNotEvictableGraphsQueryKey } = GetNotEvictableGraphs();

export const useUpdateAdmissionOptions = (onSuccess?: () => void, onError?: () => void) => {
  const queryClient = useQueryClient();
  const isDemoVersion = FeatureEnabled("DemoVersion");

  return useMutation((params: SetAdmissionOptionsParams) => setAdmissionOptionsQueryFn(params), {
    onMutate: (variables) => {
      queryClient.setQueryData([admissionOptionsQueryKey], (oldData: GetAdmissionOptionsResponse | undefined) => {
        return {
          ...oldData,
          binPackOwnerlessPods: !!variables.binPackOwnerlessPods,
        };
      });
    },
    onError: (error: Error, variables) => {
      toast.error(
        `Error ${variables.binPackOwnerlessPods ? "Automated" : "Un-Automated"} pods without owners`,
        TOAST_SETTINGS
      );
      console.log(`Error: ${error.message}`);
      onError && onError();
    },
    onSuccess: (_, variables) => {
      toast.success(
        `${variables.binPackOwnerlessPods ? "Automated" : "Un-Automated"} pods without owners`,
        TOAST_SETTINGS
      );
      onSuccess && onSuccess();
    },
    onSettled: () => {
      [
        setAdmissionOptionsQueryKey,
        getDashboardByNamespaceQueryKey,
        getNotEvictableQueryKey,
        getNotEvictableGraphsQueryKey,
      ].forEach((key) => {
        queryClient.invalidateQueries([key]).catch((error) => console.log(error));
      });
      if (isDemoVersion) queryClient.invalidateQueries([getNotEvictableQueryKey]).catch((error) => console.log(error));
    },
  });
};
