import FormInput from "../../../components/formComponents/FormInput";
import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

const IS_TOGGLE_THEME = true;

interface Props {
  isCustomizedPolicy?: boolean;
}

const RolloutSettings = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Ensure high availability"
        description={
          IS_TOGGLE_THEME
            ? "ScaleOps optimization will consider Workload’s rollout strategy, PDBs, unevictable annotations, and will ensure minimum of 1 replica at all time."
            : "Define the minimum replicas and max unavailable pods."
        }
      />
      <div className="flex flex-wrap gap-4">
        {!IS_TOGGLE_THEME && (
          <>
            <FormInput
              label="Min replicas"
              name="minReplicas"
              type="number"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
            <FormInput
              label="Max Unavailable Pods (%)"
              name="maxUnavailablePodsPercentage"
              type="number"
              className="w-[180px]"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
          </>
        )}
        {IS_TOGGLE_THEME && (
          <FormSwitch
            name="minReplicas"
            disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
            disableChange={!isCustomizedPolicy}
            getCheckedValue={(value) => !!value}
            setOnChangeValue={(checked) => (checked ? 1 : 0)}
          />
        )}
      </div>
    </div>
  );
};
export default RolloutSettings;
