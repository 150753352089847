import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { components } from "../../../api/schema";
import * as formatterUtils from "../../../utils/formatterUtils";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

export default function TotalCostsColumn({
  params,
}: {
  params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  if (params.row.isReadyRecommendation == false) {
    return (
      <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
        inactive
      </Typography>
    );
  }
  return (
    <Typography variant="body2" className={clsx("fullCellTooltipContent")} fontWeight={500}>
      <Tooltip
        title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
        maxWidth={500}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        {params.row.totalCost == undefined || params.row.totalCost < 0
          ? formatterUtils.currencyFormatter(0)
          : formatterUtils.currencyFormatter(params.row.totalCost)}
      </Tooltip>
    </Typography>
  );
}
