import { IconProps } from "./utils/utils";

const RestoreIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M2.51536 11.7141C2.41023 11.7141 2.3051 11.6744 2.22561 11.5936L0.171767 9.53846C0.0115106 9.3782 0.0115106 9.11794 0.171767 8.95769C0.332023 8.79743 0.59228 8.79743 0.752536 8.95769L2.80638 11.0141C2.96664 11.1744 2.96664 11.4346 2.80638 11.5949C2.72561 11.6744 2.62049 11.7141 2.51536 11.7141Z" />
      <path d="M2.51534 11.7141C2.41022 11.7141 2.30509 11.6744 2.2256 11.5936C2.06534 11.4333 2.06534 11.1731 2.2256 11.0128L4.2974 8.94359C4.45765 8.78333 4.71791 8.78333 4.87816 8.94359C5.03842 9.10384 5.03842 9.3641 4.87816 9.52436L2.80637 11.5936C2.7256 11.6744 2.62047 11.7141 2.51534 11.7141Z" />
      <path d="M10.9795 18.9692C9.69231 18.9692 8.44872 18.7026 7.28461 18.1756C6.16025 17.6667 5.16795 16.9436 4.3359 16.0269C4.18333 15.859 4.19615 15.6 4.3641 15.4474C4.53205 15.2949 4.79102 15.3077 4.94359 15.4756C6.4859 17.1744 8.6859 18.15 10.9795 18.15C15.4731 18.15 19.1282 14.4949 19.1282 10.0013C19.1282 5.50768 15.4731 1.85256 10.9795 1.85256C6.4859 1.85256 2.82948 5.5064 2.82948 9.99999C2.82948 10.4141 2.86025 10.8282 2.92179 11.2346C2.95641 11.459 2.80128 11.6679 2.5782 11.7013C2.35384 11.7359 2.14487 11.5808 2.11154 11.3577C2.04359 10.9115 2.00897 10.4551 2.00897 9.99999C2.00897 8.78974 2.24615 7.6141 2.7141 6.50897C3.16538 5.44102 3.81282 4.48204 4.6359 3.65768C5.46025 2.83333 6.41923 2.18717 7.48718 1.73589C8.59359 1.26794 9.76795 1.03076 10.9782 1.03076C12.1885 1.03076 13.3641 1.26794 14.4692 1.73589C15.5372 2.18717 16.4962 2.83461 17.3205 3.65768C18.1436 4.48076 18.791 5.44102 19.2423 6.50897C19.7103 7.61538 19.9474 8.78974 19.9474 9.99999C19.9474 11.2102 19.7103 12.3859 19.2423 13.491C18.791 14.559 18.1436 15.5179 17.3205 16.3423C16.4974 17.1667 15.5372 17.8128 14.4692 18.2641C13.3641 18.732 12.1897 18.9692 10.9795 18.9692Z" />
      <path d="M10.9794 11.5385C10.1307 11.5385 9.44098 10.8487 9.44098 9.99999C9.44098 9.15128 10.1307 8.46153 10.9794 8.46153C11.8282 8.46153 12.5179 9.15128 12.5179 9.99999C12.5179 10.8487 11.8269 11.5385 10.9794 11.5385ZM10.9794 9.28205C10.5833 9.28205 10.2615 9.60384 10.2615 9.99999C10.2615 10.3961 10.5833 10.7179 10.9794 10.7179C11.3756 10.7179 11.6974 10.3961 11.6974 9.99999C11.6974 9.60384 11.3743 9.28205 10.9794 9.28205Z" />
    </svg>
  );
};

export default RestoreIcon;
