import { Typography } from "@mui/material";
import clsx from "clsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "../Tooltip";

interface Props {
  title?: string;
  description?: React.ReactNode;
  info?: React.ReactNode;
  titleVariant?: "body1" | "body2";
  textDisabled?: boolean;
  tooltipMaxWidth?: number;
}

const FormTitleAndDescription = ({
  title,
  description,
  info,
  titleVariant = "body1",
  textDisabled,
  tooltipMaxWidth,
}: Props) => {
  return (
    <div className="flex flex-col">
      <Typography
        variant={titleVariant}
        fontWeight={700}
        className={clsx("flex gap-2 item-center", {
          "text-text-disable": textDisabled,
        })}
      >
        {title}
        {info && (
          <Tooltip title={info} maxWidth={tooltipMaxWidth}>
            <InfoOutlinedIcon style={{ width: 14 }} />
          </Tooltip>
        )}
      </Typography>
      <Typography
        variant="body2"
        className={clsx({
          "text-text-disable": textDisabled,
        })}
      >
        {description}
      </Typography>
    </div>
  );
};

export default FormTitleAndDescription;
