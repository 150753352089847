import { Dialog as MUIDialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DEFAULT_DIALOG_CONTENT_STYLE = {
  padding: "20px 40px 20px 40px",
};

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  dialogContentStyle?: React.CSSProperties;
  hasTopTitle?: boolean;
  fullWidth?: boolean;
  minWidth?: string | number;
}

const Dialog = ({
  isOpen,
  onClose,
  title,
  dialogContentStyle = DEFAULT_DIALOG_CONTENT_STYLE,
  children,
  hasTopTitle = true,
  fullWidth = true,
  minWidth,
}: Props) => {
  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth="xl"
      PaperProps={{
        style: {
          boxShadow: "none",
          minWidth: minWidth,
        },
      }}
    >
      {hasTopTitle && (
        <DialogTitle className="bg-text-lightBlack text-white">
          <div className="flex items-center">
            {title && <Typography className="flex-grow">{title}</Typography>}
            <CloseIcon onClick={onClose} className="cursor-pointer" />
          </div>
        </DialogTitle>
      )}
      <DialogContent style={dialogContentStyle}>{children}</DialogContent>
    </MUIDialog>
  );
};

export default Dialog;
