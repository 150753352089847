import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";
import { POLICY_TUNING_SELECTED_TAB_QUERY_KEY } from "../../pages/Overview/PolicyTuning/PolicyTuning";
import { PolicyTuningTabs } from "../../pages/Overview/PolicyTuning/utils";
import {
  getDisplayWorkloadName,
  getWorkloadType,
  SCALEOPS_CRON_JOB_COG_NAME,
  SCALEOPS_KEDA_SCALED_JOB_COG_NAME,
} from "../../utils/namesUtils";
import WorkloadTooltip from "../../utils/workloadTooltip";
import Tooltip from "../Tooltip";
import { SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM } from "./overviewUtils";
import { getWorkloadType as getWorkloadTypeForView, WorkloadType } from "../../utils/typesUtils";

interface GetWorkloadNameRowProps {
  name: string;
  namespace: string;
  type: string;
  cluster: string;
  targetTab: PolicyTuningTabs;
  setSelectedWorkload?: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
}

export const WorkloadNameRow = ({
  name,
  namespace,
  type,
  cluster,
  targetTab,
  setSelectedWorkload,
}: GetWorkloadNameRowProps) => {
  const navigate = useNavigate();
  const [, setSelectedTab] = useQueryParam(POLICY_TUNING_SELECTED_TAB_QUERY_KEY, StringParam);

  const displayWorkloadName = getDisplayWorkloadName(name);

  const [, setSelectedWorkloadOverviewId] = useQueryParam(SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM, StringParam);
  const workloadTypeForView = getWorkloadTypeForView(name, type);
  let workloadTypeForLink = getWorkloadType(type);
  switch (true) {
    case workloadTypeForLink.toLowerCase() === WorkloadType.KedaScaledJob.toLowerCase():
      workloadTypeForLink = SCALEOPS_KEDA_SCALED_JOB_COG_NAME;
      break;
    case workloadTypeForLink.toLowerCase() === WorkloadType.CronJob.toLowerCase():
      workloadTypeForLink = SCALEOPS_CRON_JOB_COG_NAME;
      break;
    default:
      break;
  }
  return (
    <Tooltip
      title={<WorkloadTooltip namespace={namespace} workloadName={name} type={workloadTypeForView} />}
      maxWidth={500}
      className="w-full relative"
    >
      <button
        onClick={() => {
          if (setSelectedWorkload) {
            const link = `${cluster}/${namespace}/${workloadTypeForLink}/${name}`;
            setSelectedWorkload(undefined);
            setSelectedWorkloadOverviewId(link);
            setSelectedTab(targetTab);
          } else {
            const link = `/?selectedWorkloadOverviewId=${cluster}%2F${namespace}%2F${workloadTypeForLink}%2F${name}&policyTuningViewPeriod=168&${POLICY_TUNING_SELECTED_TAB_QUERY_KEY}=${targetTab}`;
            navigate(link);
          }
        }}
        className="w-full inline-block truncate text-start hover:underline hover:text-main-linkBlue"
      >
        {`${namespace}/${displayWorkloadName}`}
      </button>
    </Tooltip>
  );
};

export default WorkloadNameRow;
