import * as React from "react";
import Grid from "@mui/material/Grid";
import PolicyTable from "./PolicyTable";
import * as componentUtils from "../../../components/componentUtils/overview";

export default function PoliciesView() {
  return componentUtils.windowBlock(
    "",
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PolicyTable />
      </Grid>
    </Grid>
  );
}
