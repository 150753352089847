import { useQuery } from "@tanstack/react-query";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../../../api/fetcher";

const dashboardByNamespace = getDashboardByNamespace();

const useGetWorkloadNames = () => {
  const { data, isLoading } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey],
    queryFn: () => dashboardByNamespace.queryFn({}),
  });

  if (isLoading || !data?.workloads) return [];

  const uniqueWorkloadNames = [...new Set(data.workloads.map((workload) => workload?.workloadName))].sort();

  return uniqueWorkloadNames;
};

export default useGetWorkloadNames;
