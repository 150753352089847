import { IconButton, Input } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface AddAggregatorProps {
  newLabelAggregator: string | undefined;
  setNewLabelAggregator: (value: React.SetStateAction<string | undefined>) => void;
  handleAddAggregator: () => void;
}

const AddAggregator = ({ newLabelAggregator, setNewLabelAggregator, handleAddAggregator }: AddAggregatorProps) => {
  return (
    <div className="flex items-end">
      <Input
        className=""
        placeholder={!newLabelAggregator || newLabelAggregator?.length > 0 ? "Label key aggregator " : undefined}
        value={newLabelAggregator}
        onChange={(e) => {
          setNewLabelAggregator(e.target.value);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            handleAddAggregator();
          }
        }}
        sx={{
          border: "1px solid #E5E7ED",
          borderRadius: "0.375rem",
          padding: "5px 5px",
          width: "200px",
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
      <IconButton
        color="primary"
        aria-label="add"
        size={"medium"}
        disabled={!!newLabelAggregator && !(newLabelAggregator.trim()?.length > 0)}
        onClick={handleAddAggregator}
        className="bg-blue-500"
      >
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default AddAggregator;
