import { Typography } from "@mui/material";
import { ArrayParam, BooleanParam, useQueryParam, withDefault } from "use-query-params";
import UnFilterIcon from "../../../../Icons/UnFilterIcon";
import { FilterOptions } from "./hooks/useAggregationFilters";

const ClearAggregationFilters = () => {
  const [clustersParams, setClustersParams] = useQueryParam(FilterOptions.Clusters, ArrayParam);
  const [groupByNamespaceParams, setGroupByNamespaceParams] = useQueryParam(
    FilterOptions.GroupByNamespace,
    withDefault(BooleanParam, true)
  );
  const [multiClusterParams, setMultiClusterParams] = useQueryParam(FilterOptions.MultiCluster, BooleanParam);
  const [shouldIgnoreWorkloadsParams, setShouldIgnoreWorkloadsParams] = useQueryParam(
    FilterOptions.shouldIgnoreWorkloads,
    BooleanParam
  );
  const [tagsParams, setTagsParams] = useQueryParam(FilterOptions.Tags, ArrayParam);
  const [groupByAnnotationsParams, setGroupByAnnotationsParams] = useQueryParam(
    FilterOptions.GroupByAnnotations,
    ArrayParam
  );
  const [groupByLabelsParams, setGroupByLabelsParams] = useQueryParam(FilterOptions.GroupByLabels, ArrayParam);
  const [groupByClusterParams, setGroupByClusterParams] = useQueryParam(FilterOptions.groupByCluster, BooleanParam);

  const clearFilters = () => {
    setClustersParams([]);
    setGroupByNamespaceParams(false);
    setMultiClusterParams(undefined);
    setShouldIgnoreWorkloadsParams(undefined);
    setTagsParams([]);
    setGroupByClusterParams(false);
    setGroupByAnnotationsParams([]);
    setGroupByLabelsParams([]);
  };

  const shouldHideButton =
    !clustersParams?.length &&
    !groupByNamespaceParams &&
    !multiClusterParams &&
    !shouldIgnoreWorkloadsParams &&
    !tagsParams?.length &&
    !groupByAnnotationsParams?.length &&
    !groupByLabelsParams?.length &&
    !groupByClusterParams;

  if (shouldHideButton) return null;

  return (
    <div className="w-fit">
      <Typography
        variant="caption"
        onClick={clearFilters}
        className="hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
      >
        <UnFilterIcon width={15} height={15} />
        <p>Clear all aggregations</p>
      </Typography>
    </div>
  );
};

export default ClearAggregationFilters;
