import { IconProps } from "./utils/utils";

const SearchIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M19.8105 18.912L14.6468 13.8308C15.999 12.3617 16.8298 10.4187 16.8298 8.28074C16.8292 3.70716 13.0621 6.10352e-05 8.41471 6.10352e-05C3.76737 6.10352e-05 0.000244141 3.70716 0.000244141 8.28074C0.000244141 12.8543 3.76737 16.5614 8.41471 16.5614C10.4227 16.5614 12.2644 15.8669 13.711 14.7122L18.8947 19.8134C19.1473 20.0622 19.5573 20.0622 19.8099 19.8134C20.0631 19.5646 20.0631 19.1608 19.8105 18.912ZM8.41471 15.2874C4.48256 15.2874 1.29493 12.1504 1.29493 8.28074C1.29493 4.41107 4.48256 1.27409 8.41471 1.27409C12.3469 1.27409 15.5345 4.41107 15.5345 8.28074C15.5345 12.1504 12.3469 15.2874 8.41471 15.2874Z" />
      </g>
    </svg>
  );
};

export default SearchIcon;
