import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  AttachPolicyToWorkloadParams,
  attachPolicyToWorkload,
  getDashboardByNamespace,
  GetDashboardByNamespaceResponse,
} from "../../api/fetcher";
import { useWorkloadsContext } from "../../WorkloadsContext";
import useFilterQueryParams, { FilterParamObject } from "./useFilterQueryParams";

const useAttachPolicyToWorkload = () => {
  const dashboardByNamespace = getDashboardByNamespace();
  const queryClient = useQueryClient();
  const attachPolicy = attachPolicyToWorkload();
  const { updateOverriddenWorkloads } = useWorkloadsContext();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();

  return useMutation((params: AttachPolicyToWorkloadParams) => attachPolicy.queryFn(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries([dashboardByNamespace.queryKey, filterQueryParams]);

      const previousData = queryClient.getQueryData([dashboardByNamespace.queryKey, filterQueryParams]);

      queryClient.setQueryData(
        [dashboardByNamespace.queryKey, filterQueryParams],
        (oldData: GetDashboardByNamespaceResponse | undefined) => {
          return {
            ...oldData,
            workloads: oldData?.workloads?.map((wl) => {
              if (wl.id === params.id) {
                return { ...wl, policyName: params.name };
              }
              return wl;
            }),
          };
        }
      );

      updateOverriddenWorkloads({
        ids: [params.id],
        props: { policyName: params.name },
      });

      return { previousData };
    },
    onError: (error: Error, params: AttachPolicyToWorkloadParams) => {
      toast.error("Failed to attach policy", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });

      updateOverriddenWorkloads({
        ids: [params.id],
        props: { policyName: params.oldPolicyName },
      });
      console.log(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);

      toast.success("Successfully attached policy", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useAttachPolicyToWorkload;
