/**
 * Types
 */
export type MainMetrics = {
  wastedSpend: number;
  blockedNodes: number;
  totalOptimizedPods: number;
  totalUnOptimizedPods: number;
  totalOptimizedPodsWithUnEvictableWorkloads: number;
  totalUnOptimizedPodsWithUnEvictableWorkloads: number;
  numberOfUnevictableWorkloads: number;
  optimizedPodsWithoutOwner: number;
  unOptimizedPodsWithoutOwner: number;
  notSupportingBinPackingPolicies: string[] | undefined;
};

export enum TabOptions {
  SevenDays = "7 Days",
  thirtyDays = "30 Days",
}

/**
 * Div Const
 */
export const unEvictableWorkloadsInfo = (
  <>
    <b>Unevictable workloads</b> have <b>annotation</b> that prevents autoscaler eviction, or are associated with{" "}
    <b>PDB</b> (Pod Disruption Budgets).
  </>
);

export const blockedNodesInfo = (
  <>
    <b>Blocked nodes</b> have at least one <b>unevictable workloads</b>.
  </>
);
