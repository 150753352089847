import { Typography } from "@mui/material";
import clsx from "clsx";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Icons/SearchIcon";
import Button, { BUTTON_VARIANTS } from "../Button";
import { Action } from "./Utils";

const SEARCH_ICON_SIZE = 12;

export const ActionCell = ({ icon, title, description, exploreWorkloadsLink }: Action) => {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const isHigh = Number(ref.current?.offsetHeight) > 60;

  return (
    <div className="flex gap-3 items-center" ref={ref}>
      <div className="w-[2.8rem] h-[2.8rem] rounded-full bg-primary-purpleBlue flex justify-center items-center">
        {icon}
      </div>
      <div className="relative truncate w-full">
        <Typography variant="h6" fontWeight={700} className={clsx("flex items-center flex-wrap", { "gap-2": !isHigh })}>
          <span>{title}</span>
          {exploreWorkloadsLink && (
            <Button
              variant={BUTTON_VARIANTS.extraSmallPurple}
              label={
                <div className="flex items-center justify-center gap-1">
                  <SearchIcon width={SEARCH_ICON_SIZE} height={SEARCH_ICON_SIZE} />
                  <span>EXPLORE WORKLOADS</span>
                </div>
              }
              onClick={() => navigate(exploreWorkloadsLink)}
            />
          )}
        </Typography>
        <Typography
          variant="body2"
          className={clsx("text-text-lightBlack truncate max-w-[100%]", {
            "pt-1": isHigh,
          })}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};
