import ScaleopsIcon from "../Icons/ScaleopsIcon";

const DEFAULT_ICON_SIZE = 12;

interface Props {
  className?: string;
  iconSize?: number;
}

const ScaleOpsSpan = ({ className, iconSize = DEFAULT_ICON_SIZE }: Props) => (
  <span className="text-guideline-darkBlue inline-flex items-center gap-[2px]">
    <ScaleopsIcon width={iconSize} height={iconSize} />
    <span
      className={className}
      style={{
        fontWeight: 600,
      }}
    >
      ScaleOps
    </span>
  </span>
);

export default ScaleOpsSpan;
