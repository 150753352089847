import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { SCALEOPS_COLORS } from "../colors";
import { IconProps } from "../Icons/utils/utils";
import useWindowSize from "./useWindowSize";

interface ProgressBarProps extends IconProps {
  percentage: number;
  displayValue?: {
    value1: number;
    value2: number;
  };
  mainFill?: string;
  backgroundFill?: string;
  hasTopDisplayNumber?: boolean;
  wrapperClassName?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const {
    percentage = 0,
    displayValue,
    mainFill = SCALEOPS_COLORS.main.blue,
    backgroundFill = SCALEOPS_COLORS.border,
    hasTopDisplayNumber,
    height = 12,
    rx = 5,
    width = "100%",
    wrapperClassName,
    className,
  } = props;

  const [widthPercentage, setWidthPercentage] = useState<number>(0);
  const size = useWindowSize();

  useEffect(() => {
    setWidthPercentage(0);
    setTimeout(() => {
      setWidthPercentage(percentage);
    }, 0);
  }, [size.width, percentage]);

  const maskId = `mask-${Math.round(percentage)}`;

  useEffect(() => {
    let percentageValue = 0;
    switch (true) {
      case percentage < 0:
        percentageValue = 0;
        break;
      case percentage > 100:
        percentageValue = 100;
        break;
      default:
        percentageValue = percentage;
    }

    setWidthPercentage(percentageValue);
  }, [percentage]);

  const spring = useSpring({
    from: { number: 0 },
    number: widthPercentage,
  });

  const value1Spring = useSpring({
    from: { number: 0 },
    number: displayValue?.value1,
  });

  const value2Spring = useSpring({
    from: { number: 0 },
    number: displayValue?.value2,
  });

  return (
    <div>
      <div className={clsx(wrapperClassName, "flex items-center justify-center gap-2")}>
        {hasTopDisplayNumber && (
          <Typography variant="body2">
            <animated.span>
              {spring.number
                .to((n) => n.toFixed(0))
                .to((n) => {
                  return `${n}%`;
                })}
            </animated.span>
          </Typography>
        )}
        <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 100% 100%" {...props}>
          <mask id={`${maskId}`} x="0" y="0" width="100%" height="100%" rx={rx}>
            <animated.rect
              x="0"
              y="0"
              width={spring.number
                .to((n) => n.toFixed(0))
                .to((n) => {
                  return `${n}%`;
                })}
              height={height}
              fill="white"
            />
          </mask>
          <rect width="100%" height="100%" fill={backgroundFill} rx={rx} />
          <rect width="100%" height="100%" mask={`url(#${maskId})`} fill={mainFill} rx={rx} />
        </svg>
      </div>
      {displayValue !== undefined && (
        <div className="w-full flex justify-between">
          <Typography variant="body2">
            <animated.span>
              {spring.number
                .to((n) => n.toFixed(0))
                .to((n) => {
                  return `${n}%`;
                })}
            </animated.span>
          </Typography>
          <div className="flex gap-1">
            <Typography variant="body2">
              <animated.span>
                {value1Spring.number
                  .to((n) => n.toFixed(0))
                  .to((n) => {
                    return `${n}`;
                  })}
              </animated.span>
            </Typography>
            <span className="text-text-darkGray">/</span>
            <Typography variant="body2">
              <animated.span>
                {value2Spring.number
                  .to((n) => n.toFixed(0))
                  .to((n) => {
                    return `${n}`;
                  })}
              </animated.span>
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
