import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetPolicyTuningConfigParams, resetWorkloadRecommendation } from "../../../../api/fetcher";
import { useWorkloadsContext } from "../../../../WorkloadsContext";

type mutationProps = {
  name: string;
  namespace: string;
  id: string;
  dontShowToast?: boolean;
};

const useResetRecommendation = (deleteOverriddenWorkloadsOnSuccess = true) => {
  const queryClient = useQueryClient();
  const workloadRecommendation = resetWorkloadRecommendation();
  const policyTuningConfigParamsKey = GetPolicyTuningConfigParams().queryKey;

  const { deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  return useMutation((params: mutationProps) => workloadRecommendation.queryFn(params), {
    onError: (error: Error) => {
      toast.error("An error occurred while updating workload");
      console.log(`Error: ${error.message}`);
    },
    onSuccess: async (_, values) => {
      deleteOverriddenWorkloadsOnSuccess &&
        deleteOverriddenWorkloadsProperties({
          ids: [values.id],
          propertyNames: ["auto", "isAutomationExcluded", "policyName"],
        });
      await queryClient.invalidateQueries([policyTuningConfigParamsKey]).catch((error) => console.log(error));

      !values.dontShowToast &&
        toast.success("Policy was updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
    },
  });
};

export default useResetRecommendation;
