import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetDashboardByNamespaceResponse,
  UpdatePolicyByCluster,
  UpdatePolicyByClusterParams,
  getDashboardByNamespace,
} from "../../api/fetcher";
import { useMainContext } from "../../MainContext";
import { useWorkloadsContext } from "../../WorkloadsContext";

const updatePolicy = UpdatePolicyByCluster();
const dashboardByNamespace = getDashboardByNamespace();

const useUpdatePolicyByCluster = (disableQueries?: boolean) => {
  const { currentCluster } = useMainContext();
  const queryClient = useQueryClient();
  const { updateOverriddenWorkloads, deleteOverriddenWorkloadsProperties } = useWorkloadsContext();
  const clusterName = currentCluster || "current";

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey],
    queryFn: () => dashboardByNamespace.queryFn({}),
    enabled: disableQueries !== true,
  });

  const allRowsExceptScaleopsSystemIds = (
    data?.workloads?.filter((workload) => !workload.id.includes("/scaleops-system/") && !workload.isPolicyForced) || []
  ).map((workload) => workload.id);
  useMainContext();

  return useMutation((params: UpdatePolicyByClusterParams) => updatePolicy.queryFn(params), {
    onMutate: (values) => {
      updateOverriddenWorkloads({
        ids: allRowsExceptScaleopsSystemIds,
        props: {
          policyName: values.policyName,
        },
      });
    },
    onError: (error: Error, values) => {
      deleteOverriddenWorkloadsProperties({
        ids: allRowsExceptScaleopsSystemIds,
        propertyNames: ["policyName"],
      });
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to update ${values?.policyName} policy on cluster: ${clusterName}`);
    },
    onSuccess: (_, values) => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey]).catch((error) => console.log(error));
      toast.success(`Successfully updated ${values.policyName} policy on cluster: ${clusterName}`);
    },
  });
};

export default useUpdatePolicyByCluster;
