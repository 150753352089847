import {
  VerifySlackChannel,
  VerifySlackChannelInput,
  VerifySlackToken,
  VerifySlackTokenInput,
} from "../../api/fetcher";
import { useMutation } from "@tanstack/react-query";

export type ValidateSlackInput = {
  onError: () => void;
  onSuccess: () => void;
};

export const useValidateSlackChannel = (params: ValidateSlackInput) => {
  const verifySlackToken = VerifySlackChannel();
  return useMutation(
    (params: VerifySlackChannelInput) => {
      return verifySlackToken.queryFn(params);
    },
    {
      onError: () => {
        params.onError && params.onError();
      },
      onSuccess: () => {
        params.onSuccess && params.onSuccess();
      },
    }
  );
};
export const useValidateSlackToken = (params: ValidateSlackInput) => {
  const verifySlackToken = VerifySlackToken();
  return useMutation(
    (params: VerifySlackTokenInput) => {
      return verifySlackToken.queryFn(params);
    },
    {
      onError: () => {
        params.onError && params.onError();
      },
      onSuccess: (data) => {
        data?.ok && params.onSuccess && params.onSuccess();
      },
    }
  );
};

export default useValidateSlackToken;
