import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { GetCostReportWorkloads, GetCostReportWorkloadsResponse } from "../../../../api/fetcher";
import ExportCSV from "../../../../components/exportCSV/ExportCSV";
import { Field } from "../utils";
import useWorkloadsFilters from "./hooks/useWorkloadsFilters";
import { WORKLOAD_ROW } from "./utils";
import { getDisplayWorkloadName } from "../../../../utils/namesUtils";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";

const { queryKey, queryFn } = GetCostReportWorkloads();

type CSVExportType = {
  id: string;
  workloadName: string;
  clusterName: string;
  totalCost: number;
  savingsAvailable: number;
  activeSavings: number;
  spot: number;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
};

const ExportWorkloadsCSV = ({ isGpu }: { isGpu?: boolean }) => {
  const filters = useWorkloadsFilters();

  const [rows, setRows] = useState<WORKLOAD_ROW[]>([]);

  const enableHpaOptimization = useHpaOptimizationEnabled();

  const [searchTerm] = useQueryParam("searchTerms", StringParam);

  const { data, isLoading, isError } = useQuery<GetCostReportWorkloadsResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        gpuWorkloadsOnly: isGpu,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    let sortedData = data
      ? data?.aggregatedWorkloads?.map((wl) => {
          const totalWorkloads = wl.spot + wl.onDemand;

          // Spot %
          let spotPercentage = Math.round((wl.spot / totalWorkloads) * 100);
          if (isNaN(spotPercentage) || spotPercentage < 0 || spotPercentage > 100) spotPercentage = 0;

          // On-demand %
          let onDemandPercentage = Math.round((wl.onDemand / totalWorkloads) * 100);
          if (isNaN(onDemandPercentage) || onDemandPercentage < 0 || onDemandPercentage > 100) onDemandPercentage = 0;

          return {
            ...wl,
            displayWorkloadName: `${wl.namespace}/${wl.workloadName}`,
            spot: spotPercentage,
            onDemand: onDemandPercentage,
            savingsAvailable: enableHpaOptimization ? wl.savingsAvailableWithReplicas : wl.savingsAvailable,
            activeSavings: enableHpaOptimization ? wl.activeSavingsWithReplicas : wl.activeSavings,
          };
        })
      : [];

    // filter by searchTerm
    if (searchTerm) {
      sortedData = sortedData?.filter((wl) => {
        return wl.displayWorkloadName.includes(searchTerm);
      });
    }

    setRows(sortedData);
  }, [data, searchTerm]);

  if (isLoading || isError) {
    return null;
  }

  let columns = [
    Field.workloadName,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    Field.replicas,
  ] as (keyof CSVExportType)[];

  let columnsToSum = [
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.spot,
    Field.cpu,
    Field.memory,
    Field.replicas,
  ] as (keyof CSVExportType)[];

  let columnsToRound = [
    Field.totalCost,
    Field.savingsAvailable,
    Field.activeSavings,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    Field.replicas,
  ] as (keyof CSVExportType)[];

  const customColumnNames = {
    [Field.workloadName]: "Workload",
    [Field.clusterName]: "Cluster",
    [Field.totalCost]: "Total Cost",
    [Field.savingsAvailable]: "Savings Available",
    [Field.activeSavings]: "Active Savings",
    [Field.spot]: "Spot %",
    [Field.onDemand]: "On-demand %",
    [Field.cpu]: "CPU request",
    [Field.memory]: "Memory request",
    [Field.replicas]: "Replicas",
  } as { [key in keyof CSVExportType]?: string };

  if (isGpu) {
    columns = columns.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    columnsToSum = columnsToSum.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    columnsToRound = columnsToRound.filter((column) => column !== Field.savingsAvailable && column !== Field.activeSavings);
    delete customColumnNames[Field.savingsAvailable];
    delete customColumnNames[Field.activeSavings];
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        filename="workloads_cost_report.csv"
        columns={columns}
        data={
          rows.map((row) => ({
            id: row.id,
            workloadName: getDisplayWorkloadName(row.workloadName),
            clusterName: row.clusterName,
            totalCost: row.totalCost,
            savingsAvailable: row.savingsAvailable,
            activeSavings: row.activeSavings,
            spot: row.spot,
            onDemand: row.onDemand,
            ownerCpuRequest: row.ownerCpuRequest,
            ownerMemoryRequest: row.ownerMemoryRequest,
            replicas: row.replicas,
          })) as CSVExportType[]
        }
        columnsToSum={columnsToSum}
        columnsToRound={columnsToRound}
        columnsToAverage={[Field.spot, Field.onDemand]}
        customColumnNames={customColumnNames}
      />
    </div>
  );
};

export default ExportWorkloadsCSV;
