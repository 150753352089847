import * as Yup from "yup";

export const nullablePositivePercentileValidation = Yup.number()
  .min(0, "Must be 0 or greater")
  .max(100, "Must be 100 or less")
  .nullable();

export const nullablePositiveLimitValidation = Yup.number()
  .min(0, "Must be 0 or greater")
  .max(10000, "Must be 10K or less")
  .nullable();

export const cronExpressionValidation = Yup.string().matches(
  // eslint-disable-next-line no-useless-escape
  /^((\*(\/[0-9]+)?)|[0-9\-\,\/]+)\s((\*(\/[0-9]+)?)|[0-9\-\,\/]+)\s((\*(\/[0-9]+)?)|[0-9\-\,\/]+)\s((\*(\/[0-9]+)?)|[0-9\-\,\/]+)\s((\*(\/[0-9]+)?)|[0-9\-\,\/]+)$/,
  {
    message: "Enter a valid cron expression.",
  }
);

export const nullablePositiveNumberValidation = Yup.number()
  .min(1, "Must be 1 or greater")
  .max(10000, "Must be 10K or less")
  .nullable();
