export enum WorkloadType {
  AlgoRollout = "argo-rollout",
  GenericType = "Custom-Workload",
  Family = "Family",
  CronJob = "CronJob",
  KedaScaledJob = "KedaScaledJob",
  Rollout = "Rollout",
}

export const SCALEOPS_CRON_JOB_COG_NAME = "cronjob";

export const SCALEOPS_KEDA_SCALED_JOB_COG_NAME = "kedascaledjob";

export const SCALEOPS_ROLLOUT = "scaleops-rollout";

export enum ScaleOpsProduct {
  Rightsize = "rightsize",
  HPA = "HPA",
  All = "all",
}

export const ADMIN_ROLE = "Admin";

export const getWorkloadType = (name?: string, type?: string, lowerCase = true) => {
  if (!name || !type) {
    return undefined;
  }
  const isScaleOpsRollout = name.startsWith(SCALEOPS_ROLLOUT);
  const lowerCaseType = type.toLowerCase();
  switch (true) {
    case lowerCaseType === SCALEOPS_KEDA_SCALED_JOB_COG_NAME:
      return lowerCase ? WorkloadType.KedaScaledJob.toLowerCase() : WorkloadType.KedaScaledJob;
    case lowerCaseType === SCALEOPS_CRON_JOB_COG_NAME:
      return lowerCase ? WorkloadType.CronJob.toLowerCase() : WorkloadType.CronJob;
    case lowerCaseType === WorkloadType.Family.toLowerCase() && isScaleOpsRollout:
      return lowerCase ? WorkloadType.Rollout.toLowerCase() : WorkloadType.Rollout;
    case lowerCaseType === WorkloadType.Family.toLowerCase() && !isScaleOpsRollout:
      return lowerCase ? WorkloadType.GenericType.toLowerCase() : WorkloadType.GenericType;
    default:
      return type;
  }
};

export const getWorkloadTypeDisplayValue = (type?: string): string => {
  const lowerCaseType = type?.toLowerCase();
  switch (lowerCaseType) {
    case WorkloadType.AlgoRollout.toLowerCase():
      return WorkloadType.Rollout;
    case WorkloadType.Family.toLowerCase():
      return WorkloadType.GenericType;
    default:
      return type ?? "";
  }
};
