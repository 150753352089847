import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetRebalanceStatus, GetRebalanceStatusResponse, StopNodeRebalance } from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../../pages/Roles/mutations/utils";
import { ConsolidationState } from "./utils";

const stopNodeRebalance = StopNodeRebalance();
const getRebalanceStatus = GetRebalanceStatus();

const useStopNodeConsolidation = () => {
  const queryClient = useQueryClient();
  return useMutation(() => stopNodeRebalance.queryFn(), {
    onMutate: () => {
      queryClient.setQueryData([getRebalanceStatus.queryKey], (oldData: GetRebalanceStatusResponse | undefined) => {
        return {
          ...oldData,
          status: {
            ...oldData?.status,
            state: ConsolidationState.Canceled,
          },
        };
      });
    },
    onError: () => {
      toast.error(`Error stopping consolidation`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Consolidation stopped", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getRebalanceStatus.queryKey]);
    },
  });
};

export default useStopNodeConsolidation;
