import clsx from "clsx";
import React from "react";
import AsteriskIcon from "../Icons/AsteriskIcon";
import InfoIcon from "../Icons/InfoIcon";

const ICON_SIZE = 8;

interface Props {
  content: React.ReactNode;
  iconType?: "default" | "info";
  classNames?: string;
}

const CustomListItem = ({ content, iconType = "default", classNames }: Props) => {
  let icon: React.ReactNode;

  switch (iconType) {
    case "info":
      icon = <InfoIcon />;
      break;
    default:
      icon = <AsteriskIcon />;
  }

  const displayIcon = React.cloneElement(icon || <AsteriskIcon />, {
    className: clsx(classNames, "mt-[3.5px]"),
    width: ICON_SIZE,
    height: ICON_SIZE,
  });

  return (
    <li className="flex items-start gap-1">
      <div>{displayIcon}</div>
      <span>{content}</span>
    </li>
  );
};

export default CustomListItem;
