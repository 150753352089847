import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import clsx from "clsx";
import { useState } from "react";

const ARROW_ICON_SIZE = 12;

interface Props {
  title: React.ReactNode;
  items: React.ReactNode[];
  titleClassName?: string;
}

const ExpendedDiskList = ({ title, items, titleClassName }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const content = items.map((item, index) => (
    <li key={index} className="">
      {item}
    </li>
  ));

  return (
    <>
      <span
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer gap-1 items-start w-fit underline rounded-lg"
      >
        <span className={clsx(titleClassName)}>{title}</span>
        <div>
          {isOpen ? (
            <KeyboardArrowUp
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          )}
        </div>
      </span>
      <ul
        style={{
          maxHeight: isOpen ? "100vh" : 0,
          overflow: "hidden",
          transition: isOpen ? "max-height 0.3s ease-in" : undefined,
        }}
        className="list-disc pl-5"
      >
        {isOpen ? content : null}
      </ul>
    </>
  );
};

export default ExpendedDiskList;
