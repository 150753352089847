import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Roboto, sans-serif, Helvetica, "Bai Jamjuree", Arial, Helvetica, sans-serif',
  },
  palette: {
    mode: "light",
    primary: {
      main: "#18135B",
    },
    info: {
      main: "#5353E0",
    },
    secondary: {
      main: "#2E2599",
    },
    success: {
      main: "#10AE6E",
    },
    error: {
      main: "#F7426F",
    },
    warning: {
      main: "#ed8702",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "success",
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        },
        columnHeaders: {
          backgroundColor: "#ffffff",
        },
        row: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

export default theme;
