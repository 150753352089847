import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { components } from "../../../api/schema";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import ScaleOpsSpan from "../../ScaleOpsSpan";
import Tooltip from "../../Tooltip";
import ValueChip from "../../ValueChip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import {
  getPercentageOfOptimizedReplicas,
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

export default function MemoryDiffColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;

  const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
  const memoryRequestsDisplayValue = prettyBytes(params.row.memRequests || 0.0, {
    bits: false,
    binary: true,
  });

  const recommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
    bits: false,
    binary: true,
  });

  let memoryDiff = (params.row.ownerMemoryRequests - params.row.memRecommended) * (params.row?.optimizedReplicas ?? 0);

  memoryDiff = Number(memoryDiff);

  if (Number.isNaN(memoryDiff)) memoryDiff = 0;

  const shouldValueChip =
    params.row.namespace != "scaleops-system" && params.row.auto && !Number.isNaN(memoryDiff) && memoryDiff >= 1;

  const valueChipTooltipContent = shouldValueChip ? (
    <>
      <ScaleOpsSpan className="mt-[4px]" /> has saved{" "}
      <b className="text-guideline-darkGreen">
        {prettyBytes(Math.abs(memoryDiff), {
          bits: false,
          binary: true,
        })}
      </b>{" "}
      of memory for this workload.
    </>
  ) : undefined;

  const valueChip = (
    <ValueChip
      value={memoryDiff}
      renderValue={(value) =>
        prettyBytes(Math.abs(value), {
          bits: false,
          binary: true,
        })
      }
      hide={!shouldValueChip}
      tooltipContent={valueChipTooltipContent}
    />
  );

  return (
    <div className="fullCellTooltipContent">
      <Tooltip
        title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
        maxWidth={500}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        disabled={shouldValueChip}
      >
        <div className="flex justify-center items-center gap-[.875rem]">
          <Typography
            variant="body2"
            fontWeight={500}
            className={clsx({
              "text-main-red":
                !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                params.row.memRecommended > params.row.memRequests * 1.1,
            })}
          >
            {percentageOfOptimizedReplicas > 50 && params.row.auto
              ? recommendedDisplayValue
              : memoryRequestsDisplayValue}
          </Typography>
          {params.row.isReadyRecommendation == true && (
            <>
              <RightArrowIcon width={10} height={10} />
              <Typography variant="body2" fontWeight={500} className="text-guideline-darkGreen">
                {recommendedDisplayValue}
              </Typography>
            </>
          )}
        </div>
        <div className="absolute bottom-1.5">{valueChip}</div>
      </Tooltip>
    </div>
  );
}
