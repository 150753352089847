import { useEffect } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

export enum OptimizedTypes {
  CPU = "cpu",
  Memory = "memory",
  CPUAndMemory = "cpuAndMemory",
  CPUOrMemory = "cpuOrMemory",
}

export enum CostOptimizedTypes {
  overProvisionedCPU = "Cost optimized CPU",
  overProvisionedMemory = "Cost optimized Memory",
}

export enum PerformanceOptimizedTypes {
  underProvisionedCpu = "Performance optimized CPU",
  underProvisionedMemory = "Performance optimized Memory",
}

const useOptimizedFilter = () => {
  const [optimizedCostFilter, setOptimizedCostFilter] = useQueryParam("optimizedCostFilter", ArrayParam);
  const [optimizedPerformanceFilter, setOptimizedPerformanceFilter] = useQueryParam(
    "optimizedPerformanceFilter",
    ArrayParam
  );
  const [overProvisionedWRT, setOverProvisionedWRT] = useQueryParam("overProvisionedWRTOrigin", StringParam);
  const [underProvisionedWRT, setUnderProvisionedWRT] = useQueryParam("underProvisionedWRTOrigin", StringParam);

  useEffect(() => {
    /**
     * Set optimized cost
     */

    let optimizedCostFilterValue: OptimizedTypes | undefined;

    switch (true) {
      case optimizedCostFilter?.includes(CostOptimizedTypes.overProvisionedCPU) && optimizedCostFilter.length === 1:
        optimizedCostFilterValue = OptimizedTypes.CPU;
        break;
      case optimizedCostFilter?.includes(CostOptimizedTypes.overProvisionedMemory) && optimizedCostFilter.length === 1:
        optimizedCostFilterValue = OptimizedTypes.Memory;
        break;
      case !!optimizedCostFilter?.length:
        optimizedCostFilterValue = OptimizedTypes.CPUOrMemory;
        break;
      default:
        break;
    }
    setOverProvisionedWRT(optimizedCostFilterValue);

    /**
     * Set Under Provision Filter V2
     */

    let optimizedPerformanceFilterValue: OptimizedTypes | undefined;

    switch (true) {
      case optimizedPerformanceFilter?.includes(PerformanceOptimizedTypes.underProvisionedCpu) &&
        optimizedPerformanceFilter.length === 1:
        optimizedPerformanceFilterValue = OptimizedTypes.CPU;
        break;
      case optimizedPerformanceFilter?.includes(PerformanceOptimizedTypes.underProvisionedMemory) &&
        optimizedPerformanceFilter.length === 1:
        optimizedPerformanceFilterValue = OptimizedTypes.Memory;
        break;
      case !!optimizedPerformanceFilter?.length:
        optimizedPerformanceFilterValue = OptimizedTypes.CPUOrMemory;
        break;
      default:
        break;
    }

    setUnderProvisionedWRT(optimizedPerformanceFilterValue);
  }, [optimizedCostFilter, optimizedPerformanceFilter]);

  return {
    overProvisionedWRT,
    underProvisionedWRT,
    optimizedPerformanceFilter,
    optimizedCostFilter,
    setOptimizedCostFilter,
    setOptimizedPerformanceFilter,
  };
};

export default useOptimizedFilter;
