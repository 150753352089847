import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@tanstack/react-query";
import { StringParam, useQueryParam } from "use-query-params";
import { GetNodeCaLogs, GetNodeCaLogsResponse } from "../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../colors";
import LogIcon from "../../../Icons/LogIcon";
import RefreshIcon from "../../../Icons/RefreshIcon";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";
import Button from "../../Button";
import Input from "../../Input";

type LogLine = {
  date: string;
  info: string;
};

const parseKarpenterLogs = (karpenterLogs: { [p: string]: string[] | undefined }): LogLine[] => {
  const logs = Object.values(karpenterLogs).flat() as string[];
  return logs.map((log) => {
    const logJson = JSON.parse(log) as { time: string; message: string };
    return { date: adjustedDayjs(logJson.time).format("YYYY-MM-DD HH:mm:ss.SSS"), info: logJson.message };
  });
};

const parseKubeCaLogs = (kubeCaLogs: { [p: string]: string[] | undefined }): LogLine[] => {
  const logs = Object.values(kubeCaLogs).flat() as string[];
  return logs.map((log) => {
    const [, rest] = log.split(/ (.+)/);
    const [date, rest2] = rest.split(/ {7}(.+)/);
    const [, info] = rest2.split(/ (.+)/)[1].split(/ (.+)/);
    return { date, info };
  });
};

const filterLogs = (logs: LogLine[], searchTerm: string) => {
  return logs.filter((log) => {
    return log.info.includes(searchTerm) || log.date.includes(searchTerm);
  });
};

const sortLogs = (logs: LogLine[]) => {
  return logs.sort((a, b) => {
    return b.date > a.date ? 1 : -1;
  });
};

interface Props {
  selectedNodeName: string | undefined;
  minHeight?: number;
}

const Logs = ({ selectedNodeName, minHeight }: Props) => {
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerm", StringParam);

  const { queryFn, queryKey } = GetNodeCaLogs();
  const { data, isLoading, refetch } = useQuery<GetNodeCaLogsResponse, Error>({
    queryKey: [queryKey, selectedNodeName],
    queryFn: () => queryFn(selectedNodeName ?? ""),
    enabled: !!selectedNodeName,
  });

  const response: GetNodeCaLogsResponse = (data as GetNodeCaLogsResponse) || {};
  let caName = "";
  let logs: LogLine[] = [];
  if (response?.karpenterLogs) {
    caName = "Karpenter";
    logs = parseKarpenterLogs(response.karpenterLogs);
  }
  if (response?.kubeCaLogs) {
    caName = "Cluster Autoscaler (default)";
    logs = parseKubeCaLogs(response.kubeCaLogs);
  }
  logs = filterLogs(logs, searchTerm ?? "");
  logs = sortLogs(logs);

  return (
    <div style={{ minHeight: minHeight }} className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <LogIcon width={30} height={30} />
        <Typography variant="body2">
          <b>Cluster Autoscaler Logs</b>
          <p>Explore detailed log data to monitor scaling events and troubleshoot issues</p>
        </Typography>
        <div className={"flex gap-3 ml-auto items-center"}>
          <Button
            label={
              <div className={"flex text-sm gap-2 items-center"}>
                <RefreshIcon width={16} height={16} />
                Refresh
              </div>
            }
            onClick={() => {
              refetch();
            }}
          />
          <Input
            className="min-w-[150px]"
            borderColor={SCALEOPS_COLORS.black}
            placeholder="search..."
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm ?? ""}
          />
        </div>
      </div>
      {isLoading && (
        <div className="flex m-auto items-center justify-center h-full w-full">
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <div className={"border border-border rounded p-4"}>
          <code className="font-bold text-sm">{caName}</code>
          <code className={"table"}>
            {!logs.length && <Typography variant="body2">No logs found</Typography>}
            {logs.map((line, index) => {
              return (
                <div key={index} className={"table-row"}>
                  <div className={"italic whitespace-nowrap text-sm table-cell pr-5"}>{line.date}</div>
                  <div className={"table-cell text-sm"}>{line.info}</div>
                </div>
              );
            })}
          </code>
        </div>
      )}
    </div>
  );
};

export default Logs;
