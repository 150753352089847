import { Typography } from "@mui/material";

const AutomateYourFirstWorkloadTooltip = () => {
  return (
    <div className="relative">
      <Typography variant="caption" fontWeight={600}>
        <span>Automate</span> your first workload
      </Typography>
      <p>Click the button to automate your first workload</p>
    </div>
  );
};

export default AutomateYourFirstWorkloadTooltip;
