import { Typography } from "@mui/material";
import Tooltip from "../Tooltip";

interface Props {
  title: string;
  subtitle?: string;
  tooltipContent?: React.ReactNode;
}

const CustomHeader: React.FC<Props> = ({ title, subtitle, tooltipContent }) => (
  <div className="flex flex-col items-center">
    <Tooltip title={tooltipContent} disabled={!tooltipContent}>
      <Typography className="truncate" variant="body2" fontWeight={700}>
        {title}
      </Typography>
      <Typography className="truncate" variant="caption">
        {subtitle}
      </Typography>
    </Tooltip>
  </div>
);

export default CustomHeader;
