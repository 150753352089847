import { IconProps } from "./utils/utils";

const CheckedIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M8.92381 16.2488C8.85677 16.2488 8.79089 16.2313 8.73271 16.198C8.67452 16.1647 8.62606 16.1167 8.59212 16.0589C7.31416 13.8817 3.90712 9.24581 3.87289 9.19923C3.81816 9.12483 3.79203 9.03321 3.79928 8.94113C3.80654 8.84905 3.84668 8.76265 3.91239 8.69773L4.96004 7.66242C5.02403 7.5992 5.10815 7.5604 5.19778 7.55278C5.28741 7.54515 5.37688 7.56918 5.45062 7.62069L8.84377 9.98992C11.1143 7.07839 13.2234 5.06685 14.6109 3.87989C16.1731 2.54381 17.1675 1.94231 17.2091 1.91754C17.2689 1.88168 17.3373 1.86273 17.407 1.86273H19.1019C19.1799 1.86273 19.256 1.88642 19.3202 1.93067C19.3844 1.97492 19.4337 2.03764 19.4614 2.11051C19.4891 2.18338 19.4941 2.26297 19.4756 2.33871C19.457 2.41445 19.4159 2.48277 19.3577 2.53462C14.5445 6.82169 9.31031 15.9627 9.25801 16.0545C9.22461 16.1132 9.17638 16.162 9.11816 16.1961C9.05994 16.2302 8.99378 16.2483 8.92631 16.2488L8.92381 16.2488Z" />
      <path d="M8.85 17.8512C5.03262 17.8512 1.92693 14.7456 1.92693 10.9281C1.92693 7.11072 5.03262 4.00507 8.85 4.00507C9.61542 4.00422 10.3757 4.13056 11.0997 4.37892C11.1961 4.41207 11.2755 4.48219 11.3203 4.57385C11.365 4.66551 11.3716 4.77121 11.3384 4.86769C11.3053 4.96416 11.2352 5.04352 11.1435 5.0883C11.0518 5.13308 10.9461 5.13961 10.8497 5.10645C10.206 4.88573 9.53012 4.77348 8.84966 4.7743C5.45639 4.7743 2.69581 7.53492 2.69581 10.9281C2.69581 14.3214 5.45639 17.082 8.84966 17.082C12.2429 17.082 15.0035 14.3214 15.0035 10.9281C15.0036 10.5118 14.9618 10.0965 14.8787 9.68849C14.8685 9.63899 14.8683 9.58799 14.8779 9.53839C14.8875 9.48879 14.9067 9.44156 14.9346 9.39941C14.9624 9.35726 14.9983 9.321 15.0402 9.29272C15.082 9.26443 15.1291 9.24466 15.1786 9.23455C15.2281 9.22444 15.2791 9.22417 15.3287 9.23377C15.3783 9.24337 15.4255 9.26264 15.4676 9.29049C15.5098 9.31834 15.5461 9.35422 15.5743 9.39607C15.6026 9.43793 15.6224 9.48496 15.6325 9.53445C15.726 9.99313 15.773 10.46 15.7729 10.9281C15.7731 14.7456 12.6674 17.8512 8.85 17.8512Z" />
    </svg>
  );
};

export default CheckedIcon;
