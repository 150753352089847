import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";

export default function GpuRequestColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  const gpuRequest = params.row.ownerGpuRequests;

  if (gpuRequest === 0) return <>-</>;

  // Pluralize doesn't work well here because it pluralizes "GPU" to "GPUS" instead of "GPUs"
  const label = gpuRequest > 1 ? "GPUs" : "GPU";

  return (
    <>
      {gpuRequest} {label}
    </>
  );
}
