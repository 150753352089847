export enum FileExtension {
  YAML = "yaml",
  JSON = "json",
  TEXT = "text",
}

interface ExportFileProps {
  fileString: string;
  fileName: string;
  extension?: FileExtension;
  type?: string;
}

export const exportStringAsFile = ({
  fileString,
  fileName,
  extension = FileExtension.YAML,
  type = "text/plain",
}: ExportFileProps) => {
  const fileData = fileString;
  const blob = new Blob([fileData], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `${fileName}.${extension}`;
  link.href = url;
  link.click();
};
