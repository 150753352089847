import { ArrayParam, BooleanParam, useQueryParam } from "use-query-params";
import {
  AGGREGATION_ANNOTATION_QUERY_KEY,
  AGGREGATION_LABEL_QUERY_KEY,
  AGGREGATION_NAMESPACES_QUERY_KEY,
} from "./utils";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";

const SelectedFiltersChips = () => {
  // aggregateByNamespaces
  const [aggregatedNamespaces, setAggregatedNamespaces] = useQueryParam(AGGREGATION_NAMESPACES_QUERY_KEY, BooleanParam);
  const [aggregatedLabels, setAggregatedLabels] = useQueryParam(AGGREGATION_LABEL_QUERY_KEY, ArrayParam);
  const [aggregatedAnnotations, setAggregatedAnnotations] = useQueryParam(AGGREGATION_ANNOTATION_QUERY_KEY, ArrayParam);

  return (
    <div className={"flex gap-2 flex-wrap mb-1.5"}>
      {aggregatedNamespaces && (
        <CustomSelectedFilterChip
          label={"namespaces"}
          onClick={() => {
            setAggregatedNamespaces(!aggregatedNamespaces);
          }}
        />
      )}
      {aggregatedLabels?.map((label) => (
        <CustomSelectedFilterChip
          label={label ?? ""}
          onClick={() => {
            setAggregatedLabels(aggregatedLabels?.filter((item) => item !== label));
          }}
          hasTooltip
          tooltipContent={<>Label: {label}</>}
          className="max-w-[250px] truncate"
        />
      ))}
      {aggregatedAnnotations?.map((annotation) => (
        <CustomSelectedFilterChip
          label={annotation ?? ""}
          onClick={() => {
            setAggregatedAnnotations(aggregatedAnnotations?.filter((item) => item !== annotation));
          }}
          hasTooltip
          tooltipContent={<>Annotation: {annotation}</>}
          className="max-w-[250px] truncate"
        />
      ))}
    </div>
  );
};

export default SelectedFiltersChips;
