import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import StaticExportButton from "../../../../../components/exportCSV/StaticExportButton";
import ExportNetworkWorkloadsCSV from "./ExportNetworkWorkloadsCSV";
import ExportNetworkAggregationCSV from "./ExportNetworkAggregationCSV";

const DEFAULT_POSITION_ID = "export-csv-position-id";
const MENU_ITEM_STYLE = {
  fontSize: "12px",
};

interface Props {
  positionId?: string;
}
const ExportNetworkCSVMenu = ({ positionId = DEFAULT_POSITION_ID }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id={positionId}
        className="w-fit"
        aria-controls={open ? positionId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <StaticExportButton />
      </div>
      <Menu
        id={positionId}
        aria-labelledby={positionId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem style={MENU_ITEM_STYLE}>
          <ExportNetworkWorkloadsCSV />
        </MenuItem>
        <MenuItem style={MENU_ITEM_STYLE}>
          <ExportNetworkAggregationCSV />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportNetworkCSVMenu;
