import { Typography } from "@mui/material";
import clsx from "clsx";
import IncreaseArrowIcon from "../Icons/IncreaseArrowIcon";
import DecreaseArrowIcon from "../Icons/DecreaseArrowIcon";
import Tooltip from "./Tooltip";

const ICON_PROPS = {
  width: 10,
  height: 20,
};

interface Props {
  value: number;
  renderValue?: (value: number) => React.ReactNode;
  hide?: boolean;
  className?: string;
  tooltipContent?: React.ReactNode;
}

const ValueChip = ({ value, renderValue, hide, className, tooltipContent }: Props) => {
  if (hide) {
    return null;
  }

  return (
    <Tooltip title={tooltipContent} disabled={!tooltipContent} maxWidth={500}>
      <Typography
        variant="caption"
        className={clsx(
          className,
          "flex justify-center items-center h-[16px] px-2 text-white bg-main-green rounded-full gap-[2px]"
        )}
      >
        {value >= 0 ? (
          <DecreaseArrowIcon width={ICON_PROPS.width} height={ICON_PROPS.height} />
        ) : (
          <IncreaseArrowIcon width={ICON_PROPS.width} height={ICON_PROPS.height} />
        )}
        {renderValue ? renderValue(value) : value}
      </Typography>
    </Tooltip>
  );
};

export default ValueChip;
