import { IconProps } from "./utils/utils";

const DownIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M2.56003 5.64C2.72403 5.64 2.88803 5.702 3.01303 5.827L10 12.815L16.987 5.828C17.237 5.578 17.642 5.578 17.892 5.828C18.142 6.078 18.142 6.483 17.892 6.733L10.452 14.173C10.202 14.423 9.79703 14.423 9.54703 14.173L2.10703 6.733C1.85703 6.483 1.85703 6.078 2.10703 5.828C2.23203 5.702 2.39603 5.64 2.56003 5.64Z" />
    </svg>
  );
};

export default DownIcon;
