import FormInput from "../../../components/formComponents/FormInput";
import FormSelectCornDaysAndTime from "../../../components/formComponents/FormSelectCornDaysAndTime";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import HistoryIcon from "../../../Icons/HistoryIcon";
import { shouldDisableDefaultPolicyInputs } from "../utils";

const HAS_DEBUGGING_INPUTS = false;
interface Props {
  isCustomizedPolicy?: boolean;
}

const AllowRolloutPeriod = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 pt-10 pb-5 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Allowed rollout period"
        description={
          <>
            Determine when ScaleOps allows to optimize workloads.
            <br />
            <b className="text-[12px] flex items-center gap-1 mt-1">
              <HistoryIcon width={14} height={14} /> UTC time zone
            </b>
          </>
        }
        info={
          <div className="flex flex-col gap-2">
            <span>
              By <b>default</b>, ScaleOps allows to optimize workloads during <b>the whole week</b>.
            </span>
            <span>
              If you want to customize the allowed rollout period, <b>select the days and time below</b>.
            </span>
          </div>
        }
        tooltipMaxWidth={350}
      />
      <FormSelectCornDaysAndTime
        name="evictionScheduleScaleDown"
        namesToUpdateOnChange={["evictionScheduleScaleDown", "evictionScheduleScaleUp"]}
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
      />
      <div>
        {HAS_DEBUGGING_INPUTS && (
          <div className="w-full mt-5">
            <div>--- --- ---</div>
            <div className="flex gap-4">
              <FormInput
                label="Scale Down (m h d m d)"
                name="evictionScheduleScaleDown"
                type="string"
                disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
                disableChange={!isCustomizedPolicy}
              />
              <FormInput
                label="Scale Up (m h d m d)"
                name="evictionScheduleScaleUp"
                type="string"
                disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
                disableChange={!isCustomizedPolicy}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllowRolloutPeriod;
