import numeral from "numeral";

const CustomizedBarLabel = ({
  x,
  y,
  yAdjust = 20,
  width,
  value,
  fill,
  prefix,
  color,
  fontSize = 12,
}: {
  x: number;
  y: number;
  yAdjust?: number;
  width: number;
  value: number;
  fill: string;
  prefix?: string;
  color?: string;
  fontSize?: number;
}) => {
  const displayValue = value > 1000 ? numeral(value).format("0,0.[0]a") : value;

  return (
    <text x={x + width + 5} y={y + yAdjust} fill={color ?? fill} fontSize={fontSize} fontWeight={400}>
      {prefix}
      {displayValue}
    </text>
  );
};

export default CustomizedBarLabel;
