import { DialogTitle as MuiDialogTitle, Typography } from "@mui/material";

interface Props {
  title: React.ReactNode;
}

const CenterDialogTitle = ({ title }: Props) => {
  return (
    <MuiDialogTitle className="bg-text-lightBlack text-white min-h-[3.5rem] flex items-center">
      <Typography variant="body2" className="w-full">
        {title}
      </Typography>
    </MuiDialogTitle>
  );
};

export default CenterDialogTitle;
