import clsx from "clsx";
import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GetExistingIngress, GetIngressesResponse } from "../../api/fetcher";
import React, { useEffect, useState } from "react";
import SingleSelect from "../../components/SingleSelect";
import Button from "../../components/Button";
import { FixedSizeList as List } from "react-window";
import useCreateIngress from "./mutations/useCreateIngress";
import { components } from "../../api/schema";
import useDeleteIngress from "./mutations/useDeleteIngress";
import Link from "@mui/material/Link";
import { ScaleOps } from "../../api/api";
import { INGRESS_PROGRESS } from "./mutations/utils";

const WRAPPER_CLASS_NAME = "pt-2 pb-10";
const MAX_LIST_HEIGHT = 500;
const LIST_SIZE = 33;
const PING_ERROR_LIMIT = 3;

const { queryKey, queryFn } = GetExistingIngress();

interface RowProps {
  id: string;
  name: string;
  domain: string;
  namespace: string;
  style: React.CSSProperties;
  setSelectedDomain: (id: string) => void;
  selectedDomain: string | undefined;
}
const Row = ({ id, name, domain, namespace, style, setSelectedDomain, selectedDomain }: RowProps) => {
  return (
    <MenuItem
      value={id}
      key={id}
      sx={{
        ...style,
        backgroundColor: selectedDomain === id ? "rgba(0, 0, 0, 0.04)" : "inherit",
        "& .MuiListItemText-primary": {
          fontSize: 14,
        },
      }}
      onClick={() => {
        setSelectedDomain(id);
      }}
    >
      <ListItemText
        className="text-black w-full"
        primary={
          <div className="flex gap-2 items-center">
            <p>{domain}</p>
            <p>{namespace}</p>
            <p>{name}</p>
          </div>
        }
      />
    </MenuItem>
  );
};

const IngressContainer = () => {
  const [selectedDomain, setSelectedDomain] = useState<string | undefined>(undefined);
  const [selectedIngress, setSelectedSelectedIngress] = useState<components["schemas"]["SettingsIngress"] | undefined>(
    undefined
  );
  const [scaleopsURL, setScaleopsURL] = useState<string | undefined>(undefined);
  const [workingScaleopsURL, setWorkingScaleopsURL] = useState<string | undefined>(undefined);
  const [ingressIsBeingDeleted, setIngressIsBeingDeleted] = useState<boolean>(false);
  const [ingressCheckTimer, setIngressCheckTimer] = useState<NodeJS.Timer | undefined>();
  const [ingressStatus, setIngressStatus] = useState<INGRESS_PROGRESS>(INGRESS_PROGRESS.NONE);
  const [, setPingErrorCount] = useState<number>(0);
  const { data, isError, error, isLoading } = useQuery<GetIngressesResponse>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
    refetchInterval: ingressIsBeingDeleted ? 500 : undefined,
  });

  const createIngress = useCreateIngress({ setScaleopsURL, setIngressStatus });
  const deleteIngress = useDeleteIngress({ setIngressStatus });

  useEffect(() => {
    console.log("selectedDomain", selectedDomain);
    const si = data?.ingresses?.find((ingress) => ingress.id === selectedDomain);
    if (si) {
      setSelectedSelectedIngress(si);
      if (si.statusReady) {
        setIngressStatus(INGRESS_PROGRESS.READY_IN_KUBERNETES);
      }
    }

    setIngressIsBeingDeleted(data?.existingIsBeingDeleted ?? false);
  }, [selectedDomain, data]);

  useEffect(() => {
    if (data) {
      setScaleopsURL(data?.scaleOpsURL);
      if (selectedDomain === undefined) {
        data.suggestedDomain && setSelectedDomain(data.suggestedDomain);
      }
    }
  }, [data]);

  useEffect(() => {
    let timer: NodeJS.Timer | undefined;
    if (
      workingScaleopsURL !== scaleopsURL &&
      scaleopsURL !== undefined &&
      ingressStatus !== INGRESS_PROGRESS.READY &&
      ingressStatus !== INGRESS_PROGRESS.COULD_NOT_PING_INGRESS
    ) {
      timer = setInterval(() => {
        setIngressStatus(INGRESS_PROGRESS.PINGING);
        const api = ScaleOps();
        const f = api.getFetcher();
        f.configure({ baseUrl: scaleopsURL });
        f.path("/conf")
          .method("get")
          .create()({})
          .then((response) => {
            if (response?.ok) {
              setIngressStatus(INGRESS_PROGRESS.READY);
              setWorkingScaleopsURL(scaleopsURL);
            }
          })
          .catch((error: { reason?: { data?: { redirectURL?: string } } }) => {
            console.error("Failed to check ingress", error);
            setPingErrorCount((s) => {
              if (s > PING_ERROR_LIMIT) {
                setIngressStatus(INGRESS_PROGRESS.COULD_NOT_PING_INGRESS);
              }
              return s + 1;
            });
            if (error.reason?.data?.redirectURL) {
              setWorkingScaleopsURL(scaleopsURL);
              setIngressStatus(INGRESS_PROGRESS.READY);
            }
          });
      }, 500);
      setIngressCheckTimer(timer);
    } else {
      clearInterval(ingressCheckTimer);
    }

    return () => clearInterval(timer);
  }, [scaleopsURL, workingScaleopsURL, ingressStatus]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {JSON.stringify(error)}</div>;
  }

  return (
    <div className={clsx(WRAPPER_CLASS_NAME, "flex flex-col gap-2")}>
      <div className="grow">
        <Typography variant="body1" fontWeight={700}>
          Ingress
        </Typography>
        <Typography variant="body2">Configure Your Ingress To ScaleOps</Typography>
      </div>
      <div className="max-w-[1000px]">
        <div className="flex flex-col gap-2">
          {/*Wide auto complete with selected domain from data*/}
          <SingleSelect<string | undefined>
            key={selectedDomain}
            label="Select Domain To Use For ScaleOps"
            isSearchable={true}
            selected={selectedDomain}
            setSelected={(domain) => {
              console.log("Domains: ", domain);
              setSelectedDomain(domain);
            }}
            renderValue={(value) => {
              return data?.ingresses?.find((ingress) => ingress.id === value)?.domain;
            }}
            renderOptionsFunc={(search) => {
              const optionsData = data?.ingresses ?? [];
              const filteredClusters = optionsData.filter((entity) => {
                return (
                  entity.name?.includes(search ?? "") ||
                  entity.namespace?.includes(search ?? "") ||
                  entity.domain.includes(search ?? "")
                );
              });

              const LIST_HEIGHT = Math.min(MAX_LIST_HEIGHT, LIST_SIZE * filteredClusters.length);
              return (
                <List height={LIST_HEIGHT} itemCount={filteredClusters?.length ?? 0} itemSize={LIST_SIZE} width={300}>
                  {({ index, style }) => (
                    <Row
                      selectedDomain={selectedDomain}
                      style={style}
                      setSelectedDomain={setSelectedDomain}
                      name={filteredClusters[index].name}
                      domain={filteredClusters[index].domain}
                      namespace={filteredClusters[index].namespace}
                      id={filteredClusters[index].id}
                    />
                  )}
                </List>
              );
            }}
          />
          <div>{ingressStatus}</div>
          {(ingressStatus === INGRESS_PROGRESS.READY || ingressStatus === INGRESS_PROGRESS.COULD_NOT_PING_INGRESS) &&
          !ingressIsBeingDeleted ? (
            <div>
              <Link href={workingScaleopsURL} target="_blank">
                Link To Scaleops Ingress
              </Link>
            </div>
          ) : (
            ""
          )}
          <Button
            type="button"
            label="Create Ingress"
            variant="regular"
            disabled={!selectedIngress || data?.exists || ingressIsBeingDeleted}
            onClick={() => {
              if (selectedIngress !== undefined) {
                setPingErrorCount(0);
                const copyIngressData = {
                  domain: selectedIngress.domain,
                  name: selectedIngress.name,
                  namespace: selectedIngress.namespace,
                  prefix: sessionStorage.getItem("baseName") ?? "/scaleops",
                  useTLS: true,
                };
                createIngress.mutate(copyIngressData);
              }
            }}
          />
          <Button
            disabled={!data?.exists || ingressIsBeingDeleted}
            label="Delete Ingress"
            variant="regular"
            onClick={() => {
              setPingErrorCount(0);
              deleteIngress.mutate();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IngressContainer;
