import { DialogContent, Drawer } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import DialogTitle from "./DialogTitle";
import SideMenu, { MenuItem } from "./SideMenu/SideMenu";

interface Props {
  title: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  menuItems: MenuItem[];
  defaultSelectedMenuItemId: string;
  children: React.ReactNode;
  selectedMenuItemQueryParamsKey?: string;
  noPaddingForFirstLevel?: boolean;
}

const RightDrawer: React.FC<Props> = ({
  title,
  onClose,
  isOpen,
  menuItems,
  defaultSelectedMenuItemId,
  children,
  selectedMenuItemQueryParamsKey = "selectedMenuItem",
  noPaddingForFirstLevel = true,
}: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useQueryParam(
    selectedMenuItemQueryParamsKey,
    withDefault(StringParam, defaultSelectedMenuItemId)
  );

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      style={{ zIndex: 1300 }}
      className="overflow-y-auto flex flex-col top-0"
      anchor="right"
    >
      <DialogTitle title={title} />
      <DialogContent
        className="flex"
        sx={{
          padding: 0,
          overflowY: "hidden",
          minWidth: "37.5rem",
        }}
      >
        <SideMenu
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          menuItems={menuItems}
          className="h-vh-100"
          noPaddingForFirstLevel={noPaddingForFirstLevel}
        />
        {children}
      </DialogContent>
    </Drawer>
  );
};

export default RightDrawer;
