import { CircularProgress } from "@mui/material";
import { SCALEOPS_COLORS } from "../colors";

const GraphCircularLoader = () => {
  return (
    <div className="bg-white absolute top-0 left-0 right-0 bg-opacity-50 flex items-center justify-center h-full z-50">
      <CircularProgress style={{ color: SCALEOPS_COLORS.text.darkGray }} />
    </div>
  );
};

export default GraphCircularLoader;
