import { ArrowRightIcon } from "@primer/octicons-react";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { GetAvailableSavings, GetAvailableSavingsResponse } from "../../api/fetcher";
import BetaIcon from "../../Icons/BetaIcon";
import { getPercentageValue2 } from "../../utils/formatterUtils";
import AlertBar, { AlertBarTheme } from "../AlertBar";

type AlertData = {
  title?: string;
  name: string;
  amount?: number;
  link?: string;
  rndNeeded?: boolean;
  isBeta?: boolean;
};

const AvailableSavingsAlerts = () => {
  const { queryFn, queryKey } = GetAvailableSavings();
  const { data } = useQuery<GetAvailableSavingsResponse, Error>({
    queryKey: [queryKey],
    queryFn,
    refetchInterval: 1000 * 60,
  });

  const alerts: AlertData[] = [
    {
      name: "Complete cluster automation",
      amount: data?.unautomatedWorkloads?.potentialInCpu,
      link: "/?isUnAutomated=1",
    },
    {
      name: "Fix automated unoptimized workloads",
      amount: data?.unoptimizedWorkloads?.potentialInCpu,
      link: "/?isAutomated=1&availableSavingsFilter=Positive",
    },
    {
      name: "Optimize unevictable workloads",
      amount: data?.unevictableWorkloads?.potentialInCpu,
      link: "/?unevictableTypes=notHealthy&unevictableTypes=unevictable&selectRowsOnInitialLoading=unautomated",
    },
    {
      name: "Use Recommended Policies",
      amount: data?.notUsingSmartPolicy?.potentialInCpu,
      link: "/?notUsingSmartPolicy=1",
    },
    {
      title: "Unrecognized CPU Request",
      name: "Optimize unrecognized workloads",
      amount: data?.unrecognizedWorkloads?.potentialInCpu,
      rndNeeded: true,
    },
    {
      name: "Reduce init containers pain",
      amount: data?.initContainers?.potentialInCpu,
      rndNeeded: true,
    },
    {
      title: "Available savings vs Reliability",
      name: "Decrease HPA CPU factor to 0.4",
      amount: data?.hpaCapping?.potentialInCpu,
      rndNeeded: true,
    },
    {
      name: "Clear wasted resources",
      amount: data?.wastedResources?.potentialInCpu,
      rndNeeded: true,
      link: "/?wastedResources=WorkloadErrorStuckTerminating&wastedResources=WorkloadErrorImagePullBackOff&wastedResources=WorkloadErrorPodInitializing",
    },
    {
      name: "Enable aggressive consolidation",
      amount: data?.consolidation?.potentialInCpu,
      link: "/nodes?isConsolidationDialogueOpen=1",
    },
    {
      name: "Change node groups' min size",
      amount: data?.nodeGroupsMinSize?.potentialInCpu,
      link: "/nodes?scaleDownReasons=NodeGroupMinSizeReached",
    },
    {
      name: "Enable HPA optimization",
      amount: data?.hpaOptimization?.potentialInCpu,
      link: "/?hpaOptimizationType=maxValueMinReplicas&hpaOptimizationType=predictable",
      rndNeeded: true,
      isBeta: true,
    },
  ];

  const alertsToShow = alerts.filter((alert) => alert.amount).sort((a, b) => (b.amount ?? 0) - (a.amount ?? 0));
  return (
    (alertsToShow.length > 0 && (
      <>
        {alertsToShow.map((alert) => (
          <CustomAlert
            title={alert.title}
            name={alert.name}
            amount={alert.amount}
            link={alert.link}
            rndNeeded={alert.rndNeeded}
            isBeta={alert.isBeta}
          />
        ))}
      </>
    )) ||
    null
  );
};

interface CustomAlertProps {
  title?: string;
  name: string;
  amount: number | undefined;
  link?: string;
  rndNeeded?: boolean;
  isBeta?: boolean;
}

const CustomAlert = ({ title = "Available Savings", name, amount, link, rndNeeded, isBeta }: CustomAlertProps) => {
  return (
    <AlertBar
      title={
        <div className="flex">
          {title}
          {rndNeeded && (
            <div className="ml-auto font-normal">
              <small>R&D needed</small>
            </div>
          )}
        </div>
      }
      customDescription={
        <>
          <div className="flex gap-5">
            <div className="flex gap-1">
              {name} {isBeta && <BetaIcon width={18} height={18} />}
            </div>
            {link && (
              <NavLink to={link} className="font-bold hover:underline ml-auto -mr-0.5">
                explore
                <ArrowRightIcon />
              </NavLink>
            )}
          </div>
          <div className="flex gap-5">
            Potential value in CPU:
            <div className="ml-auto font-bold">{getPercentageValue2(Number(amount))}</div>
          </div>
        </>
      }
      theme={AlertBarTheme.Green}
      withSnapshotWrapper
      wrapperClassName={"shadow-lg"}
    />
  );
};

export default AvailableSavingsAlerts;
