import { IconProps } from "./utils/utils";

const AlertIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M16.0547 10.268V8.39844C16.0547 5.67102 14.2418 3.35934 11.7578 2.6043V1.75781C11.7578 0.788555 10.9693 0 10 0C9.03076 0 8.2422 0.788555 8.2422 1.75781V2.6043C5.75822 3.35934 3.94533 5.67098 3.94533 8.39844V10.268C3.94533 12.6638 3.03212 14.9355 1.37396 16.6648C1.21146 16.8342 1.1658 17.0843 1.25791 17.3002C1.35002 17.5161 1.56212 17.6562 1.79689 17.6562H7.12927C7.40146 18.9919 8.58521 20 10 20C11.4149 20 12.5985 18.9919 12.8708 17.6562H18.2031C18.4379 17.6562 18.65 17.5161 18.7421 17.3002C18.8342 17.0843 18.7885 16.8342 18.6261 16.6648C16.9679 14.9355 16.0547 12.6638 16.0547 10.268ZM9.41408 1.75781C9.41408 1.43473 9.67693 1.17188 10 1.17188C10.3231 1.17188 10.586 1.43473 10.586 1.75781V2.37219C10.3931 2.35359 10.1977 2.34375 10 2.34375C9.80236 2.34375 9.60693 2.35359 9.41408 2.37219V1.75781ZM10 18.8281C9.23615 18.8281 8.58486 18.3382 8.34298 17.6562H11.657C11.4152 18.3382 10.7639 18.8281 10 18.8281ZM3.05978 16.4844C4.39419 14.6956 5.1172 12.5309 5.1172 10.268V8.39844C5.1172 5.70605 7.30763 3.51562 10 3.51562C12.6924 3.51562 14.8828 5.70605 14.8828 8.39844V10.268C14.8828 12.5309 15.6058 14.6956 16.9403 16.4844H3.05978Z" />
        <path d="M17.6172 8.39844C17.6172 8.72204 17.8796 8.98438 18.2031 8.98438C18.5267 8.98438 18.7891 8.72204 18.7891 8.39844C18.7891 6.05079 17.8749 3.84364 16.2148 2.1836C15.986 1.95481 15.615 1.95477 15.3862 2.1836C15.1574 2.41243 15.1574 2.7834 15.3862 3.01223C16.8249 4.45094 17.6172 6.36379 17.6172 8.39844Z" />
        <path d="M1.79688 8.98439C2.12047 8.98439 2.38281 8.72204 2.38281 8.39845C2.38281 6.36384 3.17516 4.45099 4.61383 3.01228C4.84266 2.78345 4.84266 2.41247 4.61383 2.18365C4.38504 1.95482 4.01402 1.95482 3.7852 2.18365C2.12516 3.84368 1.21094 6.05079 1.21094 8.39845C1.21094 8.72204 1.47328 8.98439 1.79688 8.98439Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlertIcon;
