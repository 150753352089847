import { IconProps } from "./utils/utils";

const RecommendationIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M2.99965 14.3342H0.999924C0.447969 14.3348 0.000646893 14.7821 6.10352e-05 15.334V19.6668C6.10352e-05 19.8508 0.149259 20 0.333336 20H3.6662C3.85028 20 3.99948 19.8508 3.99948 19.6668V15.334C3.99889 14.7821 3.55161 14.3348 2.99965 14.3342ZM3.33293 19.3335H0.666611V15.334C0.666845 15.1501 0.815926 15.001 0.999885 15.0008H2.99961C3.18357 15.001 3.33265 15.1501 3.33289 15.334L3.33293 19.3335Z" />
      <path d="M8.33223 9.66818H6.33251C5.78055 9.66877 5.33323 10.1161 5.33264 10.668V19.6668C5.33264 19.8509 5.48184 20.0001 5.66592 20.0001H8.99878C9.18286 20.0001 9.33206 19.8509 9.33206 19.6668V10.668C9.33147 10.1161 8.88419 9.66877 8.33223 9.66818ZM8.66551 19.3335H5.99919V10.668C5.99943 10.4841 6.14851 10.335 6.33247 10.3348H8.33219C8.51615 10.335 8.66523 10.4841 8.66547 10.668L8.66551 19.3335Z" />
      <path d="M13.6648 11.6679H11.6651C11.1131 11.6685 10.6658 12.1158 10.6652 12.6678V19.6668C10.6652 19.8509 10.8144 20.0001 10.9985 20.0001H14.3314C14.5154 20.0001 14.6646 19.8509 14.6646 19.6668V12.6678C14.6641 12.1158 14.2168 11.6685 13.6648 11.6679ZM13.9981 19.3335H11.3318V12.6678C11.332 12.4838 11.4811 12.3347 11.6651 12.3345H13.6648C13.8488 12.3347 13.9979 12.4838 13.9981 12.6678L13.9981 19.3335Z" />
      <path d="M18.9975 9.00159H16.9977C16.4458 9.00217 15.9984 9.44949 15.9979 10.0015V19.6668C15.9979 19.8509 16.1471 20.0001 16.3311 20.0001H19.664C19.8481 20.0001 19.9973 19.8509 19.9973 19.6668V10.0015C19.9967 9.44949 19.5494 9.00217 18.9975 9.00159ZM19.3307 19.3335H16.6644V10.0015C16.6646 9.81749 16.8137 9.66841 16.9977 9.66818H18.9974C19.1814 9.66841 19.3305 9.81749 19.3307 10.0015V19.3335H19.3307Z" />
      <path d="M16.5048 3.99516C17.1154 4.68241 18.116 4.86449 18.9296 4.43635C19.7431 4.00821 20.1596 3.08029 19.9388 2.18787C19.718 1.29545 18.9169 0.668818 17.9976 0.669365C17.3302 0.668857 16.7066 1.00155 16.3354 1.5562C15.9642 2.11085 15.8945 2.81419 16.1495 3.43094L14.1578 4.67589C13.7994 4.26965 13.291 4.02703 12.7497 4.00399C12.2085 3.98094 11.6813 4.17955 11.2898 4.55387L9.10833 2.91781C9.56952 2.02578 9.29752 0.930071 8.47271 0.357259C7.64791 -0.215553 6.52618 -0.0877582 5.85139 0.655929C5.1766 1.39962 5.15809 2.52845 5.80812 3.29385L3.01389 6.61441C2.11792 6.08722 0.967412 6.33449 0.367142 7.18324C-0.233127 8.03199 -0.0829916 9.19914 0.712603 9.8683C1.5082 10.5375 2.68378 10.4854 3.41716 9.74863C4.15053 9.01182 4.19701 7.83596 3.52409 7.04353L6.31832 3.72297C7.08552 4.17935 8.06297 4.06788 8.70772 3.45047L10.8891 5.08653C10.4646 5.90888 10.66 6.91515 11.3614 7.5189C12.0628 8.12264 13.087 8.166 13.8369 7.62373C14.5869 7.08146 14.8667 6.09534 14.5132 5.24003L16.5048 3.99516ZM17.9976 1.33595C18.7339 1.33595 19.3307 1.93282 19.3307 2.66909C19.3299 3.40501 18.7335 4.00141 17.9976 4.00223C17.2613 4.00223 16.6644 3.40536 16.6644 2.66909C16.6644 1.93282 17.2613 1.33595 17.9976 1.33595ZM1.99977 9.66817C1.2635 9.66817 0.666633 9.0713 0.666633 8.33504C0.666633 7.59877 1.2635 7.0019 1.99977 7.0019C2.73604 7.0019 3.33291 7.59877 3.33291 8.33504C3.33205 9.07095 2.73569 9.66732 1.99977 9.66817ZM7.33236 3.33568C6.5961 3.33568 5.99922 2.73881 5.99922 2.00254C5.99922 1.26628 6.5961 0.669404 7.33236 0.669404C8.06863 0.669404 8.6655 1.26628 8.6655 2.00254C8.66468 2.73846 8.06828 3.33486 7.33236 3.33568ZM12.665 7.33513C11.9287 7.33513 11.3318 6.73826 11.3318 6.002C11.3318 5.26573 11.9287 4.66886 12.665 4.66886C13.4012 4.66886 13.9981 5.26573 13.9981 6.002C13.9973 6.73791 13.4009 7.33431 12.665 7.33513Z" />
    </svg>
  );
};

export default RecommendationIcon;
