import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetBetaFeaturesQuery, UpdateBetaFeatures, UpdateBetaFeaturesParams } from "../../api/fetcher";
import { TOAST_SETTINGS } from "../../pages/Ingress/mutations/utils";

const { queryKey } = GetBetaFeaturesQuery();
const { queryFn } = UpdateBetaFeatures();

const useSaveBetaFeatures = () => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateBetaFeaturesParams) => queryFn(params), {
    onMutate: async () => {
      await queryClient.cancelQueries([queryKey]);
    },
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Error saving beta features: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Beta features successfully updated!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKey]).catch((error) => console.log(error));
    },
  });
};

export default useSaveBetaFeatures;
