import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../components/Tooltip";
import DeleteIcon from "../../Icons/DeleteIcon";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import EditIcon from "../../Icons/EditIcon";

const hasOldPolicyEditDialog = process.env.REACT_APP_FF_OLD_POLICY_EDIT_DIALOG;
const TOOLTIP_POSITION = "right";

interface Props {
  onEditClickNew: () => void;
  onEditClick: () => void;
  handleDelete: () => void;
  handleDuplicateClick: () => void;
  isCustomizedPolicy?: boolean;
  isHibernate: boolean;
  isDeletablePolicy: boolean;
  actionsDisabled?: boolean;
}

const PolicyActionCell = ({
  onEditClickNew,
  onEditClick,
  handleDelete,
  handleDuplicateClick,
  isCustomizedPolicy,
  isHibernate,
  isDeletablePolicy,
  actionsDisabled,
}: Props) => {
  return (
    <div
      className="flex flex-col gap-2"
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      {(isHibernate || hasOldPolicyEditDialog) && isCustomizedPolicy && (
        <div className="content-center">
          <Tooltip title="Edit policy" onClick={onEditClick} placement={TOOLTIP_POSITION} disabled={actionsDisabled}>
            <Typography variant={"body2"} className={clsx("flex gap-2", { "text-text-disable": actionsDisabled })}>
              <EditIcon /> Edit
            </Typography>
          </Tooltip>
        </div>
      )}
      {!isHibernate && isCustomizedPolicy && (
        <div className="content-center">
          <Tooltip title="Edit policy" onClick={onEditClickNew} placement={TOOLTIP_POSITION} disabled={actionsDisabled}>
            <Typography variant={"body2"} className={clsx("flex gap-2", { "text-text-disable": actionsDisabled })}>
              <EditIcon /> Edit
            </Typography>
          </Tooltip>
        </div>
      )}
      <div className="content-center">
        <Tooltip
          title="Duplicate policy"
          onClick={handleDuplicateClick}
          placement={TOOLTIP_POSITION}
          disabled={actionsDisabled}
        >
          <Typography variant={"body2"} className={clsx("flex gap-2", { "text-text-disable": actionsDisabled })}>
            <DuplicateIcon /> Duplicate
          </Typography>
        </Tooltip>
      </div>
      {isCustomizedPolicy && isDeletablePolicy && (
        <div className="content-center">
          <Tooltip title="Delete policy" onClick={handleDelete} placement={TOOLTIP_POSITION} disabled={actionsDisabled}>
            <Typography variant={"body2"} className={clsx("flex gap-2", { "text-text-disable": actionsDisabled })}>
              <DeleteIcon /> Delete
            </Typography>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default PolicyActionCell;
