import clsx from "clsx";
import { Typography } from "@mui/material";
import Tooltip from "./Tooltip";

export enum CHIP_THEME {
  "DEFAULT",
  "DARK",
}

interface Props {
  label: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
  theme?: CHIP_THEME;
}

const Chip = ({ label, tooltip, className, theme = CHIP_THEME.DEFAULT }: Props) => {
  return (
    <Tooltip title={tooltip} disabled={!tooltip}>
      <Typography
        variant="caption"
        className={clsx(className, "flex items-center justify-center px-2 py-1 rounded-full cursor-default", {
          "bg-gray-100 text-gray-800": theme === CHIP_THEME.DEFAULT,
          "bg-main-deepBlue text-white": theme === CHIP_THEME.DARK,
        })}
        style={{
          lineHeight: "14px",
        }}
      >
        {label}
      </Typography>
    </Tooltip>
  );
};

export default Chip;
