import { components } from "../api/schema";

const key = "scaleops-config";

export function GetConfig(): components["schemas"]["ConfGetConfResponse"] {
  const configJson: string | null = localStorage.getItem(key);
  if (configJson == null) {
    throw new Response("Could Not Load Config Local Store", { status: 412 });
  }
  return JSON.parse(configJson) as components["schemas"]["ConfGetConfResponse"];
}

export function SetConfig(conf: components["schemas"]["ConfGetConfResponse"]) {
  localStorage.setItem(key, JSON.stringify(conf));
}

export function ClearConfig() {
  localStorage.removeItem(key);
}
