import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import React, { useEffect, useState } from "react";
import DaySelection from "../DaySelection";
import SingleSelect, { SingleSelectSize } from "../SingleSelect";
import ErrorLabel from "./ErrorLabel";

const HAS_RESET_BUTTON = false;
const HAS_NATIVE_ERROR = false;

const TIME_OPTIONS = Array.from({ length: 24 }, (_, i) => {
  const hour = String(i).padStart(2, "0");
  return `${hour}`;
});

const renderFromOptionsFunc = () =>
  TIME_OPTIONS.map((timeOption) => (
    <MenuItem value={timeOption} key={timeOption}>
      <ListItemText primary={`${timeOption}:00`} />
    </MenuItem>
  ));

type Props = {
  className?: string;
  errorLabelClassName?: string;
  disableChange?: boolean;
  namesToUpdateOnChange?: string[];
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormSelectCornDaysAndTime = ({
  className,
  errorLabelClassName,
  disableChange,
  namesToUpdateOnChange,
  ...props
}: Props) => {
  namesToUpdateOnChange = namesToUpdateOnChange || [props.name];
  const [field, meta] = useField(props);
  const [from, setFrom] = useState<string | undefined>(String(field.value)?.split(" ")[1]?.split("-")[0]);
  const [to, setTo] = useState<string | undefined>(String(field.value)?.split(" ")[1]?.split("-")[1]);
  const [days, setDays] = useState<number[]>(
    String(field.value)
      ?.split(" ")[4]
      ?.split(",")
      .map((day) => Number(day))
      .filter((day) => !isNaN(day))
  );

  useEffect(() => {
    if (field.value === "* * * * *") {
      setFrom("00");
      setTo("23");
      setDays([0, 1, 2, 3, 4, 5, 6]);
    }
  }, []);

  useEffect(() => {
    if (from && to && from <= to && days && days.length > 0) {
      const newDays = days.join(",");
      const newValue = `* ${from}-${to} * * ${newDays}`;
      namesToUpdateOnChange?.map((name) => {
        field.onChange({
          target: {
            value: newValue,
            name,
          },
        });
      });
    } else {
      namesToUpdateOnChange?.map((name) => {
        field.onChange({
          target: {
            value: "* * * * *",
            name: name,
          },
        });
      });
    }
  }, [from, to, days]);

  useEffect(() => {
    if (from && to && from > to) {
      setTo(from);
    }
  }, [to, from]);

  const showPleaseSelectDaysAndTimeError = !(!from && !to && days.length === 0) && (!from || !to || days.length === 0);

  return (
    <div
      className={clsx(className, "flex flex-col gap-4 w-fit relative", {
        "opacity-29": props.disabled,
      })}
    >
      <div className="ml-[-5px]">
        <DaySelection
          disabled={props.disabled}
          selectedDays={days}
          onChange={(_, selectedDays) => {
            if (disableChange || selectedDays.length === 0) return null;
            setDays(selectedDays);
          }}
          hasSelectAtLeastOneTooltip
        />
      </div>
      <div className="flex gap-4">
        <div>
          <div className="mt-2">
            <SingleSelect<string | undefined>
              label={<span className="text-[12px] absolute mt-[-12px]">From</span>}
              className="h-[34px] w-[80px]"
              arrowIconColor="black"
              selectedColor="black"
              selected={from}
              setSelected={(option) => {
                if (disableChange) return null;
                setFrom(option);
              }}
              renderOptionsFunc={renderFromOptionsFunc}
              size={SingleSelectSize.Small}
            />
          </div>
        </div>
        <div>
          <div className="mt-2">
            <SingleSelect<string | undefined>
              label={<span className="text-[12px] absolute mt-[-12px]">To</span>}
              className="h-[34px] w-[80px]"
              arrowIconColor="black"
              selectedColor="black"
              selected={String(to)}
              setSelected={(option) => {
                if (disableChange) return null;
                setTo(option);
              }}
              renderOptionsFunc={() =>
                TIME_OPTIONS.filter((timeOption) => Number(timeOption) >= Number(from)).map((timeOption) => (
                  <MenuItem value={timeOption} key={timeOption}>
                    <ListItemText primary={`${timeOption}:59`} />
                  </MenuItem>
                ))
              }
              size={SingleSelectSize.Small}
            />
          </div>
        </div>
      </div>
      {HAS_RESET_BUTTON && (
        <p
          onClick={() => {
            if (disableChange) return null;
            setFrom(undefined);
            setTo(undefined);
            setDays([]);
          }}
          className={clsx("text-[12px] absolute top-[110px]", {
            "text-text-lightBlack hover:text-black hover:underline cursor-pointer": from || to || days.length > 0,
            "text-background-chipHover": !(from || to || days.length > 0),
          })}
        >
          Reset all
        </p>
      )}
      {HAS_NATIVE_ERROR && meta.touched && meta.error ? (
        <ErrorLabel label={meta.error} className={errorLabelClassName} />
      ) : null}
      {!HAS_NATIVE_ERROR && (
        <Typography variant="caption" className={clsx(className, "text-main-black italic")}>
          {!showPleaseSelectDaysAndTimeError && from && to && from >= to ? <p>* End time should be greater.</p> : null}
          {showPleaseSelectDaysAndTimeError ? <p>* Please select all fields.</p> : null}
        </Typography>
      )}
    </div>
  );
};

export default FormSelectCornDaysAndTime;
