import { IconProps } from "./utils/utils";

const CloseIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M10 0C4.48357 0 0 4.48357 0 10C0 15.5164 4.48357 20 10 20C15.5164 20 20 15.5164 20 10C20 4.48357 15.5164 0 10 0ZM10 18.9906C5.04695 18.9906 1.00939 14.9531 1.00939 10C1.00939 5.04695 5.04695 1.00939 10 1.00939C14.9531 1.00939 18.9906 5.04695 18.9906 10C18.9906 14.9531 14.9531 18.9906 10 18.9906Z" />
        <path d="M10.7042 9.95305L13.6385 7.01878C13.8263 6.83098 13.8263 6.50234 13.6385 6.31455C13.4507 6.12676 13.1221 6.12676 12.9343 6.31455L9.99998 9.24882L7.06571 6.31455C6.87792 6.12676 6.54928 6.12676 6.36149 6.31455C6.17369 6.50234 6.17369 6.83098 6.36149 7.01878L9.29576 9.95305L6.36149 12.8638C6.17369 13.0516 6.17369 13.3803 6.36149 13.5681C6.45538 13.662 6.59623 13.7089 6.7136 13.7089C6.83097 13.7089 6.97182 13.662 7.06571 13.5681L9.99998 10.6338L12.9343 13.5681C13.0282 13.662 13.169 13.7089 13.2864 13.7089C13.4037 13.7089 13.5446 13.662 13.6385 13.5681C13.8263 13.3803 13.8263 13.0516 13.6385 12.8638L10.7042 9.95305Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
