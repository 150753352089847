import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import * as React from "react";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetPodsResponse, PodToleration } from "../../../api/fetcher";
import { MAIN_YELLOW } from "../../../colors";
import CircleCheckIcon from "../../../Icons/CircleCheckIcon";
import CloseIcon from "../../../Icons/CloseIcon";
import InfoIcon from "../../../Icons/InfoIcon";
import WarningIcon from "../../../Icons/WarningIcon";
import { useMainContext } from "../../../MainContext";
import { CpuFormatter, MemoryFormatter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../utils/useStateWithLocalStorage";
import WorkloadTooltip from "../../../utils/workloadTooltip";
import CustomColumnsFilterButton from "../../CustomColumnsFilterButton";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../../exportCSV/ExportCSV";
import MultiSelect from "../../MultiSelect";
import { default as CustomTooltip } from "../../Tooltip";
import NotScalingDownTooltipContent from "../NotScalingDownTooltipContent";
import { INFO, WARN } from "../useGetNodeIconAndTitle";
import { getWorkloadType } from "../../../utils/typesUtils";

const DEFAULT_PAGE_SIZE = 5;
const ICON_SIZE = 20;
const WARN_ICON_SIZE = 16;
const ROWS_PER_PAGE_OPTIONS = Array.from({ length: 4 }, (_, i) => DEFAULT_PAGE_SIZE * (i + 1));
const NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY = "nodeDiagnosticsPageSize";
const memoryFormatter = MemoryFormatter();
const cpuFormatter = CpuFormatter();

interface Props {
  rows?: GetPodsResponse[];
  isLoading: boolean;
}

type CSVExportType = GetPodsResponse;

export type PodsRowEntry = {
  auto: boolean;
  binPacked: boolean;
  blockingMessage: string;
  blockingReason: string;
  cpuLimit: number;
  cpuRequest: number;
  memoryLimit: number;
  memoryRequest: number;
  cpuUsage: number;
  memoryUsage: number;
  name: string;
  namespace: string;
  ownerName: string;
  ownerType: string;
  phase: string;
  unevictable: boolean;
  blockScaleDown: boolean;
  tolerations: string;
  requiredAntiAffinity: string;
  nodeSelector: string;
};

enum Columns {
  Pod = "Pod",
  WorkloadType = "Workload Type",
  CpuRequest = "CPU Request",
  MemoryRequest = "Memory Request",
  CpuLimit = "CPU Limit",
  MemoryLimit = "Memory Limit",
  CpuUsage = "CPU Usage",
  MemoryUsage = "Memory Usage",
  Unevictable = "Unevictable",
  PodAffinity = "Pod Affinity",
  PodAntiAffinity = "Pod Anti-Affinity",
  NodeAffinity = "Node Affinity",
  NodeSelector = "Node Selector",
  Tolerations = "Tolerations",
}

const COLUMNS_MENU_OPTIONS = [
  Columns.Pod,
  Columns.WorkloadType,
  Columns.CpuRequest,
  Columns.MemoryRequest,
  Columns.CpuLimit,
  Columns.MemoryLimit,
  Columns.CpuUsage,
  Columns.MemoryUsage,
  Columns.Unevictable,
  Columns.PodAffinity,
  Columns.PodAntiAffinity,
  Columns.NodeAffinity,
  Columns.NodeSelector,
  Columns.Tolerations,
];

const renderNameCell = (
  value: string,
  namespace: string,
  workloadName: string,
  workloadType: string,
  tooltipContent: ReactNode,
  params: GridRenderCellParams<string, GetPodsResponse, string>,
  icon: React.ReactNode,
  title: string | undefined
) => {
  const { currentCluster } = useMainContext();
  const navigate = useNavigate();

  return (
    <div className="w-full flex gap-2">
      <CustomTooltip
        title={
          <NotScalingDownTooltipContent
            nodeGroup=""
            limitScaleDownMessage={params.row.blockingMessage ?? ""}
            limitScaleDownReason={params.row.blockingReason}
            limitScaleDownAction=""
            blockingOwner="Pod"
            blockingName={params.row.name}
            blockingMessage={params.row.blockingMessage}
            nodeReasonDetails={params.row.blockingAffinity ? params.row.blockingAffinity : {}}
            nodeGroups={[]}
            title={title}
            icon={icon}
          />
        }
        maxWidth={500}
      >
        {icon}
      </CustomTooltip>
      <CustomTooltip maxWidth={770} title={tooltipContent}>
        <Typography variant="body2" noWrap={true}>
          <button
            onClick={() => {
              const link = `/?selectedWorkloadOverviewId=${
                currentCluster ?? ""
              }%2F${namespace}%2F${workloadType}%2F${workloadName}`;
              navigate(link);
            }}
            className="w-full inline-block truncate text-start hover:underline hover:text-main-linkBlue"
          >
            {value}
          </button>
        </Typography>
      </CustomTooltip>
    </div>
  );
};

const renderMemoryCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const memoryValue = memoryFormatter.format(Number(value));
  return (
    <Typography variant="body2" noWrap={true}>
      {memoryValue}
    </Typography>
  );
};

const renderCpuCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const cpuValue = cpuFormatter.format(Number(value) / 1000);
  return (
    <Typography variant="body2" noWrap={true}>
      {cpuValue}
    </Typography>
  );
};

const getIconAndTitle = (severity: string | undefined) => {
  let icon: React.ReactNode;
  let title: string | undefined;
  switch (severity) {
    case WARN:
      icon = <WarningIcon width={WARN_ICON_SIZE} height={WARN_ICON_SIZE} fill={MAIN_YELLOW} className="mt-[2px]" />;
      break;
    case INFO:
      icon = <InfoIcon width={WARN_ICON_SIZE} height={WARN_ICON_SIZE} className="mt-[2px]" />;
      title = "Scale down constrains";
      break;
  }
  return { icon, title };
};

const renderJSONCell = (title: string, value: string) => {
  const strigifyAndSpacesJson = JSON.stringify(value, null, "\t");
  const tooltipContent = (
    <>
      <b>{title}: </b>
      <p className="border border-border rounded-lg w-full p-3 flex max-h-[550px] overflow-auto">
        <pre>{strigifyAndSpacesJson}</pre>
      </p>
    </>
  );
  return (
    value && (
      <CustomTooltip title={tooltipContent} disabled={!value} maxWidth={900}>
        {<InfoIcon width={ICON_SIZE} height={ICON_SIZE} />}
      </CustomTooltip>
    )
  );
};

const DEFAULT_COL_PROPS: Partial<GridColDef> = {
  flex: 1,
  minWidth: 30,
  type: "string",
  align: "center",
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: true,
};

const getColumns = (selectedColumns: Columns[]): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: Columns.Pod,
      headerAlign: "center",
      hide: !selectedColumns.includes(Columns.Pod),
      flex: 1.5,
      minWidth: 250,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, GetPodsResponse, string>) => {
        const namespace = params.row.namespace ?? "";
        const podName = params.value ?? "";
        const { icon, title } = getIconAndTitle(params.row.blockingSeverity);
        return renderNameCell(
          `${namespace}/${podName}`,
          namespace,
          params.row.ownerName,
          params.row.ownerType,
          <WorkloadTooltip namespace={namespace} workloadName={podName} />,
          params,
          icon,
          title
        );
      },
    },
    {
      field: "ownerType",
      headerName: Columns.WorkloadType,
      hide: !selectedColumns.includes(Columns.WorkloadType),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => {
        return (
          <Typography variant="body2" noWrap={true}>
            {getWorkloadType((params?.row as GetPodsResponse)?.ownerName, (params?.row as GetPodsResponse)?.ownerType)}
          </Typography>
        );
      },
    },
    {
      field: "cpuRequest",
      headerName: Columns.CpuRequest,
      hide: !selectedColumns.includes(Columns.CpuRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuUsage",
      headerName: Columns.CpuUsage,
      hide: !selectedColumns.includes(Columns.CpuUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuLimit",
      headerName: Columns.CpuLimit,
      hide: !selectedColumns.includes(Columns.CpuLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string, false),
    },
    {
      field: "memoryRequest",
      headerName: Columns.MemoryRequest,
      hide: !selectedColumns.includes(Columns.MemoryRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryUsage",
      headerName: Columns.MemoryUsage,
      hide: !selectedColumns.includes(Columns.MemoryUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryLimit",
      headerName: Columns.MemoryLimit,
      hide: !selectedColumns.includes(Columns.MemoryLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string, false),
    },
    {
      field: "unevictable",
      headerName: Columns.Unevictable,
      hide: !selectedColumns.includes(Columns.Unevictable),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) =>
        !params.value ? (
          <CloseIcon width={ICON_SIZE} height={ICON_SIZE} />
        ) : (
          <CircleCheckIcon className="text-main-green" />
        ),
    },
    {
      field: "requiredAffinity",
      headerName: Columns.PodAffinity,
      hide: !selectedColumns.includes(Columns.PodAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderJSONCell(Columns.PodAffinity, params.value as string),
    },
    {
      field: "requiredAntiAffinity",
      headerName: Columns.PodAntiAffinity,
      hide: !selectedColumns.includes(Columns.PodAntiAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderJSONCell(Columns.PodAntiAffinity, params.value as string),
    },
    {
      field: "requiredNodeAffinity",
      headerName: Columns.NodeAffinity,
      hide: !selectedColumns.includes(Columns.NodeAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderJSONCell(Columns.NodeAffinity, params.value as string),
    },
    {
      field: "nodeSelector",
      headerName: Columns.NodeSelector,
      hide: !selectedColumns.includes(Columns.NodeSelector),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderJSONCell(Columns.NodeSelector, params.value as string),
    },
    {
      field: "tolerations",
      headerName: Columns.Tolerations,
      hide: !selectedColumns.includes(Columns.Tolerations),
      ...DEFAULT_COL_PROPS,
      renderCell: (params: GridRenderCellParams<PodToleration[], GetPodsResponse, string>) => {
        if (!params.value) {
          return "";
        }
        const filterToleration = params.value.filter((t: PodToleration) => t.key);
        return renderJSONCell(Columns.Tolerations, filterToleration as unknown as string);
      },
    },
  ];
};

const PodsTable = ({ rows, isLoading }: Props) => {
  const [selectedColumns, setSelectedColumns] = useState<Columns[]>([
    Columns.Pod,
    Columns.CpuRequest,
    Columns.MemoryRequest,
    Columns.CpuUsage,
    Columns.MemoryUsage,
  ]);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_PAGE_SIZE,
    valueFormatter: (value) => parseInt(value),
  });

  if (isLoading && !rows) {
    return (
      <div className="w-full border pt-2 pb-6 relative flex items-center justify-center h-[350px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-end gap-4">
        <MultiSelect
          selected={selectedColumns}
          setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
          options={COLUMNS_MENU_OPTIONS}
          className="mb-[6px] w-[85px]"
          customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
        />
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows ?? []}
        columns={getColumns(selectedColumns)}
        autoHeight={true}
        rowHeight={65}
        getRowId={(row: GetPodsResponse) => row.name}
        loading={isLoading}
        disableSelectionOnClick
        pagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          localStorage.setItem(NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY, newPageSize.toString());
        }}
      />
      {HAS_EXPORT_TABLE_AS_CSV && rows && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_pods.csv"
            columns={[
              "name",
              "cpuRequest",
              "cpuUsage",
              "cpuLimit",
              "memoryRequest",
              "memoryUsage",
              "memoryLimit",
              "unevictable",
              "requiredAntiAffinity",
              "tolerations",
              "nodeSelector",
            ]}
            columnsToRound={["cpuRequest"]}
            data={
              rows?.map((row) => ({
                name: row.name,
                cpuRequest: row.cpuRequest,
                cpuUsage: row.cpuUsage,
                cpuLimit: row.cpuLimit,
                memoryRequest: row.memoryRequest,
                memoryUsage: row.memoryUsage,
                memoryLimit: row.memoryLimit,
                unevictable: row.unevictable,
                requiredAntiAffinity: row.requiredAntiAffinity,
                tolerations: row.tolerations,
                nodeSelector: row.nodeSelector,
              })) as CSVExportType[]
            }
            columnsToSum={["cpuRequest", "memoryRequest", "cpuLimit", "memoryLimit"]}
            customColumnNames={{
              name: Columns.Pod,
              cpuRequest: Columns.CpuRequest,
              cpuUsage: Columns.CpuUsage,
              cpuLimit: Columns.CpuLimit,
              memoryRequest: Columns.MemoryRequest,
              memoryUsage: Columns.MemoryUsage,
              memoryLimit: Columns.MemoryLimit,
              unevictable: Columns.Unevictable,
              requiredAffinity: Columns.PodAffinity,
              requiredAntiAffinity: Columns.PodAntiAffinity,
              nodeSelector: Columns.NodeSelector,
              tolerations: Columns.Tolerations,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PodsTable;
