import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Box from "@mui/material/Box";
import * as React from "react";

export default function GreenArrow(props: { padding: number }) {
  return (
    <Box
      style={{
        width: 100,
        textAlignLast: "center",
        padding: `${props.padding}px 0`,
        color: "green",
        fontWeight: 600,
      }}
    >
      <DoubleArrowIcon />
    </Box>
  );
}
