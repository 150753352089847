import { IconProps } from "./utils/utils";

const StartsIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="1" />
      <g>
        <path
          d="M9.92871 5.7131L11.1706 4.72641L9.69495 5.30904C7.48734 6.18038 7.18943 6.10089 5.71328 4.24191L4.72659 3L5.30946 4.47543C6.1808 6.68328 6.10107 6.98096 4.2421 8.45734L3.00018 9.44403L4.47585 8.8614C6.68346 7.98959 6.98114 8.06931 8.45752 9.92853L9.44421 11.1704L8.86135 9.69501C7.99001 7.48716 8.06973 7.18948 9.92871 5.7131ZM8.0018 8.67246C7.44984 8.10611 6.99341 7.85396 6.40135 7.85396C6.09497 7.8618 5.7911 7.91148 5.49816 8.00162C6.31926 7.20151 6.48013 6.60143 6.16901 5.49798C6.96935 6.31908 7.5692 6.47995 8.67265 6.16882C7.85154 6.96893 7.69067 7.56901 8.0018 8.67246Z"
          fill="white"
        />
        <path
          d="M9.86092 13.1375L11.1028 12.1508L9.6274 12.7334C8.27674 13.2665 8.143 13.2304 7.24052 12.0937L6.25407 10.8515L6.83646 12.3272C7.36955 13.6774 7.33369 13.8111 6.19675 14.7141L4.95483 15.7008L6.43027 15.1181C7.78092 14.5851 7.91467 14.6211 8.81715 15.7579L9.8036 17L9.22121 15.5243C8.68812 14.1741 8.72397 14.0404 9.86092 13.1375ZM8.45012 14.6556C8.22146 14.4404 7.91986 14.3197 7.6059 14.3178C7.50326 14.3186 7.40086 14.3284 7.29996 14.3473C7.58942 14.0395 7.70535 13.6072 7.60873 13.196C7.91658 13.4852 8.34886 13.601 8.76006 13.5042C8.4702 13.8117 8.35379 14.2441 8.45012 14.6556Z"
          fill="white"
        />
        <path
          d="M15.758 7.94808L16.9999 6.96139L15.5244 7.54401C14.1738 8.07734 14.04 8.04101 13.1376 6.9043L12.1511 5.66216L12.7335 7.13783C13.2666 8.48801 13.2307 8.62175 12.0938 9.5247L10.8519 10.5114L12.3273 9.92877C13.6777 9.39544 13.8117 9.432 14.7142 10.5685L15.7006 11.8106L15.1182 10.335C14.5852 8.98477 14.621 8.85103 15.758 7.94808ZM14.3472 9.46621C14.1185 9.25105 13.8169 9.13037 13.5029 9.12842C13.4003 9.12922 13.2979 9.13907 13.197 9.15791C13.4865 8.85017 13.6024 8.41786 13.5058 8.00657C13.8136 8.29582 14.2459 8.41158 14.6571 8.31487C14.3672 8.62237 14.2508 9.05474 14.3472 9.46621Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="14" height="14" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StartsIcon;
