import { IconProps } from "./utils/utils";

const InfoIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M11.625 9.16666C11.625 8.82149 11.3452 8.54166 11 8.54166C10.6548 8.54166 10.375 8.82149 10.375 9.16666V14.1667C10.375 14.5118 10.6548 14.7917 11 14.7917C11.3452 14.7917 11.625 14.5118 11.625 14.1667V9.16666Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 1.04166C6.05247 1.04166 2.04169 5.05244 2.04169 9.99999C2.04169 14.9476 6.05247 18.9583 11 18.9583C15.9476 18.9583 19.9584 14.9476 19.9584 9.99999C19.9584 5.05244 15.9476 1.04166 11 1.04166ZM3.29169 9.99999C3.29169 5.7428 6.74283 2.29166 11 2.29166C15.2572 2.29166 18.7084 5.7428 18.7084 9.99999C18.7084 14.2572 15.2572 17.7083 11 17.7083C6.74283 17.7083 3.29169 14.2572 3.29169 9.99999Z"
      />
      <path d="M11.8334 6.66665C11.8334 7.12688 11.4603 7.49998 11 7.49998C10.5398 7.49998 10.1667 7.12688 10.1667 6.66665C10.1667 6.20641 10.5398 5.83331 11 5.83331C11.4603 5.83331 11.8334 6.20641 11.8334 6.66665Z" />
    </svg>
  );
};

export default InfoIcon;
