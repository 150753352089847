import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { ListItem } from "@mui/material";
import List from "@mui/material/List";
import { components } from "../../api/schema";
import moment from "moment";
import "moment-duration-format";
import WarningIcon from "../../Icons/WarningIcon";

interface WarningPopupProps {
  warnings: components["schemas"]["DashIssues"];
}

export default function WarningPopup(Props: WarningPopupProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { warnings }: WarningPopupProps = Props;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const listItem = (name: string, value: number | null | undefined) => {
    if (value != undefined) {
      return (
        <ListItem>
          <ListItemText primary={`${value} x ${name} in the past 8 hours`} />
        </ListItem>
      );
    }
  };

  const haveBootTimeIndicator: (value: components["schemas"]["DashBootTimeIndicator"]) => JSX.Element | undefined = (
    value: components["schemas"]["DashBootTimeIndicator"]
  ) => {
    if (value !== null && (value.haveMemoryBootTimeIndicator === null || !value.haveMemoryBootTimeIndicator)) {
      return (
        <ListItem>
          <ListItemText
            primary={`Boot indicator not found in memory time window (${moment
              .duration(Number(value.timeWindowMillis), "milliseconds")
              .format()})`}
          />
        </ListItem>
      );
    }
    return undefined;
  };

  const open = Boolean(anchorEl);

  const shouldHide = () => {
    return (
      warnings === undefined ||
      (warnings.cpuThrottleMinutes === undefined &&
        warnings.oomKilled === undefined &&
        (warnings.haveBootTimeIndicator === undefined ||
          warnings.haveBootTimeIndicator === null ||
          warnings.haveBootTimeIndicator.haveMemoryBootTimeIndicator))
    );
  };
  return (
    <div>
      {!shouldHide() && (
        <div>
          <Typography
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <WarningIcon width={14} height={14} />
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>
              <List dense={true}>
                {listItem("minutes of CPU Throttle", warnings?.cpuThrottleMinutes)}
                {listItem("OOM Killed", warnings?.oomKilled)}
                {haveBootTimeIndicator(warnings.haveBootTimeIndicator)}
              </List>
            </Typography>
          </Popover>
        </div>
      )}
    </div>
  );
}
