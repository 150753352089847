import { Typography } from "@mui/material";
import numeral from "numeral";
import MagicWand from "../Icons/MagicWand";
import RightArrowIcon from "../Icons/RightArrowIcon";
import { ScaleOpsProduct } from "../utils/typesUtils";
import PercentageMeter from "./PercentageMeter";
import Tooltip from "./Tooltip";

interface Props {
  policyName: string;
  usageCount: number;
  usagePercentage: number;
  potentialCount: number;
  potentialPercentage: number;
  totalWorkloadCount: number;
  totalWorkloadsCount: number;
  scaleOpsProduct?: ScaleOpsProduct | undefined;
}

const UsedByWorkloads = ({
  policyName,
  usageCount,
  usagePercentage,
  potentialCount,
  potentialPercentage,
  totalWorkloadCount,
  totalWorkloadsCount,
  scaleOpsProduct,
}: Props) => {
  const linkParams = `/?smartPolicy=${policyName}&notUsingSmartPolicy=1`;
  const baseLink = scaleOpsProduct === ScaleOpsProduct.HPA ? "/hpa/workloads" : "";
  const link = baseLink + linkParams;

  const tooltipText = (
    <div className="flex flex-col">
      <div>
        <b>{policyName}</b> is used by <b>{usagePercentage}%</b> of all workloads.
      </div>
      {potentialCount > 0 && (
        <div className="flex flex-col">
          <br />
          <b>
            <div className="flex gap-1">
              <span className="flex gap-0.5 text-guideline-darkPurple w-fit items-center border-b-2">
                <MagicWand height={12} width={12} className="-mt-1" />
                <span className="w-fit">Auto Policy Detection</span>
              </span>
            </div>
          </b>
          <span className="flex gap-1 flex-wrap">
            Set <b>{potentialCount}</b> more workload{potentialCount > 1 ? "s" : ""} to use <b>{policyName}</b> policy
          </span>
          <br />
          <Typography
            variant="caption"
            className="self-end"
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <a href={link}>
              <span className="flex gap-1 items-center text-guideline-darkPurple">
                Explore workload{potentialCount > 1 ? "s" : ""} <RightArrowIcon width={14} height={14} />
              </span>
            </a>
          </Typography>
        </div>
      )}
    </div>
  );

  return (
    <Tooltip className="flex flex-col items-center justify-center w-full mt-5" title={tooltipText} maxWidth={800}>
      <div className="w-full flex justify-center">
        <PercentageMeter percentage={usagePercentage} additionalPercentage={potentialPercentage} />
      </div>
      {totalWorkloadsCount > 0 && (
        <Typography variant="body2" className="w-full text-black flex justify-center gap-2">
          {numeral(usageCount).format("0,0")} of {numeral(totalWorkloadCount).format("0,0")}{" "}
          {!!potentialCount && (
            <Typography variant="caption" className={"flex items-center"}>
              {"("}
              <MagicWand height={12} width={12} className={"mr-1 -mt-1"} />+{numeral(potentialCount).format("0,0")}{" "}
              suggested{")"}
            </Typography>
          )}
        </Typography>
      )}
    </Tooltip>
  );
};

export default UsedByWorkloads;
