import { Typography } from "@mui/material";
import AutomationIcon from "../../../Icons/AutomationIcon";
import { useMainContext } from "../../../MainContext";
import ScaleOpsSpan from "../../ScaleOpsSpan";

const ICON_SIZE = 22;

interface Props {
  notSupportingBinPackingPolicies?: string[];
}

const NotSupportingBinPackingPoliciesLinks = ({ notSupportingBinPackingPolicies }: Props) => {
  const { currentCluster } = useMainContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <AutomationIcon width={ICON_SIZE} height={ICON_SIZE} />
        <Typography variant="body2">
          Click to view <b>unevictable</b> and <b>un-optimized</b> workloads.
        </Typography>
      </div>
      <Typography variant="caption">
        Go to overview <b>page to view</b> the list of unevictable and un-optimized workloads. Use{" "}
        <b>bulk automation</b> to reduce cost and optimize your cluster.
      </Typography>
      {notSupportingBinPackingPolicies && notSupportingBinPackingPolicies.length > 0 && (
        <Typography variant="caption" className="bg-background-chip rounded-lg p-4">
          <ScaleOpsSpan className="mt-[4px]" /> detected some custom policies with <b>disabled automatic bin-pack</b>.
          <br />
          <br />
          <p className="underline">Click to edit any policy below:</p>
          <ul className="list list-disc pl-8">
            {notSupportingBinPackingPolicies.map((policy, index) => (
              <li key={index}>
                <a
                  href={`/policy?currentClusterURLParam=${String(
                    currentCluster ?? ""
                  )}&policyToOpenOnInitialLoading=${String(policy ?? "")}&selectedMenuItem=Scheduling`}
                  className="cursor-pointer text-main-linkBlue hover:text-guideline-darkBlue hover:underline"
                >
                  {policy}
                </a>
              </li>
            ))}
          </ul>
        </Typography>
      )}
    </div>
  );
};

export default NotSupportingBinPackingPoliciesLinks;
