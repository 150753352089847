import { Typography } from "@mui/material";
import { BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import UnFilterIcon from "../../Icons/UnFilterIcon";
import LabelsAndAnnotationsFilter from "./LabelsAndAnnotationsFilter";
import NamespacesFilter from "./NamespacesFilterChip";
import {
  AGGREGATION_ANNOTATION_QUERY_KEY,
  AGGREGATION_LABEL_QUERY_KEY,
  AGGREGATION_NAMESPACES_QUERY_KEY,
} from "./utils";
import SelectedFiltersChips from "./SelectedFiltersChips";

const AggregationFilters = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    [AGGREGATION_NAMESPACES_QUERY_KEY]: withDefault(BooleanParam, true),
    [AGGREGATION_LABEL_QUERY_KEY]: StringParam,
    [AGGREGATION_ANNOTATION_QUERY_KEY]: StringParam,
  });

  const hasAnyQueryParamsNotUndefined =
    queryParams[AGGREGATION_NAMESPACES_QUERY_KEY] ||
    queryParams[AGGREGATION_LABEL_QUERY_KEY] ||
    queryParams[AGGREGATION_ANNOTATION_QUERY_KEY];

  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex items-center gap-2">
        <NamespacesFilter />
        <LabelsAndAnnotationsFilter />
      </div>
      <SelectedFiltersChips />
      {hasAnyQueryParamsNotUndefined && (
        <Typography
          variant="caption"
          onClick={() => {
            setQueryParams({
              [AGGREGATION_NAMESPACES_QUERY_KEY]: false,
              [AGGREGATION_LABEL_QUERY_KEY]: undefined,
              [AGGREGATION_ANNOTATION_QUERY_KEY]: undefined,
            });
          }}
          className="w-fit hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
        >
          <UnFilterIcon width={15} height={15} />
          <p>Clear all aggregations</p>
        </Typography>
      )}
    </div>
  );
};

export default AggregationFilters;
