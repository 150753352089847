import { Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";
import useAttachHPAPolicyToWorkload from "../../../components/WorkloadStatusByNamespace/useAttachHPAPolicyToWorkload";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import useHasMultiProductLayout from "../../../utils/useHasMultiProductLayout";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import { ForcedPolicySpan } from "./PolicyNameTooltip";
import useUpdateRecommendation from "./mutation/useUpdateRecommendation";
import { Policy } from "./utils";

const NotEditableTootip = () => (
  <Typography variant="caption">
    You don't have <b>permissions</b> to save this workload.
  </Typography>
);

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
  selectedHPAPolicy: string | undefined;
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

const SaveButtonContainer = memo(({ selectedWorkload, selectedPolicy, selectedHPAPolicy, scaleOpsProduct }: Props) => {
  const hasMultiProduct = useHasMultiProductLayout();
  const isEditable = selectedWorkload?.isEditable;
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isScaleopsSystemWorkload = selectedWorkload?.namespace === "scaleops-system";
  const isForcePolicy = selectedWorkload?.isPolicyForced;
  const updateRecommendation = useUpdateRecommendation();
  const attachHPAPolicyToWorkload = useAttachHPAPolicyToWorkload();

  const { updateOverriddenWorkloads } = useWorkloadsContext();

  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    switch (true) {
      case !hasMultiProduct:
        setLabel("Save policy");
        break;
      case scaleOpsProduct === ScaleOpsProduct.HPA && hasMultiProduct:
        setLabel("Save HPA policy");
        break;
      case scaleOpsProduct !== ScaleOpsProduct.HPA && hasMultiProduct:
        setLabel("Save rightsize policy");
        break;
    }
  }, [hasMultiProduct, scaleOpsProduct]);

  const [bounceAnimation, setBounceAnimation] = useState<string | undefined>(undefined);

  const isInitialMount = useRef(true);
  const timeoutId = useRef<null | number>(null);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setBounceAnimation("animate-bounce temporary-bounce");

      timeoutId.current = window.setTimeout(() => {
        setBounceAnimation(undefined);
      }, 3000);

      return () => {
        if (timeoutId.current) clearTimeout(timeoutId.current);
      };
    }
  }, [selectedPolicy]);

  const handleSave = () => {
    if (selectedPolicy?.name == selectedPolicy?.displayName) {
      updateOverriddenWorkloads({
        ids: [selectedWorkload.id],
        props: {
          policyName: selectedPolicy?.name,
        },
      });

      if (scaleOpsProduct === ScaleOpsProduct.HPA && selectedHPAPolicy) {
        attachHPAPolicyToWorkload.mutate({
          name: selectedHPAPolicy,
          target: `${selectedWorkload.type}-${selectedWorkload.workloadName}`.toLowerCase(),
          namespaceName: selectedWorkload.namespace,
          id: selectedWorkload.id,
          oldPolicyName: String(selectedWorkload.hpaPolicyName),
        });
      } else {
        updateRecommendation.mutate({
          name: `${selectedWorkload.type}-${selectedWorkload.workloadName}`.toLowerCase(),
          namespace: selectedWorkload.namespace,
          policyName: String(selectedPolicy?.displayName),
        });
      }
    }
  };

  let tooltipContent = undefined;
  switch (true) {
    case isReadyOnlyFrontEnd:
      tooltipContent = <YouHaveReadOnlyAccess />;
      break;
    case !isEditable:
      tooltipContent = <NotEditableTootip />;
      break;
    case isScaleopsSystemWorkload:
      tooltipContent = <Typography variant="caption">Can't modify scaleops-system workloads.</Typography>;
      break;
    case isForcePolicy:
      tooltipContent = <ForcedPolicySpan />;
      break;
  }

  return (
    <Tooltip title={tooltipContent} maxWidth={500} disabled={!tooltipContent}>
      <Button
        label={label}
        onClick={handleSave}
        disabled={isReadyOnlyFrontEnd || !isEditable || isScaleopsSystemWorkload || isForcePolicy}
        className={bounceAnimation}
      />
    </Tooltip>
  );
});

export default SaveButtonContainer;
