import { Typography } from "@mui/material";
import clsx from "clsx";

interface Props {
  label: string;
  className?: string;
  hasLabel?: boolean;
}

const ErrorLabel = ({ label, className, hasLabel }: Props) => {
  return (
    <Typography
      variant="caption"
      className={clsx(className, "text-guideline-lightRed absolute w-[200px] truncate", {
        "top-[60px]": hasLabel,
        "top-[37px]": !hasLabel,
      })}
    >
      *{label}
    </Typography>
  );
};

export default ErrorLabel;
