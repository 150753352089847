import { Typography } from "@mui/material";
import RightArrowIcon from "../../../Icons/RightArrowIcon";

interface Props {
  title: string;
  before?: number;
  after?: number;
  valueFormatter: (value?: number) => string;
  fontWeight?: number;
}

const BeforeAndAfterMetrics = ({ title, before, after, valueFormatter, fontWeight = 700 }: Props) => {
  return (
    <div>
      <Typography variant="subtitle2">{title}</Typography>
      <div className="flex items-center gap-5">
        <Typography variant="h4" fontWeight={fontWeight}>
          {valueFormatter(before)}
        </Typography>
        <RightArrowIcon className="text-text-darkGray" width={24} height={24} />
        <Typography variant="h4" fontWeight={fontWeight} className="text-guideline-darkGreen">
          {valueFormatter(after)}
        </Typography>
      </div>
    </div>
  );
};

export default BeforeAndAfterMetrics;
