import Tooltip from "../Tooltip";
import { Switch } from "@mui/material";
import * as React from "react";

interface Props {
  label: string;
  checked: boolean;
  onChange: (arg0: boolean) => void;
  tooltip: string;
}

export default function NiceSwitch({ label, checked, onChange, tooltip }: Props) {
  return (
    <div className="w-36">
      <Tooltip title={tooltip}>
        <div className="justify-between items-center text-center">
          <div className="text-xs">{label}</div>
          <div className="w-full text-center">
            <Switch
              name={label}
              checked={checked}
              onChange={(e, value) => {
                onChange(value);
              }}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
