import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useWorkloadsContext } from "../../WorkloadsContext";
import {
  UpdateNamespacesInBulk,
  UpdateNamespacesInBulkParams,
  UpdatePolicyByNamespace,
  UpdatePolicyByNamespaceParams,
} from "../../api/fetcher";

const useUpdatePolicyByNamespace = () => {
  const { queryFn } = UpdatePolicyByNamespace();
  const { deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  return useMutation(
    (params: UpdatePolicyByNamespaceParams & { namespaceIds: string[] }) =>
      queryFn({
        target: params.target,
        name: params.name,
      }),
    {
      onError: (error: Error, params) => {
        if (params.namespaceIds.length > 0) {
          deleteOverriddenWorkloadsProperties({
            ids: params.namespaceIds,
            propertyNames: ["policyName"],
          });
        }

        toast.error(`Failed to ${params.target} namespace with ${params.name} policy`);
        console.log(error);
      },
      onSuccess: (_, params) => {
        toast.success(`Successfully updated ${params.target} namespace with ${params.name} policy`);
      },
    }
  );
};

export const useUpdateNamespaceInBulk = () => {
  const { queryFn } = UpdateNamespacesInBulk();
  const { deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  return useMutation((params: UpdateNamespacesInBulkParams) => queryFn(params), {
    onError: (error: Error, params) => {
      if (params.namespaces && params.namespaces.length > 0) {
        deleteOverriddenWorkloadsProperties({
          ids: params.namespaces,
          propertyNames: ["policyName", "auto"],
        });
      }

      toast.error(`Failed update namespaces`);
      console.log(error);
    },
    onSuccess: () => {
      toast.success(`Successfully updated namespaces`);
    },
  });
};

export default useUpdatePolicyByNamespace;
