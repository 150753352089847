import { Typography } from "@mui/material";
import Tooltip from "../Tooltip";
import MainDownloadIcon from "../../Icons/MainDownloadIcon";

interface Props {
  tooltipTitle?: string;
  buttonTitle?: string;
}
const StaticExportButton = ({ tooltipTitle = "Export to CSV", buttonTitle = "Export" }: Props) => (
  <Tooltip title={tooltipTitle} placement="top">
    <Typography className="cursor-pointer hover:text-text-lightBlack flex justify-center items-center gap-1 borders border border-black hover:text-test-lightBlack rounded-full py-1 px-2">
      <p className="text-[10px]">{buttonTitle}</p>
      <MainDownloadIcon width={12} height={12} />
    </Typography>
  </Tooltip>
);
export default StaticExportButton;
