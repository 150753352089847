import ActiveIssuesAlerts from "./ActiveIssuesAlerts";
import AvailableSavingsAlerts from "./AvailableSavingsAlerts";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";
import CloseIcon from "../../Icons/CloseIcon";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

const SnapshotAlertBar = () => {
  const [hideAll, setHideAll] = useState<boolean>(false);
  const alertsRef = useRef<HTMLDivElement>(null);
  const [totalAlertCount, setTotalAlertCount] = useState<number>(0);
  if (!IsSnapshotServer()) {
    return null;
  }

  useEffect(() => {
    if (!alertsRef.current) return;

    setTotalAlertCount(alertsRef.current.children.length);

    const observer = new MutationObserver(() => {
      setTotalAlertCount(alertsRef.current?.children.length || 0);
    });
    observer.observe(alertsRef.current, { childList: true });
    return () => observer.disconnect();
  }, []);

  return (
    <div
      className={clsx(
        "flex flex-col gap-1 fixed top-0 right-0 z-[1500] w-auto h-auto transition-all overflow-hidden duration-150 ease-out ",
        { "max-h-0": hideAll, "p-5 max-h-[3000px]": !hideAll }
      )}
    >
      {totalAlertCount > 1 && (
        <div className="flex w-full h-7">
          <div
            className="flex cursor-pointer shadow-2xl ml-auto w-7 px-1 items-center rounded-2xl text-sm bg-white transition-width duration-300 ease-out hover:border hover:border-black hover:w-[96px] hover:p-2.5 hover:gap-1"
            onClick={() => setHideAll(true)}
          >
            <div className="whitespace-nowrap overflow-hidden grow-0">hide all</div>
            <div className="flex grow justify-center">
              <CloseIcon className="hover:text-guideline-darkBlue" />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2.5" ref={alertsRef}>
        <ActiveIssuesAlerts />
        <AvailableSavingsAlerts />
      </div>
    </div>
  );
};

export default SnapshotAlertBar;
