import { Typography } from "@mui/material";
import { useState } from "react";
import { NumberParam, QueryParamConfig, StringParam, useQueryParam, withDefault } from "use-query-params";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { DateType, getFromDate } from "../Analytics/AnalyticsV2/utils";
import ChartsContainer from "./Charts/ChartsContainer";
import NetworkAlerts from "./Network/NetworkAlerts";
import TablesContainer from "./Tables/TablesContainer";
import { TableType } from "./Tables/utils";
import NetworkMetricsSummary from "./Network/NetworkMetricsSummary";
import { CostReportType } from "./utils";
import GpuBanner from "./Gpu/GpuBanner";
import GpuMetricsSummary from "./Gpu/GpuMetricsSummary";
import GpuCharts from "./Gpu/GpuCharts";

const ENABLE_GPU_CHARTS = false;

const tableTypeConfig: QueryParamConfig<TableType, TableType> = {
  encode: (value) => value,
  decode: (value) => value as TableType,
};

interface Props {
  reportType: CostReportType;
}

const CostContainer = ({ reportType }: Props) => {
  const [selectedTable, setSelectedTable] = useQueryParam<TableType>(
    "selectedTable",
    withDefault(tableTypeConfig, TableType.Aggregation)
  );
  const [currentEpoch] = useState(Date.now());
  const [fromDate, setFromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(7, currentEpoch)));
  const [toDate, setToDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range, setRange] = useQueryParam("range", withDefault(StringParam, "7d"));
  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromDate(date.from);
    setToDate(date.to);
    setRange(date.range);
  };

  const [reportName, costTypeName] = (() => {
    switch (reportType) {
      case CostReportType.Compute:
        return ["Compute", "compute"];
      case CostReportType.Network:
        return ["Network", "network"];
      case CostReportType.Gpu:
        return ["GPU", "GPU"];
    }
  })();

  const isGpu = reportType === CostReportType.Gpu;

  return (
    <div className="p-4 flex flex-col gap-5">
      {reportType === CostReportType.Network && <NetworkAlerts />}
      {reportType === CostReportType.Gpu && <GpuBanner />}
      <div className="flex gap-3 border border-border rounded-lg p-5 bg-white">
        <div className="flex items-center">
          <ScaleopsIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            {reportName} Report
          </Typography>
          <Typography variant="body2">
            <b>Explore your {costTypeName} cost by workloads, namespaces and clusters.</b>
          </Typography>
        </div>
        <div className="flex items-center">
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM} />
        </div>
      </div>
      {reportType === CostReportType.Network && <NetworkMetricsSummary selectedTable={selectedTable} />}
      {reportType === CostReportType.Gpu && <GpuMetricsSummary />}
      <>
        {isGpu && ENABLE_GPU_CHARTS && <GpuCharts />}
        <ChartsContainer
          selectedTable={selectedTable}
          reportType={reportType}
          hasSavings={reportType === CostReportType.Compute}
        />
      </>
      <TablesContainer
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        reportType={reportType}
        isMultiCluster
      />
    </div>
  );
};

export default CostContainer;
