import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import React from "react";
import InfoIcon from "../../Icons/InfoIcon";
import RecommendedSpan from "../RecommendedSpan";
import SingleSelect from "../SingleSelect";
import Tooltip from "../Tooltip";
import ErrorLabel from "./ErrorLabel";

export enum RenderValueType {
  Percentage = "percentage",
  Default = "default",
  Hours = "hours",
}

const defaultRenderValue = (
  value: string | number,
  options?: [string, string | number][],
  icon?: JSX.Element | string
) => {
  const selectedOption = options?.find((option) => {
    if (!option) return null;
    return option[1] === value;
  });
  if (!selectedOption) return <>{value}</>;
  return (
    <div className="flex items-center gap-2">
      {icon}
      {selectedOption[0]}
    </div>
  );
};

const renderPercentageValue = (value: string | number) => <>{Math.round(Number(value) || 0)}%</>;

const renderHoursValue = (value: string | number) => {
  const hours = Number(value) || 0;
  const hoursInt = Math.floor(hours);
  const minutes = Math.round((hours - hoursInt) * 60);
  if (hoursInt === 0) return `${minutes} min`;
  if (minutes === 0) return `${hoursInt} hour${hoursInt > 1 ? "s" : ""}`;
  return `${hoursInt} hour${hoursInt > 1 ? "s" : ""} and ${minutes} min`;
};

type Props = {
  label?: React.ReactNode;
  icon?: JSX.Element | string;
  info?: React.ReactNode;
  className?: string;
  options: [string, string | number][];
  optionsInfoTooltipContent?: [string, React.ReactNode][];
  disabledOptions?: string[];
  defaultValue?: string | number;
  isSearchable?: boolean;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  areOptionsDisabled?: boolean;
  renderValueType?: RenderValueType;
  tooltipContent?: React.ReactNode;
  fullWidth?: boolean;
  menuItemClassName?: string;
  additionalOnChange?: (value: string | number) => void;
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormSingleSelect = ({
  label,
  icon,
  info,
  options,
  className,
  isSearchable,
  optionsInfoTooltipContent,
  disabledOptions,
  defaultValue,
  isExclude,
  setIsExclude,
  areOptionsDisabled,
  renderValueType,
  tooltipContent,
  fullWidth,
  menuItemClassName,
  additionalOnChange,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  return (
    <Tooltip title={tooltipContent} disabled={!tooltipContent} placement="bottom" maxWidth={500}>
      <div className={"flex flex-col gap-1 " + (fullWidth ? "w-full" : "w-fit") + "  relative"}>
        {label && (
          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
            {label}
            {info && (
              <Tooltip title={info}>
                <InfoOutlinedIcon style={{ width: 14 }} />
              </Tooltip>
            )}
          </Typography>
        )}
        <SingleSelect<string | number>
          selected={field.value}
          className={clsx(className, "h-[34px] w-[192px]")}
          setSelected={(value) => {
            field.onChange({
              target: {
                name: field.name,
                value,
              },
            });
            if (additionalOnChange) additionalOnChange(value);
          }}
          renderOptionsFunc={(search) =>
            options
              .filter((entity) => {
                if (!isSearchable || !search) return true;
                return entity[0]?.includes(search) || false;
              })
              .map((option) => {
                if (!option) return null;

                const infoTooltipContent =
                  optionsInfoTooltipContent?.find((info) => info[0] === option[0])?.[1] || null;
                return (
                  <MenuItem
                    className={menuItemClassName}
                    value={option[1]}
                    key={option[1]}
                    disabled={areOptionsDisabled || disabledOptions?.includes(option[1] as string)}
                    sx={
                      !menuItemClassName
                        ? undefined
                        : {
                            "&.Mui-disabled": {
                              opacity: 1,
                              color: "black",
                            },
                          }
                    }
                  >
                    {icon}
                    {option[0]}
                    {option[1] === defaultValue && <RecommendedSpan />}
                    {infoTooltipContent && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <Tooltip title={infoTooltipContent} className="pointer-events-auto">
                          <InfoIcon width={14} height={14} className="ml-2" />
                        </Tooltip>
                      </div>
                    )}
                  </MenuItem>
                );
              })
          }
          renderValue={(value) => {
            switch (renderValueType) {
              case RenderValueType.Percentage:
                return renderPercentageValue(value);
              case RenderValueType.Hours:
                return renderHoursValue(value);
              default:
                return defaultRenderValue(value, options, icon);
            }
          }}
          disabled={!!props.disabled}
          isSearchable={isSearchable}
          isExclude={isExclude}
          setIsExclude={setIsExclude}
        />
        {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
      </div>
    </Tooltip>
  );
};

export default FormSingleSelect;
