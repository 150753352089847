import React, { useState } from "react";
import { windowBlock, mainTitleOfWindow } from "./componentUtils/overview";
import Grid from "@mui/material/Grid";
import WorkloadsTable, { Workload } from "./hpa/WorkloadsTable";
import { v4 as uuidv4 } from "uuid";
import { Alert, AlertProps, Snackbar } from "@mui/material";

export interface GraphRow {
  time: number;
  tuned: number;
  base: number;
  goalMetric: number;
}

// gets duration in minutes and returns a generated graph
const generateSavingsWorkloadData = (duration: number): GraphRow[] => {
  // 6 * duration - 10 seconds interval
  // 5 minutes would result in 30 data points

  return [
    {
      time: new Date(Date.now() - 1000 * 60 * duration).getTime(),
      base: 70,
      tuned: 70,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000)).getTime(),
      base: 90,
      tuned: 90,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 2)).getTime(),
      base: 180,
      tuned: 95,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 3)).getTime(),
      base: 253,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 4)).getTime(),
      base: 287,
      tuned: 100,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 5)).getTime(),
      base: 312,
      tuned: 94,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 6)).getTime(),
      base: 330,
      tuned: 88,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 7)).getTime(),
      base: 320,
      tuned: 87,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 8)).getTime(),
      base: 310,
      tuned: 86,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 9)).getTime(),
      base: 238,
      tuned: 89,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 10)).getTime(),
      base: 143,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 11)).getTime(),
      base: 100,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 12)).getTime(),
      base: 92,
      tuned: 89,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 13)).getTime(),
      base: 84,
      tuned: 87,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 14)).getTime(),
      base: 87,
      tuned: 99,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 15)).getTime(),
      base: 134,
      tuned: 110,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 16)).getTime(),
      base: 190,
      tuned: 98,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 17)).getTime(),
      base: 273,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 18)).getTime(),
      base: 310,
      tuned: 90,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 19)).getTime(),
      base: 300,
      tuned: 87,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 20)).getTime(),
      base: 291,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 21)).getTime(),
      base: 211,
      tuned: 88,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 22)).getTime(),
      base: 158,
      tuned: 85,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 23)).getTime(),
      base: 101,
      tuned: 87,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 24)).getTime(),
      base: 92,
      tuned: 91,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 25)).getTime(),
      base: 87,
      tuned: 97,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 26)).getTime(),
      base: 78,
      tuned: 92,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 27)).getTime(),
      base: 84,
      tuned: 89,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 28)).getTime(),
      base: 91,
      tuned: 87,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 29)).getTime(),
      base: 132,
      tuned: 102,
      goalMetric: 1,
    },
  ];
};
// const generateRecommendationBurst = (duration: number): GraphRow[] => {
//   // 6 * duration - 10 seconds interval
//   // 5 minutes would result in 30 data points
//
//   return [
//     {
//       time: new Date(Date.now() - 1000 * 60 * duration).getTime(),
//       trigger: 70,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000)).getTime(),
//       trigger: 90,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 2)).getTime(),
//       trigger: 95,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 3)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 4)).getTime(),
//       trigger: 100,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 5)).getTime(),
//       trigger: 94,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 6)).getTime(),
//       trigger: 88,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 7)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 8)).getTime(),
//       trigger: 86,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 9)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 10)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 11)).getTime(),
//       trigger: 93,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 12)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 13)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 14)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 15)).getTime(),
//       trigger: 99,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 16)).getTime(),
//       trigger: 110,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 17)).getTime(),
//       trigger: 98,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 18)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 19)).getTime(),
//       trigger: 90,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 20)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 21)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 22)).getTime(),
//       trigger: 88,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 23)).getTime(),
//       trigger: 85,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 24)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 25)).getTime(),
//       trigger: 91,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 26)).getTime(),
//       trigger: 97,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 27)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 28)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 29)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//   ];
// };

const generatePerformanceWorkloadData = (duration: number): GraphRow[] => {
  // 6 * duration - 10 seconds interval
  // 5 minutes would result in 30 data points

  return [
    {
      time: new Date(Date.now() - 1000 * 60 * duration).getTime(),
      tuned: 70,
      base: 70,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000)).getTime(),
      tuned: 90,
      base: 91,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 2)).getTime(),
      tuned: 110,
      base: 109,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 3)).getTime(),
      tuned: 137,
      base: 136,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 4)).getTime(),
      tuned: 183,
      base: 183,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 5)).getTime(),
      tuned: 207,
      base: 205,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 6)).getTime(),
      tuned: 243,
      base: 241,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 7)).getTime(),
      tuned: 278,
      base: 278,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 8)).getTime(),
      tuned: 294,
      base: 292,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 9)).getTime(),
      tuned: 299,
      base: 299,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 10)).getTime(),
      tuned: 287,
      base: 289,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 11)).getTime(),
      tuned: 281,
      base: 280,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 12)).getTime(),
      tuned: 273,
      base: 273,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 13)).getTime(),
      tuned: 268,
      base: 268,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 14)).getTime(),
      tuned: 260,
      base: 261,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 15)).getTime(),
      tuned: 256,
      base: 256,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 16)).getTime(),
      tuned: 249,
      base: 250,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 17)).getTime(),
      tuned: 259,
      base: 258,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 18)).getTime(),
      tuned: 265,
      base: 265,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 19)).getTime(),
      tuned: 270,
      base: 271,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 20)).getTime(),
      tuned: 274,
      base: 274,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 21)).getTime(),
      tuned: 282,
      base: 281,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 22)).getTime(),
      tuned: 276,
      base: 277,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 23)).getTime(),
      tuned: 269,
      base: 269,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 24)).getTime(),
      tuned: 248,
      base: 249,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 25)).getTime(),
      tuned: 219,
      base: 218,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 26)).getTime(),
      tuned: 198,
      base: 198,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 27)).getTime(),
      tuned: 191,
      base: 192,
      goalMetric: 1,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 28)).getTime(),
      tuned: 183,
      goalMetric: 1,
      base: 183,
    },
    {
      time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 29)).getTime(),
      tuned: 132,
      base: 133,
      goalMetric: 1,
    },
  ];
};
// const generateRecommendationOptimize = (duration: number): GraphRow[] => {
//   return [
//     {
//       time: new Date(Date.now() - 1000 * 60 * duration).getTime(),
//       trigger: 70,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000)).getTime(),
//       trigger: 90,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 2)).getTime(),
//       trigger: 95,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 3)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 4)).getTime(),
//       trigger: 99,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 5)).getTime(),
//       trigger: 91,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 6)).getTime(),
//       trigger: 88,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 7)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 8)).getTime(),
//       trigger: 86,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 9)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 10)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 11)).getTime(),
//       trigger: 93,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 12)).getTime(),
//       trigger: 94,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 13)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 14)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 15)).getTime(),
//       trigger: 93,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 16)).getTime(),
//       trigger: 99,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 17)).getTime(),
//       trigger: 110,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 18)).getTime(),
//       trigger: 101,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 19)).getTime(),
//       trigger: 98,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 20)).getTime(),
//       trigger: 96,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 21)).getTime(),
//       trigger: 91,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 22)).getTime(),
//       trigger: 88,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 23)).getTime(),
//       trigger: 85,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 24)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 25)).getTime(),
//       trigger: 91,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 26)).getTime(),
//       trigger: 97,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 27)).getTime(),
//       trigger: 92,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 28)).getTime(),
//       trigger: 89,
//       goalMetric: 1,
//     },
//     {
//       time: new Date(Date.now() - (1000 * 60 * duration - 10000 * 29)).getTime(),
//       trigger: 87,
//       goalMetric: 1,
//     },
//   ];
// };

export default function HPAView() {
  const workloadNames = ["package-picker", "ecom-store-front", "product-catalog-service"];
  const namespaceNames = ["high-priority-workloads", "low-priority-workloads", "scaleops-system"];
  const randomWorkloadName = (n: number): string => {
    return `${workloadNames[n]}`;
  };

  const [workloads, setWorkloads] = useState<Workload[]>(() => {
    return [
      {
        id: uuidv4(),
        namespace: namespaceNames[0],
        name: randomWorkloadName(1),
        triggers: [
          {
            current: "RPS ~ 278",
            recommendation: "RPS ~ 395",
            coastChange: "-337$",
            goalMetricChange: "No Change",
          },
        ],
        policy: {
          id: uuidv4(),
          name: "optimize-latency",
          schedule: 10,
          sla: [
            {
              name: "p99_latency",
              limit: 300,
              units: "ms",
              directionality: "<=",
            },
          ],
        },
        data: generatePerformanceWorkloadData(5),
        enabled: true,
      },
      {
        id: uuidv4(),
        namespace: namespaceNames[1],
        name: randomWorkloadName(0),
        triggers: [
          {
            current: "RPS ~ 300",
            recommendation: "RPS ~ 90",
            coastChange: "0$",
            goalMetricChange: "43% Improvement",
          },
        ],
        policy: {
          id: uuidv4(),
          name: "reduce-replicas",
          schedule: 5,
          sla: [
            {
              name: "p99_latancy",
              limit: 200,
              units: "ms",
              directionality: "<=",
            },
          ],
        },
        data: generateSavingsWorkloadData(5),
        enabled: false,
      },
    ];
  });

  const updateWorkload: (workload: Partial<Workload>) => Promise<Workload> = (workload: Partial<Workload>) => {
    return new Promise<Workload>((resolve, reject) => {
      let workedWorkload: Workload | undefined = undefined;
      setWorkloads((oldWorkloads) => {
        return oldWorkloads.map((w) => {
          if (w.id == workload.id) {
            workedWorkload = { ...w, ...workload };
            return workedWorkload;
          }
          return w;
        });
      });
      if (workedWorkload != undefined) {
        setSnackbar({ children: "Workload successfully saved", severity: "success" });
        resolve(workedWorkload);
      } else {
        const err = new Error("Workload not found");
        handleProcessRowUpdateError(err);
        reject(err);
      }
    });
  };
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);
  return windowBlock(
    "",
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {mainTitleOfWindow("HPA Workloads")}
        <WorkloadsTable workloads={workloads} updateWorkload={updateWorkload} />
      </Grid>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Grid>
  );
}
