import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  AttachHPAPolicy,
  AttachHPAPolicyParams,
  getDashboardByNamespace,
  GetDashboardByNamespaceResponse,
} from "../../api/fetcher";
import { useWorkloadsContext } from "../../WorkloadsContext";
import useFilterQueryParams, { FilterParamObject } from "./useFilterQueryParams";

type MutationParams = AttachHPAPolicyParams & { id: string; oldPolicyName: string };

const dashboardByNamespace = getDashboardByNamespace();
const attachHPAPolicy = AttachHPAPolicy();

const useAttachHPAPolicyToWorkload = () => {
  const queryClient = useQueryClient();
  const { updateOverriddenWorkloads } = useWorkloadsContext();

  const filterQueryParams: FilterParamObject = useFilterQueryParams();

  return useMutation((params: MutationParams) => attachHPAPolicy.queryFn(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries([dashboardByNamespace.queryKey, filterQueryParams]);

      queryClient.setQueryData(
        [dashboardByNamespace.queryKey, filterQueryParams],
        (oldData: GetDashboardByNamespaceResponse | undefined) => {
          return {
            ...oldData,
            workloads: oldData?.workloads?.map((wl) => {
              if (wl.id === params.id) {
                return { ...wl, policyName: params.name };
              }
              return wl;
            }),
          };
        }
      );

      updateOverriddenWorkloads({
        ids: [params.id],
        props: { policyName: params.name },
      });
    },
    onError: (error: Error, params: MutationParams) => {
      updateOverriddenWorkloads({
        ids: [params.id],
        props: {
          policyName: params.oldPolicyName,
        },
      });

      toast.error("Failed to attach HPA policy", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });

      console.log(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);

      toast.success("Successfully attached HPA policy", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    },
  });
};

export default useAttachHPAPolicyToWorkload;
