import { ListItemText, MenuItem } from "@mui/material";
import { useState } from "react";
import SingleSelect from "../../../components/SingleSelect";
import { TableType } from "../Tables/utils";
import { CostReportType } from "../utils";
import DailyChartContainer from "./DailyChartContainer";
import EntireWindowChartContainer from "./EntireWindowChartContainer";
import { ChartCostType, ChartType } from "./utils";

interface Props {
  selectedTable: TableType;
  reportType: CostReportType;
  hasSavings: boolean;
}

const ChartsContainer = ({ selectedTable, reportType, hasSavings }: Props) => {
  const [selectedChart, setSelectedChart] = useState<ChartType>(ChartType.EntireWindow);
  const [selectedCostType, setSelectedCostType] = useState<ChartCostType>(ChartCostType.Cost);

  return (
    <div className="border bg-white border-border rounded-lg p-5 pt-20 h-[400px] flex items-center justify-center relative">
      <div className="flex flex-col gap-2 absolute bg-white rounded-lg top-4 right-5 z-40">
        <SingleSelect<ChartType>
          label=""
          className="w-[190px]"
          selected={selectedChart}
          setSelected={(option) => {
            setSelectedChart(option);
          }}
          renderOptionsFunc={() =>
            Object.entries(ChartType).map((option) => {
              return (
                <MenuItem value={option[1]} key={option[1]}>
                  <ListItemText primary={option[1]} />
                </MenuItem>
              );
            })
          }
        />
        {hasSavings && (
          <SingleSelect<ChartCostType>
            label=""
            className="w-[190px]"
            selected={selectedCostType}
            setSelected={(option) => {
              setSelectedCostType(option);
            }}
            renderOptionsFunc={() =>
              Object.entries(ChartCostType).map((option) => {
                return (
                  <MenuItem value={option[1]} key={option[1]}>
                    <ListItemText primary={option[1]} />
                  </MenuItem>
                );
              })
            }
          />
        )}
      </div>
      {selectedChart === ChartType.Daily && (
        <DailyChartContainer selectedTable={selectedTable} reportType={reportType} costType={selectedCostType} />
      )}
      {selectedChart === ChartType.EntireWindow && (
        <EntireWindowChartContainer selectedTable={selectedTable} reportType={reportType} costType={selectedCostType} />
      )}
    </div>
  );
};

export default ChartsContainer;
