import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RightArrowWithFill from "../../../Icons/RightArrowWithFill";
import Button, { BUTTON_VARIANTS } from "../../Button";
import useUpdatePolicyByNamespace from "./useRebalaceMutation";

enum STATUS {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

interface Props {
  responseStatus: { state?: string; candidates?: string[]; inProgress?: string[]; successful?: string[] };
}

const RebalanceButtonContainer = ({ responseStatus }: Props) => {
  const [status, setStatus] = useState<STATUS>(STATUS.IDLE);
  const { mutate } = useUpdatePolicyByNamespace();

  useEffect(() => {
    // set the status based on candidates, inProgress and successful
    switch (true) {
      // success case
      case !!responseStatus.successful?.length &&
        !responseStatus.inProgress?.length &&
        !responseStatus.candidates?.length:
        setStatus(STATUS.SUCCESS);
        break;
      // loading case
      case !!responseStatus.inProgress?.length:
        setStatus(STATUS.LOADING);
        break;
      // idle case
      case !responseStatus.inProgress?.length &&
        !responseStatus.successful?.length &&
        !!responseStatus.candidates?.length:
        setStatus(STATUS.IDLE);
        break;
    }
  }, []);

  return (
    <div className="pt-8 w-full flex align-center relative gap-4">
      <Typography variant="caption" className="flex items-center max-w-[400px] text-text-lightBlack">
        Rebalance your cluster to optimize your costs and performance by evicting underutilized nodes.
      </Typography>
      <div className="bg-border w-[1px]" />
      {status === STATUS.LOADING && (
        <Typography variant="body2" className="flex items-center">
          Rebalancing
          <div className="dot-elastic ml-4" />
        </Typography>
      )}
      {status === STATUS.IDLE && (
        <Button
          label={
            <div className="flex justify-center items-center">
              Rebalance
              <RightArrowWithFill className="ml-2" width={14} height={14} outline="none" />
            </div>
          }
          variant={BUTTON_VARIANTS.mediumGreen}
          onClick={() => {
            mutate();
            setStatus(STATUS.LOADING);
          }}
        />
      )}
      {status === STATUS.SUCCESS && (
        <Typography variant="body2" className="flex items-center text-guideline-darkGreen ">
          Your cluster has been rebalanced successfully
        </Typography>
      )}
    </div>
  );
};

export default RebalanceButtonContainer;
