import { useQuery } from "@tanstack/react-query";
import { GetNamespacesResponse, getNamespaces } from "../../api/fetcher";
import { components } from "../../api/schema";

const { queryKey, queryFn } = getNamespaces();

const getNamespacesAndLabels = () => {
  const { data, error } = useQuery<GetNamespacesResponse>({
    queryKey: [queryKey],
    queryFn: () =>
      queryFn({
        multiCluster: true,
      }),
    keepPreviousData: true,
  });

  if (error) {
    console.log("Error fetching labels in getNamespacesAndLabels", error);
  }

  const namespaces = data?.namespaces
    ?.filter((ns) => ns !== undefined)
    .map((ns: components["schemas"]["V1Namespace"]) => ns.metadata?.name ?? "")
    .filter((ns) => ns != "");

  const labelsByKey = data?.namespaces?.reduce((acc, curr) => {
    if (!curr?.metadata?.labels) return acc;

    Object.entries(curr.metadata.labels).forEach(([key, value]) => {
      if (!acc[key] && value) {
        acc[key] = [value];
      } else if (value) {
        acc[key] = [...new Set([...acc[key], value])];
      }

      return acc;
    });

    return acc;
  }, {} as { [key: string]: string[] });

  return { namespaces, labelsByKey };
};

export default getNamespacesAndLabels;
