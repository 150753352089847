import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { defaultHPAPolicyValues } from "../../utils";

const minRreplicaPrectailOptions: [string, number][] = [
  ["60%", 60],
  ["70%", 70],
  ["80%", 80],
  ["90%", 90],
  ["100% (MAX)", 100],
];

const minRreplicaWindowOptions: [string, string][] = [
  ["7 days", "168h"],
  ["14 days", "336h"],
  ["21 days", "504h"],
];

interface Props {
  isCustomizedPolicy?: boolean;
}

const GeneralWorkloadOptimization = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Optimize static workloads"
        description="Automate to optimize the minimum replicas based on highest usage within the historical window."
      />
      <div className="flex flex-wrap gap-7">
        <FormSingleSelect
          label="Percentile"
          name="replicasPercentilePercentage"
          options={minRreplicaPrectailOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultHPAPolicyValues?.generalWorkloadsPercentile}
        />
        <FormSingleSelect
          label="History window (Max replicas)"
          name="replicasWindow"
          options={minRreplicaWindowOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultHPAPolicyValues?.generalWorkloadsWindow}
        />
      </div>
    </div>
  );
};

export default GeneralWorkloadOptimization;
