import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getClusters, GetClustersResponse } from "../api/fetcher";

const clustersApi = getClusters();

const useGetParentCluster = () => {
  const [parentCluster, setParentCluster] = useState<string | undefined>(undefined);

  const { data: clustersData } = useQuery<GetClustersResponse>({
    queryKey: [clustersApi.queryKey],
    queryFn: clustersApi.queryFn,
  });

  useEffect(() => {
    const parentClusterToSet = clustersData?.clusters?.find((cluster) => cluster.isParent);
    setParentCluster(parentClusterToSet?.name);
  }, [clustersData]);

  return parentCluster;
};

export default useGetParentCluster;
