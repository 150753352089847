import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../../api/fetcher";
import { ScaleOpsProduct } from "../../utils/typesUtils";

export type PoliciesStats = {
  totalWorkloadsCount: number;
  usageCount: Record<string, number>;
  usagePercentage: Record<string, number>;
  potentialCount: Record<string, number>;
  potentialPercentage: Record<string, number>;
};

const { queryKey, queryFn } = getDashboardByNamespace();

interface Props {
  scaleOpsProduct?: ScaleOpsProduct;
}

const usePoliciesStats = ({ scaleOpsProduct }: Props) => {
  const [output, setOutput] = useState<PoliciesStats>({
    totalWorkloadsCount: 0,
    usageCount: {},
    usagePercentage: {},
    potentialCount: {},
    potentialPercentage: {},
  });

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [queryKey, scaleOpsProduct],
    queryFn: () =>
      queryFn({
        scaleOpsProduct,
      }),
  });

  useEffect(() => {
    const workloads = data?.workloads;
    const policiesStats: PoliciesStats = {
      totalWorkloadsCount: 0,
      usageCount: {},
      usagePercentage: {},
      potentialCount: {},
      potentialPercentage: {},
    };

    if (workloads) {
      workloads.map((workload) => {
        let policyName = workload.policyName;
        policiesStats.totalWorkloadsCount += 1;
        policiesStats.usageCount[policyName] = policiesStats.usageCount[policyName]
          ? policiesStats.usageCount[policyName] + 1
          : 1;
        const isUsingSmartPolicy = workload.usingSmartPolicy;
        if (!isUsingSmartPolicy && workload.smartPolicyName) {
          policyName = workload.smartPolicyName;
          policiesStats.potentialCount[policyName] = policiesStats.potentialCount[policyName]
            ? policiesStats.potentialCount[policyName] + 1
            : 1;
        }
      });
      policiesStats.usagePercentage = Object.keys(policiesStats.usageCount).reduce((acc, policy) => {
        acc[policy] = Math.round((policiesStats.usageCount[policy] / policiesStats.totalWorkloadsCount) * 100);
        return acc;
      }, {} as Record<string, number>);
      policiesStats.potentialPercentage = Object.keys(policiesStats.potentialCount).reduce((acc, policy) => {
        acc[policy] = Math.round((policiesStats.potentialCount[policy] / policiesStats.totalWorkloadsCount) * 100);
        return acc;
      }, {} as Record<string, number>);

      setOutput(policiesStats);
    }
  }, [data]);
  return output;
};

export default usePoliciesStats;
