import { IconProps } from "./utils/utils";

const BulkIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M12.0833 20H2.08333C1.53099 19.9993 1.00147 19.7796 0.610917 19.3891C0.22036 18.9985 0.000653978 18.469 -7.62939e-06 17.9167L-7.62939e-06 7.91667C0.000653978 7.36434 0.22036 6.83482 0.610917 6.44426C1.00147 6.0537 1.53099 5.834 2.08333 5.83334H12.0833C12.6357 5.834 13.1652 6.0537 13.5557 6.44426C13.9463 6.83482 14.166 7.36434 14.1667 7.91667V17.9167C14.166 18.469 13.9463 18.9985 13.5557 19.3891C13.1652 19.7796 12.6357 19.9993 12.0833 20ZM2.08333 6.66667C1.75181 6.66667 1.43386 6.79837 1.19944 7.03279C0.965022 7.26721 0.833326 7.58515 0.833326 7.91667V17.9167C0.833326 18.2482 0.965022 18.5661 1.19944 18.8006C1.43386 19.035 1.75181 19.1667 2.08333 19.1667H12.0833C12.4148 19.1667 12.7328 19.035 12.9672 18.8006C13.2016 18.5661 13.3333 18.2482 13.3333 17.9167V7.91667C13.3333 7.58515 13.2016 7.26721 12.9672 7.03279C12.7328 6.79837 12.4148 6.66667 12.0833 6.66667H2.08333Z" />
        <path d="M15 17.0833H13.75C13.6395 17.0833 13.5335 17.0394 13.4554 16.9613C13.3772 16.8832 13.3333 16.7772 13.3333 16.6667C13.3333 16.5562 13.3772 16.4502 13.4554 16.372C13.5335 16.2939 13.6395 16.25 13.75 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V5C16.25 4.66848 16.1183 4.35053 15.8839 4.11611C15.6495 3.88169 15.3315 3.75 15 3.75H5C4.66848 3.75 4.35054 3.88169 4.11612 4.11611C3.8817 4.35053 3.75 4.66848 3.75 5V6.25C3.75 6.3605 3.7061 6.46649 3.62796 6.54463C3.54982 6.62277 3.44384 6.66666 3.33333 6.66666C3.22283 6.66666 3.11685 6.62277 3.0387 6.54463C2.96056 6.46649 2.91667 6.3605 2.91667 6.25V5C2.91733 4.44767 3.13703 3.91815 3.52759 3.52759C3.91815 3.13703 4.44767 2.91733 5 2.91666H15C15.5523 2.91733 16.0819 3.13703 16.4724 3.52759C16.863 3.91815 17.0827 4.44767 17.0833 5V15C17.0827 15.5523 16.863 16.0818 16.4724 16.4724C16.0819 16.863 15.5523 17.0827 15 17.0833Z" />
        <path d="M17.9167 14.1667H16.6667C16.5562 14.1667 16.4502 14.1228 16.372 14.0446C16.2939 13.9665 16.25 13.8605 16.25 13.75C16.25 13.6395 16.2939 13.5335 16.372 13.4554C16.4502 13.3772 16.5562 13.3333 16.6667 13.3333H17.9167C18.2482 13.3333 18.5661 13.2016 18.8006 12.9672C19.035 12.7328 19.1667 12.4149 19.1667 12.0833V2.08333C19.1667 1.75181 19.035 1.43387 18.8006 1.19945C18.5661 0.96503 18.2482 0.833333 17.9167 0.833333H7.91667C7.58515 0.833333 7.2672 0.96503 7.03278 1.19945C6.79836 1.43387 6.66667 1.75181 6.66667 2.08333V3.33333C6.66667 3.44384 6.62277 3.54982 6.54463 3.62796C6.46649 3.7061 6.36051 3.75 6.25 3.75C6.13949 3.75 6.03351 3.7061 5.95537 3.62796C5.87723 3.54982 5.83333 3.44384 5.83333 3.33333V2.08333C5.83399 1.531 6.0537 1.00148 6.44426 0.610925C6.83482 0.220368 7.36433 0.000661607 7.91667 0L17.9167 0C18.469 0.000661607 18.9985 0.220368 19.3891 0.610925C19.7796 1.00148 19.9993 1.531 20 2.08333V12.0833C19.9993 12.6357 19.7796 13.1652 19.3891 13.5557C18.9985 13.9463 18.469 14.166 17.9167 14.1667Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BulkIcon;
