import DateBox from "./DateBox";
import CalendarIcon from "../../Icons/CalendarIcon";
import { DateType, getFromDate } from "../../pages/Analytics/AnalyticsV2/utils";
import CustomDatesMenu, { CustomDateAnchorClassName } from "./CustomDatesMenu";
import { useEffect, useState } from "react";

interface Props {
  date: DateType;
  setDate: (date: DateType) => void;
  rangeOptions?: RangeOptions;
  minDateRangeInDays?: number;
}

export enum RangeOptions {
  SHORT = "short",
  MEDIUM = "medium",
  LONG = "long",
}

const RangeOptionsMap = {
  [RangeOptions.SHORT]: [3, 7, 14],
  [RangeOptions.MEDIUM]: [3, 7, 14, 30],
  [RangeOptions.LONG]: [3, 7, 14, 30, 60],
};

export const getDateOptions = (rangeOptions: RangeOptions = RangeOptions.SHORT): { label: string; value: number }[] => {
  const options = RangeOptionsMap[rangeOptions];

  return options?.map((option) => {
    return {
      label: `${option}d`,
      value: option,
    };
  });
};

const RangePicker = ({ date, setDate, rangeOptions, minDateRangeInDays }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dateOptions, setDateOptions] = useState(getDateOptions(rangeOptions));
  useEffect(() => {
    setDateOptions(getDateOptions(rangeOptions));
  }, [rangeOptions]);

  return (
    <div className="flex flex-row gap-0">
      <div
        className="flex justify-end relative"
        style={{
          zIndex: 9999,
          height: 30,
        }}
      >
        <div className="relative">
          <DateBox
            label={
              <div className="flex justify-center items-center gap-2">
                <CalendarIcon width={12} height={12} />
                Custom
              </div>
            }
            onClick={() => {
              setIsMenuOpen(true);
            }}
            isSelected={!date.range}
            hasLeftBorder
            className={`w-[100px] rounded-l-full ${CustomDateAnchorClassName}`}
          />
          <CustomDatesMenu
            date={date}
            setDate={setDate}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            minDateRangeInDays={minDateRangeInDays}
          />
        </div>
      </div>
      {dateOptions.map((option, index) => {
        return (
          <DateBox
            label={option.label}
            onClick={() => {
              setIsMenuOpen(false);
              setDate({
                to: Date.now(),
                from: getFromDate(option.value, Date.now()),
                range: option.label,
              });
            }}
            isSelected={date.range === option.label}
            className={index === dateOptions.length - 1 ? "rounded-r-full" : undefined}
          />
        );
      })}
    </div>
  );
};
export default RangePicker;
