import { useQuery } from "@tanstack/react-query";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../../../api/fetcher";

const dashboardByNamespace = getDashboardByNamespace();

const useGetDashboardByNamespace = (): GetDashboardByNamespaceResponse | undefined => {
  const { data, isLoading } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey],
    queryFn: () => dashboardByNamespace.queryFn({}),
  });

  if (isLoading || !data?.workloads) return undefined;

  return data;
};

export default useGetDashboardByNamespace;
