import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const getMaxHeight = (isOpen: boolean) => {
  const isTheScreenInZoom = window.innerWidth !== window.screen.width;

  switch (true) {
    case isTheScreenInZoom && isOpen:
      return undefined;
    case !isTheScreenInZoom && isOpen:
      return "20000px";
    case !isOpen:
      return 0;
  }
};

const ARROW_ICON_SIZE = "1.5rem";

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  titleVariant?: Variant;
  className?: string;
  titleClassName?: string;
  openOnMount?: boolean;
}

const AccordionSection = ({
  title,
  children,
  titleVariant = "body1",
  className,
  titleClassName,
  openOnMount,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (openOnMount) {
      setIsOpen(true);
    }
  }, [openOnMount]);

  return (
    <div className={clsx(className, "bg-white rounded-lg p-4 flex flex-col justify-center w-full")}>
      <Typography
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer items-center w-full"
        variant={titleVariant}
      >
        <Typography variant={titleVariant} fontWeight={700} className={clsx(titleClassName, "grow select-none")}>
          {title}
        </Typography>
        <div>
          {isOpen ? (
            <KeyboardArrowUp
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          )}
        </div>
      </Typography>
      <div
        style={{
          maxHeight: getMaxHeight(isOpen),
          overflow: "hidden",
          transition: isOpen ? "max-height 0.3s ease-in" : undefined,
          minHeight: "fit-content",
        }}
      >
        <div className="mt-8">{isOpen ? children : null}</div>
      </div>
    </div>
  );
};

export default AccordionSection;
