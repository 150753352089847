import { useQueryParam, BooleanParam } from "use-query-params";
import MultiSelectByQueryParams from "../../../components/MultiSelectByQueryParams";
import useGetTagsFromClusters from "../../../components/reports/useGetTagsFromClusters";
import useGetClusterNames from "../../../MainMenu/useGetClusterNames";

const HAS_TAGS_FILTER = false;
interface Props {
  isMultiCluster?: boolean;
  hasLeftBorder?: boolean;
  withTags?: boolean;
}

const AnalyticFilters = ({ isMultiCluster, hasLeftBorder = true, withTags = true }: Props) => {
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);

  const tags = useGetTagsFromClusters();
  const clusterNames = useGetClusterNames();

  return (
    <div className="flex gap-4 items-center ml-4">
      {isMultiCluster && (
        <>
          {hasLeftBorder && <div className="bg-border h-8 w-[1px]" />}
          {HAS_TAGS_FILTER && withTags && (
            <MultiSelectByQueryParams
              hasIsExclude={false}
              options={tags}
              queryKey="tags"
              tooltipPrefix={
                isTagsExclude ? (
                  <>
                    tags <b>exclude: </b>
                  </>
                ) : (
                  <>
                    tags <b>include: </b>
                  </>
                )
              }
              hasVirtualizedList
            />
          )}
          <MultiSelectByQueryParams
            hasIsExclude={false}
            options={clusterNames}
            queryKey="clusters"
            hasVirtualizedList
          />
        </>
      )}
    </div>
  );
};

export default AnalyticFilters;
