import { MenuItem, TextField } from "@mui/material";
import List from "@mui/material/List";
import { components } from "../../../api/schema";
import ListItem from "@mui/material/ListItem";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";

export interface MetricConfProps {
  policyMetricGoalsList: components["schemas"]["V1alpha1MetricConfWithValue"][] | undefined;
  onChange: (metricsConfList: components["schemas"]["V1alpha1MetricConfWithValue"][]) => void;
  disabled: boolean;
  availableMetricsList: components["schemas"]["V1alpha1MetricConf"][];
  valueTitle: string;
}

interface MetricConfListItemProps {
  metricName: string;
  value?: number;
  labels?: string[];
  availableMetricsList: components["schemas"]["V1alpha1MetricConf"][];
  queryToolTip?: string;
  disabled?: boolean;
  onDelete: (name: string) => void;
  onChange: (name: string, value?: number, query?: string) => void;
  valueTitle: string;
}

function MetricListItem(props: MetricConfListItemProps) {
  const { availableMetricsList, disabled, onDelete, onChange, valueTitle } = props;

  const theme = useTheme();

  const [name, setName] = useState<string>(props.metricName);
  const [value, setValue] = useState<number | undefined>(props.value);

  useEffect(() => {
    onChange(name, value);
  }, [name, value]);

  const metricNamesFromConfs = (metricGoalsList: components["schemas"]["V1alpha1MetricConf"][]) => {
    return metricGoalsList.map((m) => m.name || "");
  };

  return (
    <FormControl>
      <ListItem sx={{ display: "flex", flexWrap: "wrap", p: 0 }}>
        <Box
          display="flex"
          marginTop={1}
          sx={{ "& > :not(style)": { m: 0, width: "90%" } }}
          justifyContent="space-between"
          component={"form"}
        >
          <Box display="flex" marginTop={0} justifyContent="space-between" sx={{ "& > :not(style)": { m: 0 } }}>
            <Box style={{ width: "280px" }}>
              <InputLabel
                shrink={true}
                margin={"dense"}
                focused={false}
                sx={{ paddingTop: "10px" }}
                defaultValue={name}
                id="metrics_name"
                variant="standard"
              >
                Metrics
              </InputLabel>
              <Select
                labelId="metrics_name_label"
                id="metrics_name"
                defaultValue={name}
                label="Metrics"
                style={{ width: "170px" }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                disabled={disabled}
                variant="standard"
              >
                {metricNamesFromConfs(availableMetricsList).map((metricName: string) => (
                  <MenuItem
                    key={metricName}
                    value={metricName}
                    style={{
                      fontWeight: theme.typography.fontWeightRegular,
                    }}
                  >
                    {metricName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <TextField
              id="value"
              label={valueTitle}
              type="number"
              style={{ float: "left", width: "18ch", marginRight: "7ch" }}
              defaultValue={value}
              variant="standard"
              disabled={disabled}
              InputLabelProps={{
                shrink: true,
                margin: "dense",
              }}
              inputProps={{
                step: "1",
                min: "0",
                inputMode: "numeric",
                margin: "dense",
              }}
              onChange={(event) => {
                setValue(Number(event.target.value));
              }}
            />
          </Box>
          <Box sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
            <IconButton
              onClick={() => onDelete(name)}
              disabled={disabled}
              key="delete_trigger_metric"
              color="primary"
              aria-label="delete"
              size={"medium"}
              style={{ paddingTop: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </FormControl>
  );
}
export default function MetricConfList(props: MetricConfProps) {
  const { onChange, disabled, availableMetricsList, valueTitle }: MetricConfProps = props;
  const [metricsConfList, setMetricsConfList] = useState(props.policyMetricGoalsList || []);

  const deleteMetric = (index: number): void => {
    const updatedMetricsConfList = [...metricsConfList];
    updatedMetricsConfList.splice(index, 1);
    setMetricsConfList(updatedMetricsConfList);
  };

  const emptyMetrics = () => {
    const metric: components["schemas"]["V1alpha1MetricConfWithValue"] = {};
    metric.metricConfName = "";
    metric.value = 0;
    return metric;
  };

  const isValidForUpdateMetricsConfList = (
    metricsConfList: components["schemas"]["V1alpha1MetricConfWithValue"][]
  ): boolean => {
    const invalidItems: number = metricsConfList.map(({ metricConfName }) => {
      return metricConfName == "";
    }).length;
    return invalidItems > 0;
  };

  useEffect(() => {
    if (isValidForUpdateMetricsConfList(metricsConfList)) {
      onChange(metricsConfList);
    }
  }, [metricsConfList]);

  const addMetric = (): void => {
    setMetricsConfList(metricsConfList.concat(emptyMetrics()));
  };

  return (
    <Box>
      <List dense={true}>
        {metricsConfList?.map((metricConf: components["schemas"]["V1alpha1MetricConfWithValue"], index: number) => (
          <MetricListItem
            key={index.toString() + (metricConf?.metricConfName || "")}
            metricName={metricConf.metricConfName || ""}
            value={metricConf.value || undefined}
            availableMetricsList={availableMetricsList}
            onDelete={() => {
              deleteMetric(index);
            }}
            onChange={(name, value) => {
              const updatedMetricsConfList = [...metricsConfList];
              updatedMetricsConfList[index] = {
                ...metricConf,
                metricConfName: name,
                value: value,
              };
              setMetricsConfList(updatedMetricsConfList);
            }}
            valueTitle={valueTitle}
          />
        ))}
        <Box sx={{ paddingTop: "15px", alignItems: "left", m: -1 }}>
          <IconButton
            color="primary"
            aria-label="add"
            size={"medium"}
            disabled={disabled}
            key="add_metric"
            onClick={() => addMetric()}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </List>
    </Box>
  );
}
