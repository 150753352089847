import React from "react";
import { components } from "../api/schema";
import { policyType } from "./policyUtils";
import useGetApi from "../api/useGetApi";

export const UpdateAuto = (
  checked: boolean,
  id: string | undefined,
  type: string | undefined,
  namespace: string | undefined
) => {
  const api = useGetApi();

  if (id != undefined && type != undefined && namespace != undefined) {
    return api.getFetcher().path("/api/v1/auto/{namespace}/{workloadType}/{workloadName}").method("post").create()({
      namespace: namespace,
      workloadName: id,
      workloadType: type,
      state: checked,
    });
  } else {
    return Promise.resolve();
  }
};

export const ApplyRecommendation = (
  namespace: string,
  ownerName: string,
  ownerType: string,
  recommendationType: string
) => {
  const api = useGetApi();

  return api.getFetcher().path("/api/v1/recommendation/{namespace}/{name}/{muttype}/apply").method("patch").create()({
    namespace: namespace,
    name: ownerName,
    type: ownerType,
    muttype: recommendationType,
  });
};

export const FetchAndSetActivePolicyByName = (
  policies: components["schemas"]["V1alpha1Policy"][],
  policyName: string,
  setActivePolicy: React.Dispatch<React.SetStateAction<components["schemas"]["V1alpha1Policy"] | undefined>>
) => {
  const api = useGetApi();

  const changedPolicy = policies.find((r) => r.metadata?.name == policyName);

  if (policyType(changedPolicy) != "Schedule") {
    setActivePolicy(changedPolicy);
  } else {
    return api
      .getFetcher()
      .path("/api/v1/policy/{name}")
      .method("get")
      .create()({
        name: policyName,
      })
      .then((response) => {
        if (response.ok) setActivePolicy(response.data.activePolicy);
        else setActivePolicy(undefined);
      });
  }
  return Promise.resolve();
};
