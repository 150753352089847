import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateConsolidation, GetNodeGroupInfo, GetNodeGroupInfoResponse } from "../../api/fetcher";
import { components } from "../../api/schema";

const useUpdateConsolidation = () => {
  const { queryKey: GetNodeGroupInfoQueryKey } = GetNodeGroupInfo();
  const queryClient = useQueryClient();
  const consolidation = updateConsolidation();

  return useMutation((params: components["schemas"]["UpdateConsolidationInput"]) => consolidation.queryFn(params), {
    onMutate: async ({ provisionerName, optimize }) => {
      await queryClient.cancelQueries([GetNodeGroupInfoQueryKey]);

      const previousNodeGroupInfo = queryClient.getQueryData<GetNodeGroupInfoResponse>([GetNodeGroupInfoQueryKey]);

      if (previousNodeGroupInfo) {
        queryClient.setQueryData<GetNodeGroupInfoResponse>([GetNodeGroupInfoQueryKey], {
          ...previousNodeGroupInfo,
          provisioners: previousNodeGroupInfo.provisioners.map((provisioner) => {
            if (provisioner.name === provisionerName) {
              return {
                ...provisioner,
                consolidate: optimize,
                annotation: {
                  ...provisioner.annotation,
                  "scaleops/provisioner": optimize ? "consolidate" : "spread",
                },
              };
            }

            return provisioner;
          }),
        });
      }

      return { previousNodeGroupInfo };
    },
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error("Provisioners consolidation failed to update");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GetNodeGroupInfoQueryKey]);
      toast.success("Provisioners consolidation was updated successfully");
    },
  });
};

export default useUpdateConsolidation;
