import { IconProps } from "./utils/utils";

const CircleArrowUpWithFillIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="0.35" />
      <path
        d="M15.0167 8.04584L10.2042 3.23334C10.0875 3.11667 9.9125 3.11667 9.79584 3.23334L4.98334 8.04584C4.89584 8.13334 4.86667 8.25 4.925 8.36667C4.95417 8.45417 5.07084 8.54167 5.1875 8.54167H7.84167V16.5625C7.84167 16.7375 7.95834 16.8542 8.13334 16.8542H11.8375C12.0125 16.8542 12.1292 16.7375 12.1292 16.5625V8.54167H14.7833C14.9 8.54167 15.0167 8.48334 15.0458 8.36667C15.1333 8.25 15.1042 8.13334 15.0167 8.04584Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleArrowUpWithFillIcon;
