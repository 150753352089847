import { LegendType } from "recharts";
import { OverviewChartColors } from "./overviewUtils";
import { Typography } from "@mui/material";

export const StripsSvg = (
  <svg width="20" height="10">
    <defs>
      <pattern id="pattern-stripe" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
        <rect width="2" height="4" fill="red" opacity={0.4} />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#pattern-stripe)" />
  </svg>
);

export const stripsPattern = (
  <pattern id="color-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
    <rect width="1" height="8" transform="translate(0,0)" fill={OverviewChartColors.Waste} />
  </pattern>
);

interface Props {
  showRecommended?: boolean;
  showAllocatable?: boolean;
}

const LegendPayloads = ({ showRecommended, showAllocatable }: Props) => {
  const recommended = {
    id: "recommended",
    type: "rect" as LegendType,
    value: "Recommended",
    color: OverviewChartColors.Recommended,
  };

  const allocable = {
    id: "allocatable",
    type: "rect" as LegendType,
    value: "Allocatable",
    color: OverviewChartColors.Allocatable,
  };

  const legends = [
    {
      id: "usage",
      type: "rect" as LegendType,
      value: "Usage",
      color: OverviewChartColors.Usage,
    },
    {
      id: "request",
      type: "rect" as LegendType,
      value: "Request",
      color: OverviewChartColors.Request,
    },
    {
      id: "waste",
      type: "rect" as LegendType,
      value: "Waste",
      color: OverviewChartColors.Waste,
      fill: `url(#color-stripe)`,
    },
    ...(showAllocatable ? [allocable] : []),
    ...(showRecommended ? [recommended] : []),
  ];

  return (
    <div className="flex gap-2 pl-[80px]">
      {legends.map((payload, index) => {
        const regularRec = (
          <div
            className="w-4 h-2"
            style={{
              backgroundColor: payload.color,
            }}
          />
        );

        return (
          <div className="flex items-center gap-2" key={index}>
            {payload.value === "Waste" ? StripsSvg : regularRec}
            <Typography
              variant="caption"
              style={{
                color: payload.color,
              }}
            >
              {payload.value}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default LegendPayloads;
