import { Typography } from "@mui/material";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CurveType } from "recharts/types/shape/Curve";
import { GetReportsGraphResponse } from "../../api/fetcher";
import { SCALEOPS_COLORS } from "../../colors";
import ChartTooltipElement from "../../components/ChartTooltipElement";
import ChartTooltipTime from "../../components/ChartTooltipTime";
import Loading from "../../components/Loading";
import { DateType } from "../../pages/Analytics/AnalyticsV2/utils";
import {
  capitalizeFirstLetter,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  spacedCamelCase,
} from "../../utils/formatterUtils";
import { CHART_WRAPPER_CLASS_NAME, NO_OUTLINE, TOOLTIP_WRAPPER_CLASS_NAME } from "../../utils/styleUtils";
import useHpaOptimizationEnabled from "../WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { GraphLine, LineStyle, ResourceType } from "./graphUtils";
import { getDeducedGraphData } from "./utils";
import { adjustedDayjs } from "../../utils/dateAndTimeUtils";

export const savingDiffPattern = (
  <pattern id="color-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
    <rect width="2" height="8" fill={SCALEOPS_COLORS.main.green} />
  </pattern>
);

type GraphData = {
  [key: string]: number | string | undefined;
}[];

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string }[];
  label?: string;
  selectedChartComponents: string[];
  resourceType: ResourceType;
  valueSuffix?: React.ReactNode;
  hasSavingsDiff?: boolean;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  selectedChartComponents,
  hasSavingsDiff,
  resourceType,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    let diffValue = 0;
    if (hasSavingsDiff) {
      const requestValue = payload.find((item) => item.name === GraphLine.Requests)?.value;
      const requestOriginValue = payload.find((item) => item.name === GraphLine.RequestsOrigin)?.value;
      diffValue = Number(requestOriginValue) - Number(requestValue);
      diffValue = Math.round(diffValue * 100) / 100;
    }

    payload = payload.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);

    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_TIME_FORMAT} />}
        {payload.reverse().map((item, index) => {
          if (selectedChartComponents.includes(item.name ?? "")) {
            let value: string | number = "";
            if (resourceType === ResourceType.Memory) {
              value = prettyBytes(Number(item.value) || 0.0, {
                bits: false,
                binary: true,
              });
            } else {
              value = item.value;
            }

            return (
              <ChartTooltipElement
                key={index}
                color={item?.stroke ?? ""}
                value={<>{value}</>}
                label={capitalizeFirstLetter(spacedCamelCase(item.name ?? ""))}
              />
            );
          }
        })}
        {hasSavingsDiff && !isNaN(diffValue) && diffValue > 0 && (
          <ChartTooltipElement
            key={diffValue}
            color={SCALEOPS_COLORS.main.green}
            value={
              <>
                {resourceType === ResourceType.Memory
                  ? prettyBytes(Number(diffValue) || 0.0, {
                      bits: false,
                      binary: true,
                    })
                  : diffValue}
              </>
            }
            label="Savings"
            isDashed
          />
        )}
      </div>
    );
  }

  return null;
};

const HEIGHT = "h-[250px]";

interface Props {
  selectedGraphLines: string[];
  resourceType: ResourceType;
  data?: GetReportsGraphResponse;
  isLoading: boolean;
  error: Error | null;
  isError: boolean;
  setDate?: (date: DateType) => void;
  includedGraphLines?: GraphLine[];
  hasSavingsDiff?: boolean;
}

const ResourceGraph = ({
  selectedGraphLines,
  resourceType,
  setDate,
  data,
  isLoading,
  error,
  isError,
  includedGraphLines = [
    GraphLine.Usage,
    GraphLine.Requests,
    GraphLine.RequestsOrigin,
    GraphLine.Recommendation,
    GraphLine.Allocatable,
  ],
  hasSavingsDiff,
}: Props) => {
  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);
  const [animationActive, setAnimationActive] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined);

  const hpaOptimizationEnabled = useHpaOptimizationEnabled();
  useEffect(() => {
    setAnimationActive(true);
    setTimeout(() => setAnimationActive(false), 3000);

    return () => {
      setAnimationActive(false);
    };
  }, []);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && setDate) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  useEffect(() => {
    let parsedData: {
      [key: string]: number | string;
    }[] =
      data?.values?.map((dataPoint) => {
        const { timestamp, values } = dataPoint;
        if (values)
          Object.entries(values).forEach(([key, value]) => {
            if (values && typeof value === "number" && key !== "timestamp") {
              return (values[key] = Math.round(value * 100) / 100);
            }
          });
        return {
          date: String(timestamp),
          ...values,
        };
      }) ?? [];

    parsedData = parsedData.map((dataPoint) => {
      const {
        allocatableCpu,
        allocatableMemory,
        cpuRequestsOrigin,
        cpuRequestsOriginWithReplicas,
        memoryRequestsOrigin,
        memoryRequestsOriginWithReplicas,
        ...rest
      } = dataPoint;
      return {
        ...rest,
        cpuRequestsOrigin:
          hpaOptimizationEnabled && cpuRequestsOriginWithReplicas ? cpuRequestsOriginWithReplicas : cpuRequestsOrigin,
        memoryRequestsOrigin:
          hpaOptimizationEnabled && memoryRequestsOriginWithReplicas
            ? memoryRequestsOriginWithReplicas
            : memoryRequestsOrigin,
        [resourceType === ResourceType.CPU ? "cpuAllocatable" : "memoryAllocatable"]:
          resourceType === ResourceType.CPU ? allocatableCpu : allocatableMemory,
      };
    });

    setGraphData(getDeducedGraphData(parsedData));
  }, [data]);

  if (isLoading) {
    return (
      <div className={clsx(HEIGHT, "w-[50%]")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
  }

  const firstXPointString = graphData && String(graphData[0]?.date);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix() * 1000;
  const lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix() * 1000;

  let title = "";
  switch (resourceType) {
    case ResourceType.CPU:
      title = "CPU";
      break;
    case ResourceType.Memory:
      title = "Memory";
      break;
    case ResourceType.Rightsize:
      title = "Optimized rightsize pods";
      break;
    case ResourceType.Unevictable:
      title = "Optimized unvictable pods";
      break;
    default:
      break;
  }

  return (
    <div className={clsx(HEIGHT, CHART_WRAPPER_CLASS_NAME, "w-[50%]")}>
      <Typography variant="body2" className="w-full text-center">
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          data={graphData}
          onMouseDown={(e) => {
            e.activeLabel &&
              setDate &&
              setSelectPosition({
                ...selectPosition,
                from: adjustedDayjs(e.activeLabel).unix() * 1000,
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              setDate &&
              setSelectPosition({
                ...selectPosition,
                to: adjustedDayjs(e.activeLabel).unix() * 1000,
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to && setDate) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <Tooltip
            content={
              <CustomTooltip
                selectedChartComponents={selectedGraphLines}
                resourceType={resourceType}
                hasSavingsDiff={hasSavingsDiff}
              />
            }
            wrapperStyle={NO_OUTLINE}
          />
          {Object.values(GraphLine).map((value) => {
            if (!selectedGraphLines.includes(value) || !includedGraphLines.includes(value)) return null;
            const dataKey = `${resourceType}${capitalizeFirstLetter(value)}`;

            return (
              <Area
                type={LineStyle[value].type as CurveType}
                dataKey={dataKey}
                name={value}
                strokeWidth={LineStyle[value].strokeWidth}
                stroke={LineStyle[value].stroke}
                fill={
                  hasSavingsDiff && value === GraphLine.RequestsOrigin ? "url(#color-stripe)" : LineStyle[value].fill
                }
                fillOpacity={LineStyle[value].fillOpacity}
                dot={false}
                isAnimationActive={animationActive}
              />
            );
          })}
          {selectedGraphLines.includes(GraphLine.RequestsOrigin) &&
            includedGraphLines.includes(GraphLine.RequestsOrigin) && (
              <Area
                type={LineStyle[GraphLine.RequestsOrigin].type as CurveType}
                dataKey={`${resourceType}${capitalizeFirstLetter(GraphLine.RequestsOrigin)}`}
                name={GraphLine.RequestsOrigin}
                strokeWidth={LineStyle[GraphLine.RequestsOrigin].strokeWidth}
                stroke={LineStyle[GraphLine.RequestsOrigin].stroke}
                fill="none"
                fillOpacity={LineStyle[GraphLine.RequestsOrigin].fillOpacity}
                dot={false}
                isAnimationActive={animationActive}
              />
            )}
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <YAxis
            style={{ fontSize: "x-small" }}
            tickFormatter={(tick: string) => {
              if (Number(tick) === -Infinity) return "";
              if (resourceType === ResourceType.Memory) {
                return prettyBytes(Number(tick) || 0.0, {
                  bits: false,
                  binary: true,
                });
              }
              return tick;
            }}
            strokeWidth={2}
          />
          <XAxis
            dataKey="date"
            style={{ fontSize: "x-small" }}
            interval={graphData ? Math.floor(graphData.length / 5) : undefined}
            strokeWidth={2}
            tickFormatter={(tick: string) => {
              if (graphData === undefined || graphData?.length === 0) return "";
              return adjustedDayjs(String(tick).replace(/\//g, "-")).format(DEFAULT_DATE_FORMAT);
            }}
          />
          {hasSavingsDiff &&
            selectedGraphLines.includes(GraphLine.Savings) &&
            selectedGraphLines.includes(GraphLine.RequestsOrigin) &&
            selectedGraphLines.includes(GraphLine.Requests) && <defs>{savingDiffPattern}</defs>}
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke={SCALEOPS_COLORS.main.blue}
              fill={SCALEOPS_COLORS.main.blue}
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ResourceGraph;
