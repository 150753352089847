import ChartTooltipElement from "../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../components/ChartTooltipTime";
import { customNumberFormatter, DEFAULT_DATE_FORMAT } from "../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../utils/styleUtils";

const MAX_ELEMENT_TO_DISPLAY = 10;

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; color: string }[];
  label?: string;
}

const DailyChartTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_FORMAT} />}
        {payload.slice(0, MAX_ELEMENT_TO_DISPLAY).map((p) => (
          <ChartTooltipElement color={p.color} value={`$${customNumberFormatter(Number(p.value))}`} label={p.name} />
        ))}

        {payload.length > MAX_ELEMENT_TO_DISPLAY && (
          <span className="text-[10px] mt-2">+ {payload.length - MAX_ELEMENT_TO_DISPLAY} more items</span>
        )}
      </div>
    );
  }

  return null;
};

export default DailyChartTooltip;
