import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { GetNodesAndCost, GetNodesAndCostResponse } from "../../api/fetcher";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import NodesCostChart from "./NodeCostChart";
import { GraphDataPoint, TabOptions } from "./nodesCostUtils";
import { adjustedDayjs } from "../../utils/dateAndTimeUtils";

const STEP_SIZE = 600; // 10 minutes
const HAS_NODES_AND_TABS = false;

const NodesCostChartContainer = () => {
  const nodesAndCost = GetNodesAndCost();
  const [selectedTab, setSelectedTab] = useState(TabOptions.Cost);

  const [from] = useState(adjustedDayjs().subtract(30, "day").unix() * 1000);
  const [to] = useState(adjustedDayjs().unix() * 1000);

  const { data, isLoading, isError, error } = useQuery<GetNodesAndCostResponse, Error>({
    queryKey: [nodesAndCost.queryKey, from, to, STEP_SIZE, selectedTab],
    queryFn: () =>
      nodesAndCost.queryFn({
        from,
        to,
        stepSizeSeconds: STEP_SIZE,
      }),
  });

  if (isError) {
    console.log("Error fetching", nodesAndCost.queryKey, error);
    return null;
  }

  const parseData: GraphDataPoint[] =
    data?.dataPoints?.map((dataPoint) => {
      const currentCost = Number(dataPoint.values?.currentCost);
      const currentNodes = Number(dataPoint.values?.currentNodes);
      const recommendedCost = Number(dataPoint.values?.recommendedCost);
      const recommendedNodes = Number(dataPoint.values?.recommendedNodes);

      return {
        timestamp: String(dataPoint.timestamp),
        currentCost: currentCost,
        costWasteMask: Number.isNaN(recommendedCost) ? currentCost : 0,
        recommendedCost: recommendedCost,
        currentNodes: currentNodes,
        nodesWasteMask: Number.isNaN(recommendedNodes) ? currentNodes : 0,
        recommendedNodes: recommendedNodes,
      };
    }) || [];

  return (
    <div
      className={clsx(
        "bg-white w-full rounded-bl-lg border-br-lg border-tr-lg flex items-center justify-center relative h-[230px]",
        {
          "mt-[40px]": HAS_NODES_AND_TABS,
        }
      )}
    >
      {HAS_NODES_AND_TABS && (
        <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-34px] w-full")}>
          {Object.entries(TabOptions).map(([key, value]) => {
            return (
              <Tab
                key={key}
                isSelected={selectedTab === value}
                onClick={() => {
                  setSelectedTab(value);
                }}
                name={value}
                dataTestId={`node-cost-chart-${key}-tab`}
              />
            );
          })}
        </div>
      )}
      {isLoading && <CircularProgress />}
      {data && <NodesCostChart data={parseData} selectedTab={selectedTab} />}
    </div>
  );
};

export default NodesCostChartContainer;
