import { useQuery } from "@tanstack/react-query";
import { GetLabelsResponseV2, getLabelsV2 } from "../../api/fetcher";
import MultiSelectByQueryParams from "../MultiSelectByQueryParams";
import { AGGREGATION_ANNOTATION_QUERY_KEY, AGGREGATION_LABEL_QUERY_KEY } from "./utils";
import React from "react";

const { queryKey, queryFn } = getLabelsV2();

interface Props {
  labelsQueryKey?: string;
  annotationsQueryKey?: string;
  isMultiCluster?: boolean;
}

const LabelsAndAnnotationsFilter = ({
  labelsQueryKey = AGGREGATION_LABEL_QUERY_KEY,
  annotationsQueryKey = AGGREGATION_ANNOTATION_QUERY_KEY,
  isMultiCluster,
}: Props) => {
  const { data, isLoading, error } = useQuery<GetLabelsResponseV2>({
    queryKey: [queryKey, isMultiCluster],
    queryFn: () => queryFn(isMultiCluster, true),
  });

  if (error) {
    console.log("Error fetching labels", error);
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <MultiSelectByQueryParams options={data?.labels} queryKey={labelsQueryKey} hasIsExclude={false} name="labels" />
      <MultiSelectByQueryParams
        options={data?.annotations}
        queryKey={annotationsQueryKey}
        hasIsExclude={false}
        name="annotations"
      />
    </div>
  );
};

export default LabelsAndAnnotationsFilter;
