import React from "react";
import Tooltip from "./Tooltip";

export enum TooltipContent {
  None,
  FeatureNotSupported,
}

const contentMap = new Map<TooltipContent, React.ReactNode>([
  [TooltipContent.None, null],
  [
    TooltipContent.FeatureNotSupported,
    <>
      Feature is <b>not supported in this cluster</b>
      <br />
      Please upgrade to latest version
    </>,
  ],
]);

interface VisibilityProps {
  disabled?: boolean;
  disabledTooltipContent?: TooltipContent;
  toolTipMaxWidth?: number;
}

const withDisabled =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P & VisibilityProps) => {
    const { disabled, disabledTooltipContent = TooltipContent.None, toolTipMaxWidth } = props;
    const tooltipContent = contentMap.get(disabledTooltipContent) || null;

    return (
      <Tooltip
        disabled={!disabled || !disabledTooltipContent}
        title={tooltipContent}
        addScaleOpsCubeIconToTopLeft
        maxWidth={toolTipMaxWidth}
      >
        <div className="relative">
          <WrappedComponent {...props} />
          {disabled && <div className="w-full h-full bg-white opacity-60 cursor-not-allowed absolute top-0 left-0" />}
        </div>
      </Tooltip>
    );
  };

export default withDisabled;
