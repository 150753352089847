import { Dialog, DialogContent } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { HPAPolicy } from "../../../api/fetcher";
import Button from "../../../components/Button";
import DialogTitle from "../../../components/DialogTitle";
import FormInput from "../../../components/formComponents/FormInput";
import useCreateHPAPolicy from "../hooks/useCreateHPAPolicy";
import { policyNameValidation } from "../utils";

interface Props {
  selectedPolicyToDuplicate: HPAPolicy | undefined;
  setSelectedPolicyToDuplicate: React.Dispatch<React.SetStateAction<HPAPolicy | undefined>>;
  existingPolicyNames: string[];
}

const DuplicateHPAPolicy = ({
  selectedPolicyToDuplicate,
  setSelectedPolicyToDuplicate,
  existingPolicyNames,
}: Props) => {
  const createHPAPolicy = useCreateHPAPolicy();

  const handleClose = () => {
    setSelectedPolicyToDuplicate(undefined);
  };

  return (
    <Dialog
      open={!!selectedPolicyToDuplicate}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle title="Duplicate Policy" />
      <DialogContent className="h-[12.5rem] w-[25rem]">
        <Formik
          initialValues={{
            newPolicyName: selectedPolicyToDuplicate?.metadata?.name
              ? selectedPolicyToDuplicate?.metadata?.name + "-copy"
              : undefined,
          }}
          validationSchema={Yup.object({
            newPolicyName: policyNameValidation(existingPolicyNames),
          })}
          onSubmit={(values) => {
            createHPAPolicy.mutate({
              policy: {
                ...selectedPolicyToDuplicate,
                metadata: {
                  ...selectedPolicyToDuplicate?.metadata,
                  name: values.newPolicyName,
                  resourceVersion: undefined,
                  creationTimestamp: undefined,
                  uid: undefined,
                  generation: undefined,
                },
              },
            });
            handleClose();
          }}
        >
          {(formik) => {
            useEffect(() => {
              formik.validateForm();
            }, []);

            return (
              <Form className="w-full h-full pt-4 flex flex-col justify-between">
                <FormInput label="New Policy Name" name="newPolicyName" type="text" className="w-full" />
                <div className="flex justify-end mt-8">
                  <Button label="Duplicate" type="submit" disabled={!formik.isValid} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateHPAPolicy;
