import { IconProps } from "./utils/utils";

const ThickRightArrow = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M1.53332 12.5417L10.8666 12.5417L9.06664 14.3417C8.46664 14.9417 8.46664 15.9417 9.06664 16.5417L10.4666 18.0084C11.0666 18.6084 12.0666 18.6084 12.6666 18.0084L19.5333 11.1417C19.8 10.8084 20 10.4084 20 10.0084C20 9.54169 19.8667 9.14173 19.5333 8.80837L12.6666 2.00837C12.4 1.74169 12 1.54169 11.5333 1.54169C11.1333 1.54169 10.7333 1.67501 10.4666 2.00837L9.06664 3.40837C8.46664 4.00837 8.46664 5.00837 9.06664 5.60837L11 7.47501L1.53332 7.47501C0.66668 7.47501 8.96083e-07 8.20837 8.20319e-07 9.07501L6.51303e-07 11.0083C5.75536e-07 11.875 0.66668 12.5417 1.53332 12.5417ZM1.26668 9.07501L1.33332 9.07501C1.33332 8.94169 1.46664 8.80833 1.53332 8.80833L12.6 8.80833C12.8667 8.80833 13.1333 8.67501 13.2 8.40833C13.3333 8.14165 13.2667 7.87501 13.0667 7.67498L10 4.67497C9.93332 4.60829 9.93332 4.47497 10 4.34161L11.4 2.94162C11.4667 2.87494 11.6 2.8083 11.7334 2.94162L18.6 9.80837C18.6666 9.87501 18.6666 9.94169 18.6666 10.0083C18.6666 10.075 18.6666 10.1417 18.6 10.2083L11.7333 17.075C11.6666 17.1417 11.6 17.1417 11.6 17.1417C11.5333 17.1417 11.4667 17.1417 11.4667 17.075L10 15.6751C9.93332 15.6084 9.93332 15.4084 10 15.3417L12.9333 12.3417C13.1333 12.1417 13.2 11.8751 13.0666 11.6084C12.9333 11.3417 12.7333 11.2084 12.4666 11.2084L1.46664 11.2084C1.4 11.2084 1.26664 11.2084 1.26664 11.0751L1.26664 9.07501L1.26668 9.07501Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThickRightArrow;
