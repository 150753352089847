import { DataGrid, GridSortDirection } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetNetworkReportWorkloads, GetNetworkReportWorkloadsResponse } from "../../../../api/fetcher";
import { ASC, DESC } from "../../../../components/WorkloadStatusByNamespace/utils";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../../utils/useStateWithLocalStorage";
import { Field } from "../utils";
import useWorkloadsFilters from "../Workloads/hooks/useWorkloadsFilters";
import ExportNetworkCSVMenu from "./ExportCSV/ExportNetworkCSVMenu";
import { getColumns, WORKLOAD_ROW } from "./utils";

const NETWORK_COST_PAGE_SIZE_LOCAL_STORAGE_KEY = "networkCostPageSize";

const INITIAL_SORT_MODEL = [
  {
    field: String(Field.totalCost),
    sort: DESC as GridSortDirection,
  },
];

const { queryKey, queryFn } = GetNetworkReportWorkloads();

interface Props {
  selectedColumns: (string | undefined)[];
}

const NetworkWorkloadsTable = ({ selectedColumns }: Props) => {
  const workloadsFilters = useWorkloadsFilters();

  const [rows, setRows] = useState<WORKLOAD_ROW[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortField, setSortField] = useQueryParam("sortField", withDefault(StringParam, String(Field.totalCost)));
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", withDefault(StringParam, DESC));
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: NETWORK_COST_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });

  const [searchTerm] = useQueryParam("searchTerms", StringParam);

  const { data, isError, error, isLoading } = useQuery<GetNetworkReportWorkloadsResponse, Error>({
    queryKey: [queryKey, workloadsFilters],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    let sortedData = data?.aggregatedWorkloads
      ? data?.aggregatedWorkloads?.map((wl) => {
          return {
            ...wl,
            displayWorkloadName: `${wl.namespace}/${wl.workloadName}`,
          };
        })
      : [];

    // filter by searchTerm
    if (searchTerm) {
      sortedData = sortedData?.filter((wl) => {
        return wl.displayWorkloadName.includes(searchTerm);
      });
    }

    if (sortField && sortDirection) {
      // sort by displayWorkloadName
      if (sortField === Field.workloadName) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.displayWorkloadName.localeCompare(b.displayWorkloadName);
          } else {
            return b.displayWorkloadName.localeCompare(a.displayWorkloadName);
          }
        });
        // clusterName
      } else if (sortField === Field.clusterName) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.clusterName.localeCompare(b.clusterName);
          } else {
            return b.clusterName.localeCompare(a.clusterName);
          }
        });
        // replicas
      } else if (sortField === Field.replicas) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.replicas - b.replicas;
          } else {
            return b.replicas - a.replicas;
          }
        });
        // totalCost
      } else if (sortField === Field.totalCost) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.totalCost.total - b.totalCost.total;
          } else {
            return b.totalCost.total - a.totalCost.total;
          }
        });
        // crossAZCost
      } else if (sortField === Field.crossAZCost) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.crossAZCost.total - b.crossAZCost.total;
          } else {
            return b.crossAZCost.total - a.crossAZCost.total;
          }
        });
        // intraAZCost
      } else if (sortField === Field.intraAZCost) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.intraAZCost.total - b.intraAZCost.total;
          } else {
            return b.intraAZCost.total - a.intraAZCost.total;
          }
        });
        // totalDataTransfer
      } else if (sortField === Field.totalDataTransfer) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.totalDataTransfer.total - b.totalDataTransfer.total;
          } else {
            return b.totalDataTransfer.total - a.totalDataTransfer.total;
          }
        });
        // intraAZDataTransfer
      } else if (sortField === Field.intraAZDataTransfer) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.intraAZDataTransfer.total - b.intraAZDataTransfer.total;
          } else {
            return b.intraAZDataTransfer.total - a.intraAZDataTransfer.total;
          }
        });
        // crossAZDataTransfer
      } else if (sortField === Field.crossAZDataTransfer) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.crossAZDataTransfer.total - b.crossAZDataTransfer.total;
          } else {
            return b.crossAZDataTransfer.total - a.crossAZDataTransfer.total;
          }
        });
      }
    }

    sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    setRows(sortedData);
    setRowCount(data?.aggregatedWorkloads.length || 0);
  }, [data, page, pageSize, sortField, sortDirection, searchTerm]);

  if (isError) {
    console.log("Error fetching network cost table data: ", error);
  }

  return (
    <div className="flex flex-col gap-3 relative">
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        autoHeight
        rowHeight={ROW_HEIGHT}
        sx={{ ...getDataGridSx(false, false) }}
        columns={getColumns(selectedColumns)}
        rows={rows}
        initialState={{
          pagination: {
            pageSize: pageSize,
          },
        }}
        pageSize={pageSize}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(x) => {
          if (isInitialSortModel) setIsInitialSortModel(false);
          setSortField(x[0]?.field);
          setSortDirection(x[0]?.sort);
        }}
        sortModel={
          isInitialSortModel && !sortField && !sortDirection
            ? INITIAL_SORT_MODEL
            : [
                {
                  field: String(sortField),
                  sort: String(sortDirection) as GridSortDirection,
                },
              ]
        }
        rowCount={rowCount}
        disableSelectionOnClick
      />
      <ExportNetworkCSVMenu />
    </div>
  );
};

export default NetworkWorkloadsTable;
