import { useQuery } from "@tanstack/react-query";
import { GetClusterPermissions, GetClusterPermissionsResponse } from "../api/fetcher";

const { queryFn, queryKey } = GetClusterPermissions();

const useCanUserAutomateCluster = () => {
  const { data, error } = useQuery<GetClusterPermissionsResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  if (error) {
    console.log("Error fetching CanAutomateCluster", error);
  }

  if (!data || error) {
    return false;
  }

  return !!data?.CanAutomateCluster;
};

export default useCanUserAutomateCluster;
