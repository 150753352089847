import { useState } from "react";
import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { components } from "../../api/schema";
import MultiSelect from "../../components/MultiSelect";
import SingleSelect, { SingleSelectSize } from "../../components/SingleSelect";
import Tooltip from "../../components/Tooltip";
import { capitalizeFirstLetter, spacedCamelCase } from "../../utils/formatterUtils";
import { usePatchRole } from "./mutations/usePatchRole";

const TOP_ROW_CLASS_NAME = "w-[33.3%]";

export enum FilterType {
  All = "All",
  ByNamespace = "By name",
  ByLabel = "By label",
}

export enum Operators {
  In = "In",
  NotIn = "NotIn",
  Exists = "Exists",
  DoesNotExist = "DoesNotExist",
}

export const Label = ({
  label,
  className,
  hasOptionalSpan,
}: {
  label: string;
  className?: string;
  hasOptionalSpan?: boolean;
}) => (
  <Typography variant="caption" className={className}>
    {label}
    {hasOptionalSpan && (
      <>
        {" "}
        <span className="text-text-disable text-[10px]">(optional)</span>
      </>
    )}
  </Typography>
);

interface Props {
  namespaces: string[] | undefined;
  labelsByKey: Record<string, string[]> | undefined;
  targetNamespaces?: components["schemas"]["RolesTargetNamespace"][];
  id?: string;
  defaultSelectedFilterType?: FilterType;
  disable?: boolean;
}

const NamespaceRules = ({
  namespaces,
  labelsByKey,
  targetNamespaces,
  id,
  defaultSelectedFilterType,
  disable,
}: Props) => {
  const update = usePatchRole();
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(defaultSelectedFilterType || FilterType.All);

  const [selectedOperator, setSelectedOperator] = useState<Operators>(
    targetNamespaces &&
      targetNamespaces[0].labelSelector?.matchExpressions &&
      targetNamespaces[0].labelSelector?.matchExpressions[0]
      ? (targetNamespaces[0].labelSelector?.matchExpressions[0].operator as Operators)
      : Operators.In
  );

  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    targetNamespaces &&
      targetNamespaces[0].labelSelector?.matchExpressions &&
      targetNamespaces[0].labelSelector?.matchExpressions[0]
      ? targetNamespaces[0].labelSelector?.matchExpressions[0].key
      : undefined
  );

  const [selectedValues, setSelectedValues] = useState<string[]>(
    targetNamespaces &&
      targetNamespaces[0].labelSelector?.matchExpressions &&
      targetNamespaces[0].labelSelector?.matchExpressions[0] &&
      targetNamespaces[0].labelSelector?.matchExpressions[0].values &&
      targetNamespaces[0].labelSelector?.matchExpressions[0].values.length > 0
      ? targetNamespaces[0].labelSelector?.matchExpressions[0].values
      : []
  );

  const [selectedNamespaces, setSelectedNamespaces] = useState<string[]>(
    targetNamespaces && targetNamespaces[0].namespaceNames ? targetNamespaces[0].namespaceNames : []
  );

  if (disable) {
    return (
      <Tooltip
        title={
          <>
            <b>Namespace filters</b> are disable for this role
          </>
        }
        className="w-full relative"
      >
        <SingleSelect<string>
          disabled
          className="w-full"
          selected="All"
          setSelected={(option) => {
            setSelectedFilterType(option as FilterType);
          }}
          renderOptionsFunc={() =>
            Object.values(FilterType).map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  <ListItemText
                    primary={capitalizeFirstLetter(option)}
                    sx={{
                      fontSize: "12px",
                    }}
                  />
                </MenuItem>
              );
            })
          }
          size={SingleSelectSize.MediumWithSmallFont}
        />
      </Tooltip>
    );
  }

  return (
    <div className="w-full relative flex flex-col gap-2">
      <div className="w-full flex relative gap-2 items-end">
        <div
          className={clsx({
            TOP_ROW_CLASS_NAME: selectedFilterType !== FilterType.All,
            "w-full": selectedFilterType === FilterType.All,
            "w-[33.3%]": selectedFilterType !== FilterType.All,
          })}
        >
          <SingleSelect<string>
            className="w-[100%]"
            selected={selectedFilterType}
            setSelected={(option) => {
              setSelectedFilterType(option as FilterType);

              switch (true) {
                case id && option === FilterType.All:
                  update.mutate({
                    id: String(id),
                    targetNamespaces: [
                      {
                        namespaceNames: undefined,
                        labelSelector: undefined,
                      },
                    ],
                  });
                  break;
                case id && option === FilterType.ByNamespace && selectedNamespaces && selectedNamespaces.length > 0:
                  update.mutate({
                    id: String(id),
                    targetNamespaces: [
                      {
                        namespaceNames: selectedNamespaces,
                        labelSelector: undefined,
                      },
                    ],
                  });
                  break;
                case id &&
                  option === FilterType.ByLabel &&
                  selectedKey &&
                  selectedOperator &&
                  selectedValues &&
                  selectedValues.length > 0:
                  update.mutate({
                    id: String(id),
                    targetNamespaces: [
                      {
                        namespaceNames: undefined,
                        labelSelector: {
                          matchExpressions: [
                            {
                              key: selectedKey,
                              operator: selectedOperator,
                              values: selectedValues,
                            },
                          ],
                        },
                      },
                    ],
                  });
                  break;
                default:
                  break;
              }
            }}
            renderOptionsFunc={() =>
              Object.values(FilterType).map((option) => {
                return (
                  <MenuItem value={option} key={option}>
                    <ListItemText
                      primary={capitalizeFirstLetter(option)}
                      sx={{
                        fontSize: "12px",
                      }}
                    />
                  </MenuItem>
                );
              })
            }
            size={SingleSelectSize.MediumWithSmallFont}
          />
        </div>
        {selectedFilterType === FilterType.ByLabel && (
          <div className={TOP_ROW_CLASS_NAME}>
            <SingleSelect<string>
              label={<Label label="Key" />}
              className="w-[100%]"
              selected={selectedKey ?? ""}
              setSelected={(option) => {
                setSelectedValues([]);
                setSelectedKey(option);
                id &&
                  (selectedOperator === Operators.Exists || selectedOperator === Operators.DoesNotExist) &&
                  update.mutate({
                    id,
                    targetNamespaces: [
                      {
                        labelSelector: {
                          matchExpressions: [
                            {
                              key: option,
                              operator: selectedOperator,
                              values: undefined,
                            },
                          ],
                        },
                      },
                    ],
                  });
              }}
              renderOptionsFunc={() =>
                Object.keys(labelsByKey ?? {}).map((option) => {
                  return (
                    <MenuItem value={option} key={option}>
                      <ListItemText
                        primary={capitalizeFirstLetter(option)}
                        sx={{
                          fontSize: "12px",
                        }}
                      />
                    </MenuItem>
                  );
                })
              }
              size={SingleSelectSize.MediumWithSmallFont}
            />
          </div>
        )}

        {selectedFilterType === FilterType.ByLabel && (
          <div className={TOP_ROW_CLASS_NAME}>
            <SingleSelect<string>
              label={<Label label="Operator" />}
              className="w-[100%]"
              selected={selectedOperator}
              setSelected={(option) => {
                setSelectedOperator(option as Operators);
                switch (true) {
                  case id && (option === Operators.Exists || option === Operators.DoesNotExist) && !!selectedKey:
                    update.mutate({
                      id: String(id),
                      targetNamespaces: [
                        {
                          labelSelector: {
                            matchExpressions: [
                              {
                                key: selectedKey,
                                operator: option,
                              },
                            ],
                          },
                        },
                      ],
                    });
                    break;
                  case id &&
                    option !== Operators.Exists &&
                    option !== Operators.DoesNotExist &&
                    selectedValues &&
                    selectedValues.length > 0 &&
                    !!selectedKey:
                    update.mutate({
                      id: String(id),
                      targetNamespaces: [
                        {
                          labelSelector: {
                            matchExpressions: [
                              {
                                key: selectedKey,
                                operator: option,
                                values: selectedValues,
                              },
                            ],
                          },
                        },
                      ],
                    });
                    break;
                  default:
                    break;
                }
              }}
              renderOptionsFunc={() =>
                Object.values(Operators).map((option) => {
                  return (
                    <MenuItem value={option} key={option}>
                      <ListItemText
                        primary={spacedCamelCase(option)}
                        sx={{
                          fontSize: "12px",
                        }}
                      />
                    </MenuItem>
                  );
                })
              }
              size={SingleSelectSize.MediumWithSmallFont}
            />
          </div>
        )}
      </div>
      {selectedFilterType === FilterType.ByLabel &&
        selectedOperator !== Operators.Exists &&
        selectedOperator !== Operators.DoesNotExist &&
        labelsByKey && (
          <div className="w-full">
            <MultiSelect
              isSearchable
              hasVirtualizedList
              label={<Label label="Values" />}
              wrapperClassName="w-full"
              className="h-[34px] w-full"
              selected={selectedValues}
              setSelected={(options) => {
                setSelectedValues(options as string[]);
              }}
              customOnClose={() =>
                id &&
                update.mutate({
                  id,
                  targetNamespaces: [
                    {
                      labelSelector: {
                        matchExpressions: [
                          {
                            key: selectedKey,
                            operator: selectedOperator,
                            values: selectedValues,
                          },
                        ],
                      },
                    },
                  ],
                })
              }
              options={selectedKey ? labelsByKey[selectedKey] : []}
              fontSize="12px"
              disabled={!selectedKey}
            />
          </div>
        )}
      {selectedFilterType === FilterType.ByNamespace && (
        <MultiSelect
          isSearchable
          hasVirtualizedList
          label={<Label label="Namespaces" />}
          wrapperClassName="w-full"
          className="h-[34px] w-full"
          selected={selectedNamespaces}
          setSelected={(options) => {
            setSelectedNamespaces(options as string[]);
          }}
          customOnClose={() => {
            id &&
              update.mutate({
                id,
                targetNamespaces: [
                  {
                    namespaceNames: selectedNamespaces,
                    labelSelector: undefined,
                  },
                ],
              });
          }}
          options={namespaces ?? []}
          fontSize="12px"
        />
      )}
    </div>
  );
};

export default NamespaceRules;
