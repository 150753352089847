import { IconProps } from "./utils/utils";

const HideIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M12.483 10.1684L13.6189 9.03249C13.7016 9.34171 13.75 9.66499 13.75 9.99999C13.75 12.0678 12.0678 13.75 10 13.75C9.665 13.75 9.34172 13.7016 9.0325 13.6189L10.1684 12.483C11.4105 12.3983 12.3983 11.4105 12.483 10.1684ZM19.9213 9.6964C19.8444 9.55812 18.7541 7.64765 16.6228 6.02859L15.7133 6.93812C17.2673 8.08077 18.252 9.41343 18.6397 10.0009C17.8989 11.1289 14.993 15 10 15C9.25125 15 8.555 14.9042 7.89984 14.7514L6.87281 15.7784C7.81703 16.068 8.85484 16.25 10 16.25C16.5427 16.25 19.7864 10.5462 19.9213 10.3036C20.0263 10.1147 20.0263 9.88531 19.9213 9.6964ZM17.3169 3.56687L3.56687 17.3169C3.44484 17.4389 3.28484 17.5 3.125 17.5C2.96516 17.5 2.80516 17.4389 2.68313 17.3169C2.43891 17.0727 2.43891 16.6772 2.68313 16.4331L4.4325 14.6837C1.60437 12.9764 0.167344 10.4631 0.07875 10.3036C-0.02625 10.1147 -0.02625 9.88515 0.07875 9.69624C0.213594 9.45374 3.45734 3.74999 10 3.74999C11.6917 3.74999 13.157 4.13593 14.4097 4.70656L16.4331 2.68312C16.6773 2.4389 17.0728 2.4389 17.3169 2.68312C17.5609 2.92734 17.5611 3.32281 17.3169 3.56687ZM5.36453 13.7517L6.94687 12.1694C6.50984 11.5562 6.25 10.8086 6.25 9.99999C6.25 7.93218 7.93219 6.24999 10 6.24999C10.8086 6.24999 11.5563 6.50984 12.1694 6.94671L13.4489 5.66718C12.438 5.26234 11.2892 4.99999 10 4.99999C5.00703 4.99999 2.10109 8.87109 1.36047 9.99906C1.83078 10.7119 3.18266 12.5162 5.36453 13.7517ZM7.85422 11.2619L11.2619 7.85421C10.8902 7.63468 10.4622 7.49999 10 7.49999C8.62156 7.49999 7.5 8.62156 7.5 9.99999C7.5 10.4622 7.63469 10.8901 7.85422 11.2619Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HideIcon;
