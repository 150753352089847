import { memo } from "react";
import TopCostBarChart from "./TopCostBarChart";
import UnderlineOption from "../../../components/UnderlineOption";
import Loading from "../../../components/Loading";
import Tooltip from "../../../components/Tooltip";

const getXDaysAgoTimestamp = (x: number) => Math.floor(Date.now() / 1000) - 60 * 60 * 24 * x;

export enum SelectedTime {
  NOW = "now",
  LAST_30_DAYS = "30 days",
}

interface Props {
  defaultCurrentCost: number;
  defaultOptimizedCost: number;
  isInitialLoading: boolean;
  currentCost: number;
  selectedTime: SelectedTime;
  optimizedCost: number;
  setSelectedTime: (selectedTime: SelectedTime) => void;
  setStartTime: (startTime: number) => void;
}

const CostChartContainer = memo(
  ({
    defaultCurrentCost,
    defaultOptimizedCost,
    isInitialLoading,
    currentCost,
    selectedTime,
    optimizedCost,
    setSelectedTime,
    setStartTime,
  }: Props) => {
    return (
      <div className="relative">
        {isInitialLoading && selectedTime !== SelectedTime.NOW ? (
          <div className="min-h-[130px] flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <TopCostBarChart
            currentCost={currentCost ?? defaultCurrentCost}
            optimizedCost={optimizedCost ?? defaultOptimizedCost}
            currentPrefix={selectedTime !== SelectedTime.NOW ? "Average: $" : undefined}
          />
        )}
        <div className="flex gap-4 absolute bottom-[-30px]">
          <Tooltip
            title={
              <>
                Live <b>Cost</b> and <b>potential cost</b>.
              </>
            }
          >
            <UnderlineOption
              isSelected={selectedTime === SelectedTime.NOW}
              onClick={() => {
                setSelectedTime(SelectedTime.NOW);
                setStartTime(getXDaysAgoTimestamp(1));
              }}
              label="Live"
            />
          </Tooltip>
          <Tooltip
            title={
              <>
                <b>Cost</b> and <b>potential cost</b> based on <b>last 30 days</b> average resource consumption.
              </>
            }
          >
            <UnderlineOption
              isSelected={selectedTime === SelectedTime.LAST_30_DAYS}
              onClick={() => {
                setSelectedTime(SelectedTime.LAST_30_DAYS);
                setStartTime(getXDaysAgoTimestamp(30));
              }}
              label="30 days"
            />
          </Tooltip>
        </div>
      </div>
    );
  }
);

export default CostChartContainer;
