import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetAlertsSettings,
  AlertsSettingsResponse,
  UpdateMultiAlertsSettings,
  UpdateMultiAlertsSettingsInput,
} from "../../api/fetcher";
import { useMultiClusterParams } from "./ClusterMultiSelect";

//UpdateMultiAlertsSettings
const useUpdateAlertsSettings = (invalidateQueries: boolean, onError?: () => void) => {
  const updateAlertsSettings = UpdateMultiAlertsSettings();
  const getAlertsSettings = GetAlertsSettings();
  const queryClient = useQueryClient();
  const multiClusterParams = useMultiClusterParams();

  return useMutation(
    (params: UpdateMultiAlertsSettingsInput) => {
      if (params.multiCluster) {
        params.clusters = multiClusterParams.clusters;
        params.tags = multiClusterParams.tags;
      }
      return updateAlertsSettings.queryFn(params);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([getAlertsSettings.queryKey]);
        const previousSettings: AlertsSettingsResponse | undefined = queryClient.getQueryData([
          getAlertsSettings.queryKey,
        ]);

        // queryClient.setQueryData([getAlertsSettings.queryKey], newSettings);
        return { previousSettings };
      },
      onError: (err, newSettings, context) => {
        toast.error("Error updating alerts settings");
        console.log("Error updating alerts settings", err, newSettings, context);
        queryClient.setQueryData([getAlertsSettings.queryKey], context?.previousSettings);
        onError && onError();
      },
      onSuccess: () => {
        toast.success("Alerts settings updated");
        if (invalidateQueries) {
          queryClient.invalidateQueries([getAlertsSettings.queryKey]);
        }
      },
    }
  );
};
export default useUpdateAlertsSettings;
