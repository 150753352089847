import clsx from "clsx";

interface Props {
  title: string;
  disabled?: boolean;
}

const ExportCSVOptionButton = ({ title, disabled = false }: Props) => {
  return (
    <p
      className={clsx({
        "opacity-50": disabled,
      })}
    >
      {title}
    </p>
  );
};

export default ExportCSVOptionButton;
