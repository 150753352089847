import { FixedSizeList as List } from "react-window";
import { ArrayParam, BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { EXCLUDE_SIGN, INCLUDE_SIGN } from "../utils/namesUtils";
import CustomSelectedFilterChip from "./CustomSelectedFilterChip";

const MAX_NUMBER_OF_CHIPS_PER_FILTER = 3;
const LIST_HEIGHT = 170;
const LIST_SIZE = 20;
const VIRTUALIZED_LIST_THRESHOLD = 12;

export enum FilterType {
  BOOLEAN = "boolean",
  ARRAY = "array",
}

interface Props {
  label: string;
  queryParam: string;
  filterType: FilterType;
  excludeQueryParam?: string;
  isSelectedByDefault?: boolean;
}

// @barel22 the new and update filter chip component
const FilterChip = ({ label, queryParam, filterType, excludeQueryParam, isSelectedByDefault }: Props) => {
  const [isBoolean, setIsBoolean] = useQueryParam(queryParam, withDefault(BooleanParam, !!isSelectedByDefault));
  const [selectedArray, setSelectedArray] = useQueryParam(queryParam, ArrayParam);
  const [isExclude] = useQueryParam(excludeQueryParam ?? "", BooleanParam);

  if (filterType === FilterType.BOOLEAN) {
    return <>{isBoolean && <CustomSelectedFilterChip label={label} onClick={() => setIsBoolean(false)} />}</>;
  }

  if (selectedArray && selectedArray?.length > MAX_NUMBER_OF_CHIPS_PER_FILTER) {
    const sizeHasPassedThreshold = selectedArray && selectedArray?.length > VIRTUALIZED_LIST_THRESHOLD;
    return (
      <CustomSelectedFilterChip
        label={
          <>
            {selectedArray?.length} {label} {excludeQueryParam && <>({isExclude ? EXCLUDE_SIGN : INCLUDE_SIGN})</>}
          </>
        }
        tooltipContent={
          <>
            {selectedArray?.length} {label} selected:{" "}
            {!excludeQueryParam ? <>({isExclude ? "excluded" : "included"})</> : ""}
            {!sizeHasPassedThreshold && (
              <ul className="list-disc ml-4">
                {selectedArray?.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            )}
            {sizeHasPassedThreshold && (
              <List height={LIST_HEIGHT} itemCount={selectedArray?.length ?? 0} itemSize={LIST_SIZE} width={380}>
                {({ index, style }) => (
                  <li style={style} className="truncate">
                    {selectedArray?.[index]}
                  </li>
                )}
              </List>
            )}
          </>
        }
        hasTooltip
        onClick={() => setSelectedArray([])}
      />
    );
  }

  return (
    <>
      {selectedArray?.map((name) => {
        return (
          <CustomSelectedFilterChip
            label={
              <>
                {label}
                {isExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {name ?? ""}
              </>
            }
            onClick={() => {
              setSelectedArray((s) => s?.filter((l) => l !== name));
            }}
            className="max-w-[200px]"
            hasTooltip
            tooltipContent={
              excludeQueryParam ? (
                <>
                  labels {isExclude ? <b>exclude</b> : <b>include</b>} "{name ?? ""}".
                </>
              ) : undefined
            }
          />
        );
      })}
    </>
  );
};

export default FilterChip;
