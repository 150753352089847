import ChartTooltipElement from "../../../components/ChartTooltipElement";
import { customNumberFormatter } from "../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../utils/styleUtils";

const MAX_ELEMENT_TO_DISPLAY = 10;

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; color: string }[];
  label?: string;
}

const EntireWindowTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && (
          <p style={{ fontWeight: 600 }} className="text-[10px]">
            {label}
          </p>
        )}
        {payload.slice(0, MAX_ELEMENT_TO_DISPLAY).map((p) => (
          <ChartTooltipElement color={p.color} value={`$${customNumberFormatter(Number(p.value))}`} label={p.name} />
        ))}

        {payload.length > MAX_ELEMENT_TO_DISPLAY && (
          <span className="text-[10px] mt-2">+ {payload.length - MAX_ELEMENT_TO_DISPLAY} more items</span>
        )}
      </div>
    );
  }

  return null;
};

export default EntireWindowTooltip;
