import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import React from "react";
import Tooltip from "./Tooltip";

interface Props {
  label: React.ReactNode;
  onClick: () => void;
  className?: string;
  hasTooltip?: boolean;
  tooltipContent?: React.ReactNode;
  clearIcon?: React.ReactNode;
}

const CustomSelectedFilterChip = ({
  label,
  onClick,
  className,
  hasTooltip,
  tooltipContent,
  clearIcon = <ClearIcon sx={{ fontSize: 15, padding: "0px" }} />,
}: Props) => (
  <Tooltip title={tooltipContent ?? label} disabled={!hasTooltip} maxWidth={400}>
    <div
      onClick={onClick}
      className={clsx(
        className,
        "cursor-pointer border border-guideline-darkGray rounded-full bg-guideline-lightGray hover:bg-guideline-darkGray px-2 flex items-center justify-center h-[35px]"
      )}
    >
      <span className="truncate text-[12px]">{label}</span>
      <IconButton style={{ padding: "0px" }} onClick={onClick}>
        {clearIcon}
      </IconButton>
    </div>
  </Tooltip>
);

export default CustomSelectedFilterChip;
