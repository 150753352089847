import { Typography } from "@mui/material";
import { components } from "../../../api/schema";
import InfoIcon from "../../../Icons/InfoIcon";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import CustomCheckBox from "../../CustomCheckBox";
import ExpendedDiskList from "../../ExpendedDiskList";
import Tooltip from "../../Tooltip";
import useSetRebalanceSpec from "./useSetRebalanceSpec";

const ICON_SIZE = 12;

const TOOLTIP_CONTENT = () => (
  <Typography variant="caption">
    <p>
      <b>Scale down Karpenter underutilized nodes resource</b> by ignoring the following checks:
    </p>

    <ExpendedDiskList
      title="Resource Policies"
      items={["PDB (Pod Disruption Budget)", "Nodepool policy", "Is Nodepool / provisioner expired"]}
    />
    <ExpendedDiskList
      title="Annotations and Labels"
      items={[
        "Annotations",
        "Mandatory labels",
        "Node init label",
        "Not consolidate label",
        "Not have empty annotation",
      ]}
    />
    <ExpendedDiskList title="Resource Utilization" items={["Budget", "Utilization"]} />
    <ExpendedDiskList title="Resource Conditions" items={["DeletionTimestamp == zero", "Live at least 20 min"]} />
  </Typography>
);

interface Props {
  spec: components["schemas"]["Node_detailsRebalancePlanSpec"] | undefined;
}

const ForceDeleteKarpenterSwitch = ({ spec }: Props) => {
  const { userRole } = useGetUserRole();
  const setRebalanceSpec = useSetRebalanceSpec();

  return (
    <Tooltip
      title={<TOOLTIP_CONTENT />}
      maxWidth={500}
      className="flex gap-2 items-center justify-start w-fit"
      placement="right"
    >
      <CustomCheckBox
        value={!!spec?.forceDeleteKarpenter}
        onClick={(checked) => {
          setRebalanceSpec.mutate({
            ...spec,
            forceDeleteKarpenter: !!checked,
          });
        }}
        disabled={userRole !== "Admin"}
      />
      <Typography variant="body2" className="flex gap-[2px] items-center justify-start cursor-default">
        <span>Scale down Karpenter underutilized nodes resource</span>
        <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
      </Typography>
    </Tooltip>
  );
};

export default ForceDeleteKarpenterSwitch;
