import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetVersionWithCustomCluster, GetVersionWithCustomClusterResponse } from "../api/fetcher";
import { components } from "../api/schema";
import useGetParentCluster from "./useGetParentCluster";

const getVersion = GetVersionWithCustomCluster();

const useGetFreeTrialData = () => {
  const parentCluster = useGetParentCluster();
  const [freeTrialData, setFreeTrialData] = useState<components["schemas"]["VersionFreeTrial"] | undefined>(undefined);

  const { data: versionData } = useQuery<GetVersionWithCustomClusterResponse>({
    queryKey: [getVersion.queryKey, parentCluster],
    queryFn: () => getVersion.queryFn({ customCluster: parentCluster }),
    enabled: !parentCluster,
  });

  useEffect(() => {
    setFreeTrialData(versionData?.freeTrial);
  }, [versionData]);

  return freeTrialData;
};

export default useGetFreeTrialData;
