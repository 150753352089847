import { components } from "../../api/schema";

export type Action = {
  title: string;
  description: React.ReactNode;
  icon: JSX.Element;
  exploreWorkloadsLink?: string;
};

export type UevictableTableRowType = { id: string; action: Action } & components["schemas"]["UnevictableWorkloadGroup"];

export enum ChartsTabOptions {
  Week = "7 Days",
  Month = "30 Days",
}
