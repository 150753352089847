import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetDashboardByNamespaceResponse,
  PostAutoCluster,
  PostAutoClusterParams,
  getDashboardByNamespace,
} from "../../api/fetcher";
import { useWorkloadsContext } from "../../WorkloadsContext";
import { workloadRowsExceptExcludedAndScaleOpsIds } from "./utils";

const { queryFn } = PostAutoCluster();
const dashboardByNamespace = getDashboardByNamespace();

const usePostAutoCluster = (disableQueries?: boolean) => {
  const queryClient = useQueryClient();

  const { updateOverriddenWorkloads, deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, "get-all-workloads-for-bulk-automation"],
    queryFn: () => dashboardByNamespace.queryFn({}),
    enabled: disableQueries !== true,
  });

  const rowIds = workloadRowsExceptExcludedAndScaleOpsIds(data);

  return useMutation((params: PostAutoClusterParams) => queryFn(params), {
    onMutate: (values) => {
      updateOverriddenWorkloads({
        ids: rowIds,
        props: {
          auto: values.state,
        },
      });
    },
    onError: (error: Error, values) => {
      deleteOverriddenWorkloadsProperties({
        ids: rowIds,
        propertyNames: ["auto"],
      });
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to ${values?.state ? "automate" : "un-automate"} cluster`, {
        position: "top-center",
      });
    },
    onSuccess: (_, values) => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey]).catch((error) => console.log(error));
      toast.success(`Cluster ${values?.state ? "automated" : "un-automated"} successfully`, {
        position: "top-center",
        style: { width: 350 },
      });
    },
  });
};

export default usePostAutoCluster;
