import FormInput from "../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const MinReplicasAllowed = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Minimum replicas boundary"
        description={"Set the minimum replicas allowed for the workload."}
      />
      <FormInput
        name="minAllowed"
        type="number"
        required
        max={100000}
        disabled={!isCustomizedPolicy}
        className="w-[80px]"
      />
    </div>
  );
};

export default MinReplicasAllowed;
