import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const SkipRolloutUponAutomation = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Optimization upon automation"
        description="Allow ScaleOps to rollout upon workload automation."
      />
      <FormSwitch
        name="skipRolloutUponAutomation"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
        getCheckedValue={(value) => !value}
        setOnChangeValue={(checked) => !checked}
      />
    </div>
  );
};
export default SkipRolloutUponAutomation;
