import { IconProps } from "./utils/utils";

const BinPackingIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M18.1988 4.04838L10.6157 0.148708C10.2302 -0.0495489 9.7698 -0.0495489 9.38429 0.148708L1.80124 4.04838C1.3506 4.28014 1.07068 4.73886 1.07068 5.24563V14.7544C1.07068 15.2612 1.3506 15.7199 1.80124 15.9517L9.38433 19.8513C9.76984 20.0496 10.2302 20.0496 10.6157 19.8513L18.1988 15.9517C18.6495 15.7199 18.9294 15.2612 18.9294 14.7544V5.24563C18.9294 4.73886 18.6494 4.2801 18.1988 4.04838ZM18.2627 14.7544C18.2627 15.0102 18.1214 15.2418 17.8939 15.3588L10.3108 19.2584C10.1161 19.3585 9.88381 19.3585 9.68917 19.2584L2.10613 15.3588C1.87866 15.2418 1.73733 15.0102 1.73733 14.7544V5.24563C1.73733 4.98983 1.87862 4.75824 2.10613 4.64127C10.2873 0.43405 9.76443 0.666558 10 0.666558C10.2367 0.666558 9.71647 0.435967 17.8829 4.63561L10.0006 8.67328L3.56776 5.35592C3.40421 5.27154 3.20312 5.3358 3.11871 5.49943C3.03433 5.66306 3.09858 5.86407 3.26221 5.94848L9.66667 9.25122V17.5441C9.66667 17.7282 9.81593 17.8774 10 17.8774C10.1841 17.8774 10.3334 17.7282 10.3334 17.5441V9.25184L18.2603 5.19129C18.264 5.23729 18.2627 14.6882 18.2627 14.7544Z" />
      </g>
    </svg>
  );
};

export default BinPackingIcon;
