import { ArrayParam, useQueryParam } from "use-query-params";
import useGetClusterNames from "../../MainMenu/useGetClusterNames";
import MultiSelect from "../../components/MultiSelect";
import { Typography } from "@mui/material";

interface Props {
  widthClass?: string;
  disabled?: boolean;
}

export const useMultiClusterParams = () => {
  const [tags] = useQueryParam("tags", ArrayParam);
  const [clusters] = useQueryParam("clusters", ArrayParam);
  return { tags: tags as string[], clusters: clusters as string[] };
};

const ClusterMultiSelect = ({ widthClass = "w-[368px]", disabled = false }: Props) => {
  const allClusters = useGetClusterNames();
  const [clusters, setClusters] = useQueryParam("clusters", ArrayParam);
  return (
    <div>
      <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
        Select the clusters you wish to modify
      </Typography>
      <MultiSelect
        disabled={disabled}
        options={allClusters}
        selected={(clusters as string[]) || []}
        setSelected={(selected) => {
          setClusters(selected as (string | null)[]);
        }}
        className={`${widthClass} h-[36px]`}
        isSearchable
        renderValue={(selected: (string | undefined)[]) => {
          if (selected.length === 0) {
            return "All";
          }
          if (selected.length === 1) {
            return selected[0] as string;
          }
          return `${selected.length} selected`;
        }}
        // listHeight="50px"
        // listWidth="368px"
      />
    </div>
  );
  // const AnalyticFilters = ({ isMultiCluster, hasLeftBorder = true, withTags = true }: Props) => {
  //     const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);
  //
  //     const tags = useGetTagsFromClusters();
  //     const clusterNames = useGetClusterNames();
  //
  //     return (
  //         <div className="flex gap-4 items-center ml-4">
  //             {isMultiCluster && (
  //                 <>
  //                     {hasLeftBorder && <div className="bg-border h-8 w-[1px]" />}
  //                     {withTags && (
  //                         <MultiSelectByQueryParams
  //                             hasIsExclude={false}
  //                             options={tags}
  //                             queryKey="tags"
  //                             tooltipPrefix={
  //                                 isTagsExclude ? (
  //                                     <>
  //                                         tags <b>exclude: </b>
  //                                     </>
  //                                 ) : (
  //                                     <>
  //                                         tags <b>include: </b>
  //                                     </>
  //                                 )
  //                             }
  //                             hasVirtualizedList
  //                         />
  //                     )}
  //                     <MultiSelectByQueryParams
  //                         hasIsExclude={false}
  //                         options={clusterNames}
  //                         queryKey="clusters"
  //                         hasVirtualizedList
  //                     />
  //                 </>
  //             )}
  //         </div>
  //     );
  // };
};
export default ClusterMultiSelect;
