import { IconProps } from "./utils/utils";

const MagicWand = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M17.5369 10.665C17.3214 10.665 17.1464 10.84 17.1464 11.0556C17.1464 11.2712 17.3213 11.4462 17.5369 11.4462C17.7525 11.4462 17.9275 11.2712 17.9275 11.0556C17.9275 10.84 17.7525 10.665 17.5369 10.665Z" />
        <path d="M12.7427 2.45332C12.5904 2.30062 12.3428 2.30062 12.1904 2.45332C12.0378 2.60562 12.0378 2.85328 12.1904 3.00559C12.3428 3.15828 12.5904 3.15828 12.7427 3.00559C12.8954 2.85328 12.8954 2.60566 12.7427 2.45332Z" />
        <path d="M19.7654 7.65879L18.0119 5.30926L18.9536 2.53223C19.2653 1.61312 18.3865 0.735038 17.4678 1.04644L14.6908 1.98816L12.3412 0.234609C11.5635 -0.34543 10.4589 0.217382 10.4689 1.1857L10.4678 4.12031L8.11189 5.8134C7.31986 6.37414 7.51373 7.60074 8.44072 7.88887L9.55556 8.23547L0.458338 17.3326C-0.15217 17.942 -0.153108 18.9318 0.457986 19.5418C1.06822 20.1525 2.05681 20.1529 2.66724 19.542L11.7646 10.4446L12.1112 11.5593C12.3986 12.4842 13.625 12.6816 14.1851 11.8905L15.8798 9.53234L18.8144 9.53125C19.7822 9.54211 20.3454 8.43668 19.7654 7.65879ZM2.11478 18.9897C1.8099 19.2948 1.31599 19.2954 1.01021 18.9893C0.707634 18.6873 0.702595 18.1925 1.01045 17.8852L10.3982 8.49742L11.2406 8.75934L11.5026 9.60187L2.11478 18.9897ZM18.8163 8.75398L15.6792 8.75516C15.5535 8.7552 15.4356 8.81184 15.3622 8.91387L13.5492 11.4369C13.3613 11.7024 12.9532 11.6367 12.8571 11.3275L12.3203 9.60109C12.3201 9.60016 12.3197 9.59922 12.3195 9.59828L12.3185 9.59531L11.9256 8.33145C11.8874 8.20867 11.7913 8.11258 11.6686 8.07445L8.67248 7.14289C8.36506 7.04734 8.29545 6.64039 8.56537 6.4493L11.0861 4.63777C11.1882 4.56441 11.2448 4.44648 11.2448 4.32078L11.246 1.18375C11.246 1.18203 11.246 1.18035 11.246 1.17863C11.2419 0.85457 11.6139 0.666796 11.874 0.860702L14.3881 2.73703C14.4911 2.81394 14.6254 2.83519 14.7471 2.79391L17.7186 1.78625C18.0244 1.68242 18.3181 1.97371 18.2137 2.28137L17.2061 5.25281C17.1648 5.37457 17.186 5.50883 17.2629 5.61187L19.1392 8.12582C19.3279 8.37891 19.1589 8.75398 18.8163 8.75398Z" />
        <path d="M14.5792 3.85453L13.9352 3.37375C13.7623 3.24477 13.5176 3.28016 13.3885 3.45313C13.2595 3.62598 13.295 3.87066 13.4679 3.99973L14.112 4.48051C14.2855 4.61004 14.5301 4.5734 14.6586 4.40113C14.7876 4.22832 14.7521 3.98356 14.5792 3.85453Z" />
        <path d="M8.16326 2.07254H7.77267V1.68195C7.77267 1.46629 7.59783 1.29137 7.38208 1.29137C7.16634 1.29137 6.9915 1.46625 6.9915 1.68195V2.07254H6.60091C6.38521 2.07254 6.21033 2.24742 6.21033 2.46312C6.21033 2.67879 6.38517 2.85371 6.60091 2.85371H6.9915V3.2443C6.9915 3.45996 7.16634 3.63488 7.38208 3.63488C7.59783 3.63488 7.77267 3.46 7.77267 3.2443V2.85371H8.16326C8.37896 2.85371 8.55384 2.67883 8.55384 2.46312C8.55384 2.24742 8.37896 2.07254 8.16326 2.07254Z" />
        <path d="M16.7558 13.7896H16.3653V13.399C16.3653 13.1833 16.1904 13.0084 15.9747 13.0084C15.759 13.0084 15.5841 13.1833 15.5841 13.399V13.7896H15.1935C14.9778 13.7896 14.8029 13.9645 14.8029 14.1802C14.8029 14.3959 14.9778 14.5708 15.1935 14.5708H15.5841V14.9614C15.5841 15.177 15.7589 15.352 15.9747 15.352C16.1904 15.352 16.3653 15.1771 16.3653 14.9614V14.5708H16.7558C16.9716 14.5708 17.1464 14.3959 17.1464 14.1802C17.1464 13.9645 16.9716 13.7896 16.7558 13.7896Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MagicWand;
