import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { GetVersion, GetVersionResponse } from "../api/fetcher";
import SnapshotWrapper from "../components/SnapshotWrapper";
import { IsSnapshotServer } from "../utils/FeaturesHelper";

const RAW_ELEMENT_HTML = `<div id="scaleopsIsBlocked" style="position: fixed; z-index: 999999; top: 0px; left: 0px; width: 100vw; height: 100vh; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);"><div class="bg-white px-16 py-8 text-black flex flex-col justify-end items-center gap-4 rounded-lg"><p class="MuiTypography-root MuiTypography-body1 css-197pl4-MuiTypography-root">Your ScaleOps free trial is paused ⌛</p><p class="MuiTypography-root MuiTypography-body2 css-u7zghk-MuiTypography-root">Contact <b class="underline cursor-pointer text-main-linkBlue hover:text-main-linkBlueHover">support@scaleops.com</b> to activate your account.</p></div></div>`;
const ID = "scaleopsIsBlocked";
const INTERVAL = 1000;
const getVersion = GetVersion();

const FixedBlockedAccountDiv = () => (
  <div className="fixed bottom-5 right-5">
    <SnapshotWrapper wrappedType="alert-bar">
      <Typography variant="body2" className="bg-red-500 text-white px-8 py-2 rounded-lg" style={{ zIndex: 999999 }}>
        <b>Blocked Account</b>
      </Typography>
    </SnapshotWrapper>
  </div>
);

const BlockedFE = () => {
  const isSnapshotServer = IsSnapshotServer();
  const isLoginPage = window.location.pathname === "/login";

  const { data } = useQuery<GetVersionResponse>({
    queryKey: [getVersion.queryKey],
    queryFn: getVersion.queryFn,
    enabled: !isLoginPage,
  });

  useEffect(() => {
    if (data?.freeTrial?.isBlocked && !isSnapshotServer) {
      const interval = setInterval(() => {
        const oldElement = document.getElementById(ID);

        if (oldElement) {
          document.body.removeChild(oldElement);
        }

        const newElement = document.createElement("div");
        newElement.innerHTML = RAW_ELEMENT_HTML;
        document.body.appendChild(newElement);
      }, INTERVAL);

      return () => clearInterval(interval);
    }
  }, [data]);

  if (data?.freeTrial?.isBlocked && isSnapshotServer) {
    return <FixedBlockedAccountDiv />;
  }

  return null;
  /**
   * Note: do not delete this code snippet, it is used to develop the rendered HTML
   */
  // return (
  //   <div
  //     id={ID}
  //     style={{
  //       position: "fixed",
  //       zIndex: 999999,
  //       top: 0,
  //       left: 0,
  //       width: "100vw",
  //       height: "100vh",
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       backgroundColor: "rgba(0, 0, 0, 0.8)",
  //     }}
  //   >
  //     <div className="bg-white px-16 py-8 text-black flex flex-col justify-end items-center gap-4 rounded-lg">
  //       <Typography variant="body1" fontWeight={600}>
  //         Your ScaleOps free trial is paused &#8987;
  //       </Typography>
  //       <Typography variant="body2" fontWeight={400}>
  //         Contact{" "}
  //         <b
  //           className="underline cursor-pointer text-main-linkBlue hover:text-main-linkBlueHover"
  //           onClick={() => {
  //             window.open("mailto:support@scaleops.com");
  //           }}
  //         >
  //           support@scaleops.com
  //         </b>{" "}
  //         to activate your account.
  //       </Typography>
  //     </div>
  //   </div>
  // );
};

export default BlockedFE;
