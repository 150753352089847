import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetUnevictableData, GetUnevictableDataResponse } from "../../api/fetcher";
import AdvancedSettingsIcon from "../../Icons/AdvancedSettingsIcon";
import BinPackingIcon from "../../Icons/BinPackingIcon";
import DisableIcon from "../../Icons/DisableIcon";
import MultipleCubesIcon from "../../Icons/MultipleCubesIcon";
import { get24HoursAgoEpoch } from "../../utils/dateAndTimeUtils";
import { TabSelectedTime } from "../../utils/tabUtils";
import UnEvictableContainer from "../autoscalers/UnEvictableV/UnEvictableContainer";
import TopSection from "./TopSection";
import UnEvictableCharts from "./UnevictableResourcesCharts";
import UnevictableTable from "./UnevictableTable";
import { UevictableTableRowType } from "./Utils";

const { queryFn, queryKey } = GetUnevictableData();
const ICON_SIZE = 29;
const ICON_CLASS_NAME = "text-white fill-current m-2";
const ICON_PROPS = { width: ICON_SIZE, height: ICON_SIZE, className: ICON_CLASS_NAME };

{
  /* REMINDER: DO NOT MESS WITH ISRAEL! 😍  */
}
const actions = {
  unevictableWorkloads: {
    title: "Unevictable workloads",
    description: "Bin pack pods with PDB or annotation that prevent node scale down.",
    icon: <MultipleCubesIcon {...ICON_PROPS} />,
    exploreWorkloadsLink: "/?unevictableTypes=unevictable",
  },
  ownerlessWorkloads: {
    title: "Pods without owner",
    description: "Bin pack pods without owner that prevent node scale down.",
    icon: <BinPackingIcon {...ICON_PROPS} />,
  },
  unhealthyWorkloads: {
    title: "Un-ready workloads",
    description: "Bin pack pods of un-ready workloads with availability issues that prevent node scale down.",
    icon: <DisableIcon {...ICON_PROPS} />,
    exploreWorkloadsLink: "/?unevictableTypes=notHealthy",
  },
  kubeSystemWorkloads: {
    title: "Kube-system workloads",
    description: "Bin pack kube-system pods that prevent node scale down.",
    icon: <AdvancedSettingsIcon {...ICON_PROPS} />,
  },
};

export default function Unevictable() {
  const now = Date.now();
  const [rows, setRows] = useState<UevictableTableRowType[]>([]);
  const [startTime, setStartTime] = useState<number | undefined>(get24HoursAgoEpoch(Date.now()));
  const [selectedTime, setSelectedTime] = useState<TabSelectedTime>(TabSelectedTime.LAST_1_DAY);

  const { data, isLoading, isError, error } = useQuery<GetUnevictableDataResponse, Error>({
    queryKey: [queryKey, startTime],
    queryFn: () =>
      queryFn({
        from: startTime,
        to: selectedTime === TabSelectedTime.LIVE ? undefined : now,
      }),
  });

  useEffect(() => {
    if (data) {
      const unevictableWorkloads = {
        id: "binPackUnevictablePods",
        action: actions["unevictableWorkloads"],
        ...data.unevictableWorkloads,
      };
      const ownerlessWorkloads = {
        id: "binPackOwnerlessPods",
        action: actions["ownerlessWorkloads"],
        ...data.ownerlessWorkloads,
      };
      const unhealthyWorkloads = {
        id: "binPackUnHealthyPods",
        action: actions["unhealthyWorkloads"],
        ...data.unhealthyWorkloads,
      };
      const kubeSystemWorkloads = {
        id: "binPackKubeSystemPods",
        action: actions["kubeSystemWorkloads"],
        ...data.kubeSystemWorkloads,
      };

      const foundClusterAutoscalerShowKubeSystem = data?.foundClusterAutoscalerShowKubeSystem ?? false;
      if (foundClusterAutoscalerShowKubeSystem) {
        setRows([unevictableWorkloads, ownerlessWorkloads, unhealthyWorkloads, kubeSystemWorkloads]);
      } else {
        setRows([unevictableWorkloads, ownerlessWorkloads, unhealthyWorkloads]);
      }
    }
  }, [data]);

  if (isError) {
    console.log(error);
    return null;
  }

  const totalOptimizedPods = data?.optimizedPods ?? 0;
  const totalUnOptimizedPods = Number(data?.totalPods ?? 0) - totalOptimizedPods;

  return (
    <div className="flex flex-col gap-5">
      <div>
        <TopSection
          monthlyCost={data?.cost ?? 0}
          totalOptimizedPods={totalOptimizedPods}
          totalUnOptimizedPods={totalUnOptimizedPods}
          wastedSpend={data?.wastedSpend ?? 0}
          blockedNodes={data?.blockedNodes ?? 0}
          disableAnimation={false}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setStartTime={setStartTime}
          isLoading={isLoading}
        />
      </div>
      <div>
        <UnEvictableCharts />
      </div>
      <div>
        <UnevictableTable rows={rows} isLoading={false} />
      </div>
      <div>
        <UnEvictableContainer topSection={false} showBlockedCharts={false} openOnMount />
      </div>
    </div>
  );
}
