import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridRowClassNameParams, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { GetHPAPolicies, GetHPAPoliciesResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../../../utils/styleUtils";
import { ADMIN_ROLE, ScaleOpsProduct } from "../../../utils/typesUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import PolicyPageTitleAndDescription from "../../Policies/PolicyPageTitleAndDescription";
import usePoliciesStats from "../../Policies/usePoliciesStats";
import useDeleteHPAPolicy from "../hooks/useDeleteHPAPolicy";
import { DEFAULT_POLICY_NAME, getColumns, HPAMutationType, POLICY_ROW_HEIGHT, Row } from "../utils";
import DuplicateHPAPolicy from "./DuplicateHPAPolicy";
import EditAndCreateHPAPolicy from "./EditAndCreateHPAPolicy";

const HPAPoliciesQuery = GetHPAPolicies();

const HPAPoliciesTable = () => {
  const { userRole } = useGetUserRole();
  const policiesStats = usePoliciesStats({
    scaleOpsProduct: ScaleOpsProduct.HPA,
  });
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const deleteHPAPolicy = useDeleteHPAPolicy();

  const [rows, setRows] = useState<Row[] | undefined>();
  const [isCreateNewPolicyDialogueOpen, setIsCreateNewPolicyDialogueOpen] = useState(false);
  const [selectedPolicyToDuplicate, setSelectedPolicyToDuplicate] = useState<
    components["schemas"]["V1alpha1HpaPolicy"] | undefined
  >(undefined);
  const [selectedPolicyToEdit, setSelectedPolicyToEdit] = useState<
    components["schemas"]["V1alpha1HpaPolicy"] | undefined
  >(undefined);
  const [existingPolicyNames, setExistingPolicyNames] = useState<string[]>([]);

  const [policyToOpenOnInitialLoading, setPolicyToOpenOnInitialLoading] = useQueryParam(
    "policyToOpenOnInitialLoading",
    StringParam
  );

  const { data, isLoading, error } = useQuery<GetHPAPoliciesResponse, Error>({
    queryKey: [HPAPoliciesQuery.queryKey],
    queryFn: () => HPAPoliciesQuery.queryFn(),
  });

  const handleOpenPolicyDialogue = (params: Row["rowPolicyData"]) => {
    setSelectedPolicyToEdit(params);
  };

  useEffect(() => {
    if (data?.policies) {
      setExistingPolicyNames([...new Set(data.policies.map((policy) => String(policy.metadata?.name)))]);
    }
  }, [data]);

  useEffect(() => {
    let rowsData: Row[] | undefined = data?.policies?.map((policy, index) => {
      return {
        id: index,
        name: String(policy.metadata?.name),
        rowPolicyData: policy,
        isBuiltIn: isBuiltInPolicy(policy),
      };
    });

    // show default policies first
    rowsData = rowsData?.sort((a, b) => {
      if (a?.isBuiltIn && !b?.isBuiltIn) return -1;
      if (!a?.isBuiltIn && b?.isBuiltIn) return 1;
      return 0;
    });

    // show production policy first
    rowsData = rowsData?.sort((a, b) => {
      if (a.name === DEFAULT_POLICY_NAME && b.name !== DEFAULT_POLICY_NAME) return -1;
      if (a.name !== DEFAULT_POLICY_NAME && b.name === DEFAULT_POLICY_NAME) return 1;
      return 0;
    });

    setRows(rowsData ?? []);
  }, [data]);

  useEffect(() => {
    if (rows && rows.length > 0 && policyToOpenOnInitialLoading) {
      const policyToOpen = rows.find((row) => row.name === policyToOpenOnInitialLoading);

      if (policyToOpen) {
        handleOpenPolicyDialogue(policyToOpen.rowPolicyData);
      }
      setPolicyToOpenOnInitialLoading(undefined);
    }
  }, [rows, policyToOpenOnInitialLoading]);

  if (error) {
    console.log("error fetching HPA policies table:", error);
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full h-[50vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  let buttonTooltip = <YouHaveReadOnlyAccess />;
  if (!isReadyOnlyFrontEnd && userRole !== ADMIN_ROLE) {
    buttonTooltip = (
      <Typography variant="caption">
        You don't have <b>permission to create</b> new policy
      </Typography>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="w-full flex justify-between items-end">
          <PolicyPageTitleAndDescription scaleOpsProduct={ScaleOpsProduct.HPA} />
          <div>
            <Tooltip title={buttonTooltip} disabled={!(isReadyOnlyFrontEnd || userRole !== ADMIN_ROLE)} maxWidth={500}>
              <Button
                onClick={() => setIsCreateNewPolicyDialogueOpen(true)}
                label="Create new policy"
                fontSize={12}
                disabled={isReadyOnlyFrontEnd || userRole !== ADMIN_ROLE}
              />
            </Tooltip>
          </div>
        </div>
        <DataGrid
          rows={rows ?? []}
          columns={getColumns({
            policiesStats,
            isReadyOnlyFrontEnd,
            userRole,
            deleteHPAPolicy,
            setSelectedPolicyToDuplicate,
            setSelectedPolicyToEdit,
          })}
          sx={getDataGridSx()}
          hideFooter
          autoHeight
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={HEADER_HEIGHT}
          rowHeight={POLICY_ROW_HEIGHT}
          onRowClick={(params: GridRowParams<Row>) => {
            handleOpenPolicyDialogue(params.row.rowPolicyData);
          }}
          getRowClassName={(row: GridRowClassNameParams<Row>) => {
            if (row?.row?.isBuiltIn) return "cursor-pointer automatedRow";

            return "cursor-pointer";
          }}
        />
      </div>
      {selectedPolicyToDuplicate && (
        <DuplicateHPAPolicy
          selectedPolicyToDuplicate={selectedPolicyToDuplicate}
          setSelectedPolicyToDuplicate={setSelectedPolicyToDuplicate}
          existingPolicyNames={existingPolicyNames}
        />
      )}
      {selectedPolicyToEdit && (
        <EditAndCreateHPAPolicy
          isOpen={!!selectedPolicyToEdit}
          selectedPolicyToEdit={selectedPolicyToEdit}
          setSelectedPolicyToEdit={setSelectedPolicyToEdit}
          mutationType={HPAMutationType.UPDATE}
          existingPolicyNames={existingPolicyNames}
        />
      )}
      {isCreateNewPolicyDialogueOpen && (
        <EditAndCreateHPAPolicy
          isOpen={isCreateNewPolicyDialogueOpen}
          setIsOpen={setIsCreateNewPolicyDialogueOpen}
          mutationType={HPAMutationType.CREATE}
          existingPolicyNames={existingPolicyNames}
        />
      )}
    </>
  );
};

export default HPAPoliciesTable;
