import { useEffect } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

export enum ProvisionV2Types {
  CPU = "cpu",
  Memory = "memory",
  CPUAndMemory = "cpuAndMemory",
  CPUOrMemory = "cpuOrMemory",
}

export enum OverProvisionedTypes {
  overProvisionedCPU = "CPU over provisioned",
  overProvisionedMemory = "Memory over provisioned",
}

export enum UnderProvisionedTypes {
  underProvisionedCpu = "CPU under provisioned",
  underProvisionedMemory = "Memory under provisioned",
}

const useOverProvisionedFilter = () => {
  const [overProvisionedFilter, setOverProvisionedFilter] = useQueryParam("overProvisionedFilter", ArrayParam);
  const [underProvisionedFilter, setUnderProvisionedFilter] = useQueryParam("underProvisionedFilter", ArrayParam);
  const [overProvisioned2, setOverProvisioned2] = useQueryParam("overProvisionedV2", StringParam);
  const [underProvisioned2, setUnderProvisioned2] = useQueryParam("underProvisionedV2", StringParam);

  useEffect(() => {
    /**
     * Set Over Provision Filter V2
     */

    let overProvisionedFilterValue: ProvisionV2Types | undefined;

    switch (true) {
      case overProvisionedFilter?.includes(OverProvisionedTypes.overProvisionedCPU) &&
        overProvisionedFilter.length === 1:
        overProvisionedFilterValue = ProvisionV2Types.CPU;
        break;
      case overProvisionedFilter?.includes(OverProvisionedTypes.overProvisionedMemory) &&
        overProvisionedFilter.length === 1:
        overProvisionedFilterValue = ProvisionV2Types.Memory;
        break;
      case !!overProvisionedFilter?.length:
        overProvisionedFilterValue = ProvisionV2Types.CPUOrMemory;
        break;
      default:
        break;
    }
    setOverProvisioned2(overProvisionedFilterValue);

    /**
     * Set Under Provision Filter V2
     */

    let underProvisionedFilterValue: ProvisionV2Types | undefined;

    switch (true) {
      case underProvisionedFilter?.includes(UnderProvisionedTypes.underProvisionedCpu) &&
        underProvisionedFilter.length === 1:
        underProvisionedFilterValue = ProvisionV2Types.CPU;
        break;
      case underProvisionedFilter?.includes(UnderProvisionedTypes.underProvisionedMemory) &&
        underProvisionedFilter.length === 1:
        underProvisionedFilterValue = ProvisionV2Types.Memory;
        break;
      case !!underProvisionedFilter?.length:
        underProvisionedFilterValue = ProvisionV2Types.CPUOrMemory;
        break;
      default:
        break;
    }

    setUnderProvisioned2(underProvisionedFilterValue);
  }, [overProvisionedFilter, underProvisionedFilter]);

  return {
    overProvisioned2,
    underProvisioned2,
    underProvisionedFilter,
    overProvisionedFilter,
    setOverProvisionedFilter,
    setUnderProvisionedFilter,
  };
};

export default useOverProvisionedFilter;
