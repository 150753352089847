import { MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { SCALEOPS_COLORS } from "../../../colors";
import BulkIcon from "../../../Icons/BulkIcon";
import CircleIconWrapper from "../../../Icons/CircleIconWrapper";
import DownIcon from "../../../Icons/DownIcon";
import { MenuCategoryType } from "./utils";

interface Props extends MenuCategoryType {
  handleClose: () => void;
  showBottomBorder: boolean;
  openedCategory: string | null;
  setOpenedCategory: (categoryName: string | null) => void;
}

const MenuCategory = ({
  categoryName,
  categoryDescription,
  options,
  openedCategory,
  setOpenedCategory,
  handleClose,
  showBottomBorder,
}: Props) => {
  const isOpen = openedCategory === String(categoryName);

  return (
    <div
      className={clsx({
        "border-b border-border": showBottomBorder,
      })}
    >
      <Typography
        variant="body1"
        fontWeight={700}
        className="flex w-full items-center py-2 cursor-pointer group"
        onClick={() => setOpenedCategory(isOpen ? null : String(categoryName))}
      >
        <div className="grow group-hover:underline">{categoryName}</div>
        <DownIcon
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            transition: isOpen ? "transform 0.3s" : undefined,
          }}
        />
      </Typography>
      <div
        className="flex flex-col gap-2"
        style={{
          maxHeight: isOpen ? "100vh" : 0,
          overflow: "hidden",
          transition: "max-height 0.3s",
        }}
      >
        {categoryDescription && (
          <Typography
            variant="caption"
            className="border border-border rounded-lg p-4 flex items-center justify-start gap-2"
          >
            {categoryDescription?.icon || (
              <CircleIconWrapper
                className="text-white bg-primary-purpleBlue"
                icon={<BulkIcon width={14} height={14} />}
              />
            )}
            {categoryDescription?.content}
          </Typography>
        )}
        <div className="pb-2 ml-1.5">
          {options.map((option, index) => (
            <MenuItem
              key={`category-menu-item-${index}`}
              onClick={() => {
                option.onClick();
                handleClose();
              }}
              disabled={option.isDisabled}
              className="flex items-center justify-start gap-1.5"
              sx={
                option.emphasize
                  ? {
                      background: SCALEOPS_COLORS.guideline.darkGreen,
                      borderRadius: "0.5rem",
                      color: "white",
                      fontWeight: 700,
                      "&:hover": {
                        background: SCALEOPS_COLORS.guideline.darkerGreen,
                      },
                    }
                  : undefined
              }
            >
              {option.customOption || (
                <>
                  {option.icon}
                  {option.optionName}
                </>
              )}
            </MenuItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuCategory;
