import { toast } from "react-toastify";

export const TOAST_SETTINGS = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1000,
};

export enum INGRESS_PROGRESS {
  NONE = "",
  READY = "Ingress enabled",
  COULD_NOT_PING_INGRESS = "Failed to verify the ingress from the browser, please click the link below to verify the ingress",
  CREATED_IN_KUBERNETES = "Ingress created in Kubernetes",
  FAILED_TO_CREATE = "Failed to create ingress",
  FAILED_TO_DELETE = "Failed to delete ingress",
  DELETED = "Ingress deleted",
  READY_IN_KUBERNETES = "Ingress ready", // Ingress statusReady == true
  PINGING = "Querying ingress...", // From Fetcher in the browser
}
