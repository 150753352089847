import { IconProps } from "./utils/utils";

const PlanetIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#955BA5"
        />
        <path
          d="M2.47655 16.5931C2.73238 16.8909 3.1549 16.9824 3.51104 16.8172C6.38108 15.4037 8.99143 13.515 11.2317 11.231C13.5157 8.99074 15.4044 6.38038 16.8179 3.51034C16.9831 3.15421 16.8916 2.73169 16.5938 2.47586C15.9539 1.91578 15.2444 1.44069 14.4828 1.06241C10.6315 -0.871962 5.9741 -0.12063 2.92648 2.92667C-0.121132 5.97397 -0.872943 10.6313 1.06104 14.4828C1.44009 15.2442 1.91586 15.9535 2.47655 16.5931Z"
          fill="#955BA5"
        />
        <path
          d="M2.92658 12.4076C3.40707 12.4076 3.79658 12.0181 3.79658 11.5376C3.79658 11.0571 3.40707 10.6676 2.92658 10.6676C2.44609 10.6676 2.05658 11.0571 2.05658 11.5376C2.05658 12.0181 2.44609 12.4076 2.92658 12.4076Z"
          fill="#B49ACA"
        />
        <path
          d="M17.0735 13.6379C17.554 13.6379 17.9435 13.2484 17.9435 12.7679C17.9435 12.2875 17.554 11.8979 17.0735 11.8979C16.593 11.8979 16.2035 12.2875 16.2035 12.7679C16.2035 13.2484 16.593 13.6379 17.0735 13.6379Z"
          fill="#B49ACA"
        />
        <path
          d="M8.15486 10.6897C8.8755 10.6897 9.45969 10.1055 9.45969 9.38484C9.45969 8.66421 8.8755 8.08002 8.15486 8.08002C7.43423 8.08002 6.85004 8.66421 6.85004 9.38484C6.85004 10.1055 7.43423 10.6897 8.15486 10.6897Z"
          fill="#B49ACA"
        />
        <path
          d="M15.5587 18.3138V18.3172C14.4335 19.0706 13.1662 19.5858 11.8345 19.831C11.1444 19.1304 11.0102 18.0539 11.5074 17.2054C12.0045 16.3568 13.0092 15.9475 13.9578 16.2071C14.9064 16.4666 15.5627 17.3304 15.5587 18.3138Z"
          fill="#B49ACA"
        />
        <path
          d="M5.18829 1.23656C5.18002 1.34001 5.17243 1.44345 5.17243 1.55173C5.16962 3.10453 6.07339 4.5161 7.48483 5.16342C8.89627 5.81073 10.5557 5.57469 11.7306 4.55946C12.9056 3.54423 13.3799 1.93664 12.9442 0.446213C10.3591 -0.357786 7.558 -0.0723411 5.18829 1.23656Z"
          fill="#B49ACA"
        />
        <path
          d="M18.9379 5.51725L18.9414 5.5207C18.0104 7.86208 16.0586 10.7069 13.3828 13.3828C10.7069 16.0586 7.86209 18.0104 5.52071 18.9414L5.51726 18.9379C3.54829 19.7173 1.93795 19.7724 1.08278 18.9173C0.227604 18.0621 0.281742 16.4507 1.06105 14.4828C1.45403 15.2659 1.94827 15.994 2.53105 16.6483C2.53376 16.6544 2.53868 16.6594 2.54485 16.6621C2.80415 16.9074 3.18724 16.969 3.51036 16.8173C6.38017 15.4033 8.99047 13.5147 11.2311 11.231C13.5147 8.99046 15.4033 6.38017 16.8173 3.51036C16.969 3.18723 16.9075 2.80414 16.6621 2.54484C16.6594 2.53868 16.6545 2.53375 16.6483 2.53105C15.9983 1.94309 15.2695 1.44877 14.4828 1.06242C16.4517 0.283114 18.0614 0.227597 18.9166 1.08277C19.7717 1.93794 19.7173 3.54829 18.9379 5.51725Z"
          fill="#FF5364"
        />
        <path
          d="M8.96549 4.13792C8.77505 4.13792 8.62067 3.98354 8.62067 3.7931C8.62067 3.60265 8.77505 3.44827 8.96549 3.44827C9.91724 3.44713 10.6885 2.67587 10.6896 1.72413C10.6896 1.53369 10.844 1.3793 11.0345 1.3793C11.2249 1.3793 11.3793 1.53369 11.3793 1.72413C11.3778 3.0566 10.298 4.1364 8.96549 4.13792Z"
          fill="#955BA5"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlanetIcon;
