import prettyBytes from "pretty-bytes";

/*
  Graph headroom - used by y-axis domain
*/
export const MAX_Y_AXIS_DOMAIN = 1.2;

export const tickNumberFormatter = (value: string) => {
  const val = Number(value);
  if (val >= 1000000000000) {
    return `${Math.round((val / 1000000000000) * 10) / 10}t`;
  } else if (val >= 1000000000) {
    return `${Math.round((val / 1000000000) * 10) / 10}b`;
  } else if (val >= 1000000) {
    return `${Math.round((val / 1000000) * 10) / 10}m`;
  } else if (val >= 1000) {
    return `${Math.round((val / 1000) * 10) / 10}k`;
  } else {
    return value;
  }
};

export const memoryDataParser = (value: number) =>
  value && value >= 0 && value < Infinity && value > -Infinity
    ? prettyBytes(value, { bits: false, binary: true })
    : "0 B";

// Make sanke case legend text to title and remove underscores
export const formatLegendText = (key: string, regexp?: string) => {
  const formattedKey = key
    .replace(regexp ?? "", "")
    ?.split("_")
    ?.join(" ");
  return formattedKey.charAt(0) + formattedKey.slice(1);
};

export enum OverviewLinkType {
  Workload = "workload",
  Node = "node",
  MultiCluster = "multiCluster",
}

export enum ResourseOverTimeTabOptions {
  SevenDays = "7 Days",
  ThirtyDays = "30 Days",
}
