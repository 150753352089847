import { IconProps } from "./utils/utils";

const LockIcon = ({ width = 20, height = 20, className }: IconProps) => {
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20">
      <path d="M9.99999 0.588226C7.05881 0.588226 4.70587 2.94117 4.70587 5.88234V8.23529C3.70587 8.23529 2.94116 8.99999 2.94116 9.99999V17.6471C2.94116 18.6471 3.70587 19.4118 4.70587 19.4118H15.2941C16.2941 19.4118 17.0588 18.6471 17.0588 17.6471V9.99999C17.0588 8.99999 16.2941 8.23529 15.2941 8.23529V5.88234C15.2941 2.94117 12.9412 0.588226 9.99999 0.588226ZM15.8823 9.99999V17.6471C15.8823 18 15.647 18.2353 15.2941 18.2353H4.70587C4.35293 18.2353 4.11763 18 4.11763 17.6471V9.99999C4.11763 9.64705 4.35293 9.41176 4.70587 9.41176H5.2941H14.7059H15.2941C15.647 9.41176 15.8823 9.64705 15.8823 9.99999ZM5.88234 8.23529V5.88234C5.88234 3.58823 7.70587 1.7647 9.99999 1.7647C12.2941 1.7647 14.1176 3.58823 14.1176 5.88234V8.23529H5.88234Z" />
      <path d="M10 11.1765C9 11.1765 8.23529 11.9412 8.23529 12.9412C8.23529 13.7059 8.70588 14.3529 9.41176 14.5882V15.8823C9.41176 16.2353 9.64706 16.4706 10 16.4706C10.3529 16.4706 10.5882 16.2353 10.5882 15.8823V14.5882C11.2941 14.3529 11.7647 13.7059 11.7647 12.9412C11.7647 11.9412 11 11.1765 10 11.1765ZM10 13.5294C9.64706 13.5294 9.41176 13.2941 9.41176 12.9412C9.41176 12.5882 9.64706 12.3529 10 12.3529C10.3529 12.3529 10.5882 12.5882 10.5882 12.9412C10.5882 13.2941 10.3529 13.5294 10 13.5294Z" />
    </svg>
  );
};

export default LockIcon;
